//import React from "react";
import { NavigateFunction, createBrowserRouter, RouterProvider, RouteObject, Navigate } from "react-router-dom";

import { default as DefLayout } from '../Layout/def_layout'
import CloudpanelLayout from "../Layout/cloudpanel_Layout";
import ToolspanelLayout from "../Layout/toolspanel_Layout";
//import TabMenu from "../tabMenu";

import { Def, SubPage, Login, UserInformations} from "../../Containers/"

import { Pages as GlobalMenu_Pages, Router as GlobalMenu_Router, NavigationFunction as Global_Nav} from "./CloudPanel/globalMenu_Router"
import { Pages as SystemMenu_Pages, Router as SystemMenu_Router, NavigationFunction as System_Nav} from "./CloudPanel/systemMenu_Router"
import { Pages as ToolsMenu_Pages, Router as ToolsMenu_Router, NavigationFunction as Tools_Nav} from "./CloudPanel/toolsMenu_Router"
import { useUser } from "../../Services";

/* eslint-disable */
export enum RootPages {
  def="/",
  login="/login",
  userInformations="/userInformations",
  //cloud="/cloud", #Has no page ... is CloudPanel routers..
  subPage="/subpage",
}
export type Pages = RootPages | GlobalMenu_Pages | SystemMenu_Pages | ToolsMenu_Pages
export const Pages = {
  ...RootPages,
  ...GlobalMenu_Pages,
  ...SystemMenu_Pages,
  ...ToolsMenu_Pages,
}
/* eslint-enable */

const rootRouter = (userToken?:string) => [
  { 
    path: "/",
    element: <DefLayout/>,
    //loader: rootLoader,  //#prefetch loader #See https://reactrouter.com/en/main/route/loader
    children: [
      {index:true, element: <Def />},
      {path:RootPages.subPage, element: <SubPage />}
    ],
  },
  {
    path: RootPages.login,
    element: <Login/>, //MultiContainer .. Login / Create / SiteList
  },
  {
    element: <ToolspanelLayout />,
    children: [ //ToolsPanel Routers
      ...ToolsMenu_Router
   ]
  },
  {
    path: RootPages.userInformations,
    element: <UserInformations/>, //MultiContainer .. Login / Create / SiteList
  },
  {
    //path: "/cloud", #Has no page ... <ProtectedRoute isAuthenticated={isAuthenticated()} /> //TODO: -- protect route
    path: "/",
    element: userToken ? <CloudpanelLayout /> : <Navigate to="/" />,
    children: [ //CloudPanel Routers
      ...GlobalMenu_Router,
      ...SystemMenu_Router
    ]
  },
  {
    path:'*',
    element: (<Navigate to="/" />)
  }
] as RouteObject[]

export const NavigationFunction = (Navigator: NavigateFunction, navigation_page:Pages, navigation_sub?:Pages, replace:boolean=false, navArgs?:object) => {
  switch (navigation_page) {
    case RootPages.def:
      Navigator(RootPages.def, {replace:replace})
    break
    case RootPages.login:
      Navigator(RootPages.login)
    break
    case RootPages.subPage:
      Navigator(RootPages.subPage)
    break
    case RootPages.userInformations:
      Navigator(RootPages.userInformations)
    break
    default:
      if (Object.values(GlobalMenu_Pages).includes(navigation_page as any)) {
        Global_Nav(Navigator, navigation_page as GlobalMenu_Pages, navigation_sub as GlobalMenu_Pages, navArgs)
      }
      else if (Object.values(SystemMenu_Pages).includes(navigation_page as any)) {
        System_Nav(Navigator, navigation_page as SystemMenu_Pages, navigation_sub as SystemMenu_Pages)
      }
      else if (Object.values(ToolsMenu_Pages).includes(navigation_page as any)) {
        Tools_Nav(Navigator, navigation_page as ToolsMenu_Pages, navigation_sub as ToolsMenu_Pages)
      }
      else {
        console.error("Error page not found (404)???", navigation_page, navigation_sub)
        //alert("Error page not found (404)???")
      }
  }
}

export default function Root() {
  const {userToken} = useUser()
  return(
    <RouterProvider router={createBrowserRouter(rootRouter(userToken))} />
  )
}
