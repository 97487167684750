import { CellContext } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { EditAbleCell, StyledTableCellProps } from "./styledTableCell";
import { colors, css, fonts } from "../../../Styles";
import { SimplifiedInputField } from "../../BaseComponents/SimplifiedInputField";
import { getGraphColor } from "../../../Styles/colors";
import { IdentifiableRow } from "../reactiveTableRenderer";


export const DivTableCell = <T extends IdentifiableRow,> ({getValue, row, table, column, canEdit, staticColumn}: CellContext<T, string | undefined> & EditAbleCell) => {
  const initialValue = useMemo(() => getValue(), [getValue]);

  const [value, setValue] = useState(initialValue);
  const key = `${row.original.id}-${column.id}`;
  const onBlur = () => {
    if (value !==initialValue) {
      table.options.meta?.updateData(row.original.id, column.id, value)
    }
  }

  return <StyledDivCell staticColumn={staticColumn} key={key} keyPass={key+"input"} onBlur={onBlur} onChange={(e: string) => setValue(e)} canEdit={canEdit} cellData={{value, style:{backgroundColor: getGraphColor(row.original.id), ...css.colorTag, ...fonts.header, ...fonts.fontSize4, color: colors.whitePrimary}}} />
}


const StyledDivCell = ({onBlur, onChange, canEdit, cellData, keyPass, staticColumn}: StyledTableCellProps & EditAbleCell) => {
  if (canEdit) {
    if (staticColumn) { 
      return (
        <div style={{ ...cellData.style, opacity: 0.5  }} key={keyPass}>
          {String(cellData.value)}
        </div>
      );
    } else {
      return <SimplifiedInputField
        keyPass={keyPass}
        context={cellData.value}
        fieldStyle="tableEdit"
        fieldType="tableEditField"
        textStart={true}
        isTableEditField={true}
        inputType="string"
        onChange={onChange}
        onBlur={onBlur}
        key={keyPass}
        initialIsFocused={!cellData.value ? true: false}
      />;
    }
  }

  return (
    <div style={cellData.style} key={keyPass}>
      {String(cellData.value)}
    </div>
  )
} 
