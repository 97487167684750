import { TestData as AnimalData } from './animalData'; // Adjust the import path as needed
import { Pens, TestData as PenData } from './lf_Data'

// Define the structure of a Section 
export interface Section {
  id: number; 
  pens: Pens[]
}

// Sample data: List of sections with their animals
export const Sections: Section[] = [
  { 
    id: 1,
    pens: PenData.filter(pen => [300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319].includes(pen.penNo))
  },{
    id: 2,
    pens: PenData.filter(pen => [320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339].includes(pen.penNo))
  },{ 
    id: 3,
    pens: PenData.filter(pen => [340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359].includes(pen.penNo))
  },{ 
    id: 4,
    pens: PenData.filter(pen => [360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379].includes(pen.penNo))
  },{ 
    id: 5,
    pens: PenData.filter(pen => [380, 381, 382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 398, 399].includes(pen.penNo))
  },{
    id: 6,
    pens: PenData.filter(pen => [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 419].includes(pen.penNo))
  },{
    id: 7,
    pens: PenData.filter(pen => [420, 421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439].includes(pen.penNo))
  },
  {
    id: 8,
    pens: PenData.filter(pen => [440, 441, 442, 443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459].includes(pen.penNo))
  },
  {
    id: 9,
    pens: PenData.filter(pen => [460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472, 473, 474, 475, 476, 477, 478, 479].includes(pen.penNo))
  },
  {
    id: 10,
    pens: PenData.filter(pen => [480, 481, 482, 483, 484, 485, 486, 487, 488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499].includes(pen.penNo))
  },
  {
    id: 11,
    pens: PenData.filter(pen => [500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517, 518, 519].includes(pen.penNo))
  },
  {
    id: 12,
    pens: PenData.filter(pen => [520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532, 533, 534, 535, 536, 537, 538, 539].includes(pen.penNo))
  },
  {
    id: 13,
    pens: PenData.filter(pen => [540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559].includes(pen.penNo))
  },
  {
    id: 14,
    pens: PenData.filter(pen => [560, 561, 562, 563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578, 579].includes(pen.penNo))
  },
  {
    id: 15,
    pens: PenData.filter(pen => [580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592, 593, 594, 595, 596, 597, 598, 599].includes(pen.penNo))
  },
  {
    id: 16,
    pens: PenData.filter(pen => [600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619].includes(pen.penNo))
  },
  {
    id: 17,
    pens: PenData.filter(pen => [620, 621, 622, 623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637, 638, 639].includes(pen.penNo))
  },
  {
    id: 18,
    pens: PenData.filter(pen => [640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652, 653, 654, 655, 656, 657, 658, 659].includes(pen.penNo))
  },
  {
    id: 19,
    pens: PenData.filter(pen => [660, 661, 662, 663, 664, 665, 666, 667, 668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679].includes(pen.penNo))
  },
  {
    id: 20,
    pens: PenData.filter(pen => [680, 681, 682, 683, 684, 685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697, 698, 699].includes(pen.penNo))
  }
];

export const Sections2: Section[] = [
  { 
    id: 1,
    pens: PenData.filter(pen => [300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319].includes(pen.penNo))
  },{
    id: 2,
    pens: PenData.filter(pen => [320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339].includes(pen.penNo))
  },{ 
    id: 3,
    pens: PenData.filter(pen => [340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359].includes(pen.penNo))
  },{ 
    id: 4,
    pens: PenData.filter(pen => [360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379].includes(pen.penNo))
  },{ 
    id: 5,
    pens: PenData.filter(pen => [380, 381, 382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 398, 399].includes(pen.penNo))
  }
];

// Function to get a section by ID
export function getSectionById(sectionId: number) {
  const section = Sections.find(section => section.id === sectionId);

  if (!section) return null;

  // Get detailed animal data for each pen in the section
  const pensWithAnimals = section.pens.map(pen => {
    // Find the corresponding animal based on pen's animal number
    const animalData = AnimalData.find(animal => animal.animalNo === pen.animal);
    return {
      ...pen,
      animal: animalData ? animalData : null // Add the animal data to the pen
    };
  });

  return {
    ...section,
    pens: pensWithAnimals // Return the section with pens including animal data
  };
}
