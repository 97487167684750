import React from "react";

interface StyleSheet {
    [key: string]: React.CSSProperties;
}

const styles: StyleSheet = {        
    header: {
        fontFamily: "Inter Flex Bold",
        lineHeight: "normal", 
        margin: "0",
        textTransform: "uppercase",
    } as React.CSSProperties,

    textShadow:{
        textShadow: "1px 1px 4px #00000060"
    }as React.CSSProperties,
 
    paragraph: {
        fontFamily: "Inter Flex Regular",
        lineHeight: "normal", 
        margin: "0",
    }  as React.CSSProperties,

    fontWeight0: {
        fontWeight: "100"
    } as React.CSSProperties,
    fontWeight1: {
        fontWeight: "300"
    } as React.CSSProperties,
    fontWeight2: {
        fontWeight: "600"
    } as React.CSSProperties,

    fontSize0: {
        fontSize: "35px"
    } as React.CSSProperties,
    fontSize1: {
        fontSize: "22px"
    } as React.CSSProperties,
    fontSize2: {
        fontSize: "18px"
    } as React.CSSProperties,
    fontSize3: {
        fontSize: "14px"
    } as React.CSSProperties,
    fontSize4: {
        fontSize: "12px"
    } as React.CSSProperties,
    fontSize5: {
        fontSize: "10px"
    } as React.CSSProperties,
    fontSize6: {
        fontSize: "8px"
    } as React.CSSProperties,
}

export default styles
