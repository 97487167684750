import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { icons,  Units, frames } from '../../../Styles';
import { ListData_ValueTypes, TableStyles_Types } from '../../../Definitions'
import { ButtonList, ButtonTypes } from '../../../Components';
import { FeedComponentInfo } from '../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedComponentsCall';
import { ReactiveTable } from '../../../Components/Tables/reactiveTableRenderer';
import { FeedCurveInfo } from '../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall';
import Graph_QL, { DynamicDataObk } from '../../../Services/Agrisys_GqlDataCaller';
import { convertDenseToSparse, FeedCurveTableData } from './convertSparseMixtureData';
import { useEditableColumnConstructor } from '../../../Components/Tables/_Tables/useEditableColumnConstructor';

interface FeedCurveTableProps {
  showFeedCurve: FeedCurveInfo,
  feedComponents: FeedComponentInfo[],
  feedCurveComponentIds: number[],
  maintainEntries?: (data: (Partial<DynamicDataObk<any>> | Partial<DynamicDataObk<any[]>>)) => void;
  setDisableCurveNavigation: Dispatch<SetStateAction<boolean>>;
  feedCurveDispatch: Dispatch<SetStateAction<FeedCurveInfo|undefined>>;
  connection: Graph_QL.ConnectionObj;
}



export const FeedCurveTable = ({showFeedCurve, feedComponents, feedCurveComponentIds, setDisableCurveNavigation, maintainEntries, connection, feedCurveDispatch,}: FeedCurveTableProps) => {
  const [editTable, setEditTable] = useState(false);
  const [isPercentileEdit, setIsPercentileEdit] = useState(false);
  const [mixtureData, setMixtureData] = useState<FeedCurveTableData[]>([]);
  const [deleted, setDeleted] = useState<number[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    if (showFeedCurve) {
      setDeleted([])
    }
  },[showFeedCurve])

  useEffect(() => {
    const sparseData = convertDenseToSparse(showFeedCurve.mixture, showFeedCurve.milestones, feedCurveComponentIds, isPercentileEdit)
    setMixtureData(Array.from(sparseData.values()));
  },[feedCurveComponentIds, isPercentileEdit, showFeedCurve.milestones, showFeedCurve.mixture])

  const milestoneOptions = useMemo(() => (showFeedCurve.milestones.map((milestone) => ({label: milestone.milestone}))),[showFeedCurve.milestones])

  const accessorData = useMemo(() => ([
    {headerValue: t("feedCurve.table.header.day", {defaultValue: "Day"}), accessor: "id", type: ListData_ValueTypes.Number,},
    {headerValue: t("feedCurve.table.header.dryStuff", {defaultValue: "DS"}), accessor: "ds", type: ListData_ValueTypes.Number, staticColumn: true, unit: Units.pct_white},
    {headerValue: t("feedCurve.table.header.totalAmount", {defaultValue: "Total"}), accessor: "totalAmount", type: ListData_ValueTypes.Number, staticColumn: !isPercentileEdit, unit: Units.kG_white},
    {headerValue: t("feedCurve.table.header.milestones", {defaultValue: "Milestones"}), accessor: "milestone", type: ListData_ValueTypes.List, options: milestoneOptions,},
    {accessor: "removeRowActionColumn", headerValue: "notApplicable", width: "56px", type: ListData_ValueTypes.DeleteRowAction},
    ...feedCurveComponentIds.map((id: number) => ({accessor: String(id), staticColumn: isPercentileEdit, unit: isPercentileEdit ? Units.pct_white : Units.kG_white, idForColor: id, type: ListData_ValueTypes.Number, width: "minmax(190px, 1fr)", headerValue: feedComponents.find((component) => component.id === id)?.name ?? String(id)}))])
  ,[feedCurveComponentIds, feedComponents, isPercentileEdit, milestoneOptions, t])
  const columns = useEditableColumnConstructor<FeedCurveTableData>({accessorData: [...accessorData], tableStyle: TableStyles_Types.feedCurveTable, canEdit: editTable, })

  const table = useReactTable<FeedCurveTableData>({
    columns,
    data: mixtureData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnOrder: ["milestone","id", ...feedCurveComponentIds.map((id)=>(String(id))), "removeRowActionColumn", "totalAmount", "ds"],
      columnPinning: {
        left: ["milestone", "id"],
        right: ["totalAmount", "ds"],
      },
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {console.log(rowIndex, columnId)},
      deleteRow: (rowId) => {
        setDeleted(old => {old.push(rowId); return old})
        setMixtureData(old => (old.filter((mixture) => mixture.id !== rowId)));
      },
      allowDeleteRowCheck: (row) => {return (!row.original.milestone)}
    }
  })
  
  const switchTable = () => {
    setDisableCurveNavigation(!editTable);
    setDeleted([]);
    setEditTable((editTable) => !editTable);
  };

  const saveChanges = () => {
    setDisableCurveNavigation(!editTable);
    setEditTable((editTable) => !editTable);
    if (maintainEntries) {
      maintainEntries({con: connection, data: [{...showFeedCurve, mixture: showFeedCurve.mixture.filter((mixture) => !deleted.includes(mixture.day))}]})
    }
  }

  const togglePercentileEdit = () => {
    setIsPercentileEdit((old) => !old)
  }

  const percentileEditHeader = isPercentileEdit ? t("units.kg", {defaultValue: "kg"}) : t("units.pct", {defaultValue: "pct"})

  const percentileEditIcon = isPercentileEdit ? icons.kg_white : icons.kg_white

  return (
    <frames.feedCurveTableContainer>
      {/* FeedCurveTable Btns (toggle, edit...) */}
      <div style={{ backgroundColor: "transparent", display: "flex", justifyContent: "end", padding: "0px 20px", gap: "5px" }}>
        {!editTable ?
          <ButtonList
            content={[percentileEditHeader, t("buttons.edit"),]}
            onClick={(i => {
              if (i === 1) switchTable();
              if (i === 0) togglePercentileEdit();
            })}
            startEnhancers={[percentileEditIcon, icons.edit_white]}
            overrides={{
              button: { buttonType: ButtonTypes.tableButton }
            }}
          />
          :
          <ButtonList
            content={[ percentileEditHeader, t("buttons.saveBtn"), t("buttons.cancelBtn")]}
            onClick={(i => {
              if (i === 0) togglePercentileEdit();
              if (i === 1) saveChanges();
              if (i === 2) switchTable();
            })}
            startEnhancers={[percentileEditIcon, icons.check_white, icons.close]}
            overrides={{
              button: { buttonType: ButtonTypes.tableButton }
            }}
          />
        }
      </div>
      {/* FeedCurveTable */}
      <ReactiveTable table={table} tableType={TableStyles_Types.feedCurveTable} emptyText={t("feedTable.emptyText", {defaultValue: "Press edit to start configuring the feed curve"})} />
    </frames.feedCurveTableContainer>
  )
}