import React from "react";
import * as mediaQueries from "./mediaQueries"
import { default as fonts } from "./fonts"
import { colors } from "./colors"

interface StyleSheet { 
  [key: string]: React.CSSProperties;
}

const styles: StyleSheet = {        
  defBg: {
    zIndex: "1",
    width: "100%",
    position: "absolute",
    bottom: "0",
  } as React.CSSProperties,

  // "h1", header for container content and modal header
  topHeader: {
    ...fonts.header,
    ...fonts.textShadow,
    ...fonts.fontSize0,
    color: colors.greyLightTertiary,
  } as React.CSSProperties,

  // "h2", subheader for container content
  subHeader: {
    ...fonts.header,
    ...fonts.textShadow,
    ...fonts.fontSize3,
    color: colors.greyLightTertiary,
  } as React.CSSProperties,

  unitsFontstyle:{
    ...fonts.header,
    ...fonts.fontSize4,
    color: colors.greyPrimary
  } as React.CSSProperties,

  // Specifik textStyles
  progressBarLabel:{
    margin: "0", 
    display: "flex", 
    alignItems: "center",
    height: "13px",
    ...fonts.header, 
    ...fonts.textShadow, 
    ...fonts.fontSize5, 
    color: colors.whitePrimary,
  } as React.CSSProperties,
  progressBarText:{
    margin: "0", 
    display: "flex", 
    alignItems: "center",
    height: "30px",
    ...fonts.paragraph, 
    ...fonts.fontsize4,
    color: colors.greyDarkPrimary,
  } as React.CSSProperties,
  colorTag:{
    padding: "4px 5px", 
    width: "fit-content", 
    borderRadius: "6px",
  } as React.CSSProperties,
  backdropFilter:{
    position: "absolute", 
    width: "100%", 
    height: "100%", 
    backgroundColor: "black", 
    opacity: "0.5",
    top: "0", 
    left: "0"
  } as React.CSSProperties,
  // ---------------------------------------------------
  // #endregion

  // #region | Table grids
  grid_twoRows_top: {
    backgroundColor: colors.greyPrimary, 
    display: "flex", 
    flexDirection: "column", 
    alignItems: "start", 
    justifyContent: "end", 
    gap: "10px", 
    padding: "10px"
  } as React.CSSProperties,
  grid_twoRows_content: {
    border: "5px solid", 
    borderColor: colors.greyPrimary,
    backgroundColor: colors.greyLightTertiary, 
    overflowY: "auto"
  } as React.CSSProperties,

  // ---------------------------------------------------
  // #endregion

  // #region | Button
  primaryButton: {
    width: "270px",
    height: "50px",
    backgroundColor: colors.greyDarkPrimary,
    padding: "0 10px",
    justifyContent: "start",
    color: colors.greyLightTertiary
  } as React.CSSProperties,
  primaryButtonHover:{
    backgroundColor: colors.blueDarkPrimary
  } as React.CSSProperties,
  primaryButtonEnhancer: {
    width: "40px",
    marginRight: "10px"
  } as React.CSSProperties,

  globalMenuButton: {
    width: "130px",
    height: "80px",
    backgroundColor: colors.greyDarkPrimary,
    gap: "5px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    color: colors.greyLightTertiary
  } as React.CSSProperties,
  globalMenuButtonHover: {
    backgroundColor: colors.blueDarkPrimary
  } as React.CSSProperties,
  globalMenuButtonEnhancer: {
    width: "40px",
  } as React.CSSProperties,

  tabButton: {
    width: "fit-content",
    height: "40px",
    backgroundColor: colors.greyDarkPrimary,
    justifyContent: "center",
    borderRadius: "8px 8px 0px 0px",
    color: colors.greyLightTertiary,
    [mediaQueries.mediaQueries.tablet]: {
        borderRadius: "8px 8px 0px 0px",
    }
  } as React.CSSProperties,
  // tabletmenueffect:{
  //     transition: "1s ease",
  //     display: "none",  
  // }as React.CSSProperties,
  tabButtonHover: {
    backgroundColor: colors.blueDarkPrimary,
    height: "45px",
    paddingTop: "10px"
  } as React.CSSProperties,

  tableButton: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    minWidth: "50px",
    height: "50px",
    justifyContent: "end",
    padding: "0px 5px 2px 5px",
    borderRadius: "8px 8px 0px 0px",
  } as React.CSSProperties,
  tableButtonEnhancer: {
    width: "20px",
    marginBottom: "5px"
  } as React.CSSProperties,

  secondaryButton: {
    justifyContent: "center",
    maxWidth: "270px",
    minWidth: "130px",
    width: "auto"
  } as React.CSSProperties,
  secondaryButtonHover: {
    backgroundColor: colors.blueDarkPrimary
  } as React.CSSProperties,

  tertiaryButton: {
    justifyContent: "center"
  } as React.CSSProperties,
  tertiaryButtonHover: {
    backgroundColor: colors.blackPrimary
  } as React.CSSProperties,

  textButton: {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "transparent",
    fontSize: "14px",
    fontFamily: "Inter Flex Bold",
    color: colors.greyLightTertiary,
    padding: "0px",
    textTransform: "uppercase",
  } as React.CSSProperties,
  textButtonHover: {
    opacity: "0.5",
    transition: "0.2s ease"
  } as React.CSSProperties,
  textButtonEnhancer: {
    width: "20px",
    marginRight: "5px"
  } as React.CSSProperties,
  
  // ---------------------------------------------------
  // #endregion

  // #region | Language Selector
  languageSelector_primary: {
    color: colors.greyLightTertiary,
    backgroundColor: colors.greyDarkPrimary,
  } as React.CSSProperties,

  languageSelector_secondary: {
    backgroundColor: "transparent",
  } as React.CSSProperties,
  // ---------------------------------------------------
  // #endregion

  // #region | Modals
  modal: {
    backgroundColor: colors.blueDarkPrimary,
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    maxHeight: "90vh",
    overflow: "hidden"
  } as React.CSSProperties,
  modalInner_primary: {
    margin: "0", 
    padding: "0", 
    borderRadius: "0px 0px 10px 10px",
    maxHeight: "70vh",

  } as React.CSSProperties,
  modalInner_secondary: {
    margin: "0", 
    padding: "0", 
    borderRadius: "0px 0px 10px 10px",
    maxHeight: "70vh", 

    // overflowX: "hidden",
    scrollbarColor: `${colors.greyLightSecondary} transparent`,
    scrollbarWidth: "thin",
  } as React.CSSProperties,
  modal_primary: {
    padding: "60px"
  } as React.CSSProperties,
  modal_secondary: {
    padding: "40px 5px 5px 5px",
  } as React.CSSProperties,
  MaskToggleIcon: {
    width: "30px",
    transition: "0.1s",
    cursor: "pointer"
  } as React.CSSProperties,
  inputFieldEnhancer: {
    width: "25px",
    transition: "0.1s",
  } as React.CSSProperties,
  // ---------------------------------------------------
  // #endregion

  // #region | Freeda Location feeder
  modalTop: {
    display: "flex", 
    flexDirection: "row", 
    alignItems: "start", 
    justifyContent: "center", 
    gap: "60px"
  } as React.CSSProperties,
  feedCorrectionBtn: {
    backgroundColor: colors.greyDarkPrimary, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center",
    cursor: "pointer",
  } as React.CSSProperties,
  penHeaders: {
    ...fonts.header, 
    ...fonts.fontSize4, 
    color: colors.greyPrimary,
    paddingBottom: "2px"
  } as React.CSSProperties,
  // ---------------------------------------------------
  // #endregion

  // #region | TableList, TableStyles
  tableList_root: {
    height: "100%",
    // minWidth: "430px", OBS this will destroy scroll if used in modal
    width: "100%",
    borderRadius: "10px",
    // backgroundColor: colors.greyLightSecondary
  } as React.CSSProperties,

  tableStyle: {
    maxHeight: "70vh",
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    borderRadius: "10px",
    overflow: "auto",
    scrollbarColor: colors.greyLightSecondary+"transparent",
    scrollbarWidth: "thin",
    cursor: "default"
  } as React.CSSProperties,
  headCellStyle: {
    boxShadow: "none",
    backgroundColor: "transparent",
    alignItems: "center",
    padding: "2px 0px 2px 16px",
    border: "none",
    ...fonts.header,
    ...fonts.fontSize3,
    cursor: "default"
  } as React.CSSProperties,
  bodyCellStyle: {
    minWidth: "150px",
    height: "40px",
    padding: "0px 16px",
    color: colors.blackPrimary,
    alignContent: "center",
    ...fonts.paragraph,
    ...fonts.fontSize4,
    cursor: "default"
  } as React.CSSProperties,
  bottomCellStyle: {
    height: "20px",
    backgroundColor: "transparent",
    padding: "2px 0px 2px 16px",
    border: "none",
    alignItems: "center",
    boxShadow: "none",
    position: "sticky",
    bottom: "0",
    zIndex: "2",
    ...fonts.header,
    ...fonts.fontSize3,
    cursor: "default"
  } as React.CSSProperties,

  span: {
    "::[title]:hover:after": {
        padding: "40px"
    }
  } as React.CSSProperties,
  // ---------------------------------------------------
  // #endregion

  // #region | FeedCurve
  infobox: {
    width: "170px",
    // height: "237px",
    height: "150px",
    padding: "10px", 
    borderRadius: "10px", 
    backgroundColor: colors.greyDarkPrimary, 
    display: "flex", 
    flexDirection: "column", 
    gap: "10px", 
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: colors.greyDarkSecondary+colors.transparent
  } as React.CSSProperties,
  // ---------------------------------------------------
  // #endregion

  page_inner_content_overflow_scroll: {
    height: "100%", 
    width: "100%", 
    overflowY: "scroll",     
    scrollbarColor: colors.greyDarkPrimary + colors.transparent,
    scrollbarWidth: "thin"
  } as React.CSSProperties,
}

export default styles