import { styled } from "baseui";
import { colors } from "./colors";
import { decors } from "./images";
import { mediaQueries } from "./mediaQueries";

// A frame is a styling of an entire element, such as a div.

export const AbsoluteTopRight = styled("div", {
    position:"absolute",
    right:"0px",
    zIndex: "2"
});

// Def/Login flow Layout
export const Def = styled("div", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: "1",
    position: "relative",
    height: "90vh"
});

export const DecorBg = styled("div", {
    backgroundImage: `url(${decors.def_bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh"
});

// #region | CloudPanel Grid
// CloudPanel Layout
const _gridTemplateAreas_cloudPanel = `
  "logoDisplay page_outer page_outer"
  "globalMenu page_outer page_outer"
  "systemMenu page_outer page_outer"
  "statusBar statusBar languageSelector"
`;

const _gridTemplateAreas_cloudPanel_tablet = `
  "page_outer"`;

export const CloudPanel = styled("div", { 
    display: "Grid",
    gridTemplateColumns: "300px 1fr 280px",
    gridTemplateRows: "150px 350px 1fr 45px",
    gap: "5px",
    gridTemplateAreas: _gridTemplateAreas_cloudPanel,
    padding: "20px",
    height: "96vh",
    [mediaQueries.screenHeightMax]: {
        gridTemplateRows: "150px 190px 1fr 45px",
    },
    [mediaQueries.tablet]: {
        gridTemplateAreas: _gridTemplateAreas_cloudPanel_tablet,
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        zIndex: "999",
    },

})

export const LogoDisplay = styled("div", {
    backgroundColor: colors.greyDarkPrimary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderTopLeftRadius: "10px",
    width: "300px",
    [mediaQueries.tablet]: {
        gridArea: "none",
        position: "absolute",
        top: "20px",
        left: "20px",
        width: "300px",
        height: "150px",
        zIndex: "999"
    }
});

export const GlobalMenu = styled("div", {
    backgroundColor: colors.greyDarkPrimary,
    padding: "7.5px",
    [mediaQueries.screenHeightMax]: {
        padding: "7.5px 5.5px 7.5px 7.5px",
        overflowY: "scroll",
        scrollbarColor: colors.greyDarkSecondary+colors.transparent,
        scrollbarWidth: "thin",
        borderRight: "2px solid"+colors.greyDarkPrimary
    },
    [mediaQueries.tablet]: {
        padding: "7.5px 5.5px 7.5px 7.5px",
        gridArea: "none",
        position: "absolute",
        top: "175px",
        left: "20px",
        width: "285px",
        height: "190px",
        overflowY: "scroll",
        scrollbarColor: colors.greyDarkSecondary+colors.transparent,
        scrollbarWidth: "thin",
        borderRight: "2px solid"+colors.greyDarkPrimary,
        zIndex: "999"
    },

});

export const CardsColumnCount = styled("div", {
    columnCount: "2",
    columnGap: "20px", 
    width: "fit-content",
    margin: "0 auto",
    [mediaQueries.feedCurves1]: {
        columnCount: "1",
    },
    [mediaQueries.feedCurves2]: {
        columnCount: "2",
    },
    [mediaQueries.feedCurvesTablet]: {
        columnCount: "2",
    },
    [mediaQueries.feedCurves3]: {
        columnCount: "3",
    },
    [mediaQueries.feedCurves4]: {
        columnCount: "4",
    }
})

export const feedCurveTableContainer = styled("div", {
    maxHeight: "50%", 
    width: "100%", 
    display: "flex", 
    flexDirection: "column",
    [mediaQueries.screenHeightMax]: {
        maxHeight: "100%", 
    }
})

export const FeedCurveViewerContainer = styled("div", {
    height: "50%", 
    width: "100%", 
    display: "flex", 
    justifyContent: "center",
    [mediaQueries.screenHeightMax]: {
        display: "none", 
    }
})

export const SystemMenu = styled("div", {
    backgroundColor: colors.greyDarkPrimary,
    padding: "5px 0px",
    display: "flex",
    justifyContent: "center",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarColor: colors.greyDarkSecondary + colors.greyDarkPrimary,
    scrollbarWidth: "thin",
    [mediaQueries.tablet]: {
        gridArea: "none",
        position: "absolute",
        top: "385px",
        bottom: "70px",
        left: "20px",
        width: "300px",
        display: "flex",
        justifyContent: "center",
        zIndex: "999"
    }
});

export const StatusBar = styled("div", {
    backgroundColor: colors.greyDarkPrimary,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "row",
    borderBottomLeftRadius: "10px",
    padding: "0 10px",
    gap: "10px",
    [mediaQueries.tablet]: {
        gridArea: "none",
        position: "absolute",
        bottom: "20px",
        left: "20px",
        right: "305px",
        height: "45px",
        display: "flex",
        zIndex: "999"
    }
});

export const TabMenu = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexDirection: "row",
})

// ---------------------------------------------------
// #endregion

// #region | Page Content
// Grid Page Outer containing TabMenu and Inner Page
const _gridTemplateAreas_page_outer = `
    "tabMenu"
    "inner_page"
`;
export const Page_outer = styled("div", {
    width: "100%",
    display: "Grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "50px 1fr",
    gap: "5px",
    gridTemplateAreas: _gridTemplateAreas_page_outer,
    overflow: "hidden",
    height: "100%"
})
export const _page_outer_tabMenu = styled("div", {
    gridArea:"tabMenu",
    overflowX: "scroll",
    scrollbarWidth: "none",
    overflowY: "hidden",
    position: "relative",
    // borderBottom: "5px solid"+colors.blueDarkPrimary,
})
export const _page_outer_inner_page = styled("div", {
    gridArea:"inner_page", 
    overflow: "hidden", 
    padding: "15px 0px 15px 15px",
    [mediaQueries.tablet]: {
        padding: "15px 0px 2px 0px" // ACHTUNG: when removing dashed border set padding to "15px 0px 0px 0px"
    },
})

// Grid Page Inner
const _gridTemplateAreas_page_inner = `
    "_page_inner_header"
    "_page_inner_content"
`;
export const Page_inner = styled("div", {
    // border: "1px dashed #aaa",
    height: "100%",
    display: "grid",
    gridAutoColumns: "1fr",
    gridAutoRows: "60px 1fr",
    gap: "10px",
    gridTemplateAreas: _gridTemplateAreas_page_inner,
});
export const _page_inner_header = styled("div", {
    gridArea: "_page_inner_header",
    height: "100%"
});
export const _page_inner_content = styled("div", {
    gridArea: "_page_inner_content",
    overflow: "hidden",
    height: "100%",
});
export const _page_inner_content_scroll = styled("div", {
    gridArea: "_page_inner_content",
    overflowY: "scroll",
    scrollbarColor: colors.greyDarkPrimary+colors.transparent,
    scrollbarWidth: "thin",
    height: "100%",
    gap: "10px"
});
// ---------------------------------------------------
// #endregion

// #region | ToolsPanel Grid
// ToolsPanel Layout
const _gridTemplateAreas_toolsPanel = `
  "logoDisplay page_outer"
  "globalMenu page_outer"
`;
const _gridTemplateAreas_toolsPanel_tablet = `
  "page_outer"`;
export const ToolsPanel = styled("div", { 
    display: "Grid",
    gridTemplateColumns: "300px 1fr",
    gridTemplateRows: "150px 1fr",
    gap: "5px",
    gridTemplateAreas: _gridTemplateAreas_toolsPanel,
    padding: "20px",
    height: "96vh",
    [mediaQueries.tablet]: {
        gridTemplateAreas: _gridTemplateAreas_toolsPanel_tablet,
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        zIndex: "0"
    }
})
// ToolsPanel Menu
export const ToolsMenu = styled("div", {
    backgroundColor: colors.greyDarkPrimary,
    padding: "7.5px",
    alignItems: "start",
    borderRadius: "0 0 0 10px",
    [mediaQueries.tablet]: {
        gridArea: "none",
        position: "absolute",
        top: "175px",
        left: "20px",
        width: "285px",
        bottom: "20px",
        display: "flex",
        zIndex: "2"
    }
});
// ---------------------------------------------------
// #endregion

// #region | Language Selector
// Frame for the language selector
export const LanguageNode = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95px",
    height: "45px",
    margin: "0 20px",
});
// Frame for the language selector and clock
export const DateTimeLang = styled("div", {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px 0 30px",
    borderTopRightRadius: "10px", 
    borderBottomRightRadius: "10px",
});
// Frame for the language selector to handle tablet
export const LanguageSelector = styled("div", {
    gridArea: "languageSelector",
    [mediaQueries.tablet]: {
        gridArea: "none",
        position: "absolute",
        bottom: "20px",
        right: "20px",
        width: "280px",
        zIndex: "999"
    }
});
// ---------------------------------------------------
// #endregion

// #region | Table Grids
// Grid One Row
const _gridTemplateAreas_table_oneRow = `
    "grid_oneRow_top"
    "grid_oneRow_content"
`;
export const Grid_oneRow = styled("div", {
    width: "100%", 
    height: "100%", 
    display: "grid",
    gridAutoColumns: "1fr",
    gridAutoRows: "auto 1fr", // Auto for responsiveness
    gridTemplateAreas: _gridTemplateAreas_table_oneRow,
});
export const _grid_oneRow_top = styled("div", {
    gridArea: "grid_oneRow_top", 
    backgroundColor: colors.greyPrimary, 
    borderRadius: "10px 10px 0px 0px", 
    display: "flex", 
    flexDirection: "column", 
    alignItems: "start", 
    justifyContent: "center", 
    gap: "10px", 
    padding: "10px",
    minHeight: "55px"
});
export const _grid_oneRow_content = styled("div", {
    gridArea: "grid_oneRow_content", 
    border: "5px solid", 
    borderColor: colors.greyPrimary, 
    borderTop: "none", 
    borderRadius: "0 0 10px 10px", 
    backgroundColor: colors.greyLightTertiary, 
    overflowY: "scroll", 
    scrollbarColor: " #D9D9D9 #D9D9D950", 
    scrollbarWidth: "thin", 
});

// Grid Two Rows
const _gridTemplateAreas_table_twoRows = `
    "grid_twoRows_top_left grid_twoRows_top_right"
    "grid_twoRows_content_left grid_twoRows_content_right"
`;
export const Grid_twoRows = styled("div", {
    width: "100%", 
    height: "100%", 
    display: "grid",
    gridAutoColumns: "3fr 1fr",
    gridAutoRows: "auto 1fr", // Auto for responsiveness
    gap: "0 10px",
    gridTemplateAreas: _gridTemplateAreas_table_twoRows,
});
export const _grid_twoRows_top_left = styled("div", {
    gridArea: "grid_twoRows_top_left",
    borderRadius: "10px 0 0 0", 
});
export const _grid_twoRows_top_right = styled("div", {
    gridArea: "grid_twoRows_top_right",
    borderRadius: "0 10px 0 0", 
});
export const _grid_twoRows_content_left = styled("div", {
    gridArea: "grid_twoRows_content_left", 
    borderRight: "none !important", 
    borderRadius: "0 0 0 10px", 
});
export const _grid_twoRows_content_right = styled("div", {
    gridArea: "grid_twoRows_content_right",
    borderLeft: "none !important", 
    borderRadius: "0 0 10px 0", 
});
// ---------------------------------------------------
// #endregion

// Logbook
export const LogBook = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
});

// AutoPig
export const AutoPig = styled("div", {
    display: "flex",
    justifyContent: "center"
});