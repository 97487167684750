import { combineReducers } from 'redux'
import { combineSlices } from '@reduxjs/toolkit'

//#region Device Elements
import * as logCalls from './DeviceCalls/DeviceSlices/logSlice' //logsReducer, { added, deleted } as logActions from './logSlice'
import * as deviceStateCalls from './DeviceCalls/DeviceSlices/deviceStateSlice' //logsReducer, { added, deleted } as logActions from './logSlice'
import * as autopigCalls from './DeviceCalls/DeviceSlices/autopigSlice' //logsReducer, { added, deleted } as logActions from './logSlice'
//#endregion

//#region Domain Elements
import * as strucktureCalls from './DomainCalls/DomainSlices/strucktureSlice' 
import * as feedComponentsCalls from "./FeedDataCalls/FeedDataSlices/feedComponentsSlice";
import * as feedCurvesCalls from "./FeedDataCalls/FeedDataSlices/feedCurveSlice"
//#endregion

//#region User Elements
import * as userCalls from './UserCalls/UserSlices/userSlice'
//#endregion

export const deviceCalls_Reducer = combineReducers({
  logs: logCalls.default,
  state: deviceStateCalls.default,
  autopig: autopigCalls.default
})
export const DeviceActions = {
  log: logCalls.LogActions,
  state: deviceStateCalls.DeviceStateActions,
  autopig: autopigCalls.AutopigActions
}

export const domainCalls_Reducer = combineReducers({
  struckture: strucktureCalls.default,
  feedComponents: feedComponentsCalls.default,
  feedCurves: feedCurvesCalls.default
})
export const DomainActions = {
  struckture: strucktureCalls.StrucktureActions,
  feedComponents: feedComponentsCalls.FeedComponentsActions,
  feedCurves: feedCurvesCalls.FeedCurvesActions,
}

// export const userCalls_Reducer = combineReducers({
//   ???: userCalls.default,
// })
// export const UserActions = {
//   ???: userCalls.UserActions,
// }

const gqlReducer = combineSlices({
  device: deviceCalls_Reducer,
  domain: domainCalls_Reducer,
  user: userCalls.default
})
export const gqlActions = {
  device: DeviceActions,
  domain: DomainActions,
  user: userCalls.UserActions
}

export default gqlReducer