import react, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../Store"
import { useUser } from "../Services"
import { setStates } from "../Store/StateHandlers/active-slice"
import { Pages, RootPages, NavigationFunction as RootNavigation } from "../Navigation/Routers/root"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Select, Value } from "baseui/select"
import { SelectField, Button, ButtonTypes } from "../Components"
import { StyleObject } from "styletron-react"
import { images, frames, colors, icons } from "../Styles"
import { useTranslation } from "react-i18next"

interface LogoDisplay_Props {
    ChooseSite?:boolean
    goBackBtn?:string,
    overrides?:{
        gridArea?: string
    }
  }

export default function LogoDisplay({goBackBtn, overrides, ChooseSite}:LogoDisplay_Props) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const { userFarms, getUserFarms, doLogOut } = useUser()
  const activefarm = useAppSelector<string|undefined>(({activeData}) => activeData?.farm)
  const navigator = useNavigate()
 
  useEffect(() => {
    getUserFarms()
  },[])

  const _Root = useMemo<StyleObject>(() => {
    return Object.assign(
      {} as StyleObject, //default stlye
      overrides&&overrides.gridArea? {gridArea:overrides.gridArea} : {}
    )
  },[overrides])

  const Data = useMemo<{label:string, domain:string}[]>(() => {
    if (!userFarms) return []
    
    // Map userFarms to options
    const farms = userFarms.map(farm => ({
      label: farm.name,
      domain: farm.domain
    }));

     // Push another option to the list of selects that goes back to site list in login flow
    farms.push({
      label: t("buttons.goToSiteList"),
      domain: 'navigate-to-list'
    });

    // Push another option to the list of selects that goes back to site list in login flow
    farms.push({
      label: 'logout',
      domain: 'logout'
    });

    return farms
  }, [t, userFarms])

  // Handle onChange
  const handleChange = (value: Value) => {
    if (value.length > 0 && value[0].domain === 'navigate-to-list') {
      RootNavigation(navigator, Pages.login)
    } else if (value.length > 0 && value[0].domain === 'logout') {
      doLogOut()
      RootNavigation(navigator, Pages.home)
    } else {
      console.log("handle",value)
      dispatch(setStates({type:'farm', farm:value[0].domain as string}))
      //RootNavigation(navigator, Pages.kpi) //TODO få dem til at blive på reload
    }
  };

  return(
    <frames.LogoDisplay $style={_Root}>
      <img src={images.logos.whiteFreedaLogo} style={{width: "280px", marginBottom: "12px"}} alt="Company logo"/>
      {/* Go Back Button on Logo Display */}
      {!goBackBtn?(<></>):(
        <Button 
          content={goBackBtn}
          startEnhancer={icons.goLeftArrow}
          overrides={{
            buttonType: ButtonTypes.textButton, 
          }} 
          onClick={() => {RootNavigation(navigator, Pages.login)} }
        />
      )}
      {/* SelectField to choose site but also go to site list in loginflow */}
      {!ChooseSite?(<></>):
        <SelectField
          placeholder="Vælg..."
          fieldStyle="darkGrey" 
          options={Data}
          textStart={true} 
          onChange = {(value) => handleChange(value)}
          defaultValue={Data.find(e => e.domain === activefarm)?.label}
          overrides={{
            lastChildBorder: 2,
          }}
        />
      }
    </frames.LogoDisplay>  
  )
}


function AnotherRootNavigation(navigator: NavigateFunction, userInformations: RootPages) {
  throw new Error("Function not implemented.");
}