import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypes, Modal, SelectField } from "../../../Components";
import { Field_Types } from "../../../Definitions";
import { colors, fonts, icons } from "../../../Styles";

import { TestData } from "../../../Assets/TestData/animalData"

interface MoveAnimalToPen_Props{
  animalsToMove: string[]
  isModalOpen: boolean
  goBack: () => void,
}
/**
 * MoveAnimalToPen
 * @param animalsToMove Array of animal numbers as strings. This is the animals that are going to be moved.
 * @param goBack Function to go back.
 * @returns a modal with one or more animals that can be moved to another location based on selected location. 
 */
export default function MoveAnimalToPen({animalsToMove, isModalOpen, goBack}:MoveAnimalToPen_Props) {
  const {t} = useTranslation()
  const [selectedAnimal, setAnimal] = useState<number>(0)

  // Find label in select field options corresponding to their respective ID
  const findLabelById = (id: number, options: { label: string, id: number }[]) => {
    const option = options.find(option => option.id === id);
    return option ? option.label : "";
  };

  // TODO MAJA: Lav en list over locations der kan benyttes istedet
  const Data = TestData
  const FeedCurvesOptions = useMemo(() => {
  const options = Data.map(animal => ({
    label: animal.animalNo.toString(),
    id: animal.animalNo
  }));
  return options
}, [Data] )

const hasAnimalsToMove = animalsToMove && animalsToMove.length > 0

const header = animalsToMove && animalsToMove.length === 1
  ? t("buttons.move") + " " + t("animal.animal") 
  : t("buttons.move") + " " + t("globalMenu.animals")

const animalsToMoveString = animalsToMove.join(", ");

  return (
    <Modal isModalOpen={isModalOpen}
      modalHeader={header}
      modalSubHeader={"Farrowing stable section x"} //TODO MAJA: Systemnavn, section nr, pen nr. HVis flere pens valgt skal disse "listes op"
      overrides={{ 
        isPrimaryComponent: true 
      }}
      goBackBtn={t("buttons.goBackBtn")}
      onGoBack={goBack}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "60px", marginTop: "25px"}}>
        
        { hasAnimalsToMove ?
        <>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px"}}>
          <p style={{...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary, maxWidth: "210px"}}>{t("animal.animalNo")} {animalsToMoveString}</p>
            <img src={icons.goRightArrow} style={{width: "20px"}} alt="Go Right Arrow"/>
            <SelectField
              defaultValue={findLabelById(selectedAnimal, FeedCurvesOptions)} // Set default value to "Time Span"
              options={FeedCurvesOptions}
              textStart={true}
              fieldType={Field_Types.shortInputField}
              onChange={(e) => setAnimal(parseInt(e[0].id + ''))}
            />
          </div>
          <Button 
            content={header}
            overrides={{
              buttonType: ButtonTypes.tertiaryButton
            }}
            // onClick={} TODO CTO: gem til db og luk modal
          />
        </>
        : <p style={{...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary, padding: "10px"}}>{t("animal.noAnimal")}</p>
        }

      </div>
    </Modal>
  )
}