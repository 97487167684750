import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, css, fonts, frames, icons } from "../../../Styles";
import { Button, ButtonList, ButtonTypes, Modal, TableList, TableListEdit, TableList_PropHandles, InputField, InputFieldList, SelectFieldList, PageHeader } from '../../../Components';
import { TableStyles_Types } from '../../../Definitions/tableStyles';
import { ListData_ValueTypes, Data, DataValue } from '../../../Definitions/valueTypes';

import { Animals, AnimalTypes, Group, Location, TestCalculate_Progresse, TestData } from "../../../Assets/TestData/animalData"
import { ConditionData } from '../../../Assets/TestData/conditions';
import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';

export default function AnimalTable() {
  const {t} = useTranslation()
  const edittable_ref = useRef<TableList_PropHandles>(null)
  const [editTable, setEditTable] = useState(false)
  const [showAddAnimal, setShowAddAnimal] = useState(false)
  const [showQE1, setShowQE1] = useState(false) // Select Popup
  const [showQE2, setShowQE2] = useState(false) // Action Popup
  const [showQE3, setShowQE3] = useState(false) // View Changes Popup

  // Function to open Add Animal Modal Popup
  const onAddAnimal = () => {
    setShowAddAnimal(true)
  }

  // Function to handle Quick Entry Modal Popup based on level
  const ShowQuickEntry = (level: 0 | 1 | 2 | 3) => {
    setShowQE1(level === 1 ? true : false); // Select Popup
    setShowQE2(level === 2 ? true : false); // Action Popup
    setShowQE3(level === 3 ? true : false); // View Changes Popup
  };

  // Function to handle "Go Back"
  const onGoBack = () => {
    if (showQE3) {
      ShowQuickEntry(2); // Go back to Level 2
    } else if (showQE2) {
      ShowQuickEntry(1); // Go back to Level 1
    }
  };

  // Switch between edit mode and non-edit mode
  const switchTable = () => {
    setEditTable((editTable) => !editTable);
  };

  // Save Changes to DB
  const saveChangedData = () => {
    //TODO: code
    //var changed = tableref.changedData
    //foreach Current_Data .. changed.row && changed.col
      // Update call for server
      console.log("saving", edittable_ref.current?.changed)
    if (edittable_ref.current?.changed && edittable_ref.current?.changed.length) {
      edittable_ref.current?.changed.forEach(changed => {
        console.log("A changed value", changed.row, changed.col, Current_Data[changed.row][changed.col], changed.value)
      })
    }
  }

  const LocationOptions = Object.values(Location).map(location => ({
    label: location,
  }));
  const ConditionOptions = ConditionData.map(condition =>({
    label: condition.name
  }))
  const FeedCurvesOptions = FeedCurveData.map(feedCurve =>({
    label: feedCurve.name
    // value: feedCurve.id TODO Skal der være en value med på alle de her options?
  }))
  const GroupOptions = Object.values(Group).map(group => ({
    label: group,
  }))
  const AnimalTypeOptions = Object.values(AnimalTypes).map(type => ({
    label: type,
  }))

  const Data = TestData
  const Current_Data = useMemo<Data[]>(() => {
    var Res = Data.map(animal => {
      var Res_Data:Data = []

      // Condition
      const condition = ConditionData.find(cond => cond.conditionNo === animal.condition) || { name: "No data", colorTag: colors.greyPrimary };
      // Feed Plan
      const feedCurve = FeedCurveData.find(cond => cond.id === animal.feedPlan) || { name: "No data" };

      Res_Data[0] = { type:ListData_ValueTypes.Id, value: animal.animalNo, static: true } as DataValue
      Res_Data[1] = { type:ListData_ValueTypes.String, value: animal.location, static: true } as DataValue
      Res_Data[2] = { type:ListData_ValueTypes.Number, value: animal.responderNo } as DataValue
      Res_Data[3] = { type:ListData_ValueTypes.Number, value: animal.lifeNo } as DataValue
      Res_Data[4] = { type: ListData_ValueTypes.ProgressBar, value:{total: animal.totalFood, progressed: TestCalculate_Progresse(animal) }, static: true } as DataValue      
      Res_Data[5] = { type: ListData_ValueTypes.ProgressBar, value:{total: animal.totalFood, progressed: TestCalculate_Progresse(animal, true) }, static: true } as DataValue      
      Res_Data[6] = { type:ListData_ValueTypes.Div, value: condition.name, select: [{id:0, label:condition.name}], style:{backgroundColor: condition.colorTag, ...css.colorTag, ...fonts.header, ...fonts.fontSize4, color: colors.whitePrimary} } as DataValue
      Res_Data[7] = { type:ListData_ValueTypes.String, value: feedCurve.name, select: [{id:0, label:feedCurve.name}]} as DataValue
      Res_Data[8] = { type:ListData_ValueTypes.Number, value: animal.group, select: [{id:0, label:animal.group}] } as DataValue
      //Res_Data[9] = { type:ListData_ValueTypes.String, value: animal.animalType, static: true } as DataValue
      Res_Data[9] = { type:ListData_ValueTypes.String, value: animal.latestMilestone, static: true } as DataValue
      Res_Data[10] = { type:ListData_ValueTypes.Number, value: animal.milestoneDay, static: true } as DataValue
      //Res_Data[12] = { type:ListData_ValueTypes.Number, value: animal.cycleDayTotal, static: true } as DataValue
      return Res_Data
    })
    console.log("datas",Res)
    return Res
  }, [Data]);
    
  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
        <PageHeader topHeader={t("globalMenu.animals")} subHeader={t("overview")}/>
        <div>
          <Button content={t("buttons.quickEntry") + " " + t("globalMenu.animals")} onClick={() =>{ShowQuickEntry(1)}} overrides={{
                buttonType: ButtonTypes.tertiaryButton
              }} />
        </div>
        </div>
      </frames._page_inner_header>
      <frames._page_inner_content>
        {/* <frames.Grid_oneRow> */}
          {/* <frames._grid_oneRow_top>
          </frames._grid_oneRow_top>
          <frames._grid_oneRow_content> */}
          
          <div style={{height: "100%", width: "100%", display: "grid", gridAutoRows: "50px 46px calc(100% - 96px)"}}>
            <div style={{ backgroundColor: "transparent", display: "flex", justifyContent: "end", padding: "0px 20px", gap: "5px"}}>
              {!editTable? 
                <ButtonList content={[t("buttons.add"), t("buttons.edit")]} onClick={(i => {
                  if (i === 0) onAddAnimal()
                  else if (i === 1) switchTable()
                })}
                startEnhancers={[icons.add_white, icons.edit_white]}
                overrides={{
                  button:{ buttonType: ButtonTypes.tableButton}
                }} />
              : <ButtonList content={[t("buttons.saveBtn"), t("buttons.cancelBtn")]} onClick={(i => {
                  if (i === 0) saveChangedData()
                  else if (i === 1) switchTable()
                })}
                startEnhancers={[icons.check_white, icons.close]}
                overrides={{
                  button:{ buttonType: ButtonTypes.tableButton}
                }} />
              }
            </div>
            <div style={{backgroundColor: colors.greyPrimary, borderRadius: "10px 10px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center"}}>
            </div>
            {!editTable?<TableList 
              data={Current_Data}
              content={[t("animal.animalNo"), t("animal.location"), t("animal.responderNo"), t("animal.lifeNo"), t("feed.remaining.today"), t("feed.remaining.yesterday"), t("animal.condition"), t("feed.feedPlan.feedPlan"), t("animal.group"), /*t("animal.animalType"),*/ t("animal.latestMilestone"), t("animal.milestoneDay"), /*t("animal.totalCycleDay")*/]}
              tableType={TableStyles_Types.secondaryTable}
              overrides={{
                columnTemplate: "repeat(11, minmax(190px, 1fr))",
                root: {overflow: "hidden", height: "100%", width: "100%", borderRadius: "0px 0px 10px 10px"},
                // word-break: keep-all TODO Maja

              }}/>
              : <TableListEdit ref={edittable_ref}
              data={Current_Data} 
              content={[t("animal.animalNo"), t("animal.location"), t("animal.responderNo"), t("animal.lifeNo"), t("feed.remaining.today"), t("feed.remaining.yesterday"), t("animal.condition"), t("feed.feedPlan.feedPlan"), t("animal.group"), /*t("animal.animalType"),*/ t("animal.latestMilestone"), t("animal.milestoneDay"), /*t("animal.totalCycleDay")*/]}
              tableType={TableStyles_Types.secondaryTable}
              overrides={{
                columnTemplate: "repeat(11, minmax(190px, 1fr))",
                root: {overflow: "hidden", height: "100%", width: "100%", borderRadius: "0px 0px 10px 10px"}
              }}/>
            } 
          </div>
            
          {/* </frames._grid_oneRow_content>
        </frames.Grid_oneRow> */}
      </frames._page_inner_content>
      
      {/* Add Animal Modal Popup */}
      <Modal 
        isModalOpen={!!showAddAnimal} 
        modalHeader={t("animal.newAnimal")}
        overrides={{ 
          isPrimaryComponent: true 
        }}
        onCloseBtn="close"
        onClose={() => {
          setShowAddAnimal(false)
        }}
      >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "start", justifyContent: "center", gap: "40px"}}>
      <div style={{ display: "flex", flexDirection: "column"}}>
            <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 5px 22px" }}>{t("required")}</p>
            <InputFieldList 
              startEnhancers={[icons.animalNo, icons.responderNo, icons.lifeNo]}
              placeholder={[t("animal.animalNo")+"...", t("animal.responderNo")+"...", t("animal.lifeNo")+"..."]}
              inputTypes={["number", "number", "number"]}
              isRequired={[true, true, true]}
              listStyle="vertical"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column"}}>
            <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 5px 22px" }}>{t("optional")}</p>
            <SelectFieldList 
              startEnhancer={[icons.location, icons.condition, icons.feedCurves, icons.group, icons.animalType]}
              placeholder={[t("animal.location")+"...", t("animal.condition")+"...", t("feed.feedPlan.feedPlan")+"...", t("animal.group")+"...", t("animal.animalType")+"..."]}
              listStyle="vertical"
              options={[LocationOptions, ConditionOptions, FeedCurvesOptions, GroupOptions, AnimalTypeOptions]}
            />
          </div>
        </div>
        <Button 
          content={t("buttons.createBtn")}
          overrides={{
            buttonType: ButtonTypes.tertiaryButton
          }}
          // onClick={} TODO
        />
      </div>
      </Modal>

      {/* Quick Entry Modal Popup level 1, 2 and 3 */}
      <Modal 
        isModalOpen={!!showQE1 || !!showQE2 || showQE3} 
        overrides={{ 
          isPrimaryComponent: true 
        }}
        modalHeader={t("buttons.quickEntry")}
        modalSubHeader={
          showQE2 ? t("action") : showQE3 ? t("buttons.view")+" "+t("changes") : t("select")
        }
        onCloseBtn="close"
        onClose={() => {
          ShowQuickEntry(0);
        }}
        goBackBtn={showQE2 || showQE3 ? t("buttons.goBackBtn") : ""}
        onGoBack={() => { onGoBack() }}
      >
        { !showQE2&&!showQE3&&showQE1 ? ( // Select Popup
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
              <InputField placeholder={t("animal.animalNo")+"..."} startEnhancer={icons.animalNo}/>
              <SelectFieldList 
                startEnhancer={[icons.location, icons.feedCurves, icons.group, icons.animalType]}
                placeholder={[t("animal.location")+"...", t("feed.feedPlan.feedPlan")+"...", t("animal.group")+"...", t("animal.animalType")+"..."]}
                listStyle="vertical"
              />
            </div>
            <Button 
              content={t("buttons.next")}
              overrides={{
                buttonType: ButtonTypes.tertiaryButton
              }}
              onClick={() => { ShowQuickEntry(2); }}
            />
          </div>
        ) : showQE2 ? ( // Action Popup
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", gap: "40px"}}>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <SelectFieldList
                  placeholder={["Action 1...", "Action 2...", "Action 3...", "Action 4...", "Action 5..."]}
                  listStyle="vertical"
                  textStart={[true, true, true, true, true]}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <SelectFieldList
                  placeholder={["---", "---", "---", "---", "---"]}
                  listStyle="vertical"
                  textStart={[true, true, true, true, true]}
                />
              </div>
            </div>
            <ButtonList 
              content={[t("buttons.view")+" "+t("changes"), t("buttons.saveBtn")+" "+t("changes")]} 
              onClick={(i => {
                if (i === 0) ShowQuickEntry(3)
                else if (i === 1) saveChangedData()
              })}
              overrides={{
                button:{ buttonType: ButtonTypes.quaternaryButton}
            }}/>
          </div>
        ) : showQE3 ? ( // View Changes Popup
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "40px"}}>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <SelectFieldList
                  placeholder={["Action 1...", "Action 2...", "Action 3...", "Action 4...", "Action 5..."]}
                  listStyle="vertical"
                  textStart={[true, true, true, true, true]}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <SelectFieldList
                  placeholder={["---", "---", "---", "---", "---"]}
                  listStyle="vertical"
                  textStart={[true, true, true, true, true]}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Modal>

    </frames.Page_inner>
  )
}