import React from "react";
import { colors, css, icons } from "./";
import { useTranslation } from "react-i18next";

export enum Units {
  kG_grey = "kg_grey",
  kG_white = "kg_white",
  mA_grey = "mA_grey",
  pct_white = "pct_white",
  farrowedTotal_grey = "farrowedTotal_grey",
  animalsCapacity = "animalsCapacity"
}

export const useUnitsComponent = () => {
  const { t } = useTranslation();

  const units = {
    [Units.kG_grey]: (
      <div key={"kg_grey"} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "2px" }}>
        <img src={icons.kg} style={{ width: "15px" }} key={"test"}/>
        <p key={"grey"} style={{ ...css.unitsFontstyle }}>{t("units.kg")}</p>
      </div>
    ),
    [Units.kG_white]: (
      <div key={"kg_white"} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "2px" }}>
        <img src={icons.kg_white} style={{ width: "15px" }} key={"test2"}/>
        <p key={"white"} style={{ ...css.unitsFontstyle, color: colors.greyLightTertiary }}>{t("units.kg")}</p>
      </div>
    ),
    [Units.mA_grey]: (
      <div key={"ma_grey"} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "2px" }}>
        <img src={icons.mA} style={{ width: "15px" }} />
        <p style={{ ...css.unitsFontstyle }}>{t("units.mA")}</p>
      </div>
    ),
    [Units.farrowedTotal_grey]: (
      <p style={{ ...css.unitsFontstyle }}>{t("units.farrowed")} / {t("units.total")}</p>
    ),
    [Units.pct_white]: (
      <p key={"pct_white"} style={{ ...css.unitsFontstyle, color: colors.greyLightTertiary }}>% {t("units.pct")}</p>
    ),
    [Units.animalsCapacity]: (
      <p style={{ ...css.unitsFontstyle }}>{t("globalMenu.animals")} / {t("units.capacity")}</p>
    )
  };

  return (unit: Units) => units[unit];
};