import react, { CSSProperties, useState } from 'react'
import { Pen_Content, ProgressBar } from "../.."
import { colors, fonts } from "../../../Styles"
import { useTranslation } from 'react-i18next';
import { penTypes } from '../../../Definitions/valueTypes';

import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';
import { TestData as PenData} from '../../../Assets/TestData/lf_Data';
import { TestData as AnimalData, TestCalculate_Progresse } from '../../../Assets/TestData/animalData'

interface PenBasic_Props {
  penNo: number
  penType: penTypes
  hasHoverEffekt?: boolean
  onClick?: () => void
}
/**
 * Pen_Basic
 * @param penNo Unique identifier for each pen.
 * @param penType Basic, advanced and single. Each renders different content of animal data.
 * @param hasHoverEffekt Boolean that, if true, adds a hover effect to the pen.
 * @param onClick Dynamic onClick eventhandler.
 * @returns a pen with varying content of animal data for Freeda Location Feeder systems.
 */
export default function Pen_Basic({ penNo, penType, hasHoverEffekt, onClick }: PenBasic_Props) {
  const {t} = useTranslation()
  const [isHovered, setIsHovered] = useState(false);
  
  // RootStyle based on advanced content
  const _width =
    penType === penTypes.basic ? "80px"
    : penType === penTypes.advanced ? "651px"
    : penType === penTypes.single ? "651px"
    : "0px"

    const _height =
    penType === penTypes.basic ? "30px"
    : penType === penTypes.advanced ? "35px"
    : penType === penTypes.single ? "35px"
    : "0px"

  // Find the pen and related data
  const pen = PenData.find(item => item.penNo === penNo)

  // Find the pen animal and related data
  const animal = AnimalData.find(item => item.animalNo === pen?.animal);

  // #region | Data handling
  // Handle feedCurve data and parse an empty string if label is undefined
  const feedCurve = FeedCurveData.find(e => e.id === animal?.feedPlan);
  const feedCurveName = feedCurve?.name ?? "";

  // Handle PenData and feeding status
  // const penData = PenData.find(pen => pen.penNo === animal.pen);
  const feedCorrection = animal&&animal.feedCorrection?animal.feedCorrection:0
  const feeding = pen?.feeding ? 'On' : 'Off';
  const feedingStyle: CSSProperties = {
    color: pen?.feeding ? colors.systemGreen : colors.systemRed
  };

  const skipStartDate = pen?.skip?.startDate
  const skipEndDate = pen?.skip?.endDate
  const skipStartTime = pen?.skip?.startTime
  const skipEndTime = pen?.skip?.endTime

  const skipped = pen?.skip
  ? (skipStartDate === skipEndDate
      ? `Skip feeding: ${skipStartDate} ${skipStartTime} - ${skipEndTime}`
      : `Skip feeding: ${skipStartDate} ${skipStartTime} - ${skipEndDate} ${skipEndTime}`)
  : "No planned feed break";

  // Handle progress bar data
  const progressBarTotal = animal?.totalFood;
  const progressBarProgressed = animal?TestCalculate_Progresse(animal):0;;

  // Create ProgressBar component
  const progressBar = (
    <ProgressBar 
      total={progressBarTotal} 
      progressed={progressBarProgressed}
    />
  );
  // #endregion

  // #region | Content
  // Content to parse in the div
  const baseContent = 
    <>
      { animal 
        ? <span>{animal.animalNo}</span>
        : <span style={{opacity: "0.5"}}>{t("locationFeeder.penPopup.emptyPen")}</span>
      }
    </>

  const advancedContent = 
    <div style={{width: "591px", padding: "0 50px 0px 10px"}}>
      {animal? 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 90px 25px 90px 45px 131px 45px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <Pen_Content content=""/>
          <Pen_Content popoverText={t("locationFeeder.penPopup.pen")} content={pen?.penNo}/>
          <Pen_Content popoverText={t("animal.animalNo")} content={animal.animalNo}/>
          <Pen_Content popoverText={t("animal.latestMilestone")} content={animal.latestMilestone}/>
          <Pen_Content popoverText={t("animal.milestoneDay")} content={animal.milestoneDay}/>
          <Pen_Content popoverText={t("feed.feedPlan.feedPlan")} content={feedCurveName} contentStyle={{paddingLeft: "5px"}}/>
          <Pen_Content popoverText={t("feed.feedCorrections.feedCorrection")} content={feedCorrection + '%'} contentStyle={{paddingLeft: "5px"}}/>
          <Pen_Content popoverText={`${t("feed.remaining.today")} (${t("units.kg")})`}  content={progressBar}/>
          <Pen_Content popoverText={t("feed.feedCorrections.feeding")} content={feeding} contentStyle={{...feedingStyle, paddingLeft: "5px"}}/>
        </div>
      : 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 426px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <Pen_Content content=""/>
          <Pen_Content popoverText={t("locationFeeder.penPopup.pen")} content={pen?.penNo} contentStyle={{cursor: "default"}}/>
          <Pen_Content content={t("locationFeeder.penPopup.empty")} contentStyle={{opacity: "0.5", cursor: "default"}}/>
        </div>
      }
    </div>

  const singleContent = 
    <div style={{width: "591px", padding: "0 50px 0px 10px"}}>
      {animal? 
        <div style={{ width: "591px", display: "grid", gridTemplateColumns: "90px 45px 45px 411px", textAlign: "start", alignItems: "center", gap: "5px", cursor: "default"}}>
          <Pen_Content popoverText={t("feed.feedPlan.feedPlan")} content={feedCurveName} contentStyle={{paddingLeft: "5px", cursor: "default"}}/>
          <Pen_Content popoverText={t("feed.feedPlan.feedPlan")} content={feedCorrection + '%'} contentStyle={{paddingLeft: "5px", cursor: "default"}}/>
          <Pen_Content popoverText={t("feed.feedCorrections.feeding")} content={feeding} contentStyle={{...feedingStyle, paddingLeft: "5px", cursor: "default"}}/>          
          <Pen_Content popoverText={t("feed.feedCorrections.feeding")} content={skipped}/>          
        </div>
      : <></>
      }
    </div>
  // #endregion

  // Apply hover effect only if hasHoverEffekt is true
  const handleMouseEnter = () => {
    if (hasHoverEffekt && animal) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (hasHoverEffekt && animal) {
      setIsHovered(false);
    }
  };

  return (
    <div 
      onClick={animal? onClick : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        height: _height,
        width: _width,
        cursor: hasHoverEffekt && animal? "pointer" : "",
      }}
    >
      <div style={{
        width: "100%",
        height: "100%",
        textAlign: 'center',
        alignContent: "center",
        backgroundColor: colors.greyLightSecondary,
        filter: isHovered ? 'opacity(0.5)' : 'opacity(1)',
        transition: 'filter 0.3s',
        color: colors.greyDarkPrimary,
        ...fonts.paragraph,
        ...fonts.fontSize4,
      }}>
        {penType === penTypes.advanced ? advancedContent : penType === penTypes.single ? singleContent : baseContent}
      </div>
    </div>
  );
}