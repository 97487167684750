import react, { useState } from "react";
import { ButtonList, ButtonTypes, InputFieldList, SelectField, PageHeader } from "../../../Components";
import { css, frames, icons } from "../../../Styles";
import { Graph_QL, useGetDomainLog, useGetAutopig } from "../../../Services/Agrisys_GqlDataCaller"

export default function GraphQl() {  
  const data1 = [ 
    {label:"hest"}, 
    {label:"ko"}, 
    {label:"gris"}
  ]
  const data2 = [ 
    {label:"en"}, 
    {label:"to"}, 
    {label:"tre"}
  ]
  const data3 = [ 
    {label:"rød"}, 
    {label:"gul"}, 
    {label:"grøn"}
  ]

  const [domain, setDomain] = useState<string|undefined>(undefined)

  const {call: FetchLog, data:LogData, dataData:LogArr} = useGetDomainLog() //Graph_QL.newConnection({domain:"moelholm",gate:1})
  const { getState } = useGetAutopig()

  const DoFetch = () => {
    if (domain) {
      console.log("do fetch")
      localStorage.setItem("activedomain", domain)
      FetchLog(Graph_QL.newConnection({domain:domain,gate:1}))
    }
  }
  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <PageHeader topHeader='GraphQl' subHeader='Logs and Data'/>
      </frames._page_inner_header>
      <frames._page_inner_content>
        <frames.Grid_twoRows>
          <frames._grid_twoRows_top_left style={{ ...css.tableTop }}>
            <div style={{display: "flex", gap: "5px", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", maxWidth: "850px"}}>
              <SelectField
                placeholder="Domain"
                fieldStyle="lightGrey"
                textStart={true}
                options={data1}
                isCreatable={true}
                isClearable={true} //TODO: Vil ikke acceptere at man sletter indholdet (Cannot read properties of undefined (reading 'label'))
                onChange={(e) => setDomain(e[0].label as string)}
                // defaultValue="" //TODO: Sætte defaultValue til IP
              />
              <InputFieldList
                placeholder={["GateWay", "Device ID"]}  
                isRequired={[false, false]}    
                listStyle="group"
                textStart={[true, true]}
                fieldStyle={["lightGrey", "lightGrey"]}
                inputTypes={["number", "number"]}
                fieldType={["shortField", "shortField"]}
                isClearable={[true, true]}
              />
            </div>
            <div style={{margin: "0 -10px"}}> {/* Margin on textbutton is "compensated" */}
              <ButtonList  
                content={["Get Log", "live Scale", "Internal Scale", "Daily Scales", "Clear" ]}
                startEnhancers={[icons.fetch, icons.fetch, icons.fetch, icons.fetch, icons.clear ]}
                listStyle="group"
                overrides={{
                  button: {
                    buttonType: ButtonTypes.textButtonWide
                  },
                }}
              />
            </div>
          </frames._grid_twoRows_top_left>
          <frames._grid_twoRows_top_right style={{ ...css.tableTop }}>
            <div style={{margin: "0 -10px"}}> {/* Margin on textbutton is "compensated" */}
              <ButtonList  
                content={["Fetch", "Clear" ]}
                startEnhancers={[icons.fetch, icons.clear ]}
                listStyle="group"
                overrides={{
                  button: {
                    buttonType: ButtonTypes.textButtonWide
                  },
                }}
              />
            </div>
          </frames._grid_twoRows_top_right>
          <frames._grid_twoRows_content_left style={{ ...css.tableContent }}/>
          <frames._grid_twoRows_content_right style={{ ...css.tableContent }}/>
        </frames.Grid_twoRows>
      </frames._page_inner_content>
    </frames.Page_inner>
  );
}