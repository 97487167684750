import { Group } from "./animalGroups";
import { Condition } from "./conditions";
import { Location } from "./locations";

export type FilterParameter = {
    locationNo?: Location['locationNo']
    groupNo?: Group['groupNo']
    conditionNo?: Condition['conditionNo']
}

export type DataLevel = "Animal" | "Group" | "Location"

export type DataParameter = "Location" | "Group" | "Condition"

export type ArchivedReport = {
    reportNo: number // DataTYPE ID, static
    name: string // Editable for farmer
    dataLevel: DataLevel
    filterParameter: FilterParameter[]
    dataParameter: DataParameter[]
    dateStart: string
    dateEnd?: string
  };
  
export const TestData: ArchivedReport[] = [
  {
    reportNo: 1,
    name: "Standard Condition Score",
    dataLevel: "Animal",
    filterParameter: [
      {groupNo: 1}
    ],
    dataParameter: [
      "Condition",
      "Group"
    ],
    dateStart: "24/12/24",
    dateEnd: "31/12/24"
  },
  {
    reportNo: 2,
    name: "Feed Plan and Condition Score",
    dataLevel: "Group",
    filterParameter: [
      {groupNo: 1},
      {conditionNo: 1}
    ],
    dataParameter: [
      "Location",
      "Group"
    ],
    dateStart: "12/06/23",
    dateEnd: "15/06/23"
  },
  {
    reportNo: 3,
    name: "Feed Plan",
    dataLevel: "Group",
    filterParameter: [
      {groupNo: 2},
      {conditionNo: 1}
    ],
    dataParameter: [
      "Location",
    ],
    dateStart: "23/10/24",
  }
]