import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors, fonts, icons } from "../../Styles";

import { ReportTemplate, TestData as ReportTemplateData } from "../../Assets/TestData/reportTemplates";
import { ConditionData } from "../../Assets/TestData/conditions";
import { AnimalGroupData } from "../../Assets/TestData/animalGroups";

interface ReportTemplateCard_Props {
  reportId: number
  onReportSelect?: (id: number) => void // TODO: Go to "create report" 
}

export default function ReportTemplateCard({reportId, onReportSelect}: ReportTemplateCard_Props) {
  const {t} = useTranslation()
  const [isSectionHovered, setIsSectionHovered] = useState(false);
  const [report, setReport] = useState<ReportTemplate | undefined>(undefined);

  // Find the report based on reportId
  useEffect(() => {
    const TemplateReport = ReportTemplateData.find((r) => r.reportTemplateNo === reportId);
    setReport(TemplateReport);
  }, [reportId]);
  
  const reportTitle = reportId 
    ? report?.name
    : t("reports.noReportFound")

  const reportSubTitle = reportId
    ? t("reports.template")+" "+report?.reportTemplateNo
    : t("reports.noReportFound")

  const dataLevel = reportId
    ? report?.dataLevel
    : t("reports.noDataParameterFound")

  const getConditionName = (conditionNo?: number): string => {
    const condition = ConditionData.find(c => c.conditionNo === conditionNo);
    return condition ? condition.name : t("unknown")+" "+t("animal.condition");
  };

  const getGroupNumber = (groupNo?: number): string => {
    const group = AnimalGroupData.find(a => a.groupNo === groupNo);
    return group ? group.name : t("unknown")+" "+t("animal.group");
  }

  const getTemplate = (label: string, value: string) => (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px", width: "100%" }}>
      <p style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary, textAlign: "right" }}>{label}:</p>
      <p style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary, textAlign: "left" }}>{value}</p>
    </div>
  );

  // Filter Params
  const renderFilterParameters = () => {
    if (!report || !report.filterParameter.length) return t("reports.noFilterParameterFound");

    return report.filterParameter.map((param, index) => {
      return (
        <div key={index}>
          {param.conditionNo && getTemplate(t("animal.condition"), getConditionName(param.conditionNo))}
          {param.groupNo && getTemplate(t("animal.group"), getGroupNumber(param.groupNo))}
        </div>
      );
    });
  };

  // Data Params
  const renderDataParameters = () => {
    if (!report || !report.dataParameter.length) return t("reports.noDataParameterFound");

    return (
      <div style={{ }}>
        {report.dataParameter.map((param, index) => (
          <p key={index} style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary, textAlign: "center" }}>
            {param}
          </p>
        ))}
      </div>
    );
  };

  return (
    <div
      onClick={onReportSelect ? () => onReportSelect(report?.reportTemplateNo ?? 0) : undefined } 
      onMouseEnter={() => setIsSectionHovered(true)}
      onMouseLeave={() => setIsSectionHovered(false)} 
      style={{ 
        cursor: "pointer",
        borderRadius: "10px",
        height: "fit-content",
        breakInside: "avoid",
        marginBottom: "20px",
        border: "5px solid"+colors.greyPrimary, 
        width: "320px", 
        filter: isSectionHovered ? 'opacity(0.5)' : 'opacity(1)',
        transition: '0.3s'
    }}>

      <div style={{backgroundColor: colors.greyPrimary, minHeight: "50px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", padding: "10px 20px", textAlign: "center" }}>
        <p style={{...fonts.header, ...fonts.fontSize2, color: colors.greyLightTertiary}}>{reportTitle}</p>
        <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary}}>{reportSubTitle}</p>
      </div>

      <div style={{backgroundColor: colors.greyLightTertiary, borderRadius: "0px 0px 5px 5px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "20px 30px", gap: "20px"}}>
        
        {/* Data Level */}
        <div style={{width: "100%"}}>

          <div style={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center", marginBottom: "5px"}}>
            <img src={icons.imgMissing} style={{width: "20px", height: "20px"}} alt="Img Missing"/>
            <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{t("reports.dataLevel")}</p>
          </div>

          <div style={{backgroundColor: colors.greyLightSecondary, padding: "10px", borderRadius: "10px"}}>
            <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary, textAlign: "center"}}>{dataLevel}</p>
          </div>

        </div>

        {/* Filter Parameter */}
        <div style={{width: "100%"}}>
        
          <div style={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center", marginBottom: "5px"}}>
            <img src={icons.imgMissing} style={{width: "20px", height: "20px"}} alt="Img Missing"/>
            <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{t("reports.filterParameter")}</p>
          </div>
        
          <div style={{backgroundColor: colors.greyLightSecondary, padding: "10px", borderRadius: "10px"}}>
            {renderFilterParameters()}
          </div>
        </div>
        
        {/* Data Parameter */}
        <div style={{width: "100%"}}>
      
          <div style={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center", marginBottom: "5px"}}>
            <img src={icons.imgMissing} style={{width: "20px", height: "20px"}} alt="Img Missing"/>
            <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{t("reports.dataParameter")}</p>
          </div>
      
          <div style={{backgroundColor: colors.greyLightSecondary, padding: "10px", borderRadius: "10px"}}>
            <span style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary, textAlign: "center"}}>
              {renderDataParameters()}
            </span>
          </div>

        </div>
        
      </div>

    </div>
  )
}