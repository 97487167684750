import React from 'react'; 
import { useTranslation } from 'react-i18next';
import { frames } from '../../Styles'
import { PageHeader } from '../../Components';

export default function Dashboard() {
  const { t } = useTranslation();

  return (
    <frames.Page_inner>
    <frames._page_inner_header>
      <PageHeader topHeader={t("globalMenu.dashboard")} subHeader={t("overview")}/>
    </frames._page_inner_header>
      <frames._page_inner_content>

      </frames._page_inner_content>
    </frames.Page_inner>
  );
}