import react, { useMemo } from "react"
import { StyleObject } from "styletron-react"
import { AttentionPopover } from "."
import { colors, fonts, frames } from "../Styles"

import { SystemData } from "../Assets/TestData/systems"
interface StatusBar_Props {
    overrides?:{
        gridArea?: string
    }
  }

export default function StatusBar({overrides}:StatusBar_Props) {
    const _Root = useMemo<StyleObject>(() => {
        return Object.assign(
          {} as StyleObject, //default stlye
          overrides&&overrides.gridArea? {gridArea:overrides.gridArea} : {}
        )
      },[overrides?.gridArea])
    
    return(
        <frames.StatusBar $style={_Root}>
            <p style={{...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, borderRight: "2px solid", borderColor: colors.greyDarkSecondary, paddingRight: "10px"}}>System Status</p>
            <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center"}}>
              {SystemData.map((system) => (
                <AttentionPopover key={system.systemNo} systemNo={system.systemNo} />
              ))}
            </div>
        </frames.StatusBar>

    )
}