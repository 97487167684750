import { useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { useAppDispatch, useAppSelector } from "../../../Store";

import { FeedComponentsInput, FeedComponentsResponse, GET_FEED_COMPONENTS_INFO } from "./GQLCalls/getFeedComponentsCall";
import { gqlActions } from "../gqlData_Reducer";
import { FeedComponentItem } from "./FeedDataSlices/feedComponentsSlice";
import Graph_QL, { GqlHook_Returns, StateDataObject } from "..";
import { noop } from "lodash";
import { filterByConnection } from "../filterByConnection";
import { TestData } from "../../../Assets/TestData/componentsData";

export const useFeedComponents = (connection: Graph_QL.GQLCons): GqlHook_Returns<FeedComponentItem, FeedComponentsInput> => {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const [cursorId, setCursorId] = useState(-1);

  const feedComponentsState = useAppSelector(({graphQlData:{domain}}) => domain.feedComponents);
  const {fullState} = useMemo(() => {
    const state = filterByConnection(feedComponentsState, Graph_QL.ToArr(connection)) as Record<string, FeedComponentItem>
    return Object.entries(state).reduce((accumulator, [key, data]) => {
      if (data.data) {
        const value = {...data.data, domain:key}; 
        accumulator.data = accumulator.data.concat(value)
      }
      return accumulator
    },{fullState: state, data: []} as StateDataObject<FeedComponentItem>)
  },[connection, feedComponentsState]);

  const getFeedComponentsInfo = async (specific?: Graph_QL.GQLCons, input?: FeedComponentsInput) => {
    try {
    await client.query<FeedComponentsResponse>({query: GET_FEED_COMPONENTS_INFO, variables: {input}})
      .then((e) => {
        if (e && e.data) {
          dispatch({type: String(gqlActions.domain.feedComponents.maintained), payload: {con: connection?.connection, data: e.data.feedComponentsInfo}});
          setCursorId(e.data.feedComponentsInfo.at(-1)?.id ?? -1)
        }
        if (e.error) {
          // Warning this is just to not break graphql as its not implemented correctly (or merged anywhere yet (20 sep. CTO))
          dispatch({type: String(gqlActions.domain.feedComponents.maintained), payload: {con: connection?.connection, data: TestData}});
        }
      })}
      catch {
         dispatch({type: String(gqlActions.domain.feedComponents.maintained), payload: {con: connection?.connection, data: TestData}});
      }
    }
  
  const fetchMore = async (cursor: number|number[], specific?: Graph_QL.GQLCons) => {
    await client.query<FeedComponentsResponse>({query: GET_FEED_COMPONENTS_INFO, variables: {domain: cursorId, fetchMore: 50}})
      .then((e) => {
        if (e && e.data) {
          dispatch({type: String(gqlActions.domain.feedComponents.maintained), payload: {con: connection?.connection, data: e.data.feedComponentsInfo}});
          setCursorId(e.data.feedComponentsInfo.at(-1)?.id ?? -1);
        }
        if (e.error) {
          return
        }
      })
    }

  const maintainEntries = async (changedData?: Partial<FeedComponentItem>) => {
    dispatch({type: String(gqlActions.domain.feedComponents.maintained), payload: changedData})
  }

  return {call: getFeedComponentsInfo, fetchMore, deleteEntries: noop, data: fullState, maintainEntries}
} 

