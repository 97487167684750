import { FeedCurveTableData } from "../../Containers/GlobalScreens/FeedCurves/convertSparseMixtureData";
import { ProjectionFunctionConstructor, VictoryAreaData } from "./linearProjection";

// Prepare data for each component
export const getMixtureDataPerComponent = (data: Map<number, FeedCurveTableData>, feedCurveComponentIds: number[], projectionFunctionConstructor: ProjectionFunctionConstructor, maxDay: number)=> {
    // Collect all mixture data for the specific component
    
    const collectedData = new Map<number, VictoryAreaData[]>()
    feedCurveComponentIds.forEach((id) => collectedData.set(id, []));
    const dataIterator = data.entries();
    dataIterator.next();

    data.forEach((row, day) => {
      const nextRow = dataIterator.next();

      collectedData.forEach((data, componentId) => {
        const y0 = +(row[componentId] ?? 0);
        const value = {x:day, y:y0};
        if (nextRow.done) {
          data.push(value, {x: day+1, y: y0})
          return
        }        
        if ((nextRow.value[0]-day-1) === 0 || maxDay > 250) {
          data.push(value)
          return
        }
        const y1 = +(nextRow.value[1][componentId] ?? 0)
        const projectionFunction = projectionFunctionConstructor({x: day, y: y0}, {x: nextRow.value[0], y: y1})
        const valueArray = Array.from({length: nextRow.value[0]-day-1}, (_, i) => (projectionFunction(day+i+1)))
        data.push(value,...valueArray)
      })
    })
    return collectedData;
  };
  
