import react, { useState, CSSProperties, useMemo, useImperativeHandle, useRef, forwardRef, RefObject, useEffect } from 'react'
import { Button as BaseButton, ButtonOverrides } from "baseui/button"
import { useLocation } from 'react-router-dom'
import { colors, css, fonts } from "../../Styles/index"
import { ListStyle_Types, CreateListStyle } from "../../Definitions"

//#region | General Button and Button List
// Button types for style
export enum ButtonTypes {
  primaryButton ="primaryButton",
  globalMenuButton ="globalMenuButton",
  tabButton= "tabButton",
  tableButton= "tableButton",
  secondaryButton ="secondaryButton",
  tertiaryButton = "tertiaryButton",
  quaternaryButton = "quaternaryButton",
  textButton = "textButton",
  textButtonUnderlined = "textButtonUnderlined",
  textButtonSmall = "textButtonSmall",
  textButtonWide = "textButtonWide",
  secondaryTableButton = "secondaryTableButton"
}

const CreateButtonStyle = (Hover:boolean, Active:boolean, overrides?:Button_Props_overrides):CSSProperties => {
  let baseStyles = {} as CSSProperties
  let hoverStyles = {} as CSSProperties
  let activeStyles = {} as CSSProperties

  switch (overrides?.buttonType) {
    case 'globalMenuButton':
      baseStyles = { ...css.globalMenuButton, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4 }
      hoverStyles = { ...css.globalMenuButtonHover }
      activeStyles = { ...hoverStyles }
    break
    case 'tabButton': // OBS, check BUTTON-OVERRIDES further down if part of list
      baseStyles = { ...css.tabButton, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4 }
      hoverStyles = { ...css.tabButtonHover }
      activeStyles = { ...hoverStyles }
    break
    case 'tableButton':
    baseStyles = { ...css.tableButton, backgroundColor: colors.greySecondary, color: colors.greyLightTertiary, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4 }
    hoverStyles = { backgroundColor: colors.greyPrimary }
    activeStyles = { backgroundColor: colors.greyPrimary }
  break
  case 'secondaryTableButton':
    baseStyles = { ...css.tableButton, backgroundColor: colors.greyLightSecondary, color: colors.greyDarkSecondary, ...fonts.header, ...fonts.fontSize4 }
    hoverStyles = { backgroundColor: colors.greyLightPrimary }
    activeStyles = { backgroundColor: colors.greyLightPrimary }
  break
    case 'secondaryButton':
      baseStyles = { ...css.primaryButton, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, ...css.secondaryButton }
      hoverStyles = { ...css.secondaryButtonHover }
      activeStyles = { ...hoverStyles }
    break
    case 'tertiaryButton':
      baseStyles = { ...css.primaryButton, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, justifyContent: "center" }
      hoverStyles = { ...css.tertiaryButtonHover }
      activeStyles = { ...hoverStyles }
    break
    case 'quaternaryButton':
      baseStyles = { ...css.primaryButton, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, justifyContent: "center", width: "fit-content" }
      hoverStyles = { ...css.tertiaryButtonHover }
      activeStyles = { ...hoverStyles }
    break
    case 'textButton':
      baseStyles = { ...css.textButton }
      hoverStyles = {...css.textButtonHover }
      activeStyles = {...hoverStyles, }
    break
    case 'textButtonUnderlined':
      baseStyles = { ...css.textButton, textDecoration: "underline", textUnderlineOffset: "5px" }
      hoverStyles = {...css.textButtonHover }
      activeStyles = {...hoverStyles, }
    break
    case 'textButtonSmall':
      baseStyles = { ...css.textButton, ...fonts.fontSize4 }
      hoverStyles = {...css.textButtonHover }
      activeStyles = {...hoverStyles, }
    break
    case 'textButtonWide': // OBS, check BUTTON-OVERRIDES further down if part of list
      baseStyles = { ...css.textButton, margin: "0 10px" }
      hoverStyles = {...css.textButtonHover }
      activeStyles = {...hoverStyles, }
    break
    case 'primaryButton':
    default:
      baseStyles = { ...css.primaryButton, ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4 }
      hoverStyles = { ...css.primaryButtonHover } 
      activeStyles = { ...hoverStyles }
    break
  }

  const buttonStyle = {
    ...baseStyles,
    ...(Hover && !overrides?.disableHover ? hoverStyles : {}),
    ...(Active && !overrides?.disableActive ? activeStyles : {}),
    ...overrides?.baseButton,
    ...(Hover && overrides?.buttonHover && !overrides.disableHover ? overrides.buttonHover : {}),
    ...(Active && overrides?.buttonActive && !overrides.disableActive ? overrides.buttonActive : {})
  }

  return buttonStyle
}

const CreateButton_EnhancerStyle = (overrides?:Button_Props_overrides) => {
  let startEnhancerStyles;
  let endEnhancerStyles;

  switch (overrides?.buttonType) {
    case 'globalMenuButton':
      startEnhancerStyles = { ...css.globalMenuButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'tabButton':
      startEnhancerStyles = { ...css.tabButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'tableButton':
      startEnhancerStyles = { ...css.tableButtonEnhancer }
      endEnhancerStyles = { ...css.tableButtonEnhancer }
    break;
    case 'secondaryTableButton':
      startEnhancerStyles = { ...css.tableButtonEnhancer }
      endEnhancerStyles = { ...css.tableButtonEnhancer }
    break;
    case 'secondaryButton':
      startEnhancerStyles = { ...css.primaryButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'tertiaryButton':
      startEnhancerStyles = { ...css.primaryButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'textButton':
      startEnhancerStyles = { ...css.textButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'textButtonUnderlined':
      startEnhancerStyles = { ...css.textButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'textButtonSmall':
      startEnhancerStyles = { ...css.textButtonEnhancer }
      endEnhancerStyles = { ...css.textButtonEnhancer, margin: "0px 0px 0px 5px" }
    break;
    case 'textButtonWide':
      startEnhancerStyles = { ...css.textButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
    case 'primaryButton':
    default:
      startEnhancerStyles = { ...css.primaryButtonEnhancer }
      endEnhancerStyles = { ...css.globalMenuButtonEnhancer }
    break;
  }
  return { 
    startEnhancerStyles, 
    endEnhancerStyles 
  }
} 

// General Button props
interface Button_Props_overrides {
  baseButton?: CSSProperties,
  startEnhancer?: CSSProperties, //style
  endEnhancer?: CSSProperties,

  buttonHover?: CSSProperties,
  buttonActive?: CSSProperties,
  ActiveIco?: string,

  buttonType?: ButtonTypes|string,
  disableHover?:boolean,
  disableActive?:boolean
}
//#endregion

//#region | Button Component
interface Button_Props {
  content:string,
  isActive?:boolean
  onClick?:()=>void,
  startEnhancer?:string|{def:string,active:string},
  endEnhancer?:string,
  overrides?:Button_Props_overrides,
  disabled?:boolean
}

export type Button_PropHandle = {
  setActive: (state:boolean) => void,
}

/**
 * Button
 * @param content the text for the button.
 * @param onClick the click event handler for the button.
 * @param startEnhancer the start icon for the button.
 * @param endEnhancer the end icon for the button.
 * @param overrides style or component overrides for the button.
 * @returns a button from BaseUi, build from content param.
 */

const _Button:React.ForwardRefRenderFunction<Button_PropHandle, Button_Props> = ({content, onClick, startEnhancer, endEnhancer, overrides, isActive=false, disabled }, ref) => {
  const [Hover, _setHover] = useState<boolean>(false)
  const Hover_Timer = useRef<NodeJS.Timeout|null>(null)
  const setHover = (val:boolean) => {
    if (disabled) return;
    if (Hover_Timer.current) clearTimeout(Hover_Timer.current)
    _setHover(val)
    Hover_Timer.current = setTimeout(() => _setHover((v) => v), 100)
  }
  const [IsActive, setIsActive] = useState<boolean>(isActive)

  useImperativeHandle(ref, ()=>({
    setActive(state) {
      if (IsActive !== state) setIsActive(state)
    }
  }))

  const { startEnhancerStyles, endEnhancerStyles } = useMemo(() => CreateButton_EnhancerStyle(overrides), [overrides?.buttonType, overrides?.startEnhancer, overrides?.endEnhancer])

  //const { startEnhancerStyles, endEnhancerStyles } = useMemo(() => CreateButton_EnhancerStyle(overrides), [overrides]);
  const buttonStyle = useMemo(() => CreateButtonStyle(Hover, IsActive, overrides), [ Hover, IsActive, overrides])

  return (
    <BaseButton $isSelected={true} children={content}
      onMouseOver= {() =>setHover(true)}
      onMouseOut={()=>setHover(false)}
      onClick={() => {
        if (disabled) return;
        if (onClick) onClick()
        setIsActive(true)
      }}
      startEnhancer={() => {
        var icon = <></>
        if (startEnhancer) {
          if (typeof startEnhancer === "string") icon = <img alt="start_icn" src={startEnhancer} style={startEnhancerStyles} />
          else {
            var ico = startEnhancer.def
            if (startEnhancer.active && (Hover || IsActive)) ico = startEnhancer.active
            icon = <img alt="start_icn" src={ico} style={startEnhancerStyles} />
          }
        }
        return icon
      }}
      endEnhancer={() => endEnhancer?<img alt="end_icn" src={endEnhancer} style={endEnhancerStyles} />:(<></>)}
      overrides={{
        EndEnhancer: { style: ({
           margin: 0
        }) },
        StartEnhancer: { style: ({
           margin: 0
        }) },
        BaseButton: {
          style: ({ }) => (buttonStyle)
        },
      }}
    />
  )
}
const Button = forwardRef(_Button)
export default Button
// -----------------------------------------------------
//#endregion

//#region | Button List
interface ButtonList_Props {
  content:string[],
  onClick?:(Btn_Index:number)=>void,
  endEnhancers?:string[],
  startEnhancers?:string[]|{def:string,active:string}[],
  listStyle?: ListStyle_Types|string,
  overrides?:{
    button?:Button_Props_overrides,
    activeLoad?:number
    useNavigationControl?:string
    disabled?:number[]
  }
}
/**
 * Button List Container
 * @param content the text for the buttons in the list.
 * @param onClick the click event handler for each button, returns with the clicked button index.
 * @param startEnhancers the start icon for each button.
 * @param endEnhancers the end icon for each button.
 * @param overrides style or component overrides for each button.
 * @returns A list of buttons, build from content param.
 */
export const ButtonList = ({content, onClick, startEnhancers, endEnhancers, listStyle, overrides}:ButtonList_Props) => {
  let location = useLocation()
  const { seperatorPosition, listDirection } = useMemo(() => CreateListStyle(listStyle), [listStyle]);

  const [activeButton, setActiveButton] = useState<number>(overrides&&overrides.activeLoad?(overrides.activeLoad>=0?overrides.activeLoad:0):0)
  const buttonRefs = useRef<Button_PropHandle[]>([])

  const ItemOptions = useMemo(() => {
    return content.map((_text, context_Index) => { 
      var _endImage = endEnhancers&&endEnhancers[context_Index] ? endEnhancers[context_Index] : undefined
      var _startImage = startEnhancers&&startEnhancers[context_Index] ? startEnhancers[context_Index] : undefined
      var _overrides:Button_Props_overrides = overrides&&overrides.button?{...overrides.button}:{}

      // BUTTON-OVERRIDES: If button is first or last in list, padding should be changed
      const isFirst = context_Index === 0;
      const isLast = context_Index === content.length - 1;
      if (_overrides.buttonType &&  _overrides.buttonType === ButtonTypes.tabButton) { // is tabButton
        if (isFirst) { //is First
          _overrides.baseButton = {..._overrides.baseButton, borderRadius: "0px 8px 0px 0px" }
        }
      }
      if (_overrides.buttonType &&  _overrides.buttonType === ButtonTypes.textButtonWide) { // is textButtonWide
        if (isFirst) { //is First
          // _overrides.baseButton = {..._overrides.baseButton, margin: "0px 10px 0px 0px" }
        }
        if (isLast) { //is Last
          // _overrides.baseButton = {..._overrides.baseButton, margin: "0px 0px 0px 10px" }
        }
      }
      return {start:_startImage, end:_endImage, overrides:_overrides}
    })
  }, [content, startEnhancers, endEnhancers, overrides?.button])
  

  const Item_List = useMemo(() => {
    return content.map((_text, context_Index) => { 
      var options = ItemOptions[context_Index]
      var disabled = false;
      if (overrides&&overrides.disabled&&overrides.disabled.some(e => e === context_Index)) disabled = true;
      buttonRefs.current = []
      var view = [(
        <Button disabled={disabled} key={"List_Buttons_" +context_Index} ref={(el) => el?buttonRefs.current[context_Index] = el:{}} 
          content={_text} isActive={activeButton===context_Index}
          onClick={() => {
            if (!overrides?.useNavigationControl) {
              setActiveButton((n) => {
                if (n >= 0 && buttonRefs.current && buttonRefs.current[n]) buttonRefs.current[n].setActive(false)
                return context_Index
              })
            }
            if (onClick) onClick(context_Index)
          }}
          startEnhancer={options.start} endEnhancer={options.end} overrides={options.overrides}
        />
      )]
      //If last Append seperator
      if (content.length != context_Index + 1) view.push(<div key={"sep"} style={seperatorPosition as React.CSSProperties}/>)
      return view
    })
  },[content, onClick, ItemOptions])

  useEffect(() => {
    if (overrides?.useNavigationControl) {
      if (location.state && location.state[overrides.useNavigationControl] !== undefined) {
        var index = location.state[overrides.useNavigationControl]
        if (activeButton !== index) {
          setActiveButton((n) => {
            if (buttonRefs.current) {
              if (buttonRefs.current[n]) buttonRefs.current[n].setActive(false)
              if (buttonRefs.current[index]) buttonRefs.current[index].setActive(true)
            }
            return index
          })
        }
      }
      else if (buttonRefs.current && buttonRefs.current[activeButton]) buttonRefs.current[activeButton].setActive(false)
    }
  }, [location.state])

  return (
    <div style={listDirection as React.CSSProperties}>
      {Item_List}
    </div>
  )
}
// ---------------------------------------------------
//#endregion