import { CSSProperties } from "react"
import { colors, fonts } from "../Styles"

export enum Field_Types {
  primaryInputField = "primaryInputField",
  secondaryField = "secondaryField",
  shortInputField = "shortInputField",
  shortInputFieldSecondary = "shortInputFieldSecondary",
  tableEditField = "tableEditField",
  sectionEditField = "sectionEditField",
  popoverDropdown = "popoverDropdown"
}
  
export const CreateFieldTypes = (fieldType?:Field_Types|string) => {
  let height
  let width
  let maxWidth
  let dropdownBorderRadius
  let fontStyle
  let textpadding
  let distancePaddingInput
  let distancePaddingSelect
  let enhancerPadding
  let arrowMargin
  let optionContentMargin
  let dropdownWidth
  let optionContentWidth
  let dropdownHeight
  let popoverBorderRadius
  let popoverWidth
  let popoverTop
  let popoverPosition
  let dropdownListItemWidth
  let dropdownBoxShadow
  let dropdownBorder
  let showSelectArrow
  let controleContainerBorderRadiusOpen

  switch (fieldType) {
    case 'sectionEditField':
      height = "25px"
      dropdownBorderRadius = "0px 10px 10px 10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary}
      textpadding = "10px 14px"
      distancePaddingInput = "0px"
      distancePaddingSelect = "0px 0px 0px 3px"
      enhancerPadding = "0px 5px 0px 0px"
      arrowMargin = "-12px"
      optionContentMargin = "0px 10px"
      optionContentWidth = "220px"
      dropdownWidth = "280px"
      dropdownHeight = "auto"
      popoverBorderRadius = "0px"
      popoverWidth = "auto"
      popoverTop = ""
      popoverPosition = "absolute"
      dropdownListItemWidth = "auto"
      dropdownBoxShadow = "none"
      dropdownBorder = "none"
      showSelectArrow = "block"
      controleContainerBorderRadiusOpen = "10px 10px 0 0"
    break;
    case 'tableEditField':
      height = "30px"
      width = "fit-content"
      maxWidth = "100px"
      dropdownBorderRadius = "0 10px 10px 10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary}
      textpadding = "0px 14px"
      distancePaddingInput = "0px 0px 0px 20px"
      distancePaddingSelect = "0px 0px 0px 60px"
      enhancerPadding = "0px 8px"
      arrowMargin = "0px"
      optionContentMargin = "0px 10px"
      optionContentWidth = "220px"
      dropdownWidth = "280px"
      dropdownHeight = "auto"
      popoverBorderRadius = "0px"
      popoverWidth = "auto"
      popoverTop = ""
      popoverPosition = "absolute"
      dropdownListItemWidth = "auto"
      dropdownBoxShadow = "none"
      dropdownBorder = "none"
      showSelectArrow = "block"
      controleContainerBorderRadiusOpen = "10px 10px 0 0"
    break;
    case 'shortInputField':
      height = "30px"
      width = "137.5px"
      dropdownBorderRadius = "0 0 10px 10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary}
      textpadding = "0px 14px"
      distancePaddingInput = "0px 0px 0px 20px"
      distancePaddingSelect = "0px 0px 0px 60px"
      enhancerPadding = "0px 8px"
      arrowMargin = "0px"
      optionContentMargin = "0px"
      optionContentWidth = "90px"
      dropdownWidth = "137.5px"
      dropdownHeight = "122px"
      popoverBorderRadius = "0px"
      popoverWidth = "137.5px"
      popoverTop = "0px"
      popoverPosition = "relative"
      dropdownListItemWidth = "auto"
      dropdownBoxShadow = "none"
      dropdownBorder = "none"
      showSelectArrow = "block"
      controleContainerBorderRadiusOpen = "10px 10px 0 0"
    break;
    case 'shortInputFieldSecondary':
      height = "30px"
      width = "137.5px"
      dropdownBorderRadius = "0 0 10px 10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize3, color: colors.greyDarkPrimary}
      textpadding = "0px 14px"
      distancePaddingInput = "0px 0px 0px 20px"
      distancePaddingSelect = "0px 0px 0px 60px"
      enhancerPadding = "0px 8px"
      arrowMargin = "0px"
      optionContentMargin = "0px"
      optionContentWidth = "90px"
      dropdownWidth = "137.5px"
      dropdownHeight = "122px"
      popoverBorderRadius = "0px"
      popoverWidth = "137.5px"
      popoverTop = "0px"
      popoverPosition = "relative"
      dropdownListItemWidth = "auto"
      dropdownBoxShadow = "none"
      dropdownBorder = "none"
      showSelectArrow = "block"
      controleContainerBorderRadiusOpen = "10px 10px 0 0"
    break;
    case 'popoverDropdown':
      height = "30px"
      width = "280px"
      dropdownBorderRadius = "10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary}
      textpadding = "0px 14px"
      distancePaddingInput = "0px 0px 0px 20px"
      distancePaddingSelect = "0px 0px 0px 60px"
      enhancerPadding = "0px 8px"
      arrowMargin = "0px"
      optionContentMargin = "0px 6px"
      optionContentWidth = "145px"
      dropdownWidth = "200px"
      dropdownHeight = "122px"
      popoverBorderRadius = "10px"
      popoverWidth = "200px"
      popoverTop = "120px"
      popoverPosition = "absolute"
      dropdownListItemWidth = "auto"
      dropdownBoxShadow = "0 4px 16px hsla(0, 0%, 0%, 0.16)"
      dropdownBorder = "3px solid"+colors.blueDarkPrimary
      showSelectArrow = "none"
      controleContainerBorderRadiusOpen = "10px"
    break;
    case 'secondaryField':
      height = "30px"
      width = "280px"
      dropdownBorderRadius = "0 0 10px 10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize3, color: colors.greyDarkPrimary}
      // textpadding = "0px 14px"
      textpadding = "0px 14px"
      distancePaddingInput = "0px 0px 0px 20px"
      distancePaddingSelect = "0px 0px 0px 60px"
      enhancerPadding = "0px 8px"
      arrowMargin = "0px"
      optionContentMargin = "0px"
      optionContentWidth = "250px"
      dropdownWidth = "280px"
      dropdownHeight = "auto"
      popoverBorderRadius = "0px"
      popoverWidth = "280px"
      popoverTop = ""
      popoverPosition = "relative"
      dropdownListItemWidth = "250px"
      dropdownBoxShadow = "none"
      dropdownBorder = "none"
      showSelectArrow = "block"
      controleContainerBorderRadiusOpen = "10px 10px 0 0"
    break;
    default:
    case 'primaryField':
      height = "30px"
      width = "280px"
      dropdownBorderRadius = "0 0 10px 10px"
      fontStyle = {...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary}
      // textpadding = "0px 14px"
      textpadding = "0px 14px"
      distancePaddingInput = "0px 0px 0px 20px"
      distancePaddingSelect = "0px 0px 0px 60px"
      enhancerPadding = "0px 8px"
      arrowMargin = "0px"
      optionContentMargin = "0px"
      optionContentWidth = "250px"
      dropdownWidth = "280px"
      dropdownHeight = "auto"
      popoverBorderRadius = "0px"
      popoverWidth = "280px"
      popoverTop = ""
      popoverPosition = "relative"
      dropdownListItemWidth = "250px"
      dropdownBoxShadow = "none"
      dropdownBorder = "none"
      showSelectArrow = "block"
      controleContainerBorderRadiusOpen = "10px 10px 0 0"
    break;
  }
  
  return { 
    height, 
    width, 
    maxWidth, 
    dropdownBorderRadius, 
    fontStyle, 
    textpadding, 
    distancePaddingInput, 
    distancePaddingSelect, 
    enhancerPadding, 
    arrowMargin, 
    optionContentMargin,
    optionContentWidth,
    dropdownWidth,
    dropdownHeight,
    popoverBorderRadius,
    popoverWidth,
    popoverTop,
    popoverPosition,
    dropdownListItemWidth,
    dropdownBoxShadow,
    dropdownBorder,
    showSelectArrow,
    controleContainerBorderRadiusOpen
  }
}