import React from "react";
import { colors } from "../../../Styles";
import { CheckBox, CheckStyles } from "../..";

import { DataParameters, ReportTemplate } from "../../../Assets/TestData/reportTemplates";

interface Lvl3_Props {
  report?: ReportTemplate
}

export default function ReportModalLvl3({report}:Lvl3_Props) {

  // const dataParameter: DataParameter[] = ["Location", "Group", "Condition"]
  
  return(
      <div style={{width: "600px"}}>
        <div style={{borderTop: "2px solid"+colors.blueDarkSecondary, borderBottom: "2px solid"+colors.blueDarkSecondary, padding: "10px 0px", maxHeight: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{width: "fit-content", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: "100px", rowGap: "10px"}}>
            {DataParameters.map((param, index) => (
                <CheckBox key={index} text={param} checkStyle={CheckStyles.blue} isChecked={report?.dataParameter?.includes(param)}/>
            ))}
          </div>
        </div>
      </div>
  )
}