import { createSlice,  PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer, REHYDRATE, 
  //FLUSH, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { Farms_Data, User_Data } from '../GQLCalls/getCalls';

export type UserStates = {
  UserToken?:string,
  UserFarms?:Farms_Data[],
  UserInfo?:User_Data
}
export type UserStates_Keys = keyof ({all:any} & UserStates)

const initialState:UserStates = {
  UserToken: undefined,
  UserFarms: undefined,
  UserInfo: undefined,
}

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      if (action.payload && action.payload !== null) {
        state.UserToken = action.payload
        localStorage.setItem("token", action.payload)
      }
    },
    setUserFarms: (state, action) => {
      if (action.payload && action.payload !== null) {
        state.UserFarms = action.payload
      }
    },
    setUserData: (state, action) => {
      if (action.payload && action.payload !== null) {
        state.UserInfo = action.payload
      }
    },
    clear: (state, {payload}:PayloadAction<UserStates_Keys>) => {
      switch(payload) {
        case "UserFarms":
          state.UserFarms = initialState.UserFarms
          break
        case "UserToken":
          state.UserToken = initialState.UserToken
          localStorage.setItem("token", "")
          break
        case "UserInfo":
          state.UserInfo = initialState.UserInfo
          break
        case "all":
          state = {
            ...initialState, 
          }
          break
        default:
          break
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      if (action) {
        var {key, payload} = action as {key?:string, payload?:UserStates}
        if (key && payload && key === UserSlice.name) {
          //if (payload.Language) i18n.changeLanguage(payload.Language.id)
          if (payload.UserToken) localStorage.setItem("token", payload.UserToken)
        }
      }
    })
  }
})

const UserPersistConfig:PersistConfig<UserStates, any, any, any> = {
  key: UserSlice.name,
  storage: storage,
  //blacklist: [],
  whitelist: ['UserToken']
}

const { actions, reducer } = UserSlice
const presistUser = persistReducer(UserPersistConfig, reducer)
export const UserActions = UserSlice.actions
export default presistUser