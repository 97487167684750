import { useTranslation } from "react-i18next"
import { colors, fonts, icons } from "../../../Styles"
import { ButtonList, ButtonTypes, Widget } from "../..";
import { ListStyle_Types } from "../../../Definitions";
// TODO MAJA: Toggle btn text from "start" to "stop" when system is (not) running: t("locationFeeder.overView.systemOff")

/**
 * SystemControls
 * @returns A widget with btns to controle the system.
 */
export default function SystemControls() {
  const {t} = useTranslation(); 

  return(
    <Widget
      header={<p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("locationFeeder.overView.systemControls")}</p>}
      bgColor={colors.greyPrimary}
      width="100%"
      height="100%"
      content={
        <div style={{display: "flex", flexDirection: "column", gap: "30px", padding: "20px 0px"}}>
          <ButtonList 
            content={[t("locationFeeder.overView.start"), t("locationFeeder.overView.calibrate"), t("locationFeeder.overView.systemOn"), t("locationFeeder.overView.emptySystem")]} 
            startEnhancers={[icons.start, icons.calibrate, icons.onOff, icons.empty]}
            overrides={{button: {
              disableActive: true,
              buttonType: ButtonTypes.primaryButton,
              baseButton: {backgroundColor: colors.greySecondary},
              buttonHover: {backgroundColor: colors.greyDarkSecondary}
            }}}
            listStyle={ListStyle_Types.verticalProMax}
          />
        </div>
      }
    />
  )
}