export interface VictoryAreaData {
  x: number,
  y: number
}

export type ProjectionFunction = (day: number) => VictoryAreaData;
export type ProjectionFunctionConstructor = (point1: VictoryAreaData, point2: VictoryAreaData) => ProjectionFunction;


export const linearProjection: ProjectionFunctionConstructor = (point1: VictoryAreaData, point2: VictoryAreaData) => {
    const slope = calculateSlop(point1, point2);
    const intercept = calculateIntercept(point1, slope);
    return (day: number) => {return {x: day, y: (slope * day + intercept)}};
  }
  
const calculateSlop = (point1: VictoryAreaData, point2: VictoryAreaData) => {
return (point2.y-point1.y)/(point2.x - point1.x)
}

const calculateIntercept = (point: VictoryAreaData, slope: number) => {
return point.y - slope * point.x
}