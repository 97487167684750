import { NavigationFunction as RootNavigation, RootPages } from "../Navigation/Routers/root"
import { useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import "../Translations/index"
import { ButtonList } from "../Components"
import { icons } from "../Styles/index"


export default function Def() {
  const navigator = useNavigate()
  const {t} = useTranslation()
  
  const BtnFunc_Router = (btn_index:number) => {
    switch (btn_index) {
      case 0: //Test Btn _ navi is not used
        RootNavigation(navigator, RootPages.login)
        break
      case 1: //Navigate Moblie _ index = 1
        RootNavigation(navigator, RootPages.subPage)
        break
      default:
        alert("Error ???")
    }
  }

  return (
    <div>
      <ButtonList content={[t("desktopPlatform"), t("operationsPlatform")]}
        onClick={(e) => BtnFunc_Router(e)}
        startEnhancers={[icons.platformDesktop, icons.platformOperation]}
        listStyle="vertical"
      />
    </div>  
  )
} 
