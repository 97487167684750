import { useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { useAppDispatch, useAppSelector } from "../../../Store";

import { gqlActions } from "../gqlData_Reducer";
import Graph_QL, { GqlHook_Returns, StateDataObject } from "..";
import { noop } from "lodash";
import { filterByConnection } from "../filterByConnection";
import { FeedCurveItem } from "./FeedDataSlices/feedCurveSlice";
import { TestData } from "../../../Assets/TestData/feedCurvesData";
import { FeedCurveInput } from "./GQLCalls/getFeedCurvesCall";

export const useFeedCurves = (connection: Graph_QL.GQLCons): GqlHook_Returns<FeedCurveItem, FeedCurveInput> => {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const [cursorId, setCursorId] = useState(-1);

  const feedCurvesState = useAppSelector(({graphQlData:{domain}}) => domain.feedCurves);
  const {fullState} = useMemo(() => {
    const state = filterByConnection(feedCurvesState, Graph_QL.ToArr(connection)) as Record<string, FeedCurveItem>
    return Object.entries(state).reduce((accumulator, [key, data]) => {
      if (data.data) {
        const value = {...data.data, domain:key}; 
        accumulator.data = accumulator.data.concat(value)
      }
      return accumulator
    },{fullState: state, data: []} as StateDataObject<FeedCurveItem>)
  },[connection, feedCurvesState]);

  const getFeedCurvesInfo = async (specific?: Graph_QL.GQLCons, input?: FeedCurveInput ) => {
    dispatch({type: String(gqlActions.domain.feedCurves.maintained), payload: {con: connection?.connection, data: TestData}});
  }
  
  const fetchMore = async (cursor: number|number[], specific?: Graph_QL.GQLCons) => {
    
    }

  const maintainEntries = async (changedData?: Partial<FeedCurveItem>) => {
    dispatch({type: String(gqlActions.domain.feedCurves.maintained), payload: changedData})
  }

  return {call: getFeedCurvesInfo, fetchMore, deleteEntries: noop, data: fullState, maintainEntries}
} 

