import react from 'react'

import { getSectionById } from '../../../Assets/TestData/sectionsData'
import { Pen_RemainingToday } from '../..';

interface SectionRemainingToday_Props {
  sectionId: number
}
/**
 * Section_RemainingToday
 * @param sectionId Unique identifier for each section.
 * @returns a section of pens for Freeda Location Feeder systems.
 */
export default function Section_RemainingToday({ sectionId }: SectionRemainingToday_Props) {
  const section = getSectionById(sectionId);

  // Error throwback if no section number excists
  if (!section) {
    return <div>Section {sectionId} not found</div>;
  }

  const renderContent = () => {
    return section.pens.map(pen => (
      <Pen_RemainingToday
        key={pen.animal?.animalNo}
        penNo={pen.penNo}
      />
    ));
  }

  return (
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", width: "fit-content", gap: "10px", borderRadius: "10px", overflow: "hidden" }}>
          {renderContent()}
        </div>
      </div>
  );
}