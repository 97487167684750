import { CSSProperties } from "react"
import { StyledBodyCell } from "baseui/table-grid"
import { colors } from "../../../Styles"
import { flexRender, Row } from "@tanstack/react-table"
import { getFrozenColumnStyle, getRemoveRowActionStyle } from "./getTableStyles"

export const getTableContent = (rows: Row<any>[], emptyText: string, bodyCellStyle: (index: number) => CSSProperties, amountOfHeaders: number) => {
  if (rows.length > 0) {
    return (
      rows.map((row, index) => (
        <div key={index} role="row" style={{ display: "contents" }}>
          {
            row.getVisibleCells().map((cell, cellIndex) => {
              const isPinned = cell.column.getIsPinned();
              const isLastLeftColumn = cell.column.getIsLastColumn("left");
              const isFirstRightColumn = cell.column.getIsFirstColumn("right");
              
              const style = {
                ...bodyCellStyle(0),
                ...getRemoveRowActionStyle(cell.column.id),
                ...getFrozenColumnStyle(isPinned, isLastLeftColumn, isFirstRightColumn, cellIndex, row.getVisibleCells().length),
                backgroundColor: index % 2 ? colors.greyLightPrimary : colors.greyLightSecondary,
              } as CSSProperties;
              return (
                <StyledBodyCell key={cell.id} style={style} $striped={index % 2 ? true : false} >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </StyledBodyCell>
              )
            })
          }
        </div> 
      ))      
    )
  }
  return (
    <StyledBodyCell $gridColumn={`1/span ${amountOfHeaders}`} >{emptyText}</StyledBodyCell>
  )
}

