import { LangFile } from './'
const Texts:LangFile = {
    noData: "Ingen data",
    homepagetitle: "Home Page",
    desktopPlatform: "Desktop Platform",
    operationsPlatform: "Operations Platform",
    required: "Nødvendigt",
    optional: "Valgfrit",
    overview: "Oversigt",
    default: "Standard",
    select: "Vælg",    
    action: "Handling",
    changes: "Ændringer",
    unknown: "Ukendt",
    search: "Søg",
    filterBy: "Filtrer",
    yes: "Ja",
    no: "Nej",
    from: "Fra",
    to: "Til",
    settings: "Indstillinger",
    
    languages: {
        en: "English",
        dk: "Dansk",
        es: "Español",
        de: "Deutsch",
        ma: "Mandarin"
    },

    user: {
        userName: "Brugernavn",
        userInformations: "Bruger information",
        userId: "Bruger-ID",
        firstName: "Fornavn",    
        lastName: "Efternavn",
        phone: "Telefon",
        email: "Email",
        password: "Kodeord",
        repeatPassword: "Gentag kodeord",
    },
    
    buttons: {
        goBackBtn: "Gå tilbage",
        createBtn: "Opret",
        cancelBtn: "Fortryd",
        saveBtn: "Gem",
        logOutBtn: "Log ud",
        loginbtn: "Log ind",
        quickEntry: "Masserediger",
        next: "Næste",
        prev: "Forrige",
        goToSiteList: "Gå til side listen",
        details: "Detaljer",
        edit: "Rediger",
        add: "Tilføj",
        view: "Vis",
        reset: "Nulstil",
        move: "Flyt",
        skip: "Spring over",
        plan: "Planlæg",
        forNow: "Indtil videre",
        startFeeding: "Start Fodring",
        standard: "Standard"
    },
 
    cookie:{
        header:"Cookies",
        context:`Agrisys A/s use cookies for the following purposes:\n`+
        `1* To support the website's functionality, for example by remembering your settings regarding cookies, preferred languages, currencies, etc.\n`+
        `2* To perform statistical analysis of your use of the website, for example by measuring what type of content you are looking at and clicking on.\n`+
        `\n`+
        `By pressing “Accept all” you accept our use of cookies.\n`+
        `Else a minimun of cookies will be used required by the service to function, for example to manage login, and data fetching from the consys platfrom.`,
        accept:"Accept necessary",
        acceptAll:"Accept All"
    },

    loginFlow: {
        login: {
            header: "Velkommen tilbage!",
            footerText: "Endnu ikke registreret?",
            create: "Opret ny bruger",
            error: "Kan ikke logge ind"
        },
        createUser: {
            header: "Ny bruger",
            error: "Kan ikke oprette bruger"
        },
        chooseSite: {
            header: "Vælg side",
            column1: "Gårdnavn",
            column2: "Manager",
            column3: "Kolonne 3",
            column4: "Kolonne 4"
        }
    },

    globalMenu: {
        home: "Hjem",
        dashboard: "Dashboard",
        kpi: "KPI",
        feedCurves: "Foderkurver",
        ComponentAlternatives: "Komponent Alternativer",
        mixtures: "Mixturer",
        silos: "Siloer",
        siloSettings: "Silo Indstillinger",
        animals: "Dyr",
        animalGroups: "Dyregrupper",
        reports: "Rapporter",
        logBook: "Logbog",
        globalSettings: "Indstillinger"
    },
    global_subMenu: {
        animals:{
            animalsEdit: "Animals Edit"
        }
    },
    systemMenu: {

    },
    system_subMenu: {
      some:{

      },
    },

    toolsMenu: {
        tools: "Tools",
        graphQl: "Graph Ql",
        xml: "XML",
        farms: "Gårde",
        users: "Brugere"
    },
    tools_subMenu: {
        graphQl: {
            configurations: "Konfigurationer",
            software: "Software"
        }
    },
    locationFeeder: {
        sections: {
            section: "Sektion",
            sections: "Sektioner",
            to: "Til",
        },
        sectionsPopup: {
            feedCorrections: "Nuværende foderændringer",
        },
        penPopup: {
            pen: "Bås",
            activations: "Aktiveringer",
            lastActivation: "Seneste aktivering",
            activator: "Aktivator",
            feeder: "Feeder",
            motor: "Motor",
            lastDays: "Seneste 10 dage",
            inThisPen: "I denne bås",
            emptyPen: "Tom bås",
            empty: "Tom",
            penValueHeaders: "Hold musen på en værdi for at se dets type"
        },
        planSkip: {
            planSkip: "Planlæg foderstop",
            plannedSkip: "Planlagt foderstop",
            timeSpan: "Tidsinterval",
            timeAmount: "Tidsmængde",
            startDate: "Start dato",
            endDate: "Slut dato",
            startTime: "Starttidspunkt",
            endTime: "Sluttidspunkt"
        },
        overView: {
            systemControls: "System Kontrol",
            start: "Start",
            calibrate: "Kalibrér",
            systemOn: "Tænd",
            systemOff: "Sluk",
            emptySystem: "Tøm"
        }
    },
    progressComponents: {
        inThisSystem: "I dette system",
        animalsNo: "Antal dyr",
        currentProgress: "Nuværende udvikling"
    },
    feed: {
        feedPlan: {
            feedPlan: "Foderplan",
            feedCurve: "Foderkurve",
            components: "Komponenter",
            milestones: "Milepæle",
            name: "Navn",
            readyComponent: "Klar",
            priority: "Prioritet",
            ds: "TS",
            energy: "Energi",
            protein: "Protein"
        },
        feedCorrections: {
            feedCorrection: "Foderkorrigering",
            increased: "Øget",
            decreased: "Reduceret",
            disabled: "Deaktiveret",
            active: "Aktiv",
            skipFeeding: "Skip fodring",
            feeding: "Fodring"
        },
        remaining: {
            today: "Spist idag",
            yesterday: "Spist igår"
        },
        fed: {
            today: "Fodret idag",
            period: "Fodret seneste periode"
        }
    },
    units: {
        total: "Total",
        kg: "KG",
        mA: "mA",
        pct: "pct",
        farrowed: "Faret",
        minutAgo: "Minut siden",
        minutesAgo: "Minutter siden",
        hour: "Time",
        hours: "Timer",
        hourAgo: "Time siden",
        hoursAgo: "Timer siden",
        timesToday: "Gange idag",
        timeToday: "Gang idag",
        capacity: "Kapacitet"
    },
    attentions: {
        attentions: "Fejl",
        problem: "Problem",
        problems: "Problemer",
        warning: "Advarsel",
        warnings: "Advarsler",
        systemAlert: "System Alarm",
        systemGood: "System Kører",
        systemNeutral: "System Slukket",
        systemWarning: "System Advarsel",
        goToSystem: "Gå til system"
    },
    animal: {
        animal: "Dyr",
        animalNo: "Dyre nummer",
        latestMilestone: "Sidst registrerede milepæl",
        milestoneDay: "Dage siden sidst registrede milepæl",
        responderNo: "Responder nummer",
        location: "Lokation",
        lifeNo: "Livsnummer",
        condition: "Tilstand",
        group: "Gruppe",
        animalType: "Dyretype",
        totalCycleDay: "Totale cyklus dag",
        newAnimal: "Nyt dyr",
        noAnimal: "Ingen dyr valgt"
    },
    reports: {
      report: "Rapport",
      farrowing: "Farring",
      farrowingsToday: "Farringer Idag",
      farrowed: "Farede dyr",
      template: "Skabelon",
      addTemplate: "Tilføj Skabelon",
      copyTemplate: "Kopier Skabelon",
      newTemplate: "Ny Skabelon",
      createNewReport: "Opret ny rapport fra start",
      noReportFound: "Ingen rapport fundet",
      noDataParameterFound: "Intet dataparameter fundet",
      noFilterParameterFound: "Intet filterparameter fundet",
      dataLevel: "Data Level",
      filterParameter: "Filter Parameter",
      dataParameter: "Data Parameter",
      archiveReport: "Arkiver Rapport",
      unArchiveReport: "Fjern Rapport fra arkiv",
      saveAsTemplate: "Gem som skabelon",
      archive: "Arkiv",
      archivedReport: "Arkiveret rapport",
      noStartDateFound: "Ingen startdato fundet",
      noEndDateFound: "Ingen slutdato fundet",
      reportName: "Rapport navn",
      newReport: "Ny rapport",
      fromTemplate: "Fra skabelon",
      showInReport: "Vis i rapporten"
    }
}
export default Texts