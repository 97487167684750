import react, { CSSProperties, forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Input } from "baseui/input"
import { colors, css, images } from "../../Styles"
import { ListStyle_Types, CreateListStyle, Field_Types, CreateFieldTypes, FieldStyle_Types, CreateFieldStyle } from "../../Definitions"

// #region | InputField Component
// InputField Component
interface InputField_Props {
  context?:string
  placeholder?:string
  fieldStyle?: FieldStyle_Types | string
  fieldType?: Field_Types | string
  inputType?:string
  startEnhancer?:string
  endEnhancer?:string
  textStart?: boolean
  isClearable?: boolean
  isRequired?:boolean
  isTableEditField?:boolean
  maxWidthEditField?: boolean
  disabled?:boolean
  onchanged?:(e:string)=>void
  onBlur?: () => void
  overrides?:{
    startEnhancer?:CSSProperties
    endEnhancer?:CSSProperties
  }
}

export interface InputField_Handles {
  setError: (state?:boolean) => void,
  focus: () => void,
  context: string,
  setContext: (e:string) => void,
}
/**
 * InputField
 * @param placeholder text in inputfield before typing.
 * @param fieldStyle color styling for inputfield, e.g. fieldStyle="blue".
 * @param fieldType width of inputfield, e.g. "fieldType="shortField".
 * @param inputType type of inputfield, e.g. "password" or "email" etc.
 * @param startEnhancer start icon.
 * @param endEnhancer end icon.
 * @param textStart if true the text will move to the left where the startenhancer would have been.
 * @param isClearable if true a "clear icon" will appear in the end of the field if the field has a value.
 * @param isRequired if true the field is required to be filled before moving on.
 * @param overrides style overrides.
 * @returns a BaseUi input field, build from content param. 
 */
const _InputField:React.ForwardRefRenderFunction<InputField_Handles, InputField_Props> = ({ placeholder, fieldStyle, fieldType, inputType, startEnhancer, endEnhancer, textStart = false, isClearable = false, isRequired = false, disabled=false, isTableEditField=false, maxWidthEditField=false, overrides, context, onchanged, onBlur }: InputField_Props, ref) => {
  const [value, setValue] = useState(context?context:"")
  const _inputRef = useRef<any>(undefined)
  const [isFocused, setIsFocused] = useState(false)
  const [isError, setError] = useState(false)
  const [required, setRequired] = useState(false)
  
  useImperativeHandle(ref, ()=>({
    setError(state=true) {
      setError(state)
    },
    focus() {
      if (_inputRef&&_inputRef.current) _inputRef.current.focus()
    },
    context: value,
    setContext(e:string) { setValue(e) }
  }))

  // Colors from fieldStyle
  const { BgColor, BgColorHover, BorderColor } = useMemo(() => {
    var _Style = CreateFieldStyle(fieldStyle)
    return _Style
  }, [fieldStyle])

  // Width from fieldType
  const { width, maxWidth, fontStyle, height, textpadding, distancePaddingInput, enhancerPadding } = useMemo(() => {
    var _Style = CreateFieldTypes(fieldType)
    return _Style
  }, [fieldType])

  // Handle padding and margin based on the presence of the startEnhancer
  const handleDistance = () => {
    if (startEnhancer) {
      return { padding: "0px", margin: "0px 0px 0px -5px" } // with startEnhancer
    } else if (!startEnhancer && textStart) {
      return { padding: "0px", margin: "0px 0px 0px -22px" } // without startEnhancer but textStart=true
    } else {
      return { padding: distancePaddingInput } // without startEnhancer and textStart=false
    }
  }
  
  const EditField = () => {
  if (isTableEditField) {
    return { margin: "0px 0px 0px -18px" }
    } else {
      return { margin: "0px" }
    }
  }

  return (
    <Input disabled={disabled}
      placeholder={placeholder}
      startEnhancer={() => startEnhancer?<img alt="start_icn" src={startEnhancer} style={overrides?.startEnhancer?overrides.startEnhancer: {
        ...css.inputFieldEnhancer}}/> : (<></>)
      }
      endEnhancer={() => endEnhancer?<img alt="end_icn" src={endEnhancer} style={overrides?.endEnhancer?overrides.endEnhancer: { 
        ...css.inputFieldEnhancer}}/> : (<></>) 
      }
      clearable={isClearable}
      required={isRequired}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        if (onBlur) {
          onBlur()
        }
      }}
      error={isError} 
      inputRef={_inputRef}
      clearOnEscape
      value={value}
      type={inputType}
      onChange={e => {
        setError(false)
        setValue(e.target.value)
        if (onchanged) onchanged(e.target.value)
      }}

      // style overrides
      overrides={{
        Root: { style: () => Object.assign({}, {
            ...BgColor,
            width: width,
            height: height,
            padding: textpadding,
            maxWidth: maxWidthEditField ? maxWidth : "auto",
            transition: "0.1s",
            outline: isFocused ? `2px solid ${isError?colors.systemRed:colors.greyLightTertiary}` : isError?colors.systemRed:"none",
            borderRadius: "10px",
            border: "none",
            ...EditField()
          } as React.CSSProperties )},   
        Input: { style: () => Object.assign({}, { 
          ...fontStyle,
          padding: textpadding,
            // ...fonts.paragraph, 
            // ...fonts.fontSize3,
            transition: "0.1s",
            '::placeholder': { color: colors.greyLightTertiary, opacity:"30%" }
          } as React.CSSProperties )},
        InputContainer: { style: () => Object.assign({}, {
            ...BgColor,
            ...handleDistance()
          } as React.CSSProperties )}, 
        EndEnhancer: { style: () => Object.assign({}, {
            ...BgColor,
            padding: enhancerPadding
          } as React.CSSProperties )},
        StartEnhancer: { style: () => Object.assign({}, {
            ...BgColor,
            padding: enhancerPadding
          } as React.CSSProperties )},
        ClearIcon: { style: () => Object.assign({}, {
            width: "18px !important",
            height: "18px !important"
          } as React.CSSProperties )},
        ClearIconContainer: { style: () => Object.assign({}, {
            padding: "0px",
            margin: "0px -17px 0px 0px",
            zIndex: "9999"
          } as React.CSSProperties )},
        // style overrides for "show/hide" icon on password type field
        MaskToggleHideIcon: () => <img alt="hide_password" src={images.icons.passwordHide} style={ css.MaskToggleIcon }/>,
        MaskToggleShowIcon: () => <img alt="show_password" src={images.icons.passwordShow} style={ css.MaskToggleIcon }/>,
      }}
    />
  );
}
const InputField = forwardRef(_InputField)
export default InputField
// #endregion

// TODO: Hvad er det her? kommentar tak
const useRefs = () => {
  const refsByKey = useRef<Record<string,HTMLElement | null>>({})
  const setRef = (element: HTMLElement | null, key: string) => {
    refsByKey.current[key] = element;
  }
  return {refsByKey: refsByKey.current, setRef};
}

// #region | InputFieldList Component
interface InputFieldList_Props {
  placeholder: string[]
  fieldStyle?: string[]
  fieldType?: string[]
  inputTypes?: string[]
  startEnhancers?: string[];
  endEnhancers?: string[];
  textStart?: boolean[];
  isClearable?: boolean[]
  isRequired: boolean[],
  error?:boolean[]
  listStyle?: ListStyle_Types|string,
}
export type InputFieldList_PropHandle = {
  setError: (index:number, state:boolean) => void,
  focus: (index:number) => void,
  context: (index:number) => string|undefined
  refs: React.MutableRefObject<InputField_Handles[]>
}
/**
 * InputFieldList
 * @param placeholder text in inputfield before typing.
 * @param fieldStyle color styling for inputfield, e.g. fieldStyle="blue".
 * @param fieldType width of inputfield, e.g. "fieldType="shortField".
 * @param inputType type of inputfield, e.g. "password" or "email" etc.
 * @param startEnhancers start icon.
 * @param endEnhancers end icon.
 * @param textStart if true the text will move to the left where the startenhancer would have been.
 * @param isClearable if true a "clear icon" will appear in the end of the field if the field has a value.
 * @param isRequired if true the field is required to be filled before moving on.
 * @param error shows an error on the input field if it has no value.
 * @param listStyle defines the direction of the InputFields in the List and the seperator between them.
 * @returns A list of input fields with optional enhancers.
*/
const _InputFieldList:React.ForwardRefRenderFunction<InputFieldList_PropHandle, InputFieldList_Props> = ({placeholder, fieldStyle, fieldType, inputTypes, startEnhancers, endEnhancers, textStart, isClearable, isRequired, error, listStyle}: InputFieldList_Props, ref) => {
  /*const [elRefs, setElRefs] = React.useState<React.MutableRefObject<HTMLInputElement>[]>([])
  React.useEffect(() => { // add or remove refs
    setElRefs((elRefs) => Array(placeholder.length).fill(undefined).map((_, i) => elRefs[i] || createRef()))
  }, [placeholder])*/
  const { seperatorPosition, listDirection } = useMemo(() => CreateListStyle(listStyle), [listStyle]);
  const inputRefs = useRef<InputField_Handles[]>([])

  useImperativeHandle(ref, ()=>({
    setError(index=0,state) { 
      if (inputRefs.current && inputRefs.current[index]) inputRefs.current[index].setError(state)
    },
    focus(index=0) {
      if (inputRefs.current && inputRefs.current[index]) inputRefs.current[index].focus()
    },
    context(index=0) {
      //if (elRefs&&elRefs[index]&&elRefs[index].current) return elRefs[index].current.value
      if (inputRefs.current && inputRefs.current[index]) return inputRefs.current[index].context
      return undefined
    },
    refs:inputRefs
  }))

  const Item_List = useMemo(() => {
    return placeholder.map((_text, context_Index) => {
      const _fieldstyle = fieldStyle&&fieldStyle[context_Index] ? fieldStyle[context_Index] : undefined
      const _fieldType = fieldType&&fieldType[context_Index] ? fieldType[context_Index] : undefined
      const _inputType = inputTypes&&inputTypes[context_Index] ? inputTypes[context_Index]:undefined;
      var _startEnhancers = startEnhancers&&startEnhancers[context_Index] ? startEnhancers[context_Index] : undefined
      var _endEnhancers = endEnhancers&&endEnhancers[context_Index] ? endEnhancers[context_Index] : undefined
      const _textstart = textStart&&textStart[context_Index] ? textStart[context_Index] : undefined
      const _isClearable = isClearable&&isClearable[context_Index] ? isClearable[context_Index] : undefined

      var view = [(
        <InputField key={"List_Input_" + context_Index} ref={(el) => el?inputRefs.current[context_Index] = el:{}}
          placeholder={_text}
          fieldStyle={_fieldstyle}
          fieldType={_fieldType}
          inputType={_inputType}
          startEnhancer={_startEnhancers}
          endEnhancer={_endEnhancers} 
          textStart={_textstart}
          isClearable={_isClearable}
          isRequired={isRequired[context_Index]}
        />
      )]
      // Seperator position based on list style for the list where last item will not have the seperator
      if (placeholder.length != context_Index + 1) view.push(<div style={seperatorPosition as React.CSSProperties}/>)
      return view
    });
  }, [placeholder, startEnhancers, endEnhancers, inputTypes]);

  return (
    // return of div with list direction based on list style
    <div style={listDirection as React.CSSProperties}>
      {Item_List}
    </div>
  );
}
export const InputFieldList = forwardRef(_InputFieldList)
// #endregion