import react, { useEffect, useState } from 'react'
import { ButtonList, ButtonTypes, Pen_Add } from '../..'
import { penTypes } from '../../../Definitions/valueTypes'
import { css, icons } from '../../../Styles'
import { useTranslation } from 'react-i18next'

import { getSectionById } from '../../../Assets/TestData/sectionsData'

interface SectionAdd_Props {
  sectionId: number
  onPenClick?: (penId: number) => void
  onCancelBtn?: () => void
}
/**
 * Section_Add
 * @param sectionId Unique identifier for each section.
 * @param onPenClick OnClick event to handle penNo.
 * @param onCancelBtn OnClick event to handle Cancel btn.
 * @returns a section of pens for Freeda Location Feeder systems.
 */
export default function Section_Add({ sectionId, onPenClick, onCancelBtn }: SectionAdd_Props) {
  const {t} = useTranslation()
  const section = getSectionById(sectionId);
  const [selectedPenNo, setSelectedPenNo] = useState<number | null>(null);

  function CancelBtnClick() {
    if (onCancelBtn) onCancelBtn()
  }

  // Handle Pen click
  const handlePenClick = (penId: number) => {
    if (onPenClick) {
      onPenClick(penId);
      setSelectedPenNo(penId)
    }
  };

  useEffect(() => {
  console.log('Selected Pen No updated:', selectedPenNo);
}, [selectedPenNo]);

  // Error throwback if no section number excists
  if (!section) {
    return <div>Section {sectionId} not found</div>;
  }

  const AddModeBtns = 
  <ButtonList 
    content={[t("buttons.cancelBtn")]} 
    onClick={(i => {
      if (i === 0) {CancelBtnClick()}
    })}
    startEnhancers={[icons.close]}
    overrides={{
      button:{ buttonType: ButtonTypes.secondaryTableButton, disableActive: true}
    }} 
  />

  const renderContent = () => {
    return section.pens.map(pen => (
      <Pen_Add
        key={pen.animal?.animalNo}
        penNo={pen.penNo}
        hasHoverEffekt={true}
        // onClick={() => handlePenClick(pen?.penNo as number)}
      />
    ));
  }

  return (
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "end", padding: "0px 20px" }}>
          {AddModeBtns}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "", width: "fit-content", gap: "10px", borderRadius: "10px", overflow: "hidden" }}>
          {renderContent()}
        </div>
      </div>
  );
}