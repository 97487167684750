import { ReactElement, useMemo, useState } from "react";
import { colors, fonts } from "../../Styles";
import { AttentionTypes } from "../../Definitions/valueTypes";
import { CreateAttentionStyles } from "../../Definitions/attentionStyles";
  
interface AttentionCard_Props {
  attentionType: AttentionTypes,
  systemContent?: ReactElement,
  problemContent?: ReactElement,
  hover?: boolean,
  onClick?: () => void
}
/**
 * AttentionCard
 * @param attentionType Defines style and header. Could be good, alert, warning or neutral.
 * @param systemContent Content to parse in top content area. 
 * @param problemContent Content to parse in bottom content area.
 * @param hover If true, the card will have opacity: "0.5" and cursor: "pointer" on onMouseEnter.
 * @param onClick Adds an onClick event to the card.
 * @returns A AttentionCard with either good, alert, warning or neutral as type.
 */
export default function AttentionCard({attentionType, systemContent, problemContent, onClick, hover = false}: AttentionCard_Props) {
  const [isHovered, setIsHovered] = useState<boolean>(false); 
  const { textColor, attentionText, attentionColor } = useMemo(() => {
    var _Style = CreateAttentionStyles(attentionType)
    return _Style
  }, [ attentionType ]);

    return(
        <div 
          style={{
            minWidth: "170px", 
            opacity: isHovered? "0.5" : "1",
            cursor: isHovered? "pointer" : "default"
          }} 
          onClick={onClick} 
          onMouseEnter={() => hover&&setIsHovered(true)}
          onMouseLeave={() => hover&&setIsHovered(false)} 
        >
          <div style={{ backgroundColor: attentionColor, borderRadius: "8px 8px 0px 0px", padding: "7px", textAlign: "center", ...fonts.header, ...fonts.fontSize4, color: textColor}}>
            {attentionText}
          </div>
          
          <div style={{backgroundColor: colors.greyDarkSecondary, borderRadius: "0px 0px 8px 8px",}}>
            <div style={{
              height: "fit-content",
              borderRadius: "0px 0px 8px 8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "3px solid"+attentionColor,
              borderTop: "none",
              padding: "10px"
            }}>
                {systemContent}
                <div style={{height: "1px", backgroundColor: colors.greyDarkPrimary, width: "80%", margin: "10px 0px"}}></div>
                {problemContent}
            </div>
          </div>
        </div>
    )
}