import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, ButtonList, ButtonTypes, FeedCurveViewer, Modal, PageHeader, SelectField } from '../../../Components';
import { colors, frames, icons, images } from '../../../Styles';
import { FieldStyle_Types  } from '../../../Definitions'
import { useAppSelector } from '../../../Store';
import Graph_QL, { useFeedComponents } from '../../../Services/Agrisys_GqlDataCaller';
import { FeedCurveInfo } from '../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall';
import { useFeedCurves } from '../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/useFeedCurves';
import { FeedCurveTable } from './feedCurveTable';
import { getFeedCurveComponentsIds } from '../../../Components/_Components/getFeedCurveComponentsIds';
import { mediaQueriesNumbers, useMediaQuery } from '../../../Styles/mediaQueries';

export default function FeedCurve() {
  const { t } = useTranslation();
  const location = useLocation();
  const [disableCurveNavigation, setDisableCurveNavigation] = useState(false);
  const [selectedFeedCurveId, setSelectedFeedCurveId] = useState<number>();
  const [showFeedCurve, setShowFeedCurve] = useState<FeedCurveInfo | undefined>(undefined);
  const [showFeedCurveModal, setShowFeedCurveModal] = useState<boolean>(false);
  const [showSpreadingModal, setShowSpreadingModal] = useState<boolean>(false);
  const isScreenHeightMax = useMediaQuery(`(max-height: ${mediaQueriesNumbers.ScreenHeigtMax}px)`);
  const pageHeaderResponsive = useMediaQuery(`(max-width: 1481px)`);
  const hideBtns = useMediaQuery(`(min-width: 901px) and (max-width: 927px)`);
  const feedCurveWidthSmaller = useMediaQuery(`(min-width: 901px) and (max-width: 961px)`);
  const feedCurveWidthSmaller2 = useMediaQuery(`(min-width: 601px) and (max-width: 625px)`);

  // Connection
  const farm = useAppSelector(({activeData}) => activeData.farm)
  const domain = Graph_QL.ObjToString({domain: farm ?? ""});
  const connection = Graph_QL.create({domain: farm ?? ""});
  
  // #region | Feed Curves data
  const {call: getFeedCurvesInfo, data: feedCurvesState, maintainEntries} = useFeedCurves(connection);
  
  const feedCurvesData = useMemo(() => {
    return feedCurvesState && feedCurvesState[domain] ? feedCurvesState[domain].data : [];
  },[domain, feedCurvesState])

  useEffect(() => {
    if (!feedCurvesState[domain]) {
      getFeedCurvesInfo(connection, {domain})
    }
  },[connection, domain, feedCurvesState, getFeedCurvesInfo])

  useEffect(()=> {
    setShowFeedCurve(feedCurvesData.find((feedCurve) => feedCurve.id === selectedFeedCurveId))
  },[feedCurvesData, selectedFeedCurveId])

  // Get options from feedCurve Data and return the first to view if there isn't any selected from the feedCurves page.
  const feedCurvesOptions = useMemo(() => {
    const options = feedCurvesData.map(feedCurve => ({
      label: feedCurve.name,
      id: feedCurve.id
    }));
    if (!showFeedCurve && location.state?.params?.selected) {
      setSelectedFeedCurveId(location.state.params.selected);
    } else if (!showFeedCurve && options.length) {
      setSelectedFeedCurveId(options[0].id);
    }
    return options;
  }, [feedCurvesData, showFeedCurve, location.state.params.selected]);

  useEffect(() => {
    if (location.state?.params?.selected && !showFeedCurve && feedCurvesData) {
      setSelectedFeedCurveId(location.state.params.selected);
    }
  }, [feedCurvesData, location.state, selectedFeedCurveId, showFeedCurve]);

  // #endregion

  // #region | Feed Components data
  const {call: getFeedComponentsInfo, data: feedComponentsState} = useFeedComponents(connection);
 
  const feedCurveComponentIds = useMemo(() => {
    return getFeedCurveComponentsIds(showFeedCurve?.mixture)
  },[showFeedCurve]);

  const feedComponents = useMemo(() => {
    if (!showFeedCurve) {
      return []
    }
    const feedComponents = feedComponentsState && feedComponentsState[domain] ? feedComponentsState[domain].data : [];
    return feedComponents.filter((feedComponent) => feedCurveComponentIds.includes(feedComponent.id));
  },[domain, feedComponentsState, feedCurveComponentIds, showFeedCurve])

  useEffect(() => {
    if (showFeedCurve && (!feedComponentsState[domain] || feedComponents.length !== feedCurveComponentIds.length)) {
      getFeedComponentsInfo(connection, {domain, ids: feedCurveComponentIds});
    }
  },[connection, domain, feedComponents, feedComponents.length, feedComponentsState, feedCurveComponentIds, getFeedComponentsInfo, showFeedCurve])
  // #endregion

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <div>
            <PageHeader topHeader={t("globalMenu.feedCurves")} />
            <SelectField
              isDisabled={disableCurveNavigation}
              options={feedCurvesOptions}
              defaultValue={showFeedCurve?.name}
              fieldStyle={FieldStyle_Types.darkGreyInv}
              textStart={true}
              onChange={(e) => {
                setSelectedFeedCurveId(parseInt(String(e[0].id)));
              }}
            />
          </div>
          {!hideBtns 
            ? 
            <ButtonList content={["Spreading", "Corrections", "Settings"]} 
                onClick={(i => {
                  if (i === 0) {setShowSpreadingModal(true)} // TODO CTO: Save to db and swith section edit
                  else if (i === 1) {}
                })}
                overrides={{button: {buttonType: pageHeaderResponsive ? ButtonTypes.quaternaryButton : ButtonTypes.tertiaryButton, disableActive: true }}}
              />
             : <></>
          }
          
          <Button content={'Watch Feedcurve'} overrides={{baseButton: {display: isScreenHeightMax? "block" : "none"}, buttonType: ButtonTypes.tertiaryButton }}  onClick={() => setShowFeedCurveModal(true)} />
        </div>
      </frames._page_inner_header>
      <frames._page_inner_content_scroll>
        {/* FeedCurveViewer */}
        <frames.FeedCurveViewerContainer>
          {showFeedCurve && <FeedCurveViewer data={showFeedCurve} feedComponents={feedComponents} showMilestoneLabelsBox={true} invertedColors={true} feedCurveWidth={ feedCurveWidthSmaller || feedCurveWidthSmaller2? "540px" : ""}/>}
        </frames.FeedCurveViewerContainer>
        {/* FeedCurveTable */}
        {showFeedCurve && 
        <FeedCurveTable 
          feedCurveDispatch={setShowFeedCurve}
          connection={connection.connection}
          setDisableCurveNavigation={setDisableCurveNavigation}
          showFeedCurve={showFeedCurve}
          feedComponents={feedComponents}
          feedCurveComponentIds={feedCurveComponentIds}
          maintainEntries={maintainEntries}
          />}
      </frames._page_inner_content_scroll>
      {/* Popup Modal for clicked feedcurve */}
      <Modal
        isModalOpen={!!showFeedCurveModal}
        overrides={{
          isPrimaryComponent: false,
          root: { padding: "40px 5px 5px 5px" },
          background: colors.greyLightTertiary
        }}
        modalHeader={`${showFeedCurve?.name}`}
        onCloseBtn='close'
        onClose={() => { setShowFeedCurveModal(false) }}
      >
        {/* Modal content */}
        <div style={{backgroundColor: colors.greyLightTertiary, borderRadius: "0px 0px 10px 10px", padding: "0px 20px 30px 20px"}}>
          {/* FeedCurveViewer */}
          {showFeedCurve && <FeedCurveViewer data={showFeedCurve} feedComponents={feedComponents} showComponentLabels={true} showMilestoneLabels={true}/>}
        </div>
      </Modal>
      <Modal 
        modalHeader={'Spreading'}
        isModalOpen={!!showSpreadingModal}
        overrides={{
          isPrimaryComponent: false,
          root: { padding: "40px 5px 5px 5px" },
          background: colors.greyLightTertiary
        }}
        onCloseBtn='close'
        onClose={() => { setShowSpreadingModal(false) }}
      >
        <img src={images.images.spreading} style={{width: "1000px"}} alt={"Spreading"}/>
      </Modal>

    </frames.Page_inner>
  );
}