import { useState } from "react";
import { useTranslation } from "react-i18next"
import { colors, fonts, icons } from "../../../Styles"
import { AttentionCard, Button, ButtonTypes, Modal, PenModal, SelectField, Widget } from "../..";
import { AttentionTypes, FieldStyle_Types } from "../../../Definitions";

import { Sections } from '../../../Assets/TestData/sectionsData';
import { TestData as Pens } from '../../../Assets/TestData/lf_Data';
//TODO MAJA: Filter funktion

/**
 * Attentions
 * @returns A widget with AttentionCards based on pens with alert or warning in the system. Each card opens a Modal with more Pen informations.
 */
export default function Attentions() {
  const {t} = useTranslation();
  const [activePenId, setActivePenId] = useState<number | null>(null); // Pen Id from Attention to show Pen Modal

  const handleAttentionClick = (key: number) => {
    setActivePenId(key);
  };

  const filteredPens = Pens.filter(
    pen => pen.attention === AttentionTypes.alert || pen.attention === AttentionTypes.warning
  );

  const problemsTotal = Pens.filter(pen => pen.attention === AttentionTypes.alert).length;
  const problemText = problemsTotal === 1 ? t("attentions.problem") : t("attentions.problems");

  const warningsTotal = Pens.filter(pen => pen.attention === AttentionTypes.warning).length;
  const warningsText = warningsTotal === 1 ? t("attentions.warning") : t("attentions.warnings");

  const getSectionIdByPenNo = (penNo: number): number | null => {
    const section = Sections.find(section => 
      section.pens.some(pen => pen.penNo === penNo)
    );
  
    return section ? section.id : null;
  };

  // #region | Pen Modal Functionality
  // Pen Modal Btn "Previous / Next Attention"
  const handleAttentionNavigation = (direction: 'previous' | 'next') => {
    if (filteredPens.length === 0 || activePenId === null) return;
    
    const currentIndex = filteredPens.findIndex(pen => pen.penNo === activePenId);
    
    let newIndex;
    if (direction === 'previous') {
      newIndex = (currentIndex === 0) ? filteredPens.length - 1 : currentIndex - 1;
    } else {
      newIndex = (currentIndex === filteredPens.length - 1) ? 0 : currentIndex + 1;
    }
  
    setActivePenId(filteredPens[newIndex].penNo);
  };
  // #endregion

  // #region | Pen Modal Components (Btns, Seperator)
  // Prev and Next Btns
  const renderNavigationBtns = (direction: 'previous' | 'next') => {
    const content = direction === 'previous'
      ? "Previous Attention"
      : "Next Attention"
      
    const onClick = () => {
      if (direction === 'previous') {
        handleAttentionNavigation('previous');
      } else if (direction === 'next') {
        handleAttentionNavigation('next')
      }
    };

    const startEnhancers = direction === 'previous'
    ? icons.goLeftArrow
    : null;
    const endEnhancer = direction === 'next'
    ? icons.goRightArrow
    : null;
    
    return (
      <Button 
        content={content}
        onClick={onClick}
        startEnhancer={startEnhancers}
        endEnhancer={endEnhancer}
        overrides={{ 
          buttonType: ButtonTypes.textButtonSmall,
          disableActive: true
        }}
      />
    );
  }
  // #endregion

  return(
    <>
      <Widget
        header={
          <div style={{width: "100%", padding: "0px 20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("attentions.attentions")}</p>
            <SelectField placeholder="Filter By" fieldStyle={FieldStyle_Types.lightGrey} fieldType={FieldStyle_Types.lightGrey} textStart={true}/>
          </div>
        }
        bannerContent={`${problemsTotal} ${problemText} | ${warningsTotal} ${warningsText}`}
        // filterbar={true}
        width="100%"
        height="100%"
        content={
          <div style={{display: "grid", height: "fit-content", gap: "20px", padding: "20px", gridTemplateColumns: "repeat(auto-fill, minmax(170px, 1fr))", gridAutoRows: "auto", width: "100%"}}>
              {filteredPens.map((pen, index) => (
              <AttentionCard
                hover={true}
                onClick={() => handleAttentionClick(pen.penNo)}
                key={index}
                attentionType={pen.attention as AttentionTypes}
                systemContent={
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                    <p style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary }}>{t("locationFeeder.sections.section")} {getSectionIdByPenNo(pen.penNo)}</p>
                    <p style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary }}>{t("locationFeeder.penPopup.pen")} {pen.penNo}</p>
                  </div>
                }
                problemContent={
                  <p style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary }}>{pen.error}</p>
                }
              />
            ))}
          </div>
        }
      />
      {/* ------------- Pen Modal -------------- */}
      <Modal
        isModalOpen={!!activePenId}
        modalHeader={t("locationFeeder.penPopup.pen")+" "+`${activePenId}`}
        modalSubHeader={"System Navn X"+" "+t("locationFeeder.sections.section")+" "+getSectionIdByPenNo(activePenId ?? 0)}
        onCloseBtn="close"
        onClose={() => {
          setActivePenId(null)
        }}
        modalContent={
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px", height: "20px" }}>
            <div style={{ height: "20px", display: "flex" }}>
              {renderNavigationBtns('previous')}
            </div> 
            <div style={{ backgroundColor: colors.blueDarkSecondary, height: "20px", width: "1.5px", margin: "0 auto" }}/>
            <div style={{ height: "20px", display: "flex" }}>
              {renderNavigationBtns('next')}
            </div>
          </div>
        }
        overrides={{
          isPrimaryComponent: false,
          root: { padding: "40px 5px 5px 5px" },
          background: colors.greyLightTertiary
        }}
      >
        <PenModal activePenId={activePenId ?? 0}/>
      </Modal>
    </>
  )
}