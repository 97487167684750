import react, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import { NavigationFunction as RootNavigation, Pages } from "../Navigation/Routers/root"
import { useNavigate } from "react-router"
import { useDispatch } from 'react-redux'
import { setStates } from "../Store/StateHandlers/active-slice"
import { useUser } from "../Services"
import { useTranslation } from "react-i18next"
import "../Translations/index"

import { Button, Modal, ButtonTypes, TableList, InputFieldList, InputFieldList_PropHandle } from "../Components"
import { colors, icons, frames, fonts } from "../Styles/index"
import { ListData_ValueTypes, Data, DataValue } from '../Definitions/valueTypes'
import { TableStyles_Types } from "../Definitions"

// Modals on Login Page
enum Auth_States{
  Login,
  CreateUser,
  SiteList,
}

// #region | Login Modal
function Login({setNewState}:{setNewState:Dispatch<SetStateAction<Auth_States>>}) {
  const navigator = useNavigate()
  const {t} = useTranslation()
  const inputRef=useRef<InputFieldList_PropHandle>(null)
  const [Errors, _setErrors] = useState<string|null>(null)
  const Err_Timer = useRef<NodeJS.Timeout|null>(null)
  const setErrors = (val:string) => {
    if (Err_Timer.current) clearTimeout(Err_Timer.current)
    _setErrors(val)
    Err_Timer.current = setTimeout(() => _setErrors((v) => v?null:v), 2000)
  }
  
  const { doLogin } = useUser()

  // Use "Enter" to submit
  onkeydown = (event) => {
    if (event.code === "Enter") {
      LoginAction()
    }
  }

  const LoginAction = () => {
    if (inputRef && inputRef.current) {
      if (inputRef.current.refs && inputRef.current.refs.current) {
        var Username = inputRef.current.refs.current[0].context
        var Password = inputRef.current.refs.current[1].context

        if(!Username) {
          inputRef.current.refs.current[0].setError()
          inputRef.current.refs.current[0].focus()
        }
        else if (!Password) {
          inputRef.current.refs.current[1].setError()
          inputRef.current.refs.current[1].focus()
        }
        else {
          doLogin(Username, Password)
        }
        setErrors(t("loginFlow.login.error"))
      }
    }
  }

  return (
    <frames.DecorBg>      
      <Modal 
        modalHeader={t("loginFlow.login.header")}
        footerText={t("loginFlow.login.footerText")}
        onCancelBtn={t("loginFlow.login.create")}
        onCancel={() => { setNewState(Auth_States.CreateUser) }}
        overrides={{ 
          isPrimaryComponent: true,
          background: colors.blueDarkPrimary
        }}
        onCloseBtn="close"
        onClose={() => navigator(-1)}
      >
        <>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
            <InputFieldList ref={inputRef}
              startEnhancers={[icons.user, icons.password]}
              placeholder={[t("user.userName")+"...", t("user.password")+"..."]}
              inputTypes={["text", "password"]}
              isRequired={[true, true, false]}
              listStyle="vertical"
            />

            {/* Login button */}
            <Button content={t("buttons.loginbtn")} onClick={LoginAction}
              overrides={{
                buttonType: ButtonTypes.secondaryButton,
                disableActive: true,
                disableHover: true
              }}

            />
            {!Errors?<></>:<p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, position: "absolute", top: "275px" }}>{Errors}</p>}
          </div>
        </>
      </Modal>
    </frames.DecorBg>
  )
}
// ---------------------------------------------------
//#endregion

// #region | Create User Modal
function CreateUser({setNewState}:{setNewState:Dispatch<SetStateAction<Auth_States>>}) {
  const {t} = useTranslation()
  const inputRefOne=useRef<InputFieldList_PropHandle>(null)
  const inputRefTwo=useRef<InputFieldList_PropHandle>(null)
  const [Errors, _setErrors] = useState<string|null>(null)
  const Err_Timer = useRef<NodeJS.Timeout|null>(null);
  const setErrors = (val:string) => {
    if (Err_Timer.current) clearTimeout(Err_Timer.current)
    _setErrors(val)
    Err_Timer.current = setTimeout(() => _setErrors((v) => v?null:v), 2000)
  }

  return (
    <frames.DecorBg>
      <Modal 
        modalHeader={t("loginFlow.createUser.header")} 
        onCancelBtn={t("buttons.cancelBtn")}
        overrides={{ 
          isPrimaryComponent: true
        }}
        onCancel={() => { setNewState(Auth_States.Login) }}
        onCloseBtn="close"
      >
        <div >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "start", justifyContent: "center", gap: "40px"}}>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 5px 22px" }}>{t("required")}</p>
                <InputFieldList ref={inputRefOne}
                  startEnhancers={[icons.user, icons.password, icons.password]}
                  placeholder={[t("user.userName")+"...", t("user.password")+"...", t("user.repeatPassword")+"..."]}
                  inputTypes={["text", "password", "password"]}
                  isRequired={[true, true, true]}
                  listStyle="vertical"
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 5px 22px" }}>{t("optional")}</p>
                <InputFieldList ref={inputRefTwo}
                  startEnhancers={[icons.user, icons.user, icons.mail, icons.phone]}
                  placeholder={[t("user.firstName")+"...", t("user.lastName")+"...", t("user.email")+"...", t("user.phone")+"..."]}
                  inputTypes={["text", "text", "email", "tel"]}
                  isRequired={[false, false, false, false]}
                  listStyle="vertical"
                />
              </div>
            </div>
          
            {/* Create User Button */}
            <Button content={t("buttons.createBtn")} onClick={() => {
                if (inputRefOne && inputRefOne.current && inputRefTwo && inputRefTwo.current) {
                  const refsOne = inputRefOne.current.refs?.current;
                  const refsTwo = inputRefTwo.current.refs?.current;

                  if (refsOne && refsTwo) {
                    var Username = inputRefOne.current.refs.current[0].context
                    var Password = inputRefOne.current.refs.current[1].context
                    var RepeatPassword = inputRefOne.current.refs.current[2].context
                    var FirstName = inputRefTwo.current.refs.current[0].context
                    var LastName = inputRefTwo.current.refs.current[1].context
                    var Phone = inputRefTwo.current.refs.current[2].context

                    if(!Username) {
                      inputRefOne.current.refs.current[0].setError()
                      inputRefOne.current.refs.current[0].focus()
                    }
                    else if (!Password) {
                      inputRefOne.current.refs.current[1].setError()
                      inputRefOne.current.refs.current[1].focus()
                    }
                    else if (!RepeatPassword) {
                      inputRefOne.current.refs.current[2].setError()
                      inputRefOne.current.refs.current[2].focus()
                    }
                    // else {
                    //   TODO: Create user
                    // }
                    setErrors(t("loginFlow.createUser.error"))
                    setInterval(() => {
                      setErrors("")
                    }, 2000)
                  }
                }
              }}
              overrides={{
                buttonType: ButtonTypes.secondaryButton,
                disableActive: true,
                disableHover: true
              }}
            />
            {!Errors?<></>:<p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, position: "absolute", top: "375px" }}>{Errors}</p>}
          </div>
        </div>
      </Modal>
    </frames.DecorBg>   
  )
}
// ---------------------------------------------------
//#endregion

// #region | Choose Site Modal
function SiteList({setNewState}:{setNewState:Dispatch<SetStateAction<Auth_States>>}) {
  localStorage.setItem("activedomain", "")
  const { userFarms, getUserFarms, doLogOut } = useUser()
  var isTech = true

  const dispatch = useDispatch()
  const navigator = useNavigate()
  const {t} = useTranslation()

  useEffect(() => {
    getUserFarms()
  },[])

  const Data = useMemo<Data[]>(() => {
    if (!userFarms) return []
    var Res = userFarms.map(farm => {
      var Res_Data:Data = []

      Res_Data[0] = { type:ListData_ValueTypes.String, value: farm.name } as DataValue
      Res_Data[1] = { type:ListData_ValueTypes.String, value: "" } as DataValue
      Res_Data[2] = { type:ListData_ValueTypes.String, value: farm.domain } as DataValue
      Res_Data[3] = { type:ListData_ValueTypes.Number, value: farm.farm_id } as DataValue
      return Res_Data
    })
    return Res
  }, [userFarms])
  
  return (
    <frames.DecorBg>
    <Modal 
      modalHeader={t("loginFlow.chooseSite.header")}
      overrides={{ 
        isPrimaryComponent: false,
        root: { padding: "60px 5px 5px 5px" },
        background: colors.greyLightTertiary
      }}
      goBackBtn={t("buttons.logOutBtn")}
      onGoBack={() => { doLogOut() }}
      onCloseBtn="close"
    >
      <div style={{width: "100%"}}>
      <div style={{position: "absolute", top: "10px", right: "50px", paddingRight: "10px", borderRight: "2px solid", borderColor: colors.blueDarkSecondary}}>
        <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "center", alignItems: "end", gap: "10px" }}>
            <Button content={"User Informations"}
              startEnhancer={icons.user}
              onClick={() => {RootNavigation(navigator, Pages.userInformations)} } 
              overrides={{
                buttonType: ButtonTypes.textButton
              }} 
            />
            {isTech && (
              <Button content={"Tools"}
                startEnhancer={icons.system2}
                onClick={() => {RootNavigation(navigator, Pages.graphQl)} } 
                overrides={{
                  buttonType: ButtonTypes.textButton
                }} 
              />
            )}  
          </div>
        </div>
          <TableList
            data={Data}
            actions={["Nav"]}
            action={(action, data) => {
              //set active data to selected Farm
              dispatch(setStates({type:'farm', farm:Data[data][2].value}))
              RootNavigation(navigator, Pages.kpi) //TODO: Dash or KPI
            }}
            content={[t("loginFlow.chooseSite.column1"), t("loginFlow.chooseSite.column2"), t("loginFlow.chooseSite.column3"), t("loginFlow.chooseSite.column4")]}
            overrides={{
              columnTemplate: "1fr 1fr 1fr 1fr",
            }}
            tableType={TableStyles_Types.blueTable}
          />
      </div>
    </Modal>
    </frames.DecorBg>
  )
}
// ---------------------------------------------------
//#endregion

// User Authentication
export default function Authentication_Path () {
  const [currentState, setCurState] = useState<Auth_States>(Auth_States.Login) 
  const { userToken } = useUser()

  useEffect(() => {
    if (userToken) {
      setCurState(Auth_States.SiteList)
    }
    else setCurState(Auth_States.Login)
  }, [userToken])

  switch(currentState) {
    case Auth_States.Login:
      return <Login setNewState={setCurState}/>;
    case Auth_States.CreateUser:
      return <CreateUser setNewState={setCurState}/>;
    case Auth_States.SiteList:
      return <SiteList setNewState={setCurState}/>;
  }
  return <></>
}