import * as React from "react";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { CSSProperties } from "react";
import { colors, fonts } from "../../../Styles";
import { DataValue } from "../../../Definitions";

interface PenContent_Props {
  popoverText?: string
  content: DataValue['value'];
  contentStyle?: CSSProperties
  }
/**
 * Pen_Content
 * @param popoverText 
 * @param content 
 * @param contentStyle 
 * @returns A component that defines the pen content type and - if string assigned - adds a popovertext on the pen content.
 */
export default function Pen_Content({ popoverText, content, contentStyle }:PenContent_Props) {
  return popoverText ? (
    <StatefulPopover
      content={popoverText}
      accessibilityType={"tooltip"}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.topLeft}
      overrides={{
        Body: { style: () => Object.assign({}, {
          width: "fit-content",
          borderRadius: "12px",
          padding: "2px",
          backgroundColor: colors.greyLightPrimary
        } as React.CSSProperties )},
        Inner: { style: () => Object.assign({}, {
          ...fonts.header,
          ...fonts.fontSize4,
          color: colors.greyPrimary,
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: colors.greyLightTertiary
        } as React.CSSProperties )}
      }}
    >
      <span style={contentStyle}>{content}</span>
    </StatefulPopover>
  ) : (
    <span style={contentStyle}>{content}</span>
  )
}