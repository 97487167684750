import { CellContext } from "@tanstack/react-table";
import { useState } from "react";
import { EditAbleCell, StyledTableCellProps } from "./styledTableCell";
import { SimplifiedInputField } from "../../BaseComponents/SimplifiedInputField";
import { IdentifiableRow } from "../reactiveTableRenderer";

export const NumberTableCell = <T extends IdentifiableRow,> ({getValue, row, table, column, canEdit, staticColumn}: CellContext<T, number | undefined> & EditAbleCell) => {
  const initialValue = getValue();

  const [value, setValue] = useState(initialValue);
  const key = `${row.index}-${column.id}`;

  const onBlur = () => {
    if (value !==initialValue) {
      table.options.meta?.updateData(row.original.id, column.id, value);
    }
  }


  return <StyledNumberCell staticColumn={staticColumn} key={key} keyPass={key+"input"} onBlur={onBlur} onChange={(e: number) => setValue(e)} canEdit={canEdit} cellData={{value}} />
}


const StyledNumberCell = ({onBlur, onChange, canEdit, cellData, keyPass, staticColumn}: StyledTableCellProps & EditAbleCell) => {
  if (canEdit) {
    if (staticColumn) { 
      return (
        <div style={{ ...cellData.style, opacity: 0.5  }} key={keyPass}>
          {String(cellData.value) ?? ''}
        </div>
      );
    } else {
      return <SimplifiedInputField
        keyPass={keyPass}
        context={String(cellData.value)}
        fieldStyle="tableEdit" 
        fieldType="tableEditField" 
        textStart={true} 
        isTableEditField={true}
        inputType="number"
        onChange={onChange}
        onBlur={onBlur}
        key={keyPass}
      />;
    }
  }

  return (cellData.value)

}