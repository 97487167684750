import * as React from "react";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { colors, fonts } from "../../Styles";
import { Block } from "baseui/block";
import { CSSProperties, forwardRef, useEffect, useMemo, useState } from "react";

export enum InputTypes {
  String="string",
  Number="number",
}
export enum PopoverTypes {
  skipFeeding="skipFeeding",
  feedCorrection="feedCorrection"
}

interface FeedPopover_Props {
  popoverType: PopoverTypes
  inputType: InputTypes
  header: string
  input?: string | number
  btnText: string[]
  closeOnBtnClick?: boolean[]
  onClick?:(Btn_Index:number)=>void
  onChanged?:(e:string | number)=>void
  overrides?:{
    value: CSSProperties
  }
}

export interface FeedPopover_Handles {
  input: string|number,
  setInput: (e: string|number) => void
}
/**
 * FeedPopover
 * @param popoverType Defines the btn layout in the popup.
 * @param inputType Defines value type for input field in order to handle the value properly. 
 * @param header The popover header.
 * @param input The input value to display in the input container
 * @param btnText Array of strings where the length defines the amount of btns.
 * @param closeOnBtnClick Array of booleans to define if the btn should ALSO close the popover when clicked.
 * @param onClick Function to change the input value
 * @param onChanged TODO
 * @param overrides style or component overrides for the input container
 * @returns A popover build from the BaseUI StatefulPopover
 */
const _FeedPopover:React.ForwardRefRenderFunction<FeedPopover_Handles, FeedPopover_Props> = ({popoverType, inputType, header, input, btnText, closeOnBtnClick = [], onClick, onChanged, overrides}: FeedPopover_Props, ref) => {
  const [value, setValue] = useState<number | string>(input ?? (inputType === InputTypes.Number ? 0 : ''));  
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState<number | null>(null);

  useEffect(() => {
    setValue(input ?? (inputType === InputTypes.Number ? 0 : ''));
  }, [input]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = inputType === InputTypes.Number ? Number(e.target.value) : e.target.value;
    setValue(newValue);
    if (onChanged) {
      onChanged(newValue);
    }
  };

  const handleButtonClick = (index: number, close: () => void) => {
    console.log('closeOnBtnClick:', closeOnBtnClick);
    console.log('Button clicked index:', index);

    if (onClick) {
        onClick(index);
    }
    if (index < closeOnBtnClick.length && closeOnBtnClick[index]) {
      close();
  }
};

  // Btn grid based on popover type
  const popoverTypeStyle = useMemo<CSSProperties>(() => {
    if (popoverType === PopoverTypes.skipFeeding) {
      return {
        gridTemplateRows: "repeat(3, 1fr)"
      };
    } else if (popoverType === PopoverTypes.feedCorrection) {
      return {
        gridTemplateColumns: "repeat(2, 1fr)",
        rowGap: "1px",
      };
    }
    return {};
  }, [popoverType]);

  const renderBtnText = (text: string) => {
    const isImage = typeof text === 'string' && text.includes('image/');
    return isImage ? (
      <img src={text} alt="icon" style={{ width: "24px", height: "24px" }} />
    ) : (
      text
    );
  };

  // Input Container styling
  const _root = useMemo<CSSProperties>(() => {
    const baseStyle = {
      ...fonts.paragraph,
      ...fonts.fontSize4,
      color: colors.greyDarkPrimary,
      height: "25px",
      backgroundColor: colors.greyTertiary,
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "5px",
      overflow: "hidden",
      cursor: "pointer"
    }
    return Object.assign({}, baseStyle, overrides?.value || {})
  }, [overrides?.value]);
  
  return (
    <StatefulPopover
      showArrow={true}
      onClose={() => setIsOpen(false)}
      placement={PLACEMENT.top}
      initialState={{ isOpen: false }}
      content={({close}) => (
        <Block >
          <div style={{ padding: "10px 10px 5px 10px", textAlign: "center", ...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>
            {header}
          </div>
          
          <div style={{backgroundColor: colors.greyDarkSecondary, margin: "3px", borderRadius: "0px 0px 8px 8px",}}>
            <div style={{
              ...popoverTypeStyle,
              height: "fit-content",
              borderRadius: "0px 0px 8px 8px",
              display: "grid",
              border: "10px solid"+colors.greyDarkPrimary
            }}>
              {btnText.map((text, index: number) => (
                <div key={index}
                  onClick={() => handleButtonClick(index, close)}
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(null)}
                  style={{
                    padding: "5px",
                    minWidth: "110px",
                    backgroundColor: colors.greyDarkPrimary,
                    ...(popoverType === PopoverTypes.feedCorrection && index <= 1 && {
                      minWidth: "auto",
                    }),
                    ...(popoverType === PopoverTypes.feedCorrection && index === btnText.length - 1 && {
                      gridColumn: "span 2"
                  }),
                }}>
                  <div
                    style={{
                      ...fonts.header,
                      ...fonts.fontSize4,
                      borderRadius: "10px",
                      padding: "8px 10px",
                      color: colors.greyLightTertiary,
                      textAlign: "center",
                      cursor: "pointer",
                      backgroundColor: isHovered === index ? colors.greyDarkSecondary : "",
                    }}
                  >
                   {renderBtnText(text)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Block>
      )}
      overrides={{
        Arrow: { style: () => Object.assign({}, {
            backgroundColor: colors.blueDarkPrimary,
            width: "30px",
            height: "30px",
          } as React.CSSProperties 
        )},
        Body: { style: () => Object.assign({}, {
            backgroundColor: colors.blueDarkPrimary,
            borderRadius: "10px",
            width: "fit-content",
            height: "fit-content",
          } as React.CSSProperties 
        )},
        Inner: { style: () => Object.assign({}, {
            backgroundColor: colors.blueDarkPrimary,
            borderRadius: "10px"
          } as React.CSSProperties 
        )},
      }}
    >
      {/* Input Container */}
      <div 
        onClick={() => setIsOpen(true)}
        onChange={handleInputChange}
        style={{ ..._root
        }}
      >{inputType === InputTypes.Number ? value : String(value)}</div>
    </StatefulPopover>
  );
}
const FeedPopover = forwardRef(_FeedPopover)
export default FeedPopover
