import { useMemo } from "react"
import { useApolloClient } from "@apollo/client"

import { Graph_QL, DynamicDataObk, StateDataObject, GqlHook_Returns } from '../';

import { useAppSelector, useAppDispatch, RootState } from '../../../Store'
import { EntityState } from "@reduxjs/toolkit"
import { gqlActions } from "../gqlData_Reducer"

/* eslint-disable */
import { GET_SECTIONS, GetSectionsArgs, GetSectionsResponse, Section } from "./GQLCalls/getCalls"
import { GET_PENS, GetPensArgs, GetPensResponse, Pen } from "./GQLCalls/getCalls"

import { StrucktureStates, Struckture_Item } from "./DomainSlices/strucktureSlice"
import { filterByConnection } from "../filterByConnection";
/* eslint-enable */

export const useGetSections = (connection?:Graph_QL.GQLCons):GqlHook_Returns<StrucktureStates> => {
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const StrucktureStates = useAppSelector(({graphQlData:{domain:{struckture}}}) => struckture)
  const {fullState, Sections, Pens} = useMemo(() => {
    var Transformed_state = filterByConnection(StrucktureStates, Graph_QL.ToArr(connection)) as Record<string, Struckture_Item>
    return Object.entries(Transformed_state).reduce((acc, [DomainKey, DomainData]) => {
      let DomainDatas = DomainData.data
      //Sections
      if (DomainDatas.Sections) acc.Sections = acc.Sections.concat({...DomainDatas.Sections, domain:DomainKey})
      //Pens
      if (DomainDatas.Pens) acc.Pens = acc.Pens.concat({...DomainDatas.Pens, domain:DomainKey})
      return acc
    }, {fullState:Transformed_state, Sections:[], Pens:[]} as StateDataObject<StrucktureStates>)
  }, [StrucktureStates])

  const getSections = async (specefic?:Graph_QL.GQLCons) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<GetSectionsResponse>({query:GET_SECTIONS, variables:_Connection.connection as GetSectionsArgs})
        return {con:_Connection.connection, data:datas.data.getSections}
      })).then(e => {
        dispatch({type:''+gqlActions.domain.struckture.sections, payload:e})
      })
    }
  }

  const getPens = async (specefic?:Graph_QL.GQLCons) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<GetPensResponse>({query:GET_PENS, variables:_Connection.connection as GetPensArgs})
        return {con:_Connection.connection, data:datas.data.getPens}
      })).then(e => {
        dispatch({type:''+gqlActions.domain.struckture.pens, payload:e})
      })
    }
  }

  const getAll = async (specefic?:Graph_QL.GQLCons) => {
    getSections(specefic)
    getPens(specefic)
  }

  const deleteEntries = (_Data?: Graph_QL.Multi_ConnectionParams|Partial<DynamicDataObk<any>>|Partial<DynamicDataObk<any[]>>) => {
    if (_Data) {
      var _deleteData = Array.isArray(_Data)?_Data:[_Data]
      if (_deleteData.length) {
        dispatch({type:''+gqlActions.domain.struckture.deleted, payload:_deleteData})
      }
    }
    else dispatch({type:''+gqlActions.domain.struckture.clear, payload:undefined})
  }
  const fetchMore = async (lastid:number|number[], specefic?:Graph_QL.GQLCons) => {
    
  }

  return { call: getAll, fetchMore, deleteEntries, data:fullState, getSections, getPens, sectionsData:Sections, pensData:Pens }
}
