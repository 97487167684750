import { gql } from "@apollo/client"

export type SingleStringValue = {
  value: string;
}

export interface Def_MutationResponse {
  code: string;
  success: boolean;
  message: string;
}

export type LoginArgs = {
  username: string;
  password: string;
}

export interface LoginResponse {
  login: Def_MutationResponse;
}
export const USER_LOGIN = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      code message success
    }
  }`;

export const CREATE_USER = gql`
  query CreateUser($username: String!, $firstname: String!, $lastname: String!, $phone: String!, $email: String!, $password: String!) {
    createUser(username: $username, firstname: $firstname, lastname: $lastname, phone: $phone, email: $email, password: $password) {
      code message success
    }
  }`;

export interface Farms_Data {
  farm_id: number;
  name: string;
  domain: string;
}

export type UserFarmsArgs = {
  farm_id?: number;
}

export interface FarmsResponse {
  getUserFarms: Farms_Data[];
}
export const USER_FARMS = gql`
  query GetUserFarms {
    getUserFarms {
      domain farm_id name
    }
  }`;

export interface User_Data {
  user_id:number;
  username:string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  country: string;
  address: string;
}

export type UserInfoArgs = {
  jwt: string;
}

export interface UserInfoResponse {
  getUserInfo: User_Data;
}
export const USER_INFO = gql`
  query GetUserInfo($jwt: String!) {
    getUserInfo(jwt: $jwt) {
      username
      firstname
      lastname
      phone
      email
      country
      address
      user_id
    }
  }`;
  
export type UpdateUserInfoArgs = {
  jwt: string;
} & Partial<User_Data>

export interface UpdateUserInfoResponse {
  updateUserInfo: Def_MutationResponse;
}
export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($jwt: String!, $firstname: String!, $lastname: String!, $phone: String!, $email: String!, $country: String!, $address: String!) {
    updateUserInfo(jwt: $jwt, firstname: $firstname, lastname: $lastname, phone: $phone, email: $email, country: $country, address: $address) {
      code message success
    }
  }`;