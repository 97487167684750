import react from 'react'
import Pen_Attention from '../Pen/penAttention'

import { getSectionById } from '../../../Assets/TestData/sectionsData'

interface SectionAttention_Props {
  sectionId: number
  onPenClick?: (penId: number) => void
}
/**
 * Section_Attention
 * @param sectionId Unique identifier for each section.
 * @param onPenClick OnClick event to handle penNo.
 * @returns a section of pens for Freeda Location Feeder systems.
 */
export default function Section_Attention({ sectionId }: SectionAttention_Props) {
  const section = getSectionById(sectionId);

  // Error throwback if no section number excists
  if (!section) {
    return <div>Section {sectionId} not found</div>;
  }

  const renderContent = () => {
    return section.pens.map(pen => (
      <Pen_Attention
        key={pen.animal?.animalNo}
        penNo={pen.penNo}
      />
    ));
  }

  return (
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", width: "fit-content", gap: "10px", borderRadius: "10px", overflow: "hidden" }}>
          {renderContent()}
        </div>
      </div>
  );
}