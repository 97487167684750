import { useEffect, useMemo } from "react"
import { Graph_QL, useGetDomainLog } from "../../Services/Agrisys_GqlDataCaller"
import { useTranslation } from 'react-i18next';
import { colors, css, frames } from "../../Styles";
import { Button, TableList } from "../../Components";
import PageHeader from "../../Components/BaseComponents/pageHeader";

export default function Logbook() {
  const {t} = useTranslation()

  // const {call: FetchLog, data:LogData, dataData:LogArr} = useGetDomainLog(Graph_QL.newConnection({domain:"moelholm",gate:1}))  
  
  // useEffect(()=> {
  //   FetchLog()
  // }, [])

  // const Data = useMemo<string[][]>(() => {
  //   return Object.entries(LogData).map(([key, item]) => {
  //     return item.data.map(item => {
  //       return [new Date(item.timestamp).getTime()+'', item.message, item.gatewayindex+'', item.mapdeviceindex+'']
  //     })
  //   }).flat(1)
  // }, [LogData])

  return (
    // <frames.LogBook> 
    // <div>
    //   <PageHeader topHeader='Logbook' subHeader={t("overview")}/>
    // </div>
    //   <Button content="filtrér"/>
    //   <TableList 
    //     content={["Timestamp", "Message", "Gateway Index", "Map Device Index"]} 
    //     data={Data} 
    //     overrides={{
    //       columnTemplate:"0.8fr 1.7fr 0.75fr 0.75fr"
    //     }}
    //   /> 
    // </frames.LogBook>

    <frames.Page_inner>
    <frames._page_inner_header>
      <PageHeader topHeader={t("globalMenu.logBook")} subHeader={t("overview")}/>
    </frames._page_inner_header>
    <frames._page_inner_content>
    </frames._page_inner_content>
    </frames.Page_inner>
  )
}