import React, {CSSProperties, useEffect, useState} from "react";
import { Checkbox } from "baseui/checkbox";
import { colors, fonts, icons } from "../../Styles";

interface Check_Props {
  text?: string
  isChecked?: boolean;
  disabled?:boolean;
  onChange?: (e:boolean) => void;
  isTableEditField?: boolean;
  onBlur?: () => void;
  checkStyle?: CheckStyles
}
/**
 * Check
 * @param isChecked returns the value from data (boolean)
 * @param onChange handles the function of the check
 * @param isTableEditField if true, the checkbox has a different margin that fits the tableListEdit
 * @returns a BaseUi checkbox, build from content param. 
 */
export default function Check({ text, isChecked, disabled=false, onChange, isTableEditField, checkStyle }:Check_Props) {
  const [checked, setChecked] = useState(isChecked || false);

  const { colorStyle, borderStyle, borderRadius, size, textStyle, checkMark } = createCheckStyle(checkStyle);

  const _root = text
  ? { display: "flex", flexDirection: "row" as const, alignItems: "center", justifyContent: "start", gap: "10px"}
  : undefined

  useEffect(() => {
    setChecked(isChecked || false);
  }, [isChecked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  const EditField = () => {
    if (isTableEditField) {
      return { margin: "-4px 0px 0px -2.5px" }
      } else {
        return { margin: "0px" }
      }
    }

  return (
    <div style={_root}>
    <Checkbox disabled={disabled}
      checked={checked}
      onChange={handleChange}
      overrides={{
        Root: { style: () => Object.assign({}, {
          width: "fit-content",
          ...EditField()
        } as CSSProperties )},
        Checkmark: {
          style: () => Object.assign({}, {
            backgroundColor: colorStyle,
            border: borderStyle,
            borderRadius: borderRadius,
            width: size,
            height: size,
            margin: "0px",
            backgroundImage: !checked ? "" : checkMark,
            backgroundSize: "20px",
          } as CSSProperties )},
      }}
    />
    <p style={{...fonts.paragraph, ...fonts.fontSize3, color: textStyle}}>{text}</p>
    </div>
  );
}

interface CheckList_Props {
  text?: string[]
  isChecked?: boolean[]
  disabled?:boolean[]
  onChange?: ((e:boolean) => void)[]
  isTableEditField?: boolean
  // onBlur?: () => void;
  checkStyle?: CheckStyles
  isHorizontal?: boolean
}

export function CheckList ({text = [], isChecked = [], onChange = [], disabled = [], isTableEditField = false, checkStyle, isHorizontal}:CheckList_Props) {

  return(
    <div style={{display: "flex", flexDirection: isHorizontal?  "row" : "column", alignItems: "center", justifyContent: "start", gap: "20px"}}>
      {text.map((text, index) => (
        <Check
          key={index}
          text={text}
          isChecked={isChecked[index]}
          disabled={disabled[index]}
          onChange={onChange ? onChange[index] : undefined}
          isTableEditField={isTableEditField}
          checkStyle={checkStyle}
        />
      ))}
    </div>
  )
}

/* eslint-disable no-unused-vars */
export enum CheckStyles {
  transparent = "transparent",
  grey = "grey",
  blue = "blue"
}
/* eslint-enable no-unused-vars */

const createCheckStyle = (checkStyle?: CheckStyles | string ) => {
  let colorStyle = ""
  let borderStyle = ""
  let borderRadius = ""
  let size = ""
  let textStyle = ""
  let checkMark = ""

  switch (checkStyle) {
    case CheckStyles.transparent:
      colorStyle = colors.transparent
      borderStyle = "2px solid"+colors.greyLightTertiary
      borderRadius = "5px"
      size = "25px"
      textStyle = "none"
      checkMark = `url(${icons.check_green} )`
      break
    case CheckStyles.grey:
      colorStyle = colors.transparent
      borderStyle = "2px solid"+colors.greyLightSecondary
      borderRadius = "10px"
      size = "30px"
      textStyle = colors.greyDarkPrimary
      checkMark = `url(${icons.check_green} )`
      break
    case CheckStyles.blue:
      default:
      colorStyle = colors.blueDarkSecondary
      borderStyle = "none"
      borderRadius = "10px"
      size = "30px"
      textStyle = colors.greyLightTertiary
      checkMark = `url(${icons.check_white} )`
      break
  }

  return {colorStyle, borderStyle, borderRadius, size, textStyle, checkMark}
}