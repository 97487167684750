import React, { useEffect, useState } from "react";
import { NavigationFunction as RootNavigation, Pages } from "../../../Navigation/Routers/root"
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import { InputField, PageHeader, SelectField, ArchivedReportCard } from "../../../Components";
import { Field_Types, FieldStyle_Types } from "../../../Definitions";
import { colors, frames, mediaQueriesNumbers, useMediaQuery } from "../../../Styles";

import { TestData as ArchivedReportData } from "../../../Assets/TestData/reports";

// Extract feedCurveNo as feedCurveId from FeedCurvesData
const reportIds = ArchivedReportData.map(report => report.reportNo);

export default function Report() {
  const {t} = useTranslation()

  const navigator = useNavigate()
  const [showReport, setShowReport] = useState<number|undefined>(undefined)

  const [isFiltered, setIsFiltered] = useState<number>(0)
  const pageHeaderResponsive = useMediaQuery(`(max-width: ${mediaQueriesNumbers.pageHeaderResponsive2elementsMax}px)`);
  
  const onReportSelect = (id: number) => {
    console.log("Report selected with ID:", id);
    setShowReport(id);
    
    const selectedArchivedReportData = ArchivedReportData.find(report => report.reportNo === id);
    if (selectedArchivedReportData) {
      RootNavigation(navigator, Pages.reports, Pages.report, true, { selected: selectedArchivedReportData.reportNo });
    }
  };

  useEffect(() => {
    console.log("useEffect triggered with showReport:", showReport);
    if (showReport !== undefined) {
      const selectedArchivedReportData = ArchivedReportData.find(report => report.reportNo === showReport);
      if (selectedArchivedReportData) {
        console.log("Selected report data:", selectedArchivedReportData);
      } else {
        console.log("No report data found for reportNo:", showReport);
      }
    }
  }, [showReport]);

  const filterByOptions = [   
    { label: "Report Number", id: 0 },
    { label: "Report Name", id: 1 },
  ]

    // Handle selection change
    const handleFilterChange = (selectedOption: { id: number }) => {
      setIsFiltered(selectedOption.id);
    };

  // Sorted and filtered reports based on the selected filter
  const sortedReports = [...ArchivedReportData].sort((a, b) => {
    if (isFiltered === 0) { // Sort by Report Number
      return a.reportNo - b.reportNo;
    } else if (isFiltered === 1) { // Sort by Report Name
      return a.name.localeCompare(b.name);
    }
    return 0;
  }).map(report => ({
    id: report.reportNo,
    label: report.name,
  }));

  // TODO: Translate all above
  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between"}}>
          <PageHeader topHeader={t("reports.report")} subHeader={t("reports.archive")}/>
          <div style={{display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "start", gap: "10px"}}>
            <div style={{  display: "flex", flexDirection: "row", gap: "10px", zIndex: "10"}}>
              <SelectField onChange={() => handleFilterChange} fieldStyle={FieldStyle_Types.darkGreyInv} fieldType={ pageHeaderResponsive? Field_Types.shortInputField : Field_Types.primaryInputField} placeholder={t("filterBy")+"..."} textStart={true} isClearable={true} options={filterByOptions} />
              <InputField fieldStyle={FieldStyle_Types.darkGreyInv} placeholder={t("search")+"..."} textStart={true} fieldType={ pageHeaderResponsive? Field_Types.shortInputField : Field_Types.primaryInputField}/>
            </div>
          </div>
        </div>
      </frames._page_inner_header>
      <frames._page_inner_content>
        {/* Wrap of Reports Cards */}
        <div style={{
          height: "100%", 
          width: "100%", 
          overflowY: "scroll",     
          scrollbarColor: colors.greyDarkPrimary + colors.transparent,
          scrollbarWidth: "thin"
        }}>
          <frames.CardsColumnCount>
          {sortedReports.map((report) => (
              <ArchivedReportCard key={report.id} reportId={report.id} onReportSelect={onReportSelect} />
            ))}
          </frames.CardsColumnCount>
        </div>
      </frames._page_inner_content>
    </frames.Page_inner>
  )
}

// onChange={(e) => setIsFiltered(e ? e.id : 0)}