import { CSSProperties } from "react";
import { Field_Types, FieldStyle_Types } from "../../../Definitions";
import { DataValue, ListData_ValueTypes } from "../../../Definitions/valueTypes";
import SelectField from "../../BaseComponents/select";
import InputField from "../../BaseComponents/input";
import CheckBox from "../../BaseComponents/checkBox";
import ProgressBar from "../../ProgressViewer/progressBar";
import { icons } from "../../../Styles/images";

export interface StyledTableCellProps  {
  cellData: DataValue,

  keyPass?: string,
}

const StaticField = (value:string, style?:CSSProperties) => {
  const divStyle = {...style, opacity:0.5}
  return (
    <div style={divStyle}>
       {value}
    </div>
  )
}

export interface EditAbleCell {
  canEdit: boolean,
  staticColumn?: boolean,
  onBlur?: () => void,
  onChange?: (e:any) => void,
}

export default function StyledTableCell({cellData, canEdit, onChange, onBlur}: StyledTableCellProps & EditAbleCell) {
  const dataVal = cellData.value&&cellData.value.progressed?cellData.value.progressed:cellData.value

  if (!canEdit) {
    switch(cellData.type) {
      case ListData_ValueTypes.Div: 
        return (
          <div style={cellData.style ? cellData.style : {}}>
            {cellData.value.toString() || ''}
          </div>
        )
      case ListData_ValueTypes.Check:
        return cellData.value ? (
          <img src={icons.check_green} style={{ width: "20px" }} alt="Checked"/>
        ) : '';
      case ListData_ValueTypes.ProgressBar:
        return (
          <ProgressBar 
            total={cellData.value.total} 
            progressed={cellData.value.progressed}
          />
        )
      case ListData_ValueTypes.String:
      case ListData_ValueTypes.Number:
      case ListData_ValueTypes.Id:
        return cellData.value;
      default:
        //value = "Unknown";              
        return "";
    }
  } 
  if (cellData.select) {
    return <SelectField options={cellData.select}
      defaultValue={String(dataVal)}
      fieldType={Field_Types.tableEditField} 
      fieldStyle={FieldStyle_Types.tableEdit} 
      textStart={true}
      isTableEditField={true}
    />
  }
  else {
    /*eslint no-fallthrough: "error"*/
    switch(cellData.type) {
      case ListData_ValueTypes.Div:
        //TODO: test... value could be ofther than string ???
        var _style = cellData.style ? cellData.style : {};
        if (cellData.static) {
          _style = { ..._style, ...{ opacity: 0.5 } as CSSProperties };
          return (
            <div style={_style}>
              {dataVal.toString() || ''}
            </div>
          );
        } else {
          return <InputField
            context={dataVal + ""}
            fieldStyle="tableEdit"
            fieldType="tableEditField"
            textStart={true}
            isTableEditField={true}
            inputType="string"
            onchanged={onChange}
            onBlur={onBlur}
          />;
        }
      case ListData_ValueTypes.Check:
        return <CheckBox disabled={cellData.static}
          isTableEditField={true}
          isChecked={dataVal as boolean}
          onChange={e => {console.log("Res")}}
        />
      case ListData_ValueTypes.ProgressBar:
        return (
          <div style={{ opacity: 0.5 }}>
            <ProgressBar
              total={cellData.value.total}
              progressed={cellData.value.progressed}
            />
          </div>
        )
      case ListData_ValueTypes.Id:
        // dataVal = "#" + dataVal
        // fallsthrough
      case ListData_ValueTypes.Number:
        if (cellData.static) {
          return StaticField(String(dataVal))
        }
        else {
          return <InputField disabled={cellData.static}
            context={String(dataVal)} 
            fieldStyle="tableEdit" 
            fieldType="tableEditField" 
            textStart={true} 
            isTableEditField={true}
            inputType="number"
          />
        }
      case ListData_ValueTypes.String:
        if (cellData.static) {
          return StaticField(String(dataVal))
        }
        else {
          return <InputField 
            context={String(dataVal)} 
            fieldStyle="tableEdit" 
            fieldType="tableEditField" 
            textStart={true} 
            isTableEditField={true}
            inputType="string"
          />
        }
      default:
        if (cellData.static) {
            return (StaticField("Unknown")) 
          } else {
          return (
          <div style={{ opacity: 0.5 }}>
            {/* {"Unknown"} */}
            {""}
          </div>)
        }
    }
  }
}