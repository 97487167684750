import react, { useEffect, useState } from "react";
import { Button, ButtonTypes, DateField, Modal, SelectField, TimeField } from "../..";
import { colors, fonts, icons } from "../../../Styles";
import { Field_Types, FieldStyle_Types } from "../../../Definitions";
import { useTranslation } from "react-i18next";

interface SkipFeeding_Props{
  goBack: () => void,
}
/**
 * SkipFeeding
 * @param goBack Function to controle on "goBack" btn. 
 * @returns A modal with calendar etc. to make a feed skip.
 */
export default function SkipFeeding({goBack}:SkipFeeding_Props) {
  const {t} = useTranslation()
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedDateTime, setSelectedDateTime] = useState<string>("");
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [selectedTimeFormat, setSelectedTimeFormat] = useState<number>(0);
  const [selectedTimeAmount, setSelectedTimeAmount] = useState<number>(0)

  // Find label in select field options corresponding to their respective ID
  const findLabelById = (id: number, options: { label: string, id: number }[]) => {
    const option = options.find(option => option.id === id);
    return option ? option.label : "";
  };

  // Set the starttime to nearest positive quarter hour and the initial end time to 15 minutes after the rounded start time
  useEffect(() => {
    // Get current time and round to the nearest quarter hour
    const now = new Date();
    const minutes = now.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
  
    if (roundedMinutes === 60) {
      now.setHours(now.getHours() + 1);
      now.setMinutes(0);
    } else {
      now.setMinutes(roundedMinutes);
    }
  
    // Set the initial end time to 15 minutes after the rounded start time
    const initialEndTime = new Date(now);
    initialEndTime.setMinutes(now.getMinutes() + 15);
  
    setStartTime(now);
    setEndTime(initialEndTime);
  }, []);

  // #region | Selected Date and Time Logic
  useEffect(() => {
    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const formatTime = (date: Date) => {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    const _startDate = new Date(startDate);
    _startDate.setHours(0, 0, 0, 0);

    const _endDate = new Date(endDate);
    _endDate.setHours(0, 0, 0, 0);

    const now = new Date();
    const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0)
    const currentTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0, 0);

    switch (selectedTimeFormat) {
      case 1: // Time Amount
        if (_startDate.getTime() < currentDate.getTime()) {
          setSelectedDateTime("Ugyldig dag");
        } else if ((_startDate.getTime() === currentDate.getTime() && startTime.getTime() < currentTime.getTime())) {
          setSelectedDateTime("Ugyldigt tidspunkt");
        } else {
          setSelectedDateTime(`${formatDate(_startDate)} ${formatTime(startTime)} + ${(findLabelById(selectedTimeAmount, selectedTimeAmountOptions))}`)
        }
      break;
      case 2: // Time Span
      default:
        if (_startDate.getTime() < currentDate.getTime()) {
          setSelectedDateTime("Ugyldig dag");
        } else if ((_startDate.getTime() === currentDate.getTime() && startTime.getTime() < currentTime.getTime())) {
          setSelectedDateTime("Ugyldigt tidspunkt");
        } else if (_endDate.getTime() < _startDate.getTime()) {
          setSelectedDateTime("The end date you entered occurs before the start date.");
        } else if (_endDate.getTime() === _startDate.getTime()) {
          if (endTime.getTime() < startTime.getTime()) {
            setSelectedDateTime("The end time occurs before the start time.");
          } else if (startTime.getTime() === endTime.getTime()) {
            setSelectedDateTime(`${formatDate(_startDate)} ${formatTime(startTime)}`);
          } else {
            setSelectedDateTime(`${formatDate(_startDate)} ${formatTime(startTime)} - ${formatTime(endTime)}`);
          }
        } else {
          if (endTime.getTime() < startTime.getTime()) {
            setSelectedDateTime("The end time occurs before the start time.");
          } else {
            setSelectedDateTime(`${formatDate(_startDate)} ${formatTime(startTime)} - ${formatDate(_endDate)} ${formatTime(endTime)}`);
          }
        }
      break;
      }
    }, [startDate, endDate, startTime, endTime, selectedTimeFormat, selectedTimeAmount]);
  // #endregion

  // #region | Time Format and Time Amount options
  // Time Format
  const selectedTimeFormatOptions = [
    {label: t("locationFeeder.planSkip.timeSpan"), id: 0},
    {label: t("locationFeeder.planSkip.timeAmount"), id: 1}
  ];

  // Time Amount
  const selectedTimeAmountOptions = [                      
    {label:"1 " + t("units.hour"), id:0},  
    {label:"2 " + t("units.hours"), id:1},
    {label:"3 " + t("units.hours"), id:2},
    {label:"4 " + t("units.hours"), id:3},
    {label:"5 " + t("units.hours"), id:4},
    {label:"6 " + t("units.hours"), id:5},
    {label:"7 " + t("units.hours"), id:6},
    {label:"8 " + t("units.hours"), id:7},
    {label:"9 " + t("units.hours"), id:8},
    {label:"10 " + t("units.hours"), id:9},
    {label:"11 " + t("units.hours"), id:10},
    {label:"12 " + t("units.hours"), id:11},
    {label:"13 " + t("units.hours"), id:12},
    {label:"14 " + t("units.hours"), id:13},
    {label:"15 " + t("units.hours"), id:14},
    {label:"16 " + t("units.hours"), id:15},
    {label:"17 " + t("units.hours"), id:16},
    {label:"18 " + t("units.hours"), id:17},
    {label:"19 " + t("units.hours"), id:18},
    {label:"20 " + t("units.hours"), id:19},
    {label:"21 " + t("units.hours"), id:20},
    {label:"22 " + t("units.hours"), id:21},
    {label:"23 " + t("units.hours"), id:22},
    {label:"24 " + t("units.hours"), id:23}
  ]
  // #endregion

  return (
    <Modal 
      modalHeader={t("locationFeeder.planSkip.planSkip")}
      modalSubHeader={"Farrowing stable section x pen x"} //TODO: Systemnavn, section nr, pen nr. HVis flere pens valgt skal disse "listes op"
      overrides={{ 
        isPrimaryComponent: true 
      }}
      // onCloseBtn="close"
      // onClose={() => {
      //   if (onClose) onClose()
      // }}
      goBackBtn={t("buttons.goBackBtn")}
      onGoBack={() => {
        if (goBack) goBack()
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "end", gap: "40px"}}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "15px", height: "155px"}}>
            <SelectField
              defaultValue={findLabelById(selectedTimeFormat, selectedTimeFormatOptions)} // Set default value to "Time Span"
              options={selectedTimeFormatOptions}
              textStart={true}
              onChange={(e) => setSelectedTimeFormat(parseInt(e[0].id + ''))}
            />
            <div>
              <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.startDate")}</p>
              <DateField value={startDate} onChange={({ date }) => setStartDate(date)} />
            </div>
            {selectedTimeFormat === 0 
              ? <div>
                  <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.endDate")}</p>
                  <DateField value={endDate} onChange={({ date }) => setEndDate(date)} />
                </div> 
              : selectedTimeFormat === 1 ? 
                <div>
                </div> 
              : null 
            }
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", gap: "15px", height: "155px"}}>
            <div>
              <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.startTime")}</p>
              <TimeField value={startTime} onChange={(date) => setStartTime(date)} />
            </div>
            {selectedTimeFormat === 0 
              ? <div>
                  <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.endTime")}</p>
                  <TimeField value={endTime} onChange={(date) => setEndTime(date)} />
                </div>
              : selectedTimeFormat === 1 ?
                <div>
                  <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.timeAmount")}</p>
                  <SelectField 
                    startEnhancer={icons.time_white}               
                    defaultValue={findLabelById(selectedTimeAmount, selectedTimeAmountOptions)}
                    options={selectedTimeAmountOptions}
                    textStart={true}
                    onChange={(e) => {setSelectedTimeAmount(parseInt(e[0].id + ''));}}
                    fieldType={Field_Types.popoverDropdown}
                    fieldStyle={FieldStyle_Types.popoverDropdown}
                  />
                </div>
              : null
            }
          </div>
        </div>
        <p style={{ ...fonts.paragraph, color: colors.greyLightTertiary, ...fonts.fontSize3 }}>
          {t("locationFeeder.planSkip.plannedSkip")}: {selectedDateTime}
        </p>
        <Button 
          content={t("buttons.saveBtn") + " " + t("locationFeeder.planSkip.plannedSkip")}
          overrides={{
            buttonType: ButtonTypes.tertiaryButton
          }}
          // onClick={} TODO: gem til db og luk modal
        />
      </div>
    </Modal>
  )
}