//import { client } from '../../api/client'
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Graph_QL, DynamicDataObk } from '../../';

import { GET_LIVE_STATE, GetLiveStateArgs, LiveStateItem, LiveStateResponse } from "../device_QlCalls"

export type LiveState_Item = DynamicDataObk<LiveStateItem>
const dataAdapter = createEntityAdapter({
  selectId: (item:LiveState_Item) => Graph_QL.ObjToString(item.con),
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
})

//TODO: update like logSlice
const DeviceStateSlice = createSlice({
  name: 'device/state',  initialState:dataAdapter.getInitialState<LiveState_Item[]>([]),
  reducers: {
    added: (state, {payload}:PayloadAction<LiveState_Item[]>) => {
      payload.forEach(entry => { //Does not contain multiple data entries - skip Data lookup - and do full replace
        //Data Transform
        var datas = {...entry.data as LiveStateItem}
        delete datas.__typename
        dataAdapter.upsertOne(state, {con:entry.con, data:datas})
      })
    },
    deleted: (state, {payload}:PayloadAction<LiveState_Item[]|Graph_QL.Connection[]>) => {
      payload.forEach(entries => {
        //For each referance: check if exists..
        var entriesCon:Graph_QL.ConnectionObj|undefined = undefined
        if (Object.hasOwn(entries, "con")) entriesCon = (entries as LiveState_Item).con
        else if (Object.hasOwn(entries, "data")) {
          entriesCon = Graph_QL.StringtoObj(((entries as LiveState_Item).data as (LiveStateItem & Pick<Graph_QL.ConnectionParams, "domain">)).domain)
        }
        else entriesCon = (entries as Graph_QL.Connection).connection
        if (entriesCon) {
          var Current = state.ids.find(e => e === Graph_QL.ObjToString(entriesCon as Graph_QL.ConnectionObj))
          if (Current) {
            //Does not contain multiple data entries - skip Data lookup - and do full replace
            dataAdapter.removeOne(state, Current)
          }
        }
      })
    },
    clear: (state, {payload}:PayloadAction<Graph_QL.Connection[]|undefined>) => {
      if (payload) dataAdapter.removeMany(state, payload.map(e => e.toString()))
      else dataAdapter.removeAll(state)
    }
  }
})

//const { actions, reducer } = ActiveSlice
//export const { added, deleted } = LogSlice.actions
export const DeviceStateActions = DeviceStateSlice.actions
export default DeviceStateSlice.reducer