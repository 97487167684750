import { useState } from "react"
import { colors, fonts, icons, useMediaQuery } from "../../Styles"

interface ReportShortCut_Props {
  header: string
  amount: number
  onClick?: () => void
}
/**
 * ReportShortCut
 * @param header String to show.
 * @param amount Number to show.
 * @param onClick Function to handle onClick.
 * @returns A clickable component that shows an amount and header.
 */
export default function ReportShortCut({header, amount, onClick}:ReportShortCut_Props) {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const tablet = useMediaQuery(`(min-width: 601px) and (max-width: 1021px)`);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}  
      style={{ cursor: isHovered? "pointer" : "default", opacity: isHovered? "0.3" : "1", transition: "0.3s ease", backgroundColor: colors.greyLightTertiary, border: "5px solid"+colors.greyPrimary, borderRadius: "10px", height: "45px", width: tablet? "100%" : "fit-content", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
    >  
      <div style={{width: "45px", height: "70%", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "2px solid"+colors.greyLightSecondary}}><p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{amount}</p></div>
      <div style={{width: tablet? "100%" : "170px", padding: "0px 20px"}}><p style={{...fonts.paragraph, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{header}</p></div>
      <div onClick={onClick} style={{width: "45px", backgroundColor: colors.greyPrimary, height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><img src={icons.goRightArrow} style={{width: "25px"}} alt="Go To"/></div>
    </div>
  )
}