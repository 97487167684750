import { FeedCurveInfo } from "../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall";
  export const TestData: FeedCurveInfo[] = [
    {
      id: 1,
      name: "Normal sow holland",
      default: true,
      active: true,
      
      milestones: [
        {milestone: "Insemination", startDay: 1},
        {milestone: "Moved to farrowing", startDay: 115},
        {milestone: "Farrowing", startDay: 126}
      ],
      mixture:[
          {day: 1,  component: 1, amount: 2.9, ds: 75},
          {day: 29, component: 1, amount: 2.9, ds: 75},
          {day: 34, component: 1, amount: 2.7, ds: 75},
          {day: 114,component: 1, amount: 2.7, ds: 75},
          {day: 115,component: 3, amount: 2.6, ds: 75},
          {day: 125,component: 3, amount: 2.6, ds: 75},
          {day: 126,component: 3, amount: 3.2, ds: 75},
          {day: 127,component: 3, amount: 3.2, ds: 75},
          {day: 128,component: 3, amount: 3.4, ds: 75},
          {day: 129,component: 3, amount: 3.7, ds: 75},
          {day: 130,component: 3, amount: 4.1, ds: 75},
          {day: 131,component: 3, amount: 4.6, ds: 75},
          {day: 132,component: 3, amount: 5, ds: 75},
          {day: 133,component: 3, amount: 5.5, ds: 75},
          {day: 136,component: 3, amount: 6.7, ds: 75},
          {day: 140,component: 3, amount: 8, ds: 75},
          {day: 143,component: 3, amount: 8.6, ds: 75},
          {day: 154,component: 3, amount: 8.6, ds: 75},
          {day: 161,component: 3, amount: 8.6, ds: 75},
          {day: 188,component: 3, amount: 8.6, ds: 75},
      ],
    },
    {
      id: 2,
      name: "Thin sow holland",
      default: true,
      active: true,
      
      milestones: [
        {milestone: "Insemination", startDay: 1},
        {milestone: "Moved to farrowing", startDay: 115},
        {milestone: "Farrowing", startDay: 126}
      ],
      mixture:[
          {day: 1,  component: 1, amount: 3.9, ds: 75},
          {day: 29, component: 1, amount: 3.9, ds: 75},
          {day: 34, component: 1, amount: 3, ds: 75},
          {day: 114,component: 1, amount: 3, ds: 75},
          {day: 115,component: 3, amount: 2.8, ds: 75},
          {day: 125,component: 3, amount: 2.8, ds: 75},
          {day: 126,component: 3, amount: 3.4, ds: 75},
          {day: 127,component: 3, amount: 3.4, ds: 75},
          {day: 128,component: 3, amount: 3.7, ds: 75},
          {day: 129,component: 3, amount: 4.1, ds: 75},
          {day: 130,component: 3, amount: 4.5, ds: 75},
          {day: 131,component: 3, amount: 4.9, ds: 75},
          {day: 132,component: 3, amount: 5.4, ds: 75},
          {day: 133,component: 3, amount: 5.9, ds: 75},
          {day: 136,component: 3, amount: 6.8, ds: 75},
          {day: 140,component: 3, amount: 8.1, ds: 75},
          {day: 143,component: 3, amount: 8.7, ds: 75},
          {day: 154,component: 3, amount: 8.7, ds: 75},
          {day: 161,component: 3, amount: 8.7, ds: 75},
          {day: 186,component: 3, amount: 8.7, ds: 75},
      ],
    },
    {
      id: 3,
      name: "Fat sow holland",
      default: true,
      active: true,
      
      milestones: [
        {milestone: "Insemination", startDay: 1},
        {milestone: "Moved to farrowing", startDay: 115},
        {milestone: "Farrowing", startDay: 126}
      ],
      mixture:[
          {day: 1,  component: 1, amount: 2.4, ds: 75},
          {day: 29, component: 1, amount: 2.4, ds: 75},
          {day: 34, component: 1, amount: 2.6, ds: 75},
          {day: 114,component: 1, amount: 2.6, ds: 75},
          {day: 115,component: 3, amount: 2.6, ds: 75},
          {day: 125,component: 3, amount: 2.6, ds: 75},
          {day: 126,component: 3, amount: 3, ds: 75},
          {day: 127,component: 3, amount: 3, ds: 75},
          {day: 128,component: 3, amount: 3.2, ds: 75},
          {day: 129,component: 3, amount: 3.5, ds: 75},
          {day: 130,component: 3, amount: 4, ds: 75},
          {day: 131,component: 3, amount: 4.5, ds: 75},
          {day: 132,component: 3, amount: 4.9, ds: 75},
          {day: 133,component: 3, amount: 5.5, ds: 75},
          {day: 136,component: 3, amount: 6.7, ds: 75},
          {day: 140,component: 3, amount: 8, ds: 75},
          {day: 143,component: 3, amount: 8.6, ds: 75},
          {day: 154,component: 3, amount: 8.6, ds: 75},
          {day: 161,component: 3, amount: 8.6, ds: 75},
          {day: 186,component: 3, amount: 8.6, ds: 75},
      ],
    },
    {
      id: 4,
      name: "Sow with Deworming",
      default: true,
      active: true,
      
      milestones: [
        {milestone: "Insemination", startDay: 1},
        {milestone: "Moved to farrowing", startDay: 117},
        {milestone: "Farrowing", startDay: 127}
      ],
      mixture:[
          {day: 1,   component: 2, amount: 3, ds: 75},
          {day: 28,  component: 2, amount: 3, ds: 75},
          {day: 35,  component: 2, amount: 2.5, ds: 75},
          {day: 89,  component: 2, amount: 2.5, ds: 75},
          {day: 90,  component: 2, amount: 2.5, ds: 75},
          {day: 90,  component: 6, amount: 0.6, ds: 75},
          {day: 91,  component: 2, amount: 2.5, ds: 75},
          {day: 95,  component: 2, amount: 3.5, ds: 75},
          {day: 113, component: 2, amount: 3.5, ds: 75},
          {day: 114, component: 2, amount: 2.5, ds: 75},
          {day: 116, component: 2, amount: 2.5, ds: 75},
          
          {day: 117, component: 4, amount: 2, ds: 75},
          {day: 126, component: 4, amount: 2, ds: 75},

          {day: 127, component: 4, amount: 1.5, ds: 75},
          {day: 128, component: 4, amount: 1.5, ds: 75},
          {day: 129, component: 4, amount: 2, ds: 75},
          {day: 131, component: 4, amount: 3, ds: 75},
          {day: 133, component: 4, amount: 4, ds: 75},
          {day: 135, component: 4, amount: 5, ds: 75},
          {day: 137, component: 4, amount: 6.5, ds: 75},
          {day: 139, component: 4, amount: 7, ds: 75},
          {day: 141, component: 4, amount: 7.5, ds: 75},
          {day: 143, component: 4, amount: 8.5, ds: 75},
          {day: 145, component: 4, amount: 9, ds: 75},
          {day: 167, component: 4, amount: 9, ds: 75},
      ],
    },
    {
      id: 5,
      name: "2 feed types",
      default: true,
      active: true,
      
      milestones: [
        {milestone: "Insemination", startDay: 1},
        {milestone: "Moved to farrowing", startDay: 117},
        {milestone: "Farrowing", startDay: 127}
      ],
      mixture:[
          {day: 1,    component: 1, amount: 2.6, ds: 75},
          {day: 51,   component: 1, amount: 2.63, ds: 75},
          {day: 71,   component: 1, amount: 1.3, ds: 75},
          {day: 71,   component: 2, amount: 1.3, ds: 75},
          {day: 91,   component: 2, amount: 2.6, ds: 75},
          {day: 98,   component: 2, amount: 2.6, ds: 75},
          {day: 111,  component: 2, amount: 3.6, ds: 75},
          {day: 118,  component: 2, amount: 3.6, ds: 75},
          
          
          {day: 119,  component: 3, amount: 2.4, ds: 75},
          {day: 129,  component: 3, amount: 2.4, ds: 75},

          {day: 130,  component: 3, amount: 2.8, ds: 75},
          {day: 131,  component: 3, amount: 2.8, ds: 75},
          {day: 132,  component: 3, amount: 3, ds: 75},
          {day: 133,  component: 3, amount: 3.3, ds: 75},
          {day: 134,  component: 3, amount: 3.6, ds: 75},
          {day: 135,  component: 3, amount: 3.9, ds: 75},
          {day: 136,  component: 3, amount: 4.2, ds: 75},
          {day: 137,  component: 3, amount: 4.6, ds: 75},
          {day: 140,  component: 3, amount: 5.9, ds: 75},
          {day: 144,  component: 3, amount: 7.2, ds: 75},
          {day: 147,  component: 3, amount: 7.8, ds: 75},
          {day: 151,  component: 3, amount: 7.8, ds: 75},
          {day: 165,  component: 3, amount: 7.8, ds: 75},
          {day: 190,  component: 3, amount: 7.8, ds: 75},
      ],
    }
  ];
  export const TestData2: FeedCurveInfo[] = [
    {
      id: 1,
      name: "Duroc Standard",
      default: true,
      active: true,
      
      
      milestones: [
        {milestone: "Insemination", startDay: 1},
        {milestone: "Farrowing", startDay: 3},
        {milestone: "Weaning", startDay: 5}
      ],
      mixture:[
          {day: 1, component: 1, amount: 0.5, ds: 75},
          {day: 1, component: 3, amount: 0.75, ds: 50},
          {day: 1, component: 4, amount: 0.75, ds: 50},
          {day: 2, component: 1, amount: 0.5, ds: 50},
          {day: 2, component: 3, amount: 0.75, ds: 50},
          {day: 3, component: 1, amount: 0.5, ds: 50},
          {day: 3, component: 2, amount: 0.5, ds: 50},
          {day: 4, component: 1, amount: 0.5, ds: 50},
          {day: 4, component: 2, amount: 0.5, ds: 50},
          {day: 5, component: 1, amount: 0.5, ds: 50},
          {day: 5, component: 2, amount: 0.5, ds: 50},
          {day: 6, component: 2, amount: 0.5, ds: 10},
          {day: 7, component: 4, amount: 0.5, ds: 50},
          {day: 8, component: 4, amount: 0.5, ds: 50},
          {day: 9, component: 4, amount: 0.5, ds: 50},
          {day: 10, component: 4, amount: 1, ds: 100},
      ],
    },
    {
      id: 3,
      name: "CTO Standard Feed",
      default: true,
      active: true,
      
      
      milestones: [
        {milestone: "Insemination", startDay: 1, },
        {milestone: "Farrowing", startDay: 3, },
        {milestone: "Weaning", startDay: 5, }
      ],
      
      mixture:[
          {day: 1, component: 1, amount: 0.1, ds: 50},
          {day: 5, component: 1, amount: 0.5, ds: 50},
          {day: 6, component: 1, amount: 0, ds: 50},
          {day: 2, component: 2, amount: 0, ds: 50},
          {day: 4, component: 2, amount: 0.5, ds: 50},
          {day: 6, component: 2, amount: 0.5, ds: 50},
          {day: 7, component: 2, amount: 0, ds: 50},
          {day: 1, component: 3, amount: 0.5, ds: 50},
          {day: 2, component: 3, amount: 0.75, ds: 50},
          {day: 3, component: 3, amount: 0, ds: 50},
          {day: 1, component: 4, amount: 0.75, ds: 50},
          {day: 2, component: 4, amount: 0, ds: 50},
          {day: 6, component: 4, amount: 0, ds: 50},
          {day: 7, component: 4, amount: 0.5, ds:50},
          {day: 11, component: 4, amount: 0.75, ds: 50},
          {day: 12, component: 4, amount: 1, ds:100},
        ],
      },
        {
      id: 2,
      name: "Duroc Skinny",
      default: true,
      active: true,
      
      
      milestones: [
        {milestone: "Insemination", startDay: 1, },
        {milestone: "Farrowing", startDay: 3, },
        {milestone: "Weaning", startDay: 5, }
      ],

      mixture:[
          {day: 1, component: 7, amount: 0.5, ds: 50},
          {day: 1, component: 8, amount: 0.75, ds: 50},
          {day: 1, component: 9, amount: 0.75, ds: 50},
          {day: 2, component: 7, amount: 0.5, ds: 50},
          {day: 2, component: 8, amount: 0.75, ds: 50},
          {day: 3, component: 7, amount: 0.5, ds: 50},
          {day: 3, component: 8, amount: 0.5, ds: 50},
          {day: 4, component: 7, amount: 0.5, ds: 50},
          {day: 4, component: 8, amount: 0.5, ds: 50},
          {day: 5, component: 7, amount: 0.5, ds: 50},
          {day: 5, component: 8, amount: 0.5, ds: 50},
          {day: 6, component: 7, amount: 0.5, ds: 50},
          {day: 7, component: 7, amount: 0.5, ds: 50},
          {day: 8, component: 7, amount: 0.5, ds: 50},
          {day: 9, component: 7, amount: 0.5, ds: 50},
          {day: 10, component: 7, amount: 1, ds: 100},
        ],
      }, {
        id: 4,
        name: "CTO Skinny Feed",
        default: true,
        active: true,
        
        
        milestones: [
          {milestone: "Insemination", startDay: 1, },
          {milestone: "Farrowing", startDay: 5, },
          {milestone: "Weaning", startDay: 11, }
        ],
        
        mixture:[
            {day: 1, component: 1, amount: 0.1, ds: 50},
            {day: 2, component: 1, amount: 0.2, ds: 50},
            {day: 4, component: 1, amount: 0.4, ds: 50},
            {day: 5, component: 1, amount: 0.5, ds: 50},
            {day: 4, component: 2, amount: 0.5, ds: 66},
            {day: 5, component: 2, amount: 0.5, ds: 66},
            {day: 6, component: 2, amount: 0.5, ds: 66},
            {day: 1, component: 3, amount: 0.5, ds: 50},
            {day: 2, component: 3, amount: 0.75, ds: 50},
            {day: 1, component: 4, amount: 0.75, ds: 50},
            {day: 7, component: 4, amount: 0.5, ds:50},
            {day: 11, component: 4, amount: 0.75, ds: 50},
            {day: 12, component: 4, amount: 1, ds:100},
          ],
        },{
          id: 5,
          name: "CTO Skinny Feed",
          default: true,
          active: true,
          
          
          milestones: [
            {milestone: "Insemination", startDay: 1, },
            {milestone: "Farrowing", startDay: 5, },
            {milestone: "Weaning", startDay: 11, }
          ],
          
          mixture:[
              {day: 1, component: 1, amount: 0.1, ds: 50},
              {day: 2, component: 1, amount: 0.2, ds: 50},
              {day: 4, component: 1, amount: 0.4, ds: 50},
              {day: 5, component: 1, amount: 0.5, ds: 50},
              {day: 4, component: 2, amount: 0.5, ds: 66},
              {day: 5, component: 2, amount: 0.5, ds: 66},
              {day: 6, component: 2, amount: 0.5, ds: 66},
              {day: 1, component: 3, amount: 0.5, ds: 50},
              {day: 2, component: 3, amount: 0.75, ds: 50},
              {day: 1, component: 4, amount: 0.75, ds: 50},
              {day: 2, component: 4, amount: 0, ds: 50},
              {day: 4, component: 4, amount: 1, ds: 50},
              {day: 7, component: 4, amount: 0.5, ds:50},
              {day: 11, component: 4, amount: 0.75, ds: 50},
              {day: 12, component: 4, amount: 1, ds:100},
            ],
          }
  ]

// Function to get a feedcurve by ID
export function getFeedCurveById(id: number): FeedCurveInfo | undefined {
    return TestData.find(feedCurve => feedCurve.id === id);
}