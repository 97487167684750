import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { colors, css, fonts, frames, icons } from "../../../Styles";
import PageHeader from '../../../Components/BaseComponents/pageHeader';
import { useTranslation } from 'react-i18next';
// import { Button, ButtonList, ButtonTypes, Modal, TableList } from '../../../Components';
// import { AnimalTypes, Group, Location, TestData } from "../../../Assets/TestData/animalData"
// import { TableStyles_Types } from '../../../Definitions/tableStyles';
// import TableListEdit, { TableList_PropHandles } from '../../../Components/Tables/tableListEdit';
// import { ListData_ValueTypes, Data, DataValue } from '../../../Definitions/valueTypes';
// import { ConditionData } from '../../../Assets/TestData/conditions';
// import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';
// import ProgressBar from '../../../Components/ProgressViewer/progressBar';
// import { t } from 'i18next';
// import InputField, { InputFieldList } from '../../../Components/BaseComponents/input';
// import { SelectFieldList } from '../../../Components/BaseComponents/select';
// import FeedCurve from '../FeedCurves/feedCurve';
// import { AttentionTypes } from '../../../Components/AttentionComponents/attentionPopover';
// import TableData from '../../../Components/Tables/tableData';
// import { CategoricalColumn, StringColumn, NumericalColumn, CustomColumn } from "baseui/data-table";

export default function AnimalTable() {
  const { t } = useTranslation();

  // const Data = TestData
  // const Current_Data = useMemo<Data[]>(() => {
  //   var Res = Data.map(animal => {
  //     var Res_Data:Data = []

  //     // ProgressBar based on animal's totalFood day
  //     const progressBarTotal = `${animal.totalFood}` as unknown as number // TODO: er det ok med "unknown"?
  //     const progressBarProgressedToday = `${animal.remainingToday}` as unknown as number // TODO: er det ok med "unknown"?
  //     const progressBarProgressedYesterday = `${animal.remainingYesterday}` as unknown as number // TODO: er det ok med "unknown"?
  //     // Today
  //     const progressBarToday = ( <ProgressBar 
  //       total={progressBarTotal} 
  //       progressed={progressBarProgressedToday}
  //     />)
  //     // Yesterday
  //     const progressBarYesterday = ( <ProgressBar 
  //       total={progressBarTotal} 
  //       progressed={progressBarProgressedYesterday}
  //     />)

  //     // Condition
  //     const condition = ConditionData.find(cond => cond.conditionNo === animal.condition) || { name: "No data", colorTag: colors.greyPrimary };
      
  //     // Feed Plan
  //     const feedCurve = FeedCurveData.find(cond => cond.id === animal.feedPlan) || { name: "No data" };

  //     Res_Data[0] = { type:ListData_ValueTypes.Id, value: animal.animalNo, static: true } as DataValue
  //     Res_Data[1] = { type:ListData_ValueTypes.String, value: animal.location, static: true } as DataValue
  //     Res_Data[2] = { type:ListData_ValueTypes.Number, value: animal.responderNo } as DataValue
  //     Res_Data[3] = { type:ListData_ValueTypes.Number, value: animal.lifeNo } as DataValue
  //     Res_Data[4] = { type: ListData_ValueTypes.ProgressBar, value: progressBarToday, static: true } as unknown as DataValue      
  //     Res_Data[5] = { type: ListData_ValueTypes.ProgressBar, value: progressBarYesterday, static: true } as unknown as DataValue      
  //     Res_Data[6] = { type:ListData_ValueTypes.Div, value: condition.name, select: [{label:condition.name}], style:{backgroundColor: condition.colorTag, ...css.colorTag, ...fonts.header, ...fonts.fontSize4, color: colors.whitePrimary} } as DataValue
  //     Res_Data[7] = { type:ListData_ValueTypes.String, value: feedCurve.name, select: [{label:feedCurve.name}]} as DataValue
  //     Res_Data[8] = { type:ListData_ValueTypes.Number, value: animal.group, select: [{label:animal.group}] } as DataValue
  //     Res_Data[9] = { type:ListData_ValueTypes.String, value: animal.animalType, static: true } as DataValue
  //     Res_Data[10] = { type:ListData_ValueTypes.String, value: animal.latestMilestone, static: true } as DataValue
  //     Res_Data[11] = { type:ListData_ValueTypes.Number, value: animal.milestoneDay, static: true } as DataValue
  //     Res_Data[12] = { type:ListData_ValueTypes.Number, value: animal.cycleDayTotal, static: true } as DataValue
  //     return Res_Data
  //   })
    
  //   return Res
  // }, [Data]); 

  // const columns = useMemo(() => {
  //   return [
  //     createCustomColumn("Animal No.", (data) => data[0].value),
  //     createCustomColumn("Location", (data) => data[1].value),
  //     createCustomColumn("Responder No.", (data) => data[2].value),
  //     createCustomColumn("Life No.", (data) => data[3].value),
  //     createCustomColumn("Remaining Today", (data) => data[4].value),
  //     createCustomColumn("Remaining Yesterday", (data) => data[5].value),
  //     createCustomColumn("Condition", (data) => data[6].value),
  //     createCustomColumn("Feed Plan", (data) => data[7].value),
  //     createCustomColumn("Group", (data) => data[8].value),
  //     createCustomColumn("Animal Type", (data) => data[9].value),
  //     createCustomColumn("Latest Milestone", (data) => data[10].value),
  //     createCustomColumn("Days since Milestone", (data) => data[11].value),
  //     createCustomColumn("Total cycle day", (data) => data[12].value),
  //   ];
  // }, [Current_Data]);

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        
        <PageHeader topHeader={t("globalMenu.animals")} subHeader={t("globalMenu.animalGroups")}/>

      </frames._page_inner_header>
      <frames._page_inner_content>

        {/* <TableData isFilterAble={true}
          data={Current_Data}
          content={["Animal No.", "Location", "Responder No.", "Life No.", "Remaining Today", "Remaining Yesterday", "Condition", "Feed Plan", "Group", "Animal Type", "Latest Milestone", "Days since Milestone", "Total cycle day"]}
          tableType={TableStyles_Types.secondaryTable}
          overrides={{
            columnTemplate: "repeat(13, minmax(190px, 1fr))",
            // word-break: keep-all TODO Maja
            addEmptyRows: 10
        }}/> */}
        {//<TableData columns={2} titles={["Animal No.", "Location"]} data={[["test", "tester"],["is","good"],["test", "tester"],["is","good"]]}/>
        }
              
      </frames._page_inner_content>

    </frames.Page_inner>
  )
}