import { colors } from "../../Styles";

  export type Condition = {
    conditionNo: number; //DataTYPE ID, static
    name: string; 
    colorTag: string;
  };
  
  export const ConditionData: Condition[] = [
    {
      conditionNo: 1,
      name: "Normal",
      colorTag: colors.systemGreen,
    },
    {
      conditionNo: 2,
      name: "Skinny",
      colorTag: colors.systemYellow,
    },
    {
      conditionNo: 3,
      name: "Very Skinny",
      colorTag: colors.systemYellow,
    },
    {
      conditionNo: 4,
      name: "Fat",
      colorTag: colors.systemRed,
    },
    {
      conditionNo: 5,
      name: "Very Fat",
      colorTag: colors.systemRed,
    },
  ]

