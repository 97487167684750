import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer, REHYDRATE, PERSIST
  //FLUSH, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

export interface ActiveStates {
  farm: string|undefined,
  section: string|undefined,
  pen: number|undefined,
  report: string|undefined,
  autopig: string|{systemId:string, FarmId:string}|undefined,
  isShared: string|undefined
}
type ActiveState_Keys = keyof ({all:any} & ActiveStates)

const initialState:ActiveStates = {
  farm: undefined,
  section: undefined,
  pen: undefined,
  report: undefined,
  autopig: undefined,
  isShared: undefined
}


const ActiveSlice = createSlice({
  name: 'active', initialState,
  reducers: {
    setStates: (state, {payload}:PayloadAction<{type:ActiveState_Keys} & Partial<ActiveStates>>) => {
      switch(payload.type) {
        case "farm":
          var newVal = payload.farm?payload.farm:initialState.farm
          state.farm = newVal
          localStorage.setItem("activedomain", newVal?newVal:"")
          break
        case "section":
          state.section = payload.section?payload.section:initialState.section
          if (payload.pen) state.pen = payload.pen
          if (payload.isShared&&payload.isShared!==state.farm) state.isShared = payload.isShared
          else state.isShared = initialState.isShared
          break
        case "pen":
          state.pen = payload.pen?payload.pen:initialState.pen
          break
        case "isShared":
          state.isShared = payload.isShared?payload.isShared:initialState.isShared
          break
        case "report":
          state.report = payload.report?payload.report:initialState.report
          break
        case "autopig":
          state.autopig = payload.autopig?payload.autopig:initialState.autopig
          break
        case "all":
          var _new:{type?:ActiveState_Keys} &Partial<ActiveStates> = payload
          if (_new.type) delete _new.type
          state = {...state, ..._new}
          break
        default:
          break
      }
      return state
    },
    resetState: (state, {payload}:PayloadAction<ActiveState_Keys>) => {
      switch(payload) {
        case "farm":
          state.farm = initialState.farm
          break
        case "section":
          state.section = initialState.section
          break;
        case "pen":
          state.pen = initialState.pen
          break
        case "isShared":
          state.isShared = initialState.isShared
          break
        case "report":
          state.report = initialState.report
          break
        case "autopig":
          state.autopig = initialState.autopig
          break
        case "all":
          state = {
            ...initialState, 
          }
          break
        default:
          break
      }
      return state
    }
  }
})

const ActivePersistConfig:PersistConfig<ActiveStates, any, any, any> = {
  key: ActiveSlice.name,
  storage: storage,
  blacklist: [],
  //whitelist: ['firstRun', 'CookieLevel', 'Language']
}

const { actions, reducer } = ActiveSlice
export const { setStates, resetState } = actions
const presistActive = persistReducer(ActivePersistConfig, reducer)
export default presistActive
