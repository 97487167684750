import { CSSProperties } from "react"

export enum ListData_ValueTypes {
  Id = "Id",
  Div = "Div",
  Check = "Check",
  Number = "Number",
  String = "String",
  ProgressBar = "ProgressBar",
  List = "List",
  DeleteRowAction ="DeleteRowAction",
}

export interface SelectFieldOption_Values {
  id?: number
  label: string
  domain?:string
  style?: CSSProperties
}

export interface DataValue {
  type?:  ListData_ValueTypes,
  value: string|number|boolean|{ progressed: number; total: number }|any //TODO: Sorter virker kun når any eksisterer (Only for the old way)
  style?:CSSProperties,
  static?:boolean,
  select?:SelectFieldOption_Values[],
}
export type DataValue_Keys = keyof DataValue
export type Data = DataValue[]
export type ValueEdit = {row: number, col: number, value: any, key: string}

export enum penTypes {
  basic="basic",
  advanced="advanced",
  single="single",
  basicYesterday="basicYesterday",
  basicToday="basicToday"
}

export enum AttentionTypes {
  neutral = "System Off",
  good = "System Running",
  warning = "System Warning",
  alert = "System Alert",
}

export enum FeederStatus {
  idle="Idle",
  paused="Paused",
  dosing="Dosing",
  waiting="Waiting",
  measuring="Measuring",
  calibration="Waiting for Calibration",
  error="Error",
  off="Off"
}

export enum SystemTypes {
  AutoPig="AutoPig",
  LocationFeeder="Freeda Location Feeder",
  WalkThroughIn="Freeda Walk-Through /-In",
  PPT="Freeda PPT",
  Sorting="Freeda Sorting"
}