import React from 'react'
import { useTranslation } from 'react-i18next'
import { frames } from '../../../Styles'
import { PageHeader } from '../../../Components'

export type Silo = {
  uid: number;
  name: string;
  component: string;
  amount: number; //kg
};

export default function Silos() {
  const {t} = useTranslation()
  
  return (
    <frames.Page_inner>
    <frames._page_inner_header>
      <PageHeader topHeader={t("globalMenu.silos")} subHeader={t("overview")}/>
    </frames._page_inner_header>
    <frames._page_inner_content>
    </frames._page_inner_content>
    </frames.Page_inner>
  );
}