import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as resources from './resources/index'
import moment from 'moment';

import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources: {
    ...Object.entries(resources).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          translation: value,
        },
      }), {},
    ),
  },
  interpolation: {
    format: function(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      if(value instanceof Date) {
        var formated = moment(value).format(format);
        return formated
      }
      return value;
    }
  },
  compatibilityJSON: 'v3',
  supportedLngs: Object.keys(resources),
  fallbackLng: 'en',
  //lng: 'en',
})

export interface languagObject {
  id: string, label: string
}
export const CreateLangArr = (sourceid?: string, targetid?: string): languagObject[] => {
  if (sourceid) { // reads all lables from selected source lang
    const theLang = Object.entries(resources).find(([key]) => key === sourceid);
    if (theLang) {
      if (targetid) {// findes specefic label (targetid) from selected source lang
        const theTarget = Object.entries(theLang[1].languages).find(([key]) => key === targetid);
        if (theTarget) {
          return [{id:theTarget[0], label:theTarget[1]} as languagObject]
        }
      }
      else return Object.entries(theLang[1].languages).map(([key, value]) => ({ id: key, label: value }));
    }
  } else { // reads each there own lables from there file
    return Object.entries(resources).reduce((acc: languagObject[], [key, value]) => {
      const languages = value.languages as { [key: string]: string };
      acc.push({ id: key, label: languages[key] });
      return acc;
    }, []);
  }
  return [];
};
