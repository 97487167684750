import react from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryContainer } from 'victory';
import { colors, css, fonts } from '../../Styles';
import { progressColor } from '../../Definitions';

// #region | ProgressBar Component
interface ProgressBar_Props {
    isPrimaryBar?: boolean
    text?: string | number
    total?: number
    progressed?: number
}
/**
 * ProgressBar
 * @param total total value as number.
 * @param progressed progressed value as number.
 * @returns a VictoryBar with animation and label. 
 */
export default function ProgressBar ({isPrimaryBar = true, text, total = 0, progressed = 0}:ProgressBar_Props) {
  const progressedPercentage = total > 0 ? (progressed / total) * 100 : 0;

  return (
    <div style={{width: isPrimaryBar? "130px" : "80px", height: isPrimaryBar? "13px" : "30px", position: "relative"}}>
      <div style={{ height: isPrimaryBar? "13px" : "30px", width: isPrimaryBar? "130px" : "80px", position: 'relative', borderRadius: isPrimaryBar? "10px" : "0px", overflow: "hidden"}}>
        <VictoryChart
          domain={{ x: [0, 100], y: [0, 100] }}
          height={isPrimaryBar? 20 : 10}
          width={isPrimaryBar? 150 : 80}
          padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          
          containerComponent={<VictoryContainer  style={{ height: isPrimaryBar? "26px" : "30px", width: isPrimaryBar? "130px" : "80px", position: "absolute", top: isPrimaryBar? "-10px" : "0px"}}/>}
        >
          {/* Hide axis lines and labels */}
          <VictoryAxis
            dependentAxis
            tickFormat={() => ''}
          />
          {/* Total bar */}
          <VictoryBar
            horizontal
            data={[{ x: 1, y: 100 }]}
            barWidth={isPrimaryBar? 30: 55}
            style={{
              data: { fill: isPrimaryBar? colors.greyTertiary : colors.greyLightSecondary },
            }}
          />
          {/* Progress bar */}
          <VictoryBar
            horizontal
            data={[{ x: 1, y: progressedPercentage }]}
            // animate={{duration: 1000}}
            barWidth={isPrimaryBar? 30: 55}
            style={{
              data: { fill: progressColor(progressedPercentage)  },
            }}        
          />
        </VictoryChart>
      </div>

      {/* Progress/total label */}
      <div 
        style={{ width: isPrimaryBar? "131px" : "80px", height: isPrimaryBar? "13px" : "30px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      >
      
      {isPrimaryBar?
        <div style={{display: "grid", gridTemplateColumns: "5fr 1fr 5fr"}}>
          <p style={{ ...css.progressBarLabel, justifyContent: "end" }}>{`${progressed}`}</p>
          <p style={{ ...css.progressBarLabel, justifyContent: "center" }}>/</p>
          <p style={{ ...css.progressBarLabel, justifyContent: "start" }}>{`${total}`}</p>
        </div>
      : <p style={{ ...css.progressBarText, justifyContent: "center" }}>{text}</p>
      }
        
      </div>

    </div>
  );
};
// ---------------------------------------------------
//#endregion