import { useEffect, useState } from "react";
import { colors, fonts } from "../Styles";

// Function to format timestamp to hh:mm based on current time
export const formatTimeFromTimestamp = (timestamp: number) => {
    const timeInMs = timestamp * 1000; // Convert to milliseconds
    const now = Date.now(); // Current time in milliseconds
    const difference = now - timeInMs; // Difference in milliseconds
    
    const totalMinutes = Math.floor(difference / 1000 / 60);
    const hours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
    const minutes = String(totalMinutes % 60).padStart(2, '0');
  
    return `${hours}:${minutes}`;
  };

export const Clock = () => {
    const [dateTime, setDateTime] = useState(new Date());
    const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  
    function stopClock() {
      if (intervalId) clearInterval(intervalId);
    }
  
    function startClock() {
      stopClock();
      const newIntervalId = setInterval(() => {
        setDateTime(new Date());
      }, 10000); // Update every minute
      setIntervalId(newIntervalId);
    }
  
    useEffect(() => {
      // Page is loaded
      startClock();
      // Cleanup on component unmount
      return () => stopClock();
    }, []);
  
    return (
      <div style={{
        color: colors.greyLightTertiary,
        ...fonts.header, 
        ...fonts.textShadow,
        ...fonts.fontSize4,
      }}>
      <p>
          {dateTime.toLocaleString('default', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // 24-hour format
          }).replace(/,/g, '')}
        </p>
      </div>
    );
  };