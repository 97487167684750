import react, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonList, ButtonTypes, Pen_Basic } from '../..'
import { penTypes } from '../../../Definitions/valueTypes'
import { css, icons } from '../../../Styles'

import { getSectionById } from '../../../Assets/TestData/sectionsData'

export enum SectionBasicTypes {
  basicPen = "basicPen",
  advancedPen = "advancedPen",
  singlePen = "singlePen"
}

interface SectionBasic_Props {
  sectionId: number
  sectionType: SectionBasicTypes
  onPenClick?: (penId: number) => void
  onAddBtn?: () => void
  onMoveBtn?: () => void
  onEditBtn?: () => void
}
/**
 * Section_Basic
 * @param sectionId Unique identifier for each section.
 * @param sectionType Basic, advanced and single. Each renders different content of animal data.
 * @param onPenClick OnClick event to handle penNo.
 * @param onAddBtn OnClick event to handle Add btn.
 * @param onMoveBtn OnClick event to handle Move btn.
 * @param onEditBtn OnClick event to handle Edit btn.
 * @returns a section of pens for Freeda Location Feeder systems.
 */
export default function Section_Basic({ sectionId, sectionType, onPenClick, onAddBtn, onMoveBtn, onEditBtn }: SectionBasic_Props) {
  const {t} = useTranslation()
  const section = getSectionById(sectionId);
  const [selectedPenNo, setSelectedPenNo] = useState<number | null>(null);

  function AddBtnClick() {
    if (onAddBtn) onAddBtn()
  }

  function MoveBtnClick() {
    if (onMoveBtn) onMoveBtn()
  }

  function EditBtnClick() {
    if (onEditBtn) onEditBtn()
  }

  // Handle Pen click
  const handlePenClick = (penId: number) => {
    if (onPenClick) {
      onPenClick(penId);
      setSelectedPenNo(penId)
    }
  };

  const _gridTemplate = 
    sectionType===SectionBasicTypes.basicPen ? "repeat(2, 1fr)"
    : sectionType===SectionBasicTypes.advancedPen || sectionType===SectionBasicTypes.singlePen ? "auto"
    : "none"

  const advancedBtns = 
    sectionType===SectionBasicTypes.advancedPen ?
      <ButtonList 
      content={[t("buttons.add"), t("buttons.move"), t("buttons.edit")]} 
      onClick={(i => {
        if (i === 0) {AddBtnClick()}
          else if (i === 1) {MoveBtnClick()}
          else if (i === 2) {EditBtnClick()}
      })}
      startEnhancers={[icons.add, icons.moveAnimal, icons.edit]}
      overrides={{
        button:{ buttonType: ButtonTypes.secondaryTableButton, disableActive: true}
      }}
    />
    : <></>

  const columnHeaders = 
    sectionType===SectionBasicTypes.advancedPen ?
      <p style={{ ...css.penHeaders, paddingLeft: "6px" }}>
        {t("locationFeeder.penPopup.penValueHeaders")}
      </p>
    : <></>

  // Error throwback if no section number excists
  if (!section) {
    return <div>Section {sectionId} not found</div>;
  }

  const renderContent = () => {
    switch (sectionType) {
      case SectionBasicTypes.basicPen:
      return section.pens.map(pen => (
        <Pen_Basic
          key={pen.animal?.animalNo}
          penNo={pen.penNo}
          penType={penTypes.basic}
        />
      ));
      case SectionBasicTypes.advancedPen:
      return section.pens.map(pen => (
        <Pen_Basic
          key={pen.animal?.animalNo}
          penNo={pen.penNo}
          penType={penTypes.advanced}
          hasHoverEffekt={true}
          onClick={() => handlePenClick(pen?.penNo as number)}
        />
      ));
    }
  }

  return (
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "end", padding: "0px 20px" }}>
          {columnHeaders}
          {advancedBtns}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: _gridTemplate, width: "fit-content", gap: "10px", borderRadius: "10px", overflow: "hidden" }}>
          {renderContent()}
        </div>
      </div>
  );
}