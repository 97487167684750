import { css } from "../../Styles"

// #region | PageHeader Component
interface pageHeader_Props {
  topHeader?: string
  subHeader?: string
}
/**
 * PageHeader
 * @param topHeader "h1" title on page.
 * @param subHeader "h2" title on page.
 * @returns topHeader and subHeader.
 */
export default function PageHeader ({topHeader, subHeader}:pageHeader_Props) {

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <p style={{ ...css.topHeader, marginTop: "-7px" }}>{topHeader}</p>
      <p style={{ ...css.subHeader }}>{subHeader}</p>
    </div>
  )
} 