/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { frames } from "../Styles"

import { Graph_QL } from '../Services/Agrisys_GqlDataCaller'
import { useGetDeviceState, useGetDomainLog, useGetAutopig } from '../Services/Agrisys_GqlDataCaller' ///DeviceCalls/dataCalls'
import { useGetSections } from '../Services/Agrisys_GqlDataCaller' ///DomainCalls/dataCalls'
import { useAppSelector } from '../Store';

export default function SubPage() {
  const [ModuleStateColor, setModuleStateColor] = useState("gray")
  const [ModuleStateText, setModuleStateText] = useState("Unknown")
  const [ModuleText, setModuleText] = useState("")

  var Connections = Graph_QL.create([{domain:"demo"}, {domain:"tester"}])

  const {call:GetLogs, data:LogDatas, dataData:LogDatasArr, deleteEntries:DeleteLogs } = useGetDomainLog(Graph_QL.create([{domain:"ip"}, {domain:"moelholm"}]))
  const {call:GetStates, data:DeviceStates, deleteEntries:DeleteStates, dataData:DeviceStatesArr } = useGetDeviceState(Graph_QL.create([{domain:"hkhornsyld", gate:14, device:40}, {domain:"ip", gate:2, device:40}, {domain:"ip", gate:3, device:40}]))
  const {call:GetAutopigData, data:AutopigData, scalesData:AutopigScale, deleteEntries:DeleteAutopig} = useGetAutopig(Graph_QL.create([{domain:"hkhornsyld", gate:14, device:40}]))

  const {call:GetStruckture, data:Sections} = useGetSections(Connections)

  useEffect(() => {
    //console.log("Data", AutopigData, AutopigScale)
  }, [AutopigScale])

  const isSystemOnline = useMemo(() => {
    //console.log("states", DeviceStates, dataData)
    // if (_isSystemOnline && _isSystemOnline.state) {
    //   let _ModuleText = ""
    //   /*switch(System.SystemType) {
    //     case SystemTypes.AUTOPIG:
    //       _ModuleText += System.SystemName?System.SystemName:SystemTypes.AUTOPIG.charAt(0).toUpperCase() + SystemTypes.AUTOPIG.slice(1) + " #" + Name + "\n"
    //       switch(System.SystemMainState) {
    //         case -1:
    //           setModuleStateText(SystemStates.UNKNOWN)
    //           setModuleStateColor(Colors.gray)
    //           break
    //         default:
    //           setModuleStateText(SystemStates.IDLE)
    //           setModuleStateColor(Colors.gray)
    //           break
    //       }*/
    //       _ModuleText += "Live weight: " + `${_isSystemOnline.value1} g\n`
    //       _ModuleText += "Todays results: " + `${_isSystemOnline.value2* 10} g : by ${_isSystemOnline.value3} scales\n`
    //       _ModuleText += "Yesterdays results: " + `${_isSystemOnline.value4} g`
    //       /*break
    //   }*/
    //   setModuleText(_ModuleText)
    //   return _isSystemOnline
    // }
    // else {
    //   setModuleStateText("Unknown")
    //   setModuleStateColor("gray")
    //   setModuleText("")
    // }
    return undefined
  }, [DeviceStates])

  const LogsLines = useMemo(() => {
    //console.log("Logs", LogDatas, LogDatasArr)
  }, [LogDatas])

  useEffect(() => {
    //if && System.SystemType===SystemTypes.AUTOPIG) {
    GetStates()
    GetLogs()
    GetAutopigData(undefined, 5)

    GetStruckture()
  }, [])

  return ( 
    <frames.Def>
        <h1>Sub Page</h1>
        <Square/>
        <button className="square" onClick={() => {
          DeleteAutopig({con:{domain:"hkhornsyld", gate:14, device:40}, data:{Scales:undefined, Temps:undefined, State:undefined}})
          if (DeviceStatesArr && DeviceStatesArr[0]) DeleteStates({data:DeviceStatesArr[0]})
            
          if (LogDatas && LogDatas['ip__']) {
            var testData = LogDatas['ip__']
            var half_length = Math.ceil(testData.data.length / 2);   
            var leftSide = testData.data.slice(0,half_length)
            DeleteLogs({con:testData.con, data:leftSide})
          }

        }}>Clear</button>
        <button className="square" onClick={() => {
             GetLogs(Graph_QL.create({domain:"ip"}))
        }}>refetch</button>
    </frames.Def>
  )
}

const Square = () => {
    const navigate = useNavigate();
    return (
        <button className="square" onClick={() => {
            navigate("/")
        }}>Home Page (Def)</button>
    )
}
