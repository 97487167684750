import React, { useMemo } from 'react';
import { frames } from '../../../Styles';
import PageHeader from '../../../Components/BaseComponents/pageHeader';
import { useTranslation } from 'react-i18next';
import { SystemData } from '../../../Assets/TestData/systems';

export default function FreedaLocationFeeder() {
  const { t } = useTranslation();

  // System Name
  const { systemName } = useMemo(() => {
    const systemData = SystemData.find((system) => system.systemNo === 2);
    return {
      systemName: systemData?.name || "",
    };
  }, []);

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <PageHeader topHeader={systemName} subHeader={t("settings")}/>
      </frames._page_inner_header>
      <frames._page_inner_content>
      </frames._page_inner_content>
    </frames.Page_inner>
  );
}