import { AnimalGroupData } from "./animalGroups";
import { ConditionData } from "./conditions";

export type FilterParameter = {
  [key: string]: number
}

export const DataLevels = ["Animal", "Group", "Location"] as const;
export type DataLevel = typeof DataLevels[number]; 

export const DataParameters = ["Location", "Group", "Condition", "Attention", "Milestone"] as const;
export type DataParameter = typeof DataParameters[number]; 

export type ReportTemplate = {
    reportTemplateNo: number // DataTYPE ID, static
    name: string // Editable for farmer
    dataLevel: DataLevel
    filterParameter: FilterParameter[]
    dataParameter: DataParameter[]
  };

export enum FilterDisplayEnum {
  // eslint-disable-next-line no-unused-vars
  select = "select",
  // eslint-disable-next-line no-unused-vars
  range = "range"
}
  
export interface FilterDisplayType {
  id: number,
  label: string,
  displayType: FilterDisplayEnum,
  options?: {
    label: string,
    id: number,
  }[]
}

export const allFilterParameterTestData: FilterDisplayType[] = [
  {id: 1, label: "groupNo", displayType: FilterDisplayEnum.select, options: AnimalGroupData.map(group => ({
    label: group.name,
    id: group.groupNo
  })) },
  {id: 2, label: "conditionNo", displayType: FilterDisplayEnum.select, options: ConditionData.map(condition => ({
    label: condition.name,
    id: condition.conditionNo
  }))},
  {id: 3, label: "locationNo", displayType: FilterDisplayEnum.range}
]

export const TestData: ReportTemplate[] = [
  {
    reportTemplateNo: 1,
    name: "Standard Condition Score",
    dataLevel: "Animal",
    filterParameter: [
      {groupNo: 1}
    ],
    dataParameter: [
      "Condition",
      "Group"
    ]
  },
  {
    reportTemplateNo: 2,
    name: "Feed Plan and Condition Score",
    dataLevel: "Group",
    filterParameter: [
      {groupNo: 3},
      {conditionNo: 1}
    ],
    dataParameter: [
      "Location",
      "Group"
    ]
  },
  {
    reportTemplateNo: 3,
    name: "Feed Plan",
    dataLevel: "Animal",
    filterParameter: [
      {groupNo: 1},
      {conditionNo: 2}
    ],
    dataParameter: [
      "Group"
    ]
  },
  {
    reportTemplateNo: 4,
    name: "Condition Score",
    dataLevel: "Location",
    filterParameter: [
      {groupNo: 2},
      {conditionNo: 3}
    ],
    dataParameter: [
      "Location",
    ]
  },
  {
    reportTemplateNo: 5,
    name: "Feed Balance",
    dataLevel: "Location",
    filterParameter: [
      {groupNo: 2}
    ],
    dataParameter: [
      "Location",
    ]
  },
  {
    reportTemplateNo: 6,
    name: "Feed Balance (Farrowing)",
    dataLevel: "Location",
    filterParameter: [
      {groupNo: 2}
    ],
    dataParameter: [
      "Attention",
    ]
  },
  {
    reportTemplateNo: 7,
    name: "Farrowing",
    dataLevel: "Location",
    filterParameter: [
      {groupNo: 2}
    ],
    dataParameter: [
      "Milestone",
    ]
  }
]