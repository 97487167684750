import React, { useMemo, useState } from "react";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Block } from "baseui/block";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypes } from "../BaseComponents";
import { colors, fonts } from "../../Styles";
import { AttentionTypes, CreateAttentionStyles } from "../../Definitions";

import { SystemData } from "../../Assets/TestData/systems";

interface AttentionPopover_Props {
  systemNo: number
  onGoToSystemClick?: () => void
}
/**
 * AttentionPopover
 * @param systemNo Identifier for the system, used to retrieve system details.
 * @param onGoToSystemClick Optional callback for "Go to system" button click.
 * @returns A popover build from the BaseUI StatefulPopover
 */
export default function AttentionPopover({ systemNo, onGoToSystemClick}: AttentionPopover_Props) {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false)

  // Lookup system data based on systemNo
  const { systemName, systemType, attentionType } = useMemo(() => {
    const systemData = SystemData.find((system) => system.systemNo === systemNo);
    return {
      systemName: systemData?.name || "",
      systemType: systemData?.type || "",
      attentionType: systemData?.attention || AttentionTypes.neutral,
    };
  }, [systemNo]);

  const { textColor, attentionText, attentionColor } = useMemo(() => {
    var _Style = CreateAttentionStyles(attentionType)
    return _Style
  }, [ attentionType ]);
  
  return (
    <StatefulPopover
      showArrow={true}
      onClose={() => setIsOpen(false)}
      placement={PLACEMENT.top}
      initialState={{ isOpen: isOpen }}
      content={() => (
        <Block>
          <div style={{ padding: "10px 10px 5px 10px", textAlign: "center", ...fonts.header, ...fonts.fontSize3, color: textColor}}>
            {attentionText}
          </div>
          <div style={{backgroundColor: colors.greyDarkPrimary, padding: "20px", margin: "2px", borderRadius: "0px 0px 8px 8px",display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
          }}>
            <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary}}>{systemType}</p>
            <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary}}>{systemName}</p>
            <div style={{marginTop: "10px"}}>
              { //attentionType === AttentionTypes.alert || attentionType === AttentionTypes.warning ?
                <Button content={t("attentions.goToSystem")} onClick={onGoToSystemClick}
                  overrides={{
                    buttonType: ButtonTypes.textButtonSmall,
                    baseButton: {backgroundColor: attentionColor, color: textColor, width: "120px", height: "25px"}
                  }} />
                //: <></> 
              }
            </div>
          </div>
        </Block>
      )}
      overrides={{
        Arrow: { style: () => Object.assign({}, {
          backgroundColor: attentionColor,
          width: "30px",
          height: "30px",
        } as React.CSSProperties )},
        Body: { style: () => Object.assign({}, {
          backgroundColor: attentionColor,
          borderRadius: "10px",
          width: "fit-content",
          height: "fit-content",
          top: "-2px"
        } as React.CSSProperties )},
        Inner: { style: () => Object.assign({}, {
          backgroundColor: attentionColor,
          borderRadius: "10px",
        } as React.CSSProperties )},
      }}
    >
      {/* Popover Activator (to show in StatusBar) */}
      <div 
        onClick={() => setIsOpen(true)}
        style={{      
          height: "10px",
          width: "30px",
          backgroundColor: attentionColor,
          borderRadius: "10px",
          cursor: "pointer"
        }}
      />
    </StatefulPopover>
  );
}