import react, { CSSProperties } from 'react'
import { CheckBox, ProgressBar } from "../.."
import { colors, fonts } from "../../../Styles"
import { useTranslation } from 'react-i18next';

import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';
import { TestData as PenData} from '../../../Assets/TestData/lf_Data';
import { TestData as AnimalData, TestCalculate_Progresse } from '../../../Assets/TestData/animalData'
import { CheckStyles } from '../../BaseComponents/checkBox';

interface PenMove_Props {
  penNo: number
  onCheck?: (isChecked: boolean) => void
  isChecked?: boolean
}
/**
 * Pen_Move
 * @param penNo Unique identifier for each pen.
 * @param onCheck Prop to define checkbox value on component call. 
 * @returns A pen with checkbox on all pens that has an animal.
 */
export default function Pen_Move({ penNo, onCheck, isChecked }: PenMove_Props) {
  const {t} = useTranslation()
  // const [showMoveAnimalToPenModal, setMoveAnimalToPenModal] = useState(false) // Move Animal Modal

  // Find the pen and related data
  const pen = PenData.find(item => item.penNo === penNo)

  // Find the animal and related data
  const animal = AnimalData.find(item => item.animalNo === pen?.animal);

    // #region | Data handling
  // Handle feedCurve data and parse an empty string if label is undefined
  const feedCurve = FeedCurveData.find(e => e.id === animal?.feedPlan);
  const feedCurveName = feedCurve?.name ?? "";

  // Handle PenData and feeding status
  // const penData = PenData.find(pen => pen.penNo === animal.pen);
  const feedCorrection = animal?.feedCorrection
  const feeding = pen?.feeding ? 'On' : 'Off';
  const feedingStyle: CSSProperties = {
    color: pen?.feeding ? colors.systemGreen : colors.systemRed
  };

  // Handle progress bar data
  const progressBarTotal = animal?.totalFood;
  const progressBarProgressed = animal?TestCalculate_Progresse(animal):0;

  // Create ProgressBar component
  const progressBar = (
    <ProgressBar 
      total={progressBarTotal} 
      progressed={progressBarProgressed}
    />
  );
  // #endregion

  // #region | Content
  // Content to parse in the div
  // Advanced content to parse in the div
  const advancedContent =
    <div style={{width: "591px", padding: "0 50px 0px 10px"}}>
      {animal? 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 90px 25px 90px 45px 131px 45px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <CheckBox 
            checkStyle={CheckStyles.transparent}
            isChecked={isChecked}
            onChange={(checked) => {
              if (onCheck) {
                onCheck(checked);
              }
          }}/>
          <span style={{cursor: "default"}}>{pen?.penNo}</span>
          <span style={{cursor: "default"}}>{animal.animalNo}</span>
          <span style={{cursor: "default"}}>{animal.latestMilestone}</span>
          <span style={{cursor: "default"}}>{animal.milestoneDay}</span>
          <span style={{paddingLeft: "5px", cursor: "default"}}>{feedCurveName}</span>
          <span style={{paddingLeft: "5px", cursor: "default"}}>{feedCorrection}</span>
          <span style={{cursor: "default"}}> {progressBar} </span>
          <span style={{paddingLeft: "5px", ...feedingStyle,}}>{feeding}</span>
        </div>
      : 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 426px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <span></span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{pen?.penNo}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{t("locationFeeder.penPopup.empty")}</span>
        </div>
      }
    </div>
  // #endregion

  return (
    <>
      <div style={{       
        width: "651px",
        height: "35px" }}>
        <div style={{
          width: "100%",
          height: "100%",
          textAlign: 'center',
          alignContent: "center",
          backgroundColor: colors.greyLightSecondary,
          transition: 'filter 0.3s',
          color: colors.greyDarkPrimary,
          ...fonts.paragraph,
          ...fonts.fontSize4,
        }}>
          {advancedContent}
        </div>
      </div>
      {/* Move Animal Modal */}
      {/* <MoveAnimalToPen isModalOpen={showMoveAnimalToPenModal} animalsToMove={pen?.penNo ?? 0} goBack={() => setMoveAnimalToPenModal(false)} /> */}
    </>
  );
}