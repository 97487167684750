import * as React from "react";
import { DatePicker, TimePicker } from "baseui/datepicker";
import { colors, fonts, icons } from "../../Styles";
import { useState } from "react";

interface TimeField_Props {
  value: Date;
  onChange: (date: Date) => void;
}

export default function TimeField({ value, onChange }:TimeField_Props) {

  const handleChange = (date: Date | null) => {
    // Update the state only if date is not null
    if (date) {
      onChange(date); // Update parent with the new date
    }
  };

  return (
    <div style={{ position: "relative"}}>
      {/* startEnhancer */}
      <img alt="start_icn" src={icons.time_white}
        style={{
          width: "25px",
          height: "25px",
          position: "absolute",
          zIndex: "1000",
          top: "2.5px",
          left: "22px"
        }}
      />
      {/* TimePicker */}
      <TimePicker
        value={value}
        onChange={handleChange}
        step={300}
        minTime={new Date("2024-09-29T22:00:00.000Z")}
        format="24"

        overrides={{
          Select: {
            props: {
              overrides: {
                // Input
                Root: { style: () => Object.assign({}, {
                  width: "280px",
                } as React.CSSProperties )},
                ControlContainer: { style: () => Object.assign({}, {
                  backgroundColor: colors.blueDarkSecondary,
                  height: "30px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "unset",
                  border: "none",
                  cursor: "pointer"
                } as React.CSSProperties )},
                ValueContainer: { style: () => Object.assign({}, {
                  ...fonts.paragraph, 
                  ...fonts.fontSize3, 
                  color: colors.greyLightTertiary,
                  padding: "0px 0px 0px 60px",
                } as React.CSSProperties )},
                Input: { style: () => Object.assign({}, { 
                  ...fonts.paragraph, 
                  ...fonts.fontSize3, 
                  color: colors.greyLightTertiary,
                  'input::-webkit-inner-spin-button':{
                    appearance: 'none',
                  },
                  'input::-webkit-outer-spin-button':{
                    appearance: 'none',
                  },
                  '-moz-appearance': 'textfield', // For Firefox
                } as React.CSSProperties )},
                Placeholder: { style: () => Object.assign({}, {
                    ...fonts.paragraph, 
                    ...fonts.fontSize3, 
                    color: colors.greyLightTertiary,
                    opacity: "30%",
                  } as React.CSSProperties )},

                // Popover
                OptionContent: { style: () => Object.assign({}, { 
                  ...fonts.paragraph, 
                  ...fonts.fontSize3, 
                  color: colors.greyLightTertiary,
                  width: "145px",
                  padding: "10px 16px",
                  margin: "0px 6px",
                  ':hover': { backgroundColor: colors.greyDarkSecondary, borderRadius: "10px" }
                } as React.CSSProperties )},
                DropdownListItem: { style: () => Object.assign({}, { 
                  backgroundColor: colors.transparent,
                  padding: "0px",
                } as React.CSSProperties )},
                Dropdown: { style: () => Object.assign({}, {
                  backgroundColor: colors.greyDarkPrimary,
                  borderRadius: "10px",
                  width: "198px",
                  boxSizing: "unset",
                  border: "none",
                  boxShadow: "none",
                  scrollbarColor: colors.greyDarkSecondary+colors.transparent,
                  scrollbarWidth: "thin",
                } as React.CSSProperties )},
                Popover: {
                  props: {
                    overrides: {
                      Inner: { style: () => Object.assign({}, {
                        borderRadius: "10px",
                        backgroundColor: colors.greyDarkPrimary
                      } as React.CSSProperties )},
                      Body: { style: () => Object.assign({}, {
                        backgroundColor: colors.blueDarkPrimary,
                        borderRadius: "10px",
                        top: "-50px",
                        position: "absolute",
                        width: "200px",
                        padding: "3px"
                      } as React.CSSProperties )}
                    }
                  }
                },
                SelectArrow: {
                  props: {
                    overrides: {
                      Svg: { style: () => Object.assign({}, {
                        ...fonts.paragraph, 
                        ...fonts.fontSize3, 
                        color: colors.greyLightTertiary,
                        marginRight: "0px",
                        display: "none"
                      } as React.CSSProperties )}
                    }
                  }
                }
              }
            }
          },

          
        }}
      />
    </div>
  );
}
