import { useEffect, useState } from "react";
import { FeedCurve } from "../Containers";

// Media Queries as numbers for e.g. screen.innnerWith with useState
export const mediaQueriesNumbers = {
    mobileMax: 600,
    tabletMin: 601,
    tabletMax: 900,
    laptopMin: 901,
    laptopMax: 1280,
    desktopMin: 1281,
    desktopMax: 1530,
    desktopXlMin: 1531,

    feedCurveTabletMin2: 748,
    feedCurveTabletMax2: 900,
    feedCurves1Max: 1051,
    feedCurves2Min: 1052,
    feedCurves2Max: 1401,
    feedCurves3Min: 1402,
    feedCurves3Max: 1751,
    feedCurves4Min: 1752,

    pageHeaderResponsive2elementsMax: 1088,
    pageHeaderRepsponsive3elementsMax: 1347,

    ScreenHeigtMax: 709,
    ScreenHeigtMin: 710,
  };
  
  // Media Queries as strings with media query intervals for e.g. css styling
  export const mediaQueries = {
    mobile: `@media (max-width: ${mediaQueriesNumbers.mobileMax}px)`,
    tablet: `@media (min-width: ${mediaQueriesNumbers.tabletMin}px) and (max-width: ${mediaQueriesNumbers.tabletMax}px)`,
    laptop: `@media (min-width: ${mediaQueriesNumbers.laptopMin}px) and (max-width: ${mediaQueriesNumbers.laptopMax}px)`,
    desktop: `@media (min-width: ${mediaQueriesNumbers.desktopMin}px) and (max-width: ${mediaQueriesNumbers.desktopMax}px)`,
    desktopXl: `@media (min-width: ${mediaQueriesNumbers.desktopXlMin}px)`,

    feedCurvesTablet: `@media (min-width: ${mediaQueriesNumbers.feedCurveTabletMin2}px) and (max-width: ${mediaQueriesNumbers.feedCurveTabletMax2}px)`,
    feedCurves1: `@media (max-width: ${mediaQueriesNumbers.feedCurves1Max}px)`,
    feedCurves2: `@media (min-width: ${mediaQueriesNumbers.feedCurves2Min}px) and (max-width: ${mediaQueriesNumbers.feedCurves2Max}px)`,
    feedCurves3: `@media (min-width: ${mediaQueriesNumbers.feedCurves3Min}px) and (max-width: ${mediaQueriesNumbers.feedCurves3Max}px)`,
    feedCurves4: `@media (min-width: ${mediaQueriesNumbers.feedCurves4Min}px)`,

    pageHeaderResponsive2elements: `@media (max-width: ${mediaQueriesNumbers.pageHeaderResponsive2elementsMax}px)`,
    pageHeaderResponsive3elements: `@media (max-width: ${mediaQueriesNumbers.pageHeaderRepsponsive3elementsMax}px)`,

    screenHeightMin: `@media (min-height: ${mediaQueriesNumbers.ScreenHeigtMin}px)`,
    screenHeightMax: `@media (max-height: ${mediaQueriesNumbers.ScreenHeigtMax}px)`,
  };

  // Custom hook til at lytte efter skærmstørrelse
  export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setMatches(mediaQueryList.matches);

    // Lyt efter ændringer
    mediaQueryList.addEventListener('change', documentChangeHandler);

    // Ryd op ved unmount
    return () => {
      mediaQueryList.removeEventListener('change', documentChangeHandler);
    };
  }, [query]);

  return matches;
};

