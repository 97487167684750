/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMemo } from "react"
import { StyledTable } from "baseui/table-grid"

import { TableStyles_Types, CreateTableStyle } from "../../Definitions"
import { ColumnPinningPosition, flexRender, Row, RowData, Table } from "@tanstack/react-table"
import { getTableContent } from "./_Tables/getTableContent"
import { LEFT_FROZEN_TABLE_WIDTH, RIGHT_FROZEN_TABLE_WIDTH } from "./_Tables/getTableStyles"

interface TableListProps {
  tableType?: TableStyles_Types | string,
  table: Table<any>,
  emptyText: string,
}

export interface IdentifiableRow {
  id: number
}

export interface TableList_PropHandles {
  changed: {row:number,col:number, value:any}[],
}

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    deleteRow: (rowId: number) => void;
    allowDeleteRowCheck?: (row: Row<TData>) => boolean;
  }
  interface ColumnMeta<TData extends RowData, TValue> {
    width?: string;
  }
}

const getWidthIfPinned = (pinned: ColumnPinningPosition, width?: string) => {
  if (pinned === "left") {
    return width ? width+" " : `${LEFT_FROZEN_TABLE_WIDTH}px `
  }
  if (pinned === "right") {
    return width ? width+" " : `${RIGHT_FROZEN_TABLE_WIDTH}px `
  }
  return width ? width+" " : "1fr "
}


export const ReactiveTable = ({ tableType, table, emptyText }: TableListProps) => {

  const { tableStyle, bodyCellStyle } = CreateTableStyle(9, tableType);

  const headers = table.getHeaderGroups().flatMap((headerGroups) => (headerGroups.headers));
  const rows = table.getRowModel().rows;
  const tableContent = getTableContent(rows, emptyText, bodyCellStyle, headers.length);

  const {gridTemplateColumns} = useMemo(() => {
    const gridTemplateColumns = headers.reduce((acc, val) => {
      return (acc + getWidthIfPinned(val.column.getIsPinned(), val.column.columnDef.meta?.width));
    }, "")
    return {gridTemplateColumns}
  }, [headers]);

  return (
      <StyledTable
        style={tableStyle}
        role="grid"
        $gridTemplateColumns={gridTemplateColumns}
      >
        <div role="columnheader" key="header" style={{ display: "contents" }}>
          {
            headers.map((header) => {
              return flexRender(header.column.columnDef.header, header.getContext())
            })
          }
        </div>
        <div key="tableBody" role="tablist" style={{ display: "contents"}}>
        {tableContent}
      </div>
       <div role="rowheader" key="footer" style={{ display: "contents" }}>
          {
            headers.map((header) => {
              return flexRender(header.column.columnDef.footer, header.getContext())
            })
          }
        </div>
      </StyledTable>
  );
}

