import React, { CSSProperties, forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
// import { NavigateFunction } from "react-router-dom"
import { Select, TYPE, Value } from "baseui/select"
import { ListStyle_Types, CreateListStyle, Field_Types, CreateFieldTypes, FieldStyle_Types, CreateFieldStyle, SelectFieldOption_Values } from "../../Definitions/"
import { colors } from "../../Styles"

// #region | SelectField Component
interface SelectField_Props {
  context?: string
  placeholder?: string
  fieldStyle?: FieldStyle_Types | string
  fieldType?: Field_Types | string
  startEnhancer?: string
  textStart?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  // isRequired?:boolean TODO
  isTableEditField?:boolean
  maxWidthEditField?: boolean
  defaultValue?: string | number
  options?: SelectFieldOption_Values[];
  overrides?: {
    root?: CSSProperties
    lastChildBorder?: boolean|number
  }
  onChange?:(e:Value)=>void
  isDisabled?: boolean
}

export interface SelectField_Handles {
  context: string 
  setContext: (e:string) => void,
}
/**
 * SelectField
 * @param placeholder text in inputfield before typing if there is no defaultValue.
 * @param fieldStyle color styling for inputfield, e.g. fieldStyle="blue".
 * @param startEnhancer start icon.
 * @param options selectoptions (list items) in the selector aka data set.
 * @param textStart if true the text will move to the left where the startenhancer would have been.
 * @param isCreatable if true it will be possible to type whatever and add it as a selected value.
 * @param isClearable if true a "clear icon" will appear in the end of the field if the field has a value.
 * @param defaultValue value to select from start. 
 * @param options one select in the list consisting of an id, a laben and maybe a domain.
 * @param overrides style overrides.
 * @param onChange function to handle the value, e.g. setDomain.
 * @returns a BaseUi selector, build from content param. 
 */
const _SelectField:React.ForwardRefRenderFunction<SelectField_Handles, SelectField_Props> = ({ context, placeholder, fieldStyle, fieldType, startEnhancer, textStart, isCreatable = false, isClearable = false, isSearchable = false, isTableEditField=false, maxWidthEditField=false, defaultValue, options = [], overrides, onChange, isDisabled }: SelectField_Props, ref) => {
  const [value, setValue] = useState<Value>([])
  const [contextValue, setContextValue] = useState(context?context:"")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  // const [required, setRequired] = useState(false)

  useImperativeHandle(ref, ()=>({
    context: contextValue,
    setContext(e:string) { setContextValue(e) }
  }))
  
  // Colors from fieldStyle
  const { BgColor, BgColorHover, BorderColor, BgDropdownColor, SbColor } = useMemo(() => {
    var _Style = CreateFieldStyle(fieldStyle)
    return _Style
  }, [fieldStyle])

  // Width from fieldType
  const { width, maxWidth, fontStyle, height, distancePaddingSelect, arrowMargin, dropdownBorderRadius, optionContentMargin, dropdownWidth, optionContentWidth, dropdownHeight, popoverBorderRadius, popoverWidth, popoverTop, popoverPosition, dropdownListItemWidth, dropdownBoxShadow, dropdownBorder, showSelectArrow, controleContainerBorderRadiusOpen } = useMemo(() => {
    var _Style = CreateFieldTypes(fieldType)
    return _Style
  }, [fieldType])

  // Build select options
  const selectOptions = useMemo(() => {
    const optionsList: SelectFieldOption_Values[] = options.map((option, index) => ({
      id: option.id?option.id:index,
      label: option.label,
      domain: option.domain
    }));
    // optionsList.push({id: -1, label: "this", style: {color: "#E56717", opacity:"50%"} }) // Example of pushing static element with style
    var Default = optionsList.find(e => e.label === defaultValue)
    if (Default) setValue([Default])
    // console.log("default")
    return optionsList
  }, [defaultValue, options])

  // Handle padding based on the presence of the startEnhancer
  const handleDistance = () => {
    if (startEnhancer) {
      return { padding: distancePaddingSelect, paddingTop: isTableEditField? "2px !important" : "0px" } // with startEnhancer
    } else if (!startEnhancer && textStart) {
      return { padding: isTableEditField? "0 0 0 14px" : "0 0 0 19px", paddingTop: isTableEditField? "2px !important" : "0px"  } // without startEnhancer but textStart=true
    } else {
      return { padding: distancePaddingSelect, paddingTop: isTableEditField? "2px !important" : "0px" } // without startEnhancer and textStart=false
    }
  };

  const EditField = () => {
    if (isTableEditField) {
      return { margin: "-2px 0px 0px -16px" }
      } else {
        return { margin: "0px" }
      }
    }

  // Add styling for last-child if overrides.lastChildBorder is true
  const highlightLastChild = overrides?.lastChildBorder
  const _root = useMemo<CSSProperties>(() => {
    return Object.assign({}, highlightLastChild ? 
      {
        ':nth-last-child(2)': {
          borderTop: "2px solid", 
          ...BorderColor,
          paddingTop: "5px"
        },
        ':nth-last-child(3)': {
          paddingBottom: "5px"
        }
      } : {borderTop: "none"}, overrides?.root || {})
  }, [BorderColor, highlightLastChild, overrides?.root])

  return (
    <div style={{ position: "relative"}}>
      {/* startEnhancer */}
      <div>
        {!startEnhancer ? (<></>) : (
          <img alt="start_icn" src={startEnhancer}
            style={{
              width: "25px",
              height: "25px",
              position: "absolute",
              zIndex: "2",
              top: "2.5px",
              left: "22px"
            }}
          />
        )}
      </div>
      {/* selectfield */}
      <div>
        <Select
          placeholder={placeholder}
          creatable={isCreatable}
          clearable={isClearable}
          searchable={isSearchable}
          disabled={isDisabled}
          // required={isRequired}
          options={selectOptions}
          onChange={(e) => {
            console.log("this is e val", e)
            setValue(e.value)
            if (onChange) onChange(e.value)
          }}

          labelKey="label"
          valueKey="id"
          value={value}
          
          onOpen={() => setIsDropdownOpen(true)} // Set dropdown state
          onClose={() => setIsDropdownOpen(false)} // Unset dropdown state
          
          ignoreCase={false}
          type={TYPE.select}

          getOptionLabel={({ option }: any) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', ...option.style }}>
                {option.label}
              </div>
            );
          }}

          // selectfield overrides
          overrides={{
            Root: { style: () => Object.assign({}, {
              width: width,
              maxWidth: maxWidthEditField ? maxWidth : "",
              minWidth: maxWidthEditField ? maxWidth : "",
              ...EditField()
            } as React.CSSProperties )},
            ControlContainer: { style: () => Object.assign({}, {
              ...BgColor,
              borderRadius: isDropdownOpen ? controleContainerBorderRadiusOpen : "10px",
              height: height,
              display: "flex",
              alignItems: "center",
              boxSizing: "unset",
              border: "none",
              zIndex: "1"
            } as React.CSSProperties )},
            ValueContainer: { style: () => Object.assign({}, {
              ...fontStyle,
              ...handleDistance()
            } as React.CSSProperties )},
            Input: { style: () => Object.assign({}, {
              ...fontStyle,
              paddingLeft: "2px",
              'input::-webkit-inner-spin-button':{
                appearance: 'none',
              },
              'input::-webkit-outer-spin-button':{
                appearance: 'none',
              },
              '-moz-appearance': 'textfield', // For Firefox
            } as React.CSSProperties )},
            Placeholder: { style: () => Object.assign({}, {
              ...fontStyle,
              opacity: "30%",
              marginLeft: "-2px"
            } as React.CSSProperties )},
            OptionContent: { style: () => Object.assign({}, { 
              ...fontStyle,
              width: optionContentWidth,
              padding: "10px 16px",
              margin: optionContentMargin,
              backgroundColor: colors.transparent,
              ':hover': { ...BgColorHover, borderRadius: "10px" }
            } as React.CSSProperties )},
            DropdownListItem: { style: () => Object.assign({}, { 
              backgroundColor: colors.transparent,
              width: dropdownListItemWidth,
              padding: "0px",
              display: "flex",
              justifyContent: "center",
              ..._root
            } as React.CSSProperties )},
            Dropdown: { style: () => Object.assign({}, {
              ...BgDropdownColor,
              ...SbColor,
              position: "absolute",
              width: dropdownWidth,
              minWidth: dropdownWidth,
              borderRadius: dropdownBorderRadius,
              boxSizing: "unset",
              border: dropdownBorder,
              boxShadow: dropdownBoxShadow,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // scrollbarWidth: "thin",
              scrollbarWidth: "none",
              height: dropdownHeight
            } as React.CSSProperties )},
            Popover: {
              props: {
                overrides: {
                  Inner: { style: () => Object.assign({}, {
                    ...BgColor,
                    borderRadius: popoverBorderRadius,
                  } as React.CSSProperties )},
                  Body: { style: () => Object.assign({}, {
                    left: "1px",
                    ...BgColor,
                    borderRadius: popoverBorderRadius,
                    top: popoverTop,
                    position: popoverPosition,
                    width: popoverWidth,
                    height: dropdownHeight,
                    boxShadow: dropdownBoxShadow,
                  } as React.CSSProperties )}
                }
              }
            },
            SelectArrow: {
              props: {
                overrides: {
                  Svg: { style: () => Object.assign({}, {
                      ...fontStyle,
                      marginRight: arrowMargin,
                      display: showSelectArrow
                    } as React.CSSProperties )}
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
}

const SelectField = forwardRef(_SelectField)
export default SelectField
// #endregion

// SelectFieldList Component
interface SelectFieldList_Props {
  placeholder: string[]
  fieldStyle?: string[]
  startEnhancer?: string[]
  textStart?: boolean[]
  isCreatable?: boolean[]
  isClearable?: boolean[]
  // isRequired?:boolean[] TODO
  // defaultValue?: string[] TODO
  options?: SelectFieldOption_Values[][]
  // overrides?: {
  //   root?: CSSProperties
  //   lastChildBorder?: boolean[]
  // } TODO / Ikke nødvendig?
  // onChange?:(e:Value)=>void TODO
  listStyle?: ListStyle_Types|string
}

export type SelectFieldList_PropHandle = {
  context: (index:number) => string|undefined
  refs: React.MutableRefObject<SelectField_Handles[]>
}
/**
 * SelectFieldList
 * @param placeholder text in inputfield before typing if there is no defaultValue.
 * @param fieldStyle color styling for inputfield, e.g. fieldStyle="blue".
 * @param startEnhancer start icon.
 * @param textStart if true the text will move to the left where the startenhancer would have been.
 * @param isCreatable if true it will be possible to type whatever and add it as a selected value.
 * @param isClearable if true a "clear icon" will appear in the end of the field if the field has a value.
 * @param options one select in the list consisting of an id, a laben and maybe a domain.
 * @param listStyle defines the direction of the SelectFields in the List and the seperator between them.
 * @returns A list of select fields.
 */

const _SelectFieldList:React.ForwardRefRenderFunction<SelectFieldList_PropHandle, SelectFieldList_Props> = ({placeholder, fieldStyle, startEnhancer, textStart, isCreatable, isClearable, options = [], listStyle }: SelectFieldList_Props, ref) => {
  const inputRefs = useRef<SelectField_Handles[]>([])
  const { seperatorPosition, listDirection } = useMemo(() => CreateListStyle(listStyle), [listStyle])

  // Exposing methods and references to the parent component via ref
  useImperativeHandle(ref, ()=>({
    context(index=0) {
      if (inputRefs.current && inputRefs.current[index]) return inputRefs.current[index].context
      return undefined
    },
    refs:inputRefs
  }))

  const Item_List = useMemo(() => {
    return placeholder.map((_text, context_Index) => {
      const _type = fieldStyle&&fieldStyle[context_Index] ? fieldStyle[context_Index] : 'text'
      const _textstart = textStart&&textStart[context_Index] ? textStart[context_Index] : undefined
      var _startImage = startEnhancer&&startEnhancer[context_Index] ? startEnhancer[context_Index] : undefined
      const _options = options && options[context_Index] ? options[context_Index] : []
      const _isCreatable = isCreatable&&isCreatable[context_Index] ? isCreatable[context_Index] : undefined
      const _isClearable = isClearable&&isClearable[context_Index] ? isClearable[context_Index] : undefined

      var view = [(
        <SelectField key={"List_Input_" + context_Index} ref={(el) => el?inputRefs.current[context_Index] = el:{}}
        placeholder={_text}
        startEnhancer={_startImage}
        textStart={_textstart}
        isCreatable={_isCreatable}
        isClearable={_isClearable}
        options={_options}
        fieldStyle={_type}
        />
      )]
      // Seperator position based on list style for the list where last item will not have the seperator
      if (placeholder.length !== context_Index + 1) view.push(<div style={seperatorPosition as React.CSSProperties}/>)
        return view
      })
  }, [placeholder, fieldStyle, textStart, startEnhancer, options, isCreatable, isClearable, seperatorPosition])
  return (
    // return of div with list direction based on list style
    <div style={listDirection as React.CSSProperties}>
      {Item_List}
    </div>
  )
}
export const SelectFieldList = forwardRef(_SelectFieldList)

// function AnotherRootNavigation(navigator: NavigateFunction, login: any) {
//   throw new Error("Function not implemented.");
// }