import react, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { penTypes } from '../../../Definitions/valueTypes'
import { css, icons } from '../../../Styles'
import { Button, ButtonList, ButtonTypes, MoveAnimalToPen, Pen_Move } from '../..'

import { getSectionById } from '../../../Assets/TestData/sectionsData'

interface SectionMove_Props {
  sectionId: number
  onCancelBtn?: () => void
}
/**
 * Section_Move
 * @param sectionId Unique identifier for each section.
 * @param onCancelBtn OnClick event to handle Cancel btn.
 * @returns a section of pens for Freeda Location Feeder systems.
 */
export default function Section_Move({ sectionId, onCancelBtn }: SectionMove_Props) {
  const {t} = useTranslation()
  const section = getSectionById(sectionId);
  const [checkedPens, setCheckedPens] = useState<number[]>([]);
  const [animalsToMove, setAnimalsToMove] = useState<string[]>([]);
  const [showMoveAnimalToPenModal, setMoveAnimalToPenModal] = useState(false) // Move Animal Modal
  const [isAllSelected, setIsAllSelected] = useState(false);
  
  function CancelBtnClick() {
    if (onCancelBtn) onCancelBtn()
  }

  // Error throwback if no section number excists
  if (!section) {
    return <div>Section {sectionId} not found</div>;
  }

  // Handle checking/unchecking single pens
  const handlePenCheck = (penNo: number, isChecked: boolean) => {
    setCheckedPens(prevChecked => {
      const updatedPens = isChecked ? [...prevChecked, penNo] : prevChecked.filter(p => p !== penNo);
      
      const newAnimalNos = section?.pens
          .filter(pen => updatedPens.includes(pen.penNo) && pen.animal)
          .map(pen => pen.animal?.animalNo?.toString())
          .filter((animalNo): animalNo is string => animalNo !== undefined) || [];

      setAnimalsToMove(newAnimalNos);
      return updatedPens;
    });
  };

  // Handle checking/unchecking all pens
  const handleCheckAllPens = () => {
    if (isAllSelected) {
      setCheckedPens([]);
      setAnimalsToMove([]);
    } else { // if single animal is already checked and "select all" is clicked
      const updatedPens = section?.pens
        .filter(pen => pen.animal)
        .map(pen => pen.penNo) || [];
        
      const newAnimalNos = section?.pens
        .filter(pen => pen.animal)
        .map(pen => pen.animal?.animalNo?.toString())
        .filter((animalNo): animalNo is string => animalNo !== undefined) || [];
        
      setCheckedPens(updatedPens);
      setAnimalsToMove(newAnimalNos);
    }
    setIsAllSelected(!isAllSelected);
  };

  const MoveModeBtns = 
  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
    <Button content={!isAllSelected? "Select All" : "Deselect All"} startEnhancer={icons.check_green} overrides={{ buttonType: ButtonTypes.secondaryTableButton, disableActive: true}} onClick={handleCheckAllPens}/>
    <ButtonList 
      content={[`${t("buttons.move")} (${checkedPens.length})`, t("buttons.cancelBtn")]} 
      onClick={(i => {
        if (i === 0) {setMoveAnimalToPenModal(true)}
        else if (i === 1) {CancelBtnClick()}
      })}
      startEnhancers={[icons.check, icons.close]}
      overrides={{
        button:{ buttonType: ButtonTypes.secondaryTableButton, disableActive: true}
      }} 
    />
  </div>

  const renderContent = () => {
    return section.pens.map(pen => (
      <Pen_Move
        key={pen.animal?.animalNo}
        penNo={pen.penNo}
        onCheck={(isChecked) => handlePenCheck(pen.penNo, isChecked)}
        isChecked={checkedPens.includes(pen.penNo)}
      />
    ));
  }

  return (
    <>
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "end", padding: "0px 20px" }}>
          {MoveModeBtns}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "", width: "fit-content", gap: "10px", borderRadius: "10px", overflow: "hidden" }}>
          {renderContent()}
        </div>
      </div>
      {/* Move Animal Modal */}
      <MoveAnimalToPen isModalOpen={showMoveAnimalToPenModal} animalsToMove={animalsToMove} goBack={() => setMoveAnimalToPenModal(false)} />
    </>
  );
}