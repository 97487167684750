import { useMemo } from "react"
import { colors, css, fonts } from "../../Styles"

// #region | NumberDisplay Component
interface statusDisplay_Props {
  header: string
  value: number | string
  icon?: string
  valueColor?: string
}
/**
 * NumberDisplay
 * @param header header to display above the single numberDisplay component.
 * @param value the value of the number to be displayed.
 * @param unit the unit of the value.
 * @returns a nice way to display a value/number. 
 */
export default function StatusDisplay ({header, value, icon, valueColor}:statusDisplay_Props) {

  const _valueColor= useMemo(() => valueColor || colors.greyDarkPrimary, [valueColor]);

  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "5px"}}>
      {icon && <img src={icon} style={{ width: "40px" }} alt={`${header} icon`} />}
      <div>
        <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary }}>{header}</p>
        <p style={{...fonts.paragraph, ...fonts.fontSize4, color: _valueColor }}>{value}</p>
      </div>
    </div>
  )
}
// ---------------------------------------------------
//#endregion

// #region | NumberDisplayList Component
interface StatusDisplayList_Props {
  headers: string[]
  values: (number|string)[]
  icons?: string[]
  valueColor?: string[]
}
/**
 * NumberDisplayList
 * @param header header to display above the entire list of NumberDisplay components.
 * @param headers array of headers to display one header above every single NumberDisplay component.
 * @param values array of values to be displayed.
 * @param units array of units - defines the amount of NumberDisplay components to be displayed in the list.
 * @returns a nice way to display a list of values/numbers. 
 */
export function StatusDisplayList ({ headers = [], values = [], icons = [], valueColor = [] }: StatusDisplayList_Props) {

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", gap: "20px"}}>
        {headers.map((header, index) => (
            <StatusDisplay
              key={index}
              header={headers[index] ?? ""} // Default header to empty string if header is not defined
              value={values[index] ?? 0} // Default value to 0 if values is not defined
              icon={icons [index]}
              valueColor={valueColor[index] ?? colors.greyDarkPrimary}
            />
        ))}
    </div>
  )
}
// ---------------------------------------------------
//#endregion
