import { CSSProperties, useState } from "react"
import { Field_Types, CreateFieldTypes, FieldStyle_Types, CreateFieldStyle } from "../../Definitions"
import { Input } from "baseui/input"
import { colors, css, images } from "../../Styles"

interface InputField_Props {
  keyPass?: string
  context?:string
  placeholder?:string
  fieldStyle?: FieldStyle_Types | string
  fieldType?: Field_Types | string
  inputType: "string" | "number"
  startEnhancer?:string
  endEnhancer?:string
  textStart?: boolean
  isClearable?: boolean
  isRequired?:boolean
  isTableEditField?:boolean
  maxWidthEditField?: boolean
  disabled?:boolean
  onChange?:(e:string)=>void
  onBlur?: () => void
  overrides?:{
    startEnhancer?:CSSProperties
    endEnhancer?:CSSProperties
  }
  styleOverride?: CSSProperties
  initialIsFocused?: boolean;
}

const isNumeric = (num: any) => (typeof(num) === 'number' || (typeof(num) === "string" && num.trim() !== '' && !isNaN(+num)));

export const SimplifiedInputField = ({ placeholder, fieldStyle, fieldType, inputType, startEnhancer, endEnhancer, textStart = false, isClearable = false, isRequired = false, disabled=false, isTableEditField=false, maxWidthEditField=false, overrides, context, onChange, onBlur, styleOverride, initialIsFocused }: InputField_Props) => {
  const [isFocused, setIsFocused] = useState(initialIsFocused)
  const [isError, setError] = useState(false)
  
  const { BgColor } = CreateFieldStyle(fieldStyle);

  // Width from fieldType
  const { width, maxWidth, fontStyle, height, textpadding, distancePaddingInput, enhancerPadding } = CreateFieldTypes(fieldType)

  // Handle padding and margin based on the presence of the startEnhancer
  const handleDistance = () => {
    if (startEnhancer) {
      return { padding: "0px", margin: "0px 0px 0px -5px" } // with startEnhancer
    } 
    if (textStart) {
      return { padding: "0px", margin: "0px 0px 0px -26px" } // without startEnhancer but textStart=true
    }
    return { padding: distancePaddingInput } // without startEnhancer and textStart=false
  }
  
  const EditField = () => {
  if (isTableEditField) {
    return { margin: "0px 0px 0px -18px" }
    }
    return { margin: "0px" }
  }

  return (
    <Input disabled={disabled}
      autoFocus={initialIsFocused}
      placeholder={placeholder}
      startEnhancer={() => startEnhancer?<img alt="start_icn" src={startEnhancer} style={overrides?.startEnhancer?overrides.startEnhancer: {
        ...css.inputFieldEnhancer}}/> : (<></>)
      }
      endEnhancer={() => endEnhancer?<img alt="end_icn" src={endEnhancer} style={overrides?.endEnhancer?overrides.endEnhancer: { 
        ...css.inputFieldEnhancer}}/> : (<></>) 
      }
      clearable={isClearable}
      required={isRequired}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        if (onBlur && !isError) {
          onBlur()
        }
      }}
      error={isError} 
      clearOnEscape
      value={context}
      type={inputType}
      onChange={e => {
        setError(false)
        if (onChange) {
          if (inputType === "number" && (!isNumeric(e.target.value) || e.target.value === "")) {
            setError(true);
          }
          onChange(e.target.value);
        }
      }}

      // style overrides
      overrides={{
        Root: { style: {
            ...BgColor,
            width: width,
            height: height,
            padding: textpadding,
            maxWidth: maxWidthEditField ? maxWidth : "auto",
            transition: "0.1s",
            outline: isFocused ? `2px solid ${isError?colors.systemRed:colors.greyLightTertiary}` : isError?colors.systemRed:"none",
            borderRadius: "10px",
            border: "none",
            ...EditField(),
            ...styleOverride
          }
        },   
        Input: { style: { 
          ...fontStyle,
          padding: textpadding,
            // ...fonts.paragraph, 
            // ...fonts.fontSize3,
            transition: "0.1s",
            '::placeholder': { color: colors.greyLightTertiary, opacity:"30%" }
          }},
        InputContainer: { style:{
            ...BgColor,
            ...handleDistance()
          }}, 
        EndEnhancer: { style:{
            ...BgColor,
            padding: enhancerPadding
          }},
        StartEnhancer: { style: {
            ...BgColor,
            padding: enhancerPadding
          }},
        ClearIcon: {style: {
            width: "18px !important",
            height: "18px !important"
          }},
        ClearIconContainer: { style:{
            padding: "0px",
            margin: "0px -17px 0px 0px",
            zIndex: "9999"
          }},
        // style overrides for "show/hide" icon on password type field
        MaskToggleHideIcon: () => <img alt="hide_password" src={images.icons.passwordHide} style={ css.MaskToggleIcon }/>,
        MaskToggleShowIcon: () => <img alt="show_password" src={images.icons.passwordShow} style={ css.MaskToggleIcon }/>,
      }}
    />
  );
}