import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel } from '@tanstack/react-table';

import { colors, frames, icons, Units } from '../../../Styles';
import { ButtonList, ButtonTypes, PageHeader } from '../../../Components';
import { ListData_ValueTypes, TableStyles_Types } from '../../../Definitions'
import Graph_QL, { useFeedComponents } from '../../../Services/Agrisys_GqlDataCaller';
import { useAppSelector } from '../../../Store';
import { ReactiveTable } from '../../../Components/Tables/reactiveTableRenderer';

import { keyBy, merge } from 'lodash';
import { GenericDataParameters } from '../../../Components/Tables/_Tables/useColumnConstructor';
import { FeedComponentInfo } from '../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedComponentsCall';
import { useEditableColumnConstructor } from '../../../Components/Tables/_Tables/useEditableColumnConstructor';

interface DirtyData {
  id: number,
  [key: string]: unknown
}

const feedComponentAccessorKeys = {
  name: ListData_ValueTypes.Div,
  readyMixture: ListData_ValueTypes.Check,
  priority: ListData_ValueTypes.Number,
  ds: ListData_ValueTypes.Number,
  energy: ListData_ValueTypes.Number,
  protein: ListData_ValueTypes.Number,
  delete: ListData_ValueTypes.DeleteRowAction,
}

const getUnitFromAccessor = (accessor: keyof FeedComponentInfo) => {
  if (accessor === "ds" || accessor === "protein") {
    return Units.pct_white
  }
  if (accessor === "energy") {
    return Units.kG_white
  }
  return 
}

export default function Components() {
  const {t} = useTranslation();

  const farm = useAppSelector(({activeData}) => activeData.farm) ?? "";
  const domain = Graph_QL.ObjToString({domain: farm});
  const connection = Graph_QL.create({domain: farm});

  const [editTable, setEditTable] = useState(false);
  const [addedRowCounter, setAddedRowCount] = useState(0);

  const {call: getFeedComponentsInfo, data: feedComponentsState, maintainEntries} = useFeedComponents(connection);
  // Switch between edit mode and non-edit mode
  const switchTable = () => {
    setEditTable((editTable) => !editTable);
  };

  const feedComponents = useMemo(() => feedComponentsState && feedComponentsState[domain] ? feedComponentsState[domain].data: undefined,[domain, feedComponentsState])
  const [data, setData] = useState(feedComponents?.filter((component) => !component.deleted) ?? []);

  useEffect(() => {
    if (feedComponents) {
      setData(feedComponents.filter((component) => !component.deleted));
    }
  },[feedComponents])
  const dirtRef = useRef<DirtyData[]>([]);

  const saveChangedData = () => {
    setEditTable(false);
    if (maintainEntries) {
      maintainEntries({con: connection.connection, data: dirtRef.current});
      dirtRef.current = [];
    }
  }

  const clearDirt = useCallback(() => {
    if (feedComponents) {
      setData(feedComponents.filter((component) => !component.deleted));
      dirtRef.current = [];
    }
  },[feedComponents])

  const addRow = useCallback(() => {
    setEditTable(true);
    setAddedRowCount(addedRowCounter + 1);
    setData([{id: -addedRowCounter, name: "", readyMixture: false, priority: 0, ds: 0, energy: 0, protein: 0, deleted: false}, ...Object.values(merge([], keyBy(data, "id"), keyBy(dirtRef.current, "id"))).filter((row) => !row.deleted)]);
  },[addedRowCounter, data])

  useEffect(() => {
    getFeedComponentsInfo(Graph_QL.create({domain}), {domain: farm , fetchAmount: 50});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const accessorData = useMemo<GenericDataParameters[]>(() => {
    return Object.entries(feedComponentAccessorKeys).map(([accessor, type]) => {
      return {
        headerValue: t(`feed.feedPlan.${accessor}`, {defaultValue: type}),
        accessor,
        type,
        unit: getUnitFromAccessor(accessor as keyof FeedComponentInfo),
        width: accessor==="delete" ? "56px" : undefined,
        sort: true,
      }
    })
  },[t]);
  const columns = useEditableColumnConstructor<FeedComponentInfo>({accessorData, tableStyle: TableStyles_Types.secondaryTable, canEdit: editTable})
  
  const table = useReactTable({
    columns: columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (id, columnId, value) => {
        dirtRef.current = (Object.values(merge(keyBy(dirtRef.current, "id"), keyBy([{id, [columnId]: value}], "id"))))
      },
      deleteRow: (deletedRowId) => {
        dirtRef.current = Object.values(merge(keyBy(dirtRef.current, "id"),keyBy([{id: deletedRowId, deleted: true}], "id")));
        setData(Object.values(merge([], keyBy(data, "id"), keyBy(dirtRef.current, "id"))).filter((row) => !row.deleted));
      }
    }
  })

  if (!feedComponents) { return (<div></div>)}
  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <PageHeader topHeader={t("globalMenu.feedCurves")} subHeader={t("feed.feedPlan.components")}/>
      </frames._page_inner_header>
      <frames._page_inner_content>
        <div style={{ minWidth: "430px", width: "100%", borderRadius: "10px", maxHeight:"100%", display: 'flex', flexDirection: "column"}}>
          <div style={{ backgroundColor: "transparent", display: "flex", justifyContent: "end", padding: "0px 20px", gap: "5px", height: "50px"}}>
            {!editTable? 
              <ButtonList content={[t("buttons.add", {defaultValue: "Add"}), t("buttons.edit")]} onClick={(i => {
                if (i === 0) {
                  addRow();
                }
                if (i === 1) switchTable()
              })}
              startEnhancers={[icons.add_white, icons.edit_white]}
              overrides={{
                button:{ buttonType: ButtonTypes.tableButton}
              }} />
            : <ButtonList content={[t("buttons.add", {defaultValue: "Add"}), t("buttons.saveBtn"), t("buttons.cancelBtn")]} onClick={(i => {
                if (i===0) {
                  addRow();
                }
                if (i === 1) {
                  saveChangedData();
                }
                if (i === 2) {
                  switchTable();
                  clearDirt();
                }
              })}
              startEnhancers={[icons.add_white, icons.check_white, icons.close]}
              overrides={{
                button:{ buttonType: ButtonTypes.tableButton}
              }} />
            }
          </div>
          <div style={{backgroundColor: colors.greyPrimary, height:"46px", borderRadius: "10px 10px 0px 0px", display: "block"}} /> 
            {<ReactiveTable table={table} tableType={TableStyles_Types.secondaryTable} emptyText={t("feedComponents.table.emptyText", {defaultValue: "No components found"})}/>}
          </div>
        </frames._page_inner_content>
    </frames.Page_inner>
  );
}