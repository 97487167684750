import { colors, css, frames } from "../../Styles/index"
import "../../Translations/index"
import { Button, ButtonList } from "../../Components"
import { NavigationFunction, Pages } from "../Routers/root"
import { useNavigate } from "react-router"
import "../../Components/languageSelector"
import { StyleObject } from "styletron-react"
import { useEffect, useMemo, useState } from "react"
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { ButtonTypes } from "../../Components/BaseComponents/button"

interface TabMenu_Props {
  main:Pages,
  contexts:Pages[],
  overrides?:{
      gridArea?: string,
      base_alternative?: Pages
  }
}

export default function TabMenu({overrides, main, contexts}:TabMenu_Props) {
  // Tab Menu navigation endpoints
  const navigator = useNavigate()
  const {t} = useTranslation()

  const BtnFunc_Router = (btn_Index:number) => {
    if (btn_Index === 0) {
      if (!overrides?.base_alternative) NavigationFunction(navigator, Pages.home, undefined, true)
      else NavigationFunction(navigator, main, overrides?.base_alternative, true)
    }
    else NavigationFunction(navigator, main, contexts[btn_Index-1])
  }

  // _Root Overrides (Grid Area)
  const _Root = useMemo<StyleObject>(() => {
    return Object.assign(
        {} as StyleObject,
        overrides&&overrides.gridArea? {gridArea:overrides.gridArea} : {}
    )
  },[overrides?.gridArea])

  const _context = ():string[] => {
    var texts:string[] = []
    // TODO: Maja, gøre det muligt at skrive et navn "manuelt" for tab'en
    
    // texts.push(t("globalMenu." + main))
    // contexts.forEach(text => {
    //   var test = t("subMenu." + main + "." + text)
    //   texts.push(test.startsWith("subMenu")?text:test)
    // })
    // return texts
    texts.push(t(`globalMenu.${main}`, {defaultValue: main}))
    contexts.forEach(text => {
      var subText = t(text)
      texts.push(subText.startsWith("subMenu")?text:subText)
    })
    return texts
  }
  useEffect(() => {
    if (!overrides?.base_alternative) NavigationFunction(navigator, Pages.home, undefined, true)
    else NavigationFunction(navigator, main, overrides?.base_alternative, true)
  }, [])

  return (
    <frames.Page_outer>

      <frames._page_outer_tabMenu>

        <frames.TabMenu $style={_Root}>
          <ButtonList content={_context()}
            onClick={(e) => BtnFunc_Router(e)}
            listStyle="horizontal"
            overrides={{
              button: {
                buttonType: ButtonTypes.tabButton
              },
              useNavigationControl: "tab"
            }}
          />
        </frames.TabMenu>

        {/* TabMenu bottom line */}
        <div style={{height: "5px", backgroundColor: colors.blueDarkPrimary, position: "sticky", left: "0px", right: "0px"}}></div>
      </frames._page_outer_tabMenu>
      
      <frames._page_outer_inner_page>
        <Outlet/>
      </frames._page_outer_inner_page>

    </frames.Page_outer>
  )
}
