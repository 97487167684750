import React, { useState } from "react";
import { LineSegment, VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryGroup, VictoryLabel, VictoryLine, VictoryScatter, VictoryStack } from "victory";
import { colors, css, fonts } from "../../Styles";
import _ from "lodash";
import { SelectField } from "../BaseComponents";
import { Field_Types, FieldStyle_Types } from "../../Definitions";

// #region | TestData
type Last10Days = {
  date: string
  feedTotal: number
  feedConsumption: number
  activations: number
}

export const TestData: Last10Days[] = [
  {
    date: "3/11",
    feedTotal: 5,
    feedConsumption: 3.5,
    activations: 55
  },
  {
    date: "4/11",
    feedTotal: 4,
    feedConsumption: 4,
    activations: 42
  },
  {
    date: "5/11",
    feedTotal: 3,
    feedConsumption: 2.5,
    activations: 27
  },
  {
    date: "6/11",
    feedTotal: 3,
    feedConsumption: 2.5,
    activations: 81
  },
  {
    date: "7/11",
    feedTotal: 3,
    feedConsumption: 3,
    activations: 46
  },
  {
    date: "8/11",
    feedTotal: 4,
    feedConsumption: 3.5,
    activations: 55
  },
  {
    date: "9/11",
    feedTotal: 4,
    feedConsumption: 4,
    activations: 65
  },
  {
    date: "10/11",
    feedTotal: 4,
    feedConsumption: 3.5,
    activations: 23
  },
  {
    date: "11/11",
    feedTotal: 5,
    feedConsumption: 4,
    activations: 56
  },
  {
    date: "12/11",
    feedTotal: 5,
    feedConsumption: 4.5,
    activations: 89
  },
  {
    date: "13/11",
    feedTotal: 5,
    feedConsumption: 4.5,
    activations: 89
  },
  {
    date: "14/11",
    feedTotal: 5,
    feedConsumption: 4.5,
    activations: 89
  },
]
// #endregion

interface Last10DaysViewer_Props {
  data: Last10Days[]
}

export default function Last10DaysViewer({ data }: Last10DaysViewer_Props) {
  const [leftId, setLeftId] = useState<number | undefined>(2);
  const [rightId, setRightId] = useState<number | undefined>(1);

  // #region | SelectFields
  const selectedValue = [
    {label: 'Activations', id: 1},
    {label: 'Feed (KG)', id: 2}
  ];

  const handleSelectChange = (side: 'left' | 'right') => (e: any) => {
    const selectedId = e && e[0]?.id ? parseInt(e[0].id) : undefined;
    if (side === 'left') {
      setLeftId(selectedId);
    } else {
      setRightId(selectedId);
    }
  };
  
  const findLabelById = (id: number | undefined, options: { label: string, id: number }[]) => {
    if (id === undefined) {
      return ""; // Return an empty string if id is undefined
    }
    const option = options.find(option => option.id === id);
    return option ? option.label : "";
  };

  const rightSelectField =
    <SelectField
      isClearable={true}
      defaultValue={findLabelById(rightId, selectedValue)}
      options={selectedValue}
      textStart={true}
      onChange={handleSelectChange('right')}
      fieldStyle={FieldStyle_Types.tableEdit}
      fieldType={Field_Types.shortInputFieldSecondary}
    />

  const leftSelectField = 
    <SelectField
      isClearable={true}
      defaultValue={findLabelById(leftId, selectedValue)}
      options={selectedValue}
      textStart={true}
      onChange={handleSelectChange('left')}
      fieldStyle={FieldStyle_Types.tableEdit}
      fieldType={Field_Types.shortInputFieldSecondary}
    />

  // #endregion

  // #region | Data
  const activationsRange = [Math.min(...TestData.map(d => d.activations))-10, Math.max(...TestData.map(d => d.activations))];
  const feedTotalRange = [0, Math.max(...TestData.map(d => d.feedTotal))];
  
  const ticks = 6;
  const tickValues = _.range(ticks + 1);
  const tickValuesX = TestData.slice(0, 10).map((d, index) => index);
  
  const tickFormatFeed = (range: number[]) => (t: number) =>
    (range[0] + t * ((range[1] - range[0]) / ticks)).toFixed(2);
  
  const tickFormatActivations = (range: number[]) => (t: number) =>
    Math.round(range[0] + t * ((range[1] - range[0]) / ticks));
  
  const normalize =
    (range: number[], props: string) => (datum: { [x: string]: number; }) =>
      (datum[props] - range[0]) / ((range[1] - range[0]) / ticks);
  
  const domain = { y: [0, ticks] as [number, number] }
  // #endregion

  // #region | Bars
  const feedTotalBars = (rightId === 2) || (leftId === 2) ? (
    <VictoryBar
    data={data.slice(0, 10)}
      x="date"
      y={normalize(feedTotalRange, "feedTotal")}
      style={{
        data: {
          width: 20,
          fill: colors.greyLightPrimary,
        },
      }}
    />
  ): <></>

  const feedConsumptionBars = (rightId === 2) || (leftId === 2) ? (
    <VictoryBar
    data={data.slice(0, 10)}
      x="date"
      y={normalize(feedTotalRange, "feedConsumption")}
      style={{
        data: {
          width: 20,
          fill: colors.graphBlueEight,
        },
      }}
    />
  ) : <></>

  const activationsBars = (rightId === 1) || (leftId === 1) ? (
    <VictoryScatter
    data={data.slice(0, 10)}
      size={9}
      x="date"
      y={normalize(activationsRange, "activations")}
      style={{
        data: {
          fill: colors.graphActivity,
        },
      }}
      labels={TestData.map((d) => d.activations)}
      labelComponent={
        <VictoryLabel
          style={{ ...fonts.header,
            fontSize: 10,
            fill: colors.whitePrimary,
          }}
          dy={5}
        />
      }
    />
  ) : <></>
  // #endregion
  
  // #region | Y-axis
  const leftAxis = (leftId === 1) || (leftId === 2) ? ( 
    <VictoryAxis
      dependentAxis
      style={{
        ticks: {stroke: colors.greyPrimary, size: 5},
        axis: {stroke: colors.greyPrimary, strokeWidth: 2}
      }}
      offsetX={30}
      tickValues={tickValues}
      axisComponent={<LineSegment y1={26} y2={255}/>}
      orientation="left"
      
      tickFormat={leftId === 2 ? tickFormatFeed(feedTotalRange) : tickFormatActivations(activationsRange)}
      tickLabelComponent={
        <VictoryLabel
          text={({ datum }) => (datum === tickValues[0] ? "" : leftId === 2 ? tickFormatFeed(feedTotalRange)(datum) : tickFormatActivations(activationsRange)(datum))}
          style={{...fonts.paragraph, ...fonts.fontSize4, fill: colors.greyPrimary}}
        />
      }
    />
  ) : <></>
  
  const rightAxis = (rightId === 1) || (rightId === 2) ? (
    <VictoryAxis
      dependentAxis
      style={{
        ticks: {stroke: colors.greyPrimary, size: 5},
        axis: { stroke: colors.greyPrimary, strokeWidth: 2}
      }}
      offsetX={30}
      tickValues={tickValues}
      axisComponent={<LineSegment y1={26} y2={255}/>}
      orientation="right"

      tickFormat={rightId === 2 ? tickFormatFeed(feedTotalRange) : tickFormatActivations(activationsRange)}
      tickLabelComponent={
        <VictoryLabel
          text={({ datum }) => (datum === tickValues[0] ? "" : rightId === 2 ? tickFormatFeed(feedTotalRange)(datum) : tickFormatActivations(activationsRange)(datum))}
          style={{ ...fonts.paragraph, ...fonts.fontSize4, fill: colors.greyPrimary}}
        />
      }
    /> 
  ) : <></>

  // #endregion

  return (
    <div style={{display: "grid", gridAutoColumns: "1fr 1fr", gridAutoRows: "1fr 1fr", width: "fit-content", height: "360px"}}>

      <div style={{gridArea: "1/1/2/2", display: "flex", justifyContent: "start", zIndex: "2"}}>  
        {leftSelectField}
      </div>  

      <div style={{gridArea: "1/2/2/3", display: "flex", justifyContent: "end", zIndex: "2"}}>  
        {rightSelectField}
      </div>    

      <div style={{gridArea: "2/1/3/3", marginTop: "-20px", zIndex: "1"}}>  
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px"}}>          
          <div style={{ width: "600px", height: "350px", margin: "0 auto"}} >
            <VictoryChart domain={domain} width={440} height={300} containerComponent={<VictoryContainer width={450} height={300} />}>

              {feedTotalBars}
              {feedConsumptionBars}
              {activationsBars}
          
              {leftAxis}
              {rightAxis}

              {/* X-Axis: Last 10 Days */}
              <VictoryAxis
                tickValues={tickValuesX}
                tickFormat={(x: string) => `${x}`}
                standalone={false}
                style={{
                  ticks: { stroke: colors.greyPrimary, size: 5 },
                  axis: { stroke: colors.greyPrimary, strokeWidth: 2 }
                }}
                crossAxis={false}
                tickLabelComponent={
                  <VictoryLabel
                    dy={-3}
                    style={{ ...fonts.paragraph, ...fonts.fontSize4, fill: colors.greyPrimary}}
                  />
                }
                axisComponent={ <LineSegment x1={0} x2={440} /> }
              />

            </VictoryChart>
          </div>
          <div style={{ marginTop: "-20px", display: "flex", flexDirection: "row", gap: "10px"}}>
            {(leftId === 2 || rightId === 2) && 
              <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                <div style={{padding: "4px 5px", ...fonts.header, ...fonts.fontSize4, color: colors.greyDarkPrimary, backgroundColor: colors.greyLightPrimary, borderRadius: "5px"}}>Feed Total</div>
                <div style={{padding: "4px 5px", ...fonts.header, ...fonts.fontSize4, color: colors.whitePrimary, backgroundColor: colors.graphBlueEight, borderRadius: "5px"}}>Feed Consumption</div>
              </div>
            }
            {(leftId === 1 || rightId === 1) && <div style={{padding: "4px 5px", ...fonts.header, ...fonts.fontSize4, color: colors.whitePrimary, backgroundColor: colors.graphActivity, borderRadius: "5px"}}>Activations</div>}
          </div>
        </div> 
      </div> 
    </div>
  );
}