import { createColumnHelper, Row } from "@tanstack/react-table";

import { CreateTableStyle, ListData_ValueTypes } from "../../../Definitions";
import { useUnitsComponent } from "../../../Styles";

import { EditAbleCell } from "../CellTypes/styledTableCell";
import { HeadCell } from "../CellTypes/headerTableCell";
import { StringTableCell } from "../CellTypes/stringTableCell";
import { NumberTableCell } from "../CellTypes/numberTableCell";
import { CheckboxTableCell } from "../CellTypes/checkboxTableCell";
import { ListTableCell } from "../CellTypes/listTableCell";
import { Button } from "baseui/button";
import { GenericColumnConstructorProps, GenericRow } from "./useColumnConstructor";
import { DivTableCell } from "../CellTypes/divTableCell";

export const useEditableColumnConstructor = <T extends GenericRow,> ({accessorData, tableStyle, canEdit}: GenericColumnConstructorProps & EditAbleCell ) => {
  const columnHelper = createColumnHelper<T>();
  const getUnit = useUnitsComponent();
  const {headCellStyle, bottomCellStyle} = CreateTableStyle(0, tableStyle)

  return accessorData.map(({accessor, unit, staticColumn=false, type, headerValue, idForColor, sort, options, width}) => {
    const footerValue = unit ? getUnit(unit) : "";
    switch (type) {
      default: 
      case ListData_ValueTypes.String:
        return columnHelper.accessor(
          row => (row[accessor] as string) ?? "",
          {
            id: accessor,
            header: (props) => (<HeadCell {...props} cellValue={headerValue} colorId={idForColor} isSorted={props.column.getIsSorted()} key={props.header.id} style={headCellStyle} onClick={!canEdit && sort ? props.column.getToggleSortingHandler() : undefined} keyPass={props.header.id+"header"}/>),
            cell: (props) => StringTableCell<T>({...props, canEdit, staticColumn}),
            footer: (props) => (<HeadCell {...props} key={props.header.id} style={bottomCellStyle} cellValue={footerValue} keyPass={props.header.id+"footer"}/>),
            sortingFn: sort ? "alphanumeric" : undefined,
            meta: {width: width},
          }
        )
      case ListData_ValueTypes.List:
        return columnHelper.accessor(
          row => (row[accessor] as string) ?? "",
          {
            id: accessor,
            header: (props) => (<HeadCell {...props} cellValue={headerValue} colorId={idForColor} isSorted={props.column.getIsSorted()} key={props.header.id} style={headCellStyle} onClick={!canEdit && sort ? props.column.getToggleSortingHandler() : undefined} keyPass={props.header.id+"header"}/>),
            cell: (props) => ListTableCell<T>({...props, canEdit, staticColumn, options: options ? options : []}),
            footer: (props) => (<HeadCell {...props} key={props.header.id} style={bottomCellStyle} cellValue={footerValue} keyPass={props.header.id+"footer"}/>),
            sortingFn: sort ? "alphanumeric" : undefined,
            meta: {width: width},
  
          }
        )
      case ListData_ValueTypes.Number:
        return columnHelper.accessor(
          row => (row[accessor] as number) ?? "",
          {
            id: accessor,
            header: (props) => (<HeadCell {...props} cellValue={headerValue} colorId={idForColor} isSorted={props.column.getIsSorted()} key={props.header.id} style={headCellStyle} onClick={!canEdit && sort ? props.column.getToggleSortingHandler() : undefined} keyPass={props.header.id+"header"}/>),
            cell: (props) => NumberTableCell({...props, canEdit, staticColumn}),
            footer: (props) => (<HeadCell {...props} key={props.header.id} style={bottomCellStyle} cellValue={footerValue} keyPass={props.header.id+"footer"}/>),
            sortingFn: sort ? "basic" : undefined,
            meta: {width: width},
  
          }
        )
      case ListData_ValueTypes.Check:
        return columnHelper.accessor(
        row => row[accessor] as boolean,
          {
            id: accessor,
            header: (props) => (<HeadCell {...props} cellValue={headerValue} colorId={idForColor} isSorted={props.column.getIsSorted()} key={props.header.id} style={headCellStyle} onClick={!canEdit && sort ? props.column.getToggleSortingHandler() : undefined} keyPass={props.header.id+"header"}/>),
            cell: (props) => CheckboxTableCell({...props, canEdit, staticColumn}),
            footer: (props) => (<HeadCell {...props} key={props.header.id} style={bottomCellStyle} cellValue={footerValue} keyPass={props.header.id+"footer"}/>),
            sortingFn: sort ? "basic" : undefined,
            meta: {width: width},
  
          }
        )
      case ListData_ValueTypes.Div:
        return columnHelper.accessor(
          row => (row[accessor] as string) ?? "",
          {
            id: accessor,
            header: (props) => (<HeadCell {...props} cellValue={headerValue} isSorted={props.column.getIsSorted()} key={props.header.id} style={headCellStyle} onClick={canEdit ? undefined : props.column.getToggleSortingHandler()} keyPass={props.header.id+"header"}/>),
            cell: props => DivTableCell({...props, canEdit, staticColumn}),
            footer: (props) => (<HeadCell {...props} key={props.header.id} style={bottomCellStyle} cellValue={""} keyPass={props.header.id+"footer"}/>),
            sortingFn: "alphanumeric",
            meta: {width: width},
          }
        )
      case ListData_ValueTypes.DeleteRowAction:
        const checkDeletePossible = (row: Row<T>, checkFunction?: (row: Row<T>) => boolean) => {
          if (checkFunction) {
            return checkFunction(row);
          }
          return true;
        }
        return columnHelper.display(
          { 
            id: accessor,
            header: (props) => (<HeadCell {...props} key={"removeRowActionHeader"} keyPass="removeRowActionHeader" style={headCellStyle} cellValue={""}/>),
            cell: (props) => {return ((canEdit && checkDeletePossible(props.row, props.table.options.meta?.allowDeleteRowCheck)) ? (<Button style={{background: "transparent", padding: "0px"}} onClick={() => props.table.options.meta?.deleteRow(props.row.original.id)}>{"del"}</Button>) :  (<div>{}</div>))},
            footer: (props) => (<HeadCell {...props} key={"removeRowActionFooter"} keyPass="removeRowActionFooter" style={bottomCellStyle} cellValue={""}/>),
            meta: {
              width: width,
            }
          }
        )
      }
    }
  )
}