import { colors } from "../../Styles";

  export type Group = {
    groupNo: number; //DataTYPE ID, static
    name: string;
  };
  
  export const AnimalGroupData: Group[] = [
    {
      groupNo: 1,
      name: "1.1"
    },
    {
      groupNo: 2,
      name: "2.2"
    },
    {
      groupNo: 3,
      name: "3.3"
    },
    {
      groupNo: 4,
      name: "4.4"
    },
    {
      groupNo: 5,
      name: "5.5"
    },
  ]

