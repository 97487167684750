import { useMemo, useState } from 'react';
import {useTranslation } from 'react-i18next';
import FeedCurveViewer from "./curveViewer/feedCurveViewer"
import { colors, css, fonts } from '../Styles';

import { FeedComponentInfo } from '../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedComponentsCall';
import { getGraphColor } from '../Styles/colors';
import { FeedCurveInfo } from '../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall';
import { getFeedCurveComponentsIds } from './_Components/getFeedCurveComponentsIds';

interface FeedCurveCard_Props {
  feedCurveId: number
  feedCurve?: FeedCurveInfo
  onFeedCurveSelect?: (id: number) => void
  feedComponents: FeedComponentInfo[]
}

export default function FeedCurveCard({ feedCurveId, feedCurve, onFeedCurveSelect, feedComponents }: FeedCurveCard_Props) {  
  const {t} = useTranslation()
  const [isSectionHovered, setIsSectionHovered] = useState(false);

  const componentNumbers = useMemo(() => {return getFeedCurveComponentsIds(feedCurve?.mixture);},[feedCurve?.mixture])
  if (!feedCurve || !feedComponents) return <div>No data found</div>;

  // Map through the components to find their names and styles
  const feedCurveComponents = componentNumbers.map(componentNo => {
    const componentData = feedComponents.find(comp => comp.id === componentNo);
    return componentData ? (
      <div
        key={componentNo}
        style={{
          ...css.colorTag,
          backgroundColor: getGraphColor(componentNo),
          ...fonts.header,
          ...fonts.fontSize4,
          color: colors.whitePrimary
        }}
      >
        {componentData.name}
      </div>
    ) : null; // Change "" to null for better JSX rendering
  });
    
  // Render milestones
  const feedCurveMilestones = feedCurve.milestones.map((milestone, index) => (
    <p key={index} style={{margin: "5px 0", ...fonts.paragraph, ...fonts.fontSize4}}>
      {milestone.milestone}
    </p>
  ));

  const isDefault = feedCurve.default
  const isActive = feedCurve.active
  
  return (
    <div
      onClick={onFeedCurveSelect ? () => onFeedCurveSelect(feedCurveId) : undefined } 
      onMouseEnter={() => setIsSectionHovered(true)}
      onMouseLeave={() => setIsSectionHovered(false)} 
      style={{ 
        cursor: "pointer",
        borderRadius: "10px",
        height: "fit-content",
        breakInside: "avoid",
        marginBottom: "20px",
        border: "5px solid"+colors.greyPrimary, 
        width: "320px",
        filter: isSectionHovered ? 'opacity(0.5)' : 'opacity(1)',
        transition: '0.3s'
      }}>
    
        <div style={{backgroundColor: colors.greyPrimary, height: "50px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative" }}>
          <p style={{...fonts.header, ...fonts.fontSize2, color: colors.greyLightTertiary}}>{t("feed.feedPlan.feedCurve")} {feedCurve.id}</p>
          <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary}}>{feedCurve.name}</p>
          <div style={{position: "absolute", top: "2px", right: "4px"}}>
            <p style={{...fonts.header, ...fonts.fontSize5, color: colors.blueLightPrimary, textAlign: "right"}}>{ isActive? "• " + t("feed.feedCorrections.active") : ""}</p>
            <p style={{...fonts.header, ...fonts.fontSize5, color: colors.greyLightTertiary, textAlign: "right"}}>{ isDefault? t("default") : ""}</p>
          </div>
        </div>
          
        <div style={{backgroundColor: colors.greyLightTertiary, borderRadius: "0px 0px 5px 5px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
          <FeedCurveViewer data={feedCurve} feedComponents={feedComponents} isMiniature={true} />
          <div style={{display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "center", gap: "20px", marginBottom: "30px"}}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: "5px" }}>
              <p style={{...fonts.header, ...fonts.fontSize4, color: colors.greyDarkPrimary}}>{t("feed.feedPlan.components")}</p>
              {feedCurveComponents}
            </div>
            <div style={{width: "2px", height: "fit-content", backgroundColor: colors.greyPrimary}}/>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: "5px" }}>
              <p style={{...fonts.header, ...fonts.fontSize4, color: colors.greyDarkPrimary}}>{t("feed.feedPlan.milestones")}</p>
              {feedCurveMilestones}
            </div>
          </div>
        </div>
    </div>
  );
}