import { TypedDocumentNode, gql } from "@apollo/client";
import { Graph_QL } from "../";

// const ServerValues = gql`
//   fragment NameParts on Person {
//     firstName
//     lastName
//   }
// `

// # GetLive
export interface GetLiveStateArgs {
  domain:string, gate:number, device:number
  //humanTime?:boolean
}
export interface LiveStateItem {
  state:boolean, __typename?:string,
  value1:number, value2:number, value3:number,
  value4:number, value5:number, value6:number
}
export interface LiveStateResponse {
  getOnlineState:LiveStateItem
}
// export const GET_LIVE_STATES = (connection:ConnectionObj[]):TypedDocumentNode<LiveStateResponse, undefined> => {
//   return gql`query GetOnlineState {
//     ${connection.map(({ domain, gate, device }, index) => `
//       getOnlineState_${index}:getOnlineState(domain: "${domain}", gatewayindex: ${gate}, mapdeviceindex: ${device}) {
//         state
//         value1 value2 value3
//         value4 value5 value6
//       }
//     `)}
//   }`
// }
export const GET_LIVE_STATE: TypedDocumentNode<LiveStateResponse, GetLiveStateArgs> = gql`
  query GetOnlineState($domain: String!, $gate: Int!, $device: Int!) {
    getOnlineState(domain: $domain, gatewayindex: $gate, mapdeviceindex: $device) {
      state
      value1 value2 value3
      value4 value5 value6
    }
  }
`;

// # GetStartConfigs
export interface GetStartConfigsArgs {
  domain:string, gate:number, device:number
}
export interface StartConfigs {
  value:string
}
export interface StartConfigsResponse {
  getStartConfigs:StartConfigs[]
}
export const GetStartConfigs = gql`
  query GetStartConfigs($domain: String!, $gate: Int!, $device: Int!) {
    getStartConfigs(domain: $domain, gatewayindex: $gate, mapdeviceindex: $device) {
      value
    }
  }
`;

//Device calls
export interface LogItem {
  id:number, gatewayindex:number, mapdeviceindex:number,
  timestamp:Date|number, message:string,
  __typename?:string
}
export interface LogsResponse {
  getLog:LogItem[]
}
export interface GetLogsArgs {
  domain:string, gate?:number, device?:number
  humanTime?:boolean, lastid?:number, fetch?:boolean
}
export const GET_LOGS = gql`
  query GetLog($domain: String!, $gate: Int, $device: Int, $humanTime: Boolean, $lastid: Int, $fetch: Boolean) {
    getLog(domain: $domain, gatewayindex: $gate, mapdeviceindex: $device, HumanTime: $humanTime, lastid: $lastid, fetch: $fetch) {
      id gatewayindex mapdeviceindex
      timestamp message
    }
  }
`;

export interface DailyScaleItem {
  gatewayindex?:number,
  mapdeviceindex?:number,
  timestamp:Date|number,
  value1:number,
  value2:number,
  value3:number,
  value4:number,
  __typename?:string
}
export interface DailyScaleResponse {
  getDailyScales:DailyScaleItem[]
}
export type GetDailyScaleArgs = Graph_QL.ConnectionObj & {
  humanTime?:boolean,
  lastDays?:number
}
export const GET_DAILYSCALES = gql`
  query GetDailyScales($domain: String!, $gate: Int, $device: Int, $lastDays: Int, $limitDays: Int, $humanTime: Boolean) {
    getDailyScales(domain: $domain, gatewayindex: $gate, mapdeviceindex: $device, lastDays: $lastDays, limitDays: $limitDays, HumanTime: $humanTime) {
      gatewayindex mapdeviceindex
      value1 value2 value3 value4
      timestamp
    }
  }
`;

export interface DailyTempItem {
  gatewayindex?:number,
  mapdeviceindex?:number,
  timestamp:Date|number,
  value1:number,
  value2:number,
  value3:number,
  value4:number,
  __typename?:string
}
export interface DailyTempResponse {
  getDailyTemperature:DailyTempItem[]
}
export interface GetDailyTempArgs {
  domain:string,
  gate:number,
  device:number,
  humanTime?:boolean,
  lastDays?:number
}
export const GET_DAILYTEMPERATURES = gql`
  query GetDailyTemperature($domain: String!, $gate: Int, $device: Int, $lastDays: Int, $limitDays: Int, $humanTime: Boolean) {
    getDailyTemperature(domain: $domain, gatewayindex: $gate, mapdeviceindex: $device, lastDays: $lastDays, limitDays: $limitDays, HumanTime: $humanTime) {
      gatewayindex mapdeviceindex
      value1 value2 value3 value4
      timestamp
    }
  }
`;