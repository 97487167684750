import { useMemo, useState } from "react";
import { LineSegment, VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryLabel, VictoryLine, VictoryStack } from "victory";

import { colors, css, fonts } from "../../Styles";

import { getMixtureDataPerComponent } from "./getMixtureDataPerComponent";
import { linearProjection } from "./linearProjection";
import { FeedComponentInfo } from "../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedComponentsCall";
import { getGraphColor } from "../../Styles/colors";
import { FeedCurveInfo } from "../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall";
import { convertDenseToSparse } from "../../Containers/GlobalScreens/FeedCurves/convertSparseMixtureData";
import { getFeedCurveComponentsIds } from "../_Components/getFeedCurveComponentsIds";

// const CHART_WIDTH = 550
// const CHART_HEIGHT = 300

interface FeedCurveViewerProps {
  data: FeedCurveInfo,
  feedComponents: FeedComponentInfo[]
  isMiniature?: boolean
  showComponentLabels?: boolean
  showMilestoneLabels?: boolean
  showMilestoneLabelsBox?: boolean
  invertedColors?: boolean
  feedCurveWidth?: string
}

export default function FeedCurveViewer({ data: feedCurve, feedComponents, isMiniature = false, showComponentLabels = false, showMilestoneLabels = false, showMilestoneLabelsBox = false, invertedColors= false, feedCurveWidth }: FeedCurveViewerProps) {
  const [hoveredComponent, setHoveredComponent] = useState<number | null>(null);
  const feedCurveComponentIds = useMemo(() => (getFeedCurveComponentsIds(feedCurve.mixture)),[feedCurve.mixture])
  const milestone = feedCurve.milestones
  const sparseData = convertDenseToSparse(feedCurve.mixture, feedCurve.milestones, feedCurveComponentIds, false);
  
  // Find minimum and maximum days for proper domain setting on VictoryBars (Components)
  const mixtureDays = feedCurve.mixture.map((mixture) => mixture.day).filter((day, index, array) => array.indexOf(day) === index).sort((a,b) => (a-b));
  const minDay = mixtureDays.at(0) ?? 0;
  const maxDay = mixtureDays.at(-1) ?? 1;
  mixtureDays.push(maxDay+1);
  const amountOfDays = maxDay - minDay;
 
  // Find all components in the specific mixture
  const usedComponents = new Set(feedCurve.mixture.map((mixture) => mixture.component))
  const componentsUsedInData = feedComponents?.filter((component) => (usedComponents.has(component.id)));
 
  // Find minimum and maximum amounts
  const amounts = feedCurve.mixture.map((mixture) => mixture.amount);
  const minAmount = Math.min(...amounts);
  const maxAmount = Math.max(...amounts);
  const extendedMaxAmount = maxAmount * 1.2;

  const handleComponentHover = (componentNo: number | null) => {
    setHoveredComponent(componentNo);
  };

  const _feedCurveWidth = isMiniature ? "300px" : (feedCurveWidth || "600px");

  const victoryArea = useMemo(() => {
    const barData = getMixtureDataPerComponent(sparseData, feedCurveComponentIds, linearProjection, maxDay-minDay);
    return feedCurveComponentIds.map((id, index) => {
              return (
                <VictoryArea
                  interpolation={maxDay-minDay > 250 ? "linear" : "stepAfter"}
                {...(!isMiniature && showComponentLabels && {
                  events: [{
                    target: "data",
                    eventHandlers: {
                      onMouseEnter: () => {
                        setHoveredComponent(id);
                      },
                      onMouseLeave: () => {
                        setHoveredComponent(null);
                      },
                    },
                  }],
                })}
                  key={index}
                  data={barData.get(id)}
                  x="x"
                  y="y"
                  style={{
                    data: {
                      fill: hoveredComponent === id ? colors.greyDarkPrimary : getGraphColor(id),
                      stroke: "none",
                    },
                  }}
                />
              );
            })
  }, [feedCurveComponentIds, hoveredComponent, isMiniature, maxDay, minDay, showComponentLabels, sparseData])

  return (
    <>
    {/* <div style={{ width: !isMiniature ? "600px" : "300px", position: "relative"}}> */}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px"}}>          
        <div style={{ width: _feedCurveWidth, margin: "0 auto"}} >
        <VictoryChart width={550} height={300} containerComponent={<VictoryContainer width={550} height={300} />}>
          <VictoryStack>
            {victoryArea}
          </VictoryStack>

          {/* X-Axis: Days */}
          <VictoryAxis
            tickFormat={(x: any) => `${x}`}
            standalone={false}
            tickCount={!isMiniature ?  Math.min(10, amountOfDays) : Math.min(5, amountOfDays)}
            domain={[minDay, maxDay+1]}
            style={{
              ticks: { stroke: colors.greyPrimary, size: 5 },
              axis: { stroke: colors.greyPrimary, strokeWidth: !isMiniature ? 2 : 1 }
            }}
            crossAxis={false}
            label={"Days"}
            tickLabelComponent={ // axis-values (numbers)
              <VictoryLabel
                dy={-3}
                style={{ ...fonts.paragraph,
                  ...(!isMiniature ? fonts.fontSize4 : fonts.fontSize3),
                  fill: colors.greyPrimary,
                }}
              />
            }
            axisLabelComponent={ // axis-header
              <VictoryLabel
                dy={-13}
                dx={!isMiniature ? 250 : 207}
                style={{ ...fonts.header,
                  ...(!isMiniature ? fonts.fontSize4 : fonts.fontSize3),
                  fill: colors.greyPrimary,
                }}
              />
            }
            axisComponent={<LineSegment x1={35} x2={538} />}
          />
          {/* Y-Axis: Amount */}
          <VictoryAxis
            dependentAxis
            domain={[minAmount, extendedMaxAmount + 1]}
            tickFormat={(y: any) => `${y}`}
            standalone={false}
            tickCount={4}
            style={{
              ticks: { stroke: colors.greyPrimary, size: 5 },
              axis: { stroke: colors.greyPrimary, strokeWidth: !isMiniature ? 2 : 1 },
            }}
            offsetX={!isMiniature ? 40 : 36}
            crossAxis={false}
            label={"KG"}
            tickLabelComponent={ // axis-values (numbers)
              <VictoryLabel
                style={{ ...fonts.paragraph,
                  ...(!isMiniature ? fonts.fontSize4 : fonts.fontSize3),
                  fill: colors.greyPrimary,
                }}
              />
            }
            axisLabelComponent={ // axis-header
              <VictoryLabel
                angle={0}
                dy={!isMiniature ? -115 : -115}
                dx={!isMiniature ? 4 : 0}
                style={{ ...fonts.header,
                  ...(!isMiniature ? fonts.fontSize4 : fonts.fontSize3),
                  fill: colors.greyPrimary,
                }}
              />
            }
            axisComponent={<LineSegment y1={!isMiniature ? 26 : 24} y2={!isMiniature ? 255 : 255} />}
          />


          {/* Milestone in the feedCurve, add a VictoryAxis where milestone startDay is offsetX */}
          {!isMiniature && milestone.length > 0 && milestone.map((milestone, index) => {
            return (
              <g key={index}>
              <VictoryLine
                width={550}
                height={300}
                key={index}
                data={[
                  { x: milestone.startDay, y: 0 },
                  { x: milestone.startDay, y: extendedMaxAmount, label: `m${index + 1}`},
                ]}
                domain={{
                  x: [minDay, maxDay+1],
                  y: [0, extendedMaxAmount]
                }}
                style={{
                  data: { 
                    stroke: invertedColors ? colors.greyLightTertiary : colors.blackPrimary,
                    strokeWidth: 1, 
                    strokeDasharray: 2 
                  }
                }}
                scale={{ x: "linear", y: "linear" }}
                standalone={false}
                labels={({ datum }) => datum.label}
                labelComponent={
                  <VictoryLabel  
                    dy={13}
                    dx={3}
                    style={{...fonts.header,
                      ...fonts.fontSize5,
                      fill: invertedColors ? colors.greyLightTertiary : colors.blackPrimary,
                    }}
                    textAnchor="start"
                  />
                }
              />
            </g>
            )
          })}
        </VictoryChart>
        </div>

        {/* COMPONENTS LABELS */}
        {showComponentLabels?
          <div style={{ marginTop: "-20px"}}>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", whiteSpace: "nowrap"}}>
              { componentsUsedInData?.map((component, index) => (
                <div key={index} 
                  onMouseEnter={() => handleComponentHover(component.id)}
                  onMouseLeave={() => handleComponentHover(null)}
                  style={{ 
                    cursor: "default",
                    outline: hoveredComponent === component.id ? "2px solid"+colors.greyPrimary : "none",
                    outlineOffset: hoveredComponent === component.id ? "2px" : "none",
                    borderRadius: "5px"
                  }}
                > 
                  <div key={index} style={{ 
                    filter: hoveredComponent === component.id ? "opacity(0.7)" : "opacity(1)",
                    transition: "0.3s",
                    backgroundColor: getGraphColor(component.id), 
                    ...css.colorTag, 
                    ...fonts.header, 
                    ...fonts.fontSize4, 
                    color: colors.whitePrimary }}
                  >
                    {component.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        : <></> }

        {/* MILESTONES LABELS */}
        {showMilestoneLabelsBox?
          // <div style={{minWidth: "130px", position: "absolute", top: "121px", right: "41px"}} >
          <div style={{minWidth: "130px", position: "absolute", top: "184px", right: "41px"}} >
            <div style={{ backgroundColor: colors.greyDarkPrimary, borderRadius: "8px 8px 0px 0px", padding: "7px", textAlign: "center", ...fonts.header, ...fonts.fontSize4, color: colors.greyLightTertiary}}>
              {"Milestones"}
            </div>
            <div style={{backgroundColor: colors.greyDarkSecondary, borderRadius: "0px 0px 8px 8px",}}>
              <div style={{
                height: "fit-content",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                border: "3px solid"+colors.greyDarkPrimary,
                borderTop: "none",
                padding: "10px"
              }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {milestone.map((milestone, index) => (
                      <div key={index} style={{ color: colors.greyLightTertiary, ...fonts.paragraph, ...fonts.fontSize4, lineHeight: "22px" }}>
                        {`M${index + 1}: ${milestone.milestone}`}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        : showMilestoneLabels?
          <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: "20px" }}>
          {milestone.map((milestone, index) => (
            <div key={index} style={{ color: colors.greyDarkPrimary, ...fonts.header, ...fonts.fontSize4 }}>
              {`M${index + 1}: ${milestone.milestone}`}
            </div>
          ))}
          </div>
        : <></> }

      </div>
    </>
  );
}
