import { gql } from "@apollo/client";
import { GenericRow } from "../../../../Components/Tables/_Tables/useColumnConstructor";

export const GET_FEED_COMPONENTS_INFO = gql`
    query GetFeedComponentsInfo($input: FeedComponentsInfoInput!) {
        feedComponentsInfo: getFeedComponentsInfo(input: $input)  {
            id,
            name,
            readyMixture: ready_mixture,
            priority,
            ds,
            energy,
            protein,
            deleted,
        }
    }`;

export interface FeedComponentsResponse {
    feedComponentsInfo: FeedComponentInfo[]
}

export interface FeedComponentsInput {
    domain: string;
    ids?: number[];
    cursor?: number;
    fetchAmount?: number;
}

export interface FeedComponentInfo extends GenericRow {
    id: number;
    name: string;
    readyMixture: boolean;
    priority: number;
    ds: number;
    energy: number;
    protein: number;
    deleted: boolean;
}