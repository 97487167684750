import react, { CSSProperties, useEffect, useMemo, useState } from "react";
import i18n from 'i18next'
import { CreateLangArr, languagObject } from "../Translations";
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box'
import { Select } from "baseui/select";
import { css, frames, flags, colors, fonts } from "../Styles";

interface LanguageSelector_Props {
  overrides?:{
    background?: CSSProperties,
    root?: CSSProperties,
    popover?: CSSProperties,
    isPrimaryComponent:boolean
  }
}
/**
 * Languageselector
 * @param overrides style or component overrides for the selector/clock.
 * @param overrides.root the style of the whole language selector.
 * @param overrides.popover the style for the "dropdown", making it possible to change direction. 
 * @param overrides.isPrimary Checking if the component is primary or secondary, which defines the styling for root and popover.
 * @returns a BaseUi selector, build from content param.
 */
export default function LanguageSelector ({overrides}:LanguageSelector_Props) {
  // Language change
  const options = CreateLangArr()
  const [currentSelected, setcurrentSelected] = useState<languagObject>(
    options.find(e => e.id === i18n.language) || CreateLangArr(i18n.language,i18n.language)[0]
  )

  useEffect(() => {
    var item = options.find(e => e.id === currentSelected.id)
    if (item) i18n.changeLanguage(item.id)
  },[currentSelected])

  // Styling on root element
  const isPrimary = overrides?.isPrimaryComponent;
  const _root = useMemo<CSSProperties>(() => {
    return Object.assign({}, isPrimary ? css.languageSelector_primary : css.languageSelector_secondary, overrides?.root || {});
  }, [isPrimary, overrides?.root]);

  return (
    <frames.DateTimeLang style={ _root }> 
      <Clock />
      <Select clearable={false} searchable={false}
        value={[currentSelected]} 
        onChange={(nextValue) => {
          setcurrentSelected(nextValue.option as languagObject)
        }}
        options={options}
        overrides={{
          Root: {style: () => Object.assign({}, { 
            ...fonts.header,
            ...fonts.textShadow, 
            ...fonts.fontSize4,
            width: "fit-content",
          } as React.CSSProperties )
          },    
          IconsContainer: {
            component: (compData) => null,
          },
          DropdownOption: {
            component: (compData) => LanguageNode({option:compData.item,onClick:compData.onClick}),
          },
          ValueContainer: {
            component: (compData) => {
              var optionItem = compData.children[0]
              return LanguageNode({option:{id:optionItem.props.value, label:optionItem.props.children}})
            },
          },
          DropdownContainer: {
            style: () => Object.assign({}, {
              ...fonts.header, 
              ...fonts.textShadow,
              ...fonts.fontSize4,
            } as CSSProperties )
          },
          ControlContainer: {
            style: () => Object.assign({}, {
              ..._root, 
              border: "none",
            } as CSSProperties)
          },
          Dropdown: {
            style: () => Object.assign({}, {
              ..._root,
              ...fonts.header, 
              ...fonts.textShadow,
              ...fonts.fontSize4,
              boxShadow: "none",
            } as React.CSSProperties)
          },
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: () => Object.assign({}, {
                    top: "0",
                    border: "none",
                    boxShadow: "none", 
                    backgroundColor: "transparent",
                  } as React.CSSProperties)
                },
                Inner: {
                  style: () => Object.assign({}, {
                    backgroundColor: "transparent",
                  } as React.CSSProperties)
                },
              }
            }
          }
        }}
      />
    </frames.DateTimeLang>
  );
}
// ---------------------------------------------------
//#endregion

// #region | Flags element
// Flags fragment for languages
const FlagObject = ({SelectedId}:{SelectedId:string}) => {
  return (
    <AspectRatioBox aspectRatio={225 / 225} width={'25px'}>
      <AspectRatioBoxBody
        as="img" src={flags[SelectedId]}
      />
    </AspectRatioBox>
  )
}

interface LanguageNode_Props {
  option:languagObject,
  onClick?:Function
}

// Label fragment for languages
const LanguageNode = ({option,onClick}:LanguageNode_Props) => {
  return (
    <frames.LanguageNode onClick={() => onClick?onClick():undefined}>
      <p>
        {option.label}
      </p>
      <FlagObject SelectedId={option.id}/>
    </frames.LanguageNode>
  )
}
// ---------------------------------------------------
//#endregion

// #region | Clock element
export const Clock = () => {
  const [dateTime, setDateTime] = useState(new Date());
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  function stopClock() {
    if (intervalId) clearInterval(intervalId);
  }

  function startClock() {
    stopClock();
    const newIntervalId = setInterval(() => {
      setDateTime(new Date());
    }, 10000); // Update every minute
    setIntervalId(newIntervalId);
  }

  useEffect(() => {
    // Page is loaded
    startClock();
    // Cleanup on component unmount
    return () => stopClock();
  }, []);

  return (
    <div style={{
      color: colors.greyLightTertiary,
      ...fonts.header, 
      ...fonts.textShadow,
      ...fonts.fontSize4,
    }}>
    <p>
        {dateTime.toLocaleString('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // 24-hour format
        }).replace(/,/g, '')}
      </p>
    </div>
  );
};
    // ---------------------------------------------------
    //#endregion