import { useMemo } from "react"
import { useApolloClient } from "@apollo/client"

import { Graph_QL, DynamicDataObk, StateDataObject, GqlHook_Returns } from '../';

import { useAppSelector, useAppDispatch, RootState } from '../../../Store'
import { EntityState } from "@reduxjs/toolkit"
import { gqlActions } from "../gqlData_Reducer"

/* eslint-disable */
import { GET_LIVE_STATE, GetLiveStateArgs, LiveStateResponse, LiveStateItem } from "./device_QlCalls"
import { GET_LOGS, GetLogsArgs, LogsResponse, LogItem } from "./device_QlCalls"
import { GET_DAILYSCALES, GetDailyScaleArgs, DailyScaleResponse, DailyScaleItem } from "./device_QlCalls"
import { GET_DAILYTEMPERATURES, GetDailyTempArgs, DailyTempResponse, DailyTempItem } from "./device_QlCalls"

import { Log_Item } from "./DeviceSlices/logSlice"
import { LiveState_Item } from "./DeviceSlices/deviceStateSlice"
import { AutopigStates, Autopig_Item } from "./DeviceSlices/autopigSlice"
import { filterByConnection } from "../filterByConnection";
/* eslint-enable */

export const useGetDeviceState = (connection?:Graph_QL.GQLCons):GqlHook_Returns<LiveState_Item> => {
  const dispatch = useAppDispatch()
  const client = useApolloClient()
  
  const LiveStates = useAppSelector(({graphQlData:{device:{state:LiveState}}}) => LiveState)
  const {fullState, data} = useMemo(() => {
    var Transformed_state = filterByConnection(LiveStates, Graph_QL.ToArr(connection)) as Record<string, LiveState_Item>
    return Object.entries(Transformed_state).reduce((acc, [DeviceKey, DeviceData]) => {
      if (DeviceData.data) acc.data = acc.data.concat({...DeviceData.data, domain:DeviceKey})
      return acc
    }, {fullState:Transformed_state, data:[]} as StateDataObject<LiveState_Item>)
  }, [LiveStates])

  const getState = async (specefic?:Graph_QL.GQLCons) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<LiveStateResponse>({query:GET_LIVE_STATE, variables:_Connection.connection as GetLiveStateArgs})
        return {con:_Connection.connection, data:datas.data.getOnlineState}
      })).then(e => {
        dispatch({type:''+gqlActions.device.state.added, payload:e})
      })
    }
  }

  const deleteEntries = (_Data?: Graph_QL.Multi_ConnectionParams|Partial<DynamicDataObk<any>>|Partial<DynamicDataObk<any[]>>) => {
    if (_Data) {
      var _deleteData = Array.isArray(_Data)?_Data:[_Data]
      if (_deleteData.length) {
        dispatch({type:''+gqlActions.device.state.deleted, payload:_deleteData})
      }
    }
    else dispatch({type:''+gqlActions.device.state.clear, payload:undefined})
  }
  const fetchMore = async (lastid:number|number[], specefic?:Graph_QL.GQLCons) => {
    
  }

  return { call: getState, deleteEntries, data:fullState, fetchMore, dataData:data }
}

/**
 * Call Gql-Server For Domain Logs
 * @param connection default connection strings - domains to fetch
 * @returns {call, fetchmore, delete, data}
 * 
 * @Call activate fetch for default or [specefic domains provided]
 * @Fetchmore Lastid, Pagination
 * @Delete data record or clear connection entries
 * @Data Current allocated data
 */
export const useGetDomainLog = (connection?: Graph_QL.GQLCons):GqlHook_Returns<Log_Item> => {
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const LogStates = useAppSelector(({graphQlData:{device:{logs}}}) => logs)
  const {fullState, data} = useMemo(() => {
    var Transformed_state = filterByConnection(LogStates, Graph_QL.ToArr(connection)) as Record<string, Log_Item>
    return Object.entries(Transformed_state).reduce((acc, [DeviceKey, DeviceData]) => {
      if (DeviceData.data) acc.data = acc.data.concat({...DeviceData.data, domain:DeviceKey})
      return acc
    }, {fullState:Transformed_state, data:[]} as StateDataObject<Log_Item>)
  }, [LogStates])

  const getLogs = async (specefic?:Graph_QL.GQLCons) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<LogsResponse>({query:GET_LOGS, variables:_Connection.connection as GetLogsArgs})
        return {con:_Connection.connection, data:datas.data.getLog} as DynamicDataObk<LogItem[]>
      })).then(e => {
        dispatch({type:''+gqlActions.device.log.added, payload:e})
      })
    }
  }

  const deleteEntries = (_Data?: Graph_QL.Multi_ConnectionParams|Partial<DynamicDataObk<any>>|Partial<DynamicDataObk<any[]>>) => {
    if (_Data) {
      var _deleteData = Array.isArray(_Data)?_Data:[_Data]
      if (_deleteData.length) {
        dispatch({type:''+gqlActions.device.log.deleted, payload:_deleteData})
        return
      }
    }
    else dispatch({type:''+gqlActions.device.log.clear, payload:undefined})
  }

  const fetchMore = async (lastid:number|number[], specefic?:Graph_QL.GQLCons) => {
    var _lastId = Array.isArray(lastid)?lastid:[lastid]
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection, index) => {
        var fetchId = _lastId[index]!==undefined?_lastId[index]:_lastId[0]

        var datas = await client.query({query:GET_LOGS, variables:{
          ..._Connection.connection, lastid:fetchId, fetch:fetchId?true:false
        } as GetLogsArgs})
        return {con:_Connection.connection, data:datas.data.getLog}
      })).then(e => {
        dispatch({type:''+gqlActions.device.log.added, payload:e})
      })
    }
  }
  
  return { call: getLogs, fetchMore, deleteEntries, data:fullState, dataData: data }
}
type lastDay = number
export const useGetAutopig = (connection?: Graph_QL.GQLCons):GqlHook_Returns<AutopigStates, lastDay> => {
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const AutopigState = useAppSelector(({graphQlData:{device:{autopig}}}) => autopig)
  const {fullState, Scales, Temps} = useMemo(() => {
    var Transformed_state = filterByConnection(AutopigState, Graph_QL.ToArr(connection)) as Record<string, Autopig_Item>
    return Object.entries(Transformed_state).reduce((acc, [DeviceKey, DeviceData]) => {
      let DeviceDatas = DeviceData.data
      //Scales
      if (DeviceDatas.Scales) acc.Scales = acc.Scales.concat(DeviceDatas.Scales.map(datas => ({...datas, domain:DeviceKey})))
      //Temps
      if (DeviceDatas.Temps) acc.Temps = acc.Temps.concat(DeviceDatas.Temps.map(datas => ({...datas, domain:DeviceKey})))
      //Configs
      //Curves
      //States
      if (DeviceDatas.State) acc.State.push({...DeviceDatas.State, domain:DeviceKey})
      return acc
    }, {fullState:Transformed_state, Scales:[], Temps:[], Configuration:[], CurveSet:[], State:[]} as StateDataObject<AutopigStates>)
  }, [AutopigState])

  const getScales = async (specefic?:Graph_QL.GQLCons, lastDays?:number) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<DailyScaleResponse>({query:GET_DAILYSCALES, variables:{..._Connection.connection, 
          lastDays:_Connection.data.lastdays?_Connection.data.lastdays:lastDays
        } as GetDailyScaleArgs})
        return {con:_Connection.connection, data:datas.data.getDailyScales}
      })).then(e => {
        dispatch({type:''+gqlActions.device.autopig.scales, payload:e})
      })
    }
  }
  const getTemps = async (specefic?:Graph_QL.GQLCons, lastDays?:number) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<DailyTempResponse>({query:GET_DAILYTEMPERATURES, variables:{..._Connection.connection,
          lastDays:_Connection.data.lastdays?_Connection.data.lastdays:lastDays
        } as GetDailyTempArgs})
        return {con:_Connection.connection, data:datas.data.getDailyTemperature}
      })).then(e => {
        dispatch({type:''+gqlActions.device.autopig.temps, payload:e})
      })
    }
  }
  const getState = async (specefic?:Graph_QL.GQLCons, lastDays?:number) => {
    var Connections:Graph_QL.MultiConnection|undefined = Graph_QL.ToArr(specefic?specefic:connection)
    if (Connections) {
      await Promise.all(Connections.cons.map(async (_Connection) => {
        var datas = await client.query<LiveStateResponse>({query:GET_LIVE_STATE, variables:_Connection.connection as GetLiveStateArgs})
        return {con:_Connection.connection, data:datas.data.getOnlineState}
      })).then(e => {
        //TODO: Device State or Autopig State ???
        //dispatch({type:''+gqlActions.device.state.added, payload:e})
        dispatch({type:''+gqlActions.device.autopig.state, payload:e})
      })
    }
  }

  const getConfiguration = () => {

  }

  const getCurveSet = () => {
    
  }

  const getAll = (specefic?:Graph_QL.GQLCons, lastDays?:number) => {
    getState(specefic, lastDays)
    getScales(specefic, lastDays)
    getTemps(specefic, lastDays)
  }

  const deleteEntries = (_Data?: Graph_QL.Multi_ConnectionParams|Partial<DynamicDataObk<any>>|Partial<DynamicDataObk<any[]>>) => {
    if (_Data) {
      var _deleteData = Array.isArray(_Data)?_Data:[_Data]
      if (_deleteData.length) {
        dispatch({type:''+gqlActions.device.autopig.deleted, payload:_deleteData})
      }
    }
    else dispatch({type:''+gqlActions.device.autopig.clear, payload:undefined})
  }
  const fetchMore = async (lastid:number|number[], specefic?:Graph_QL.GQLCons) => {
    
  }

  return { call:getAll, fetchMore, deleteEntries, data:fullState, getScales, getTemps, getState, getCurveSet, getConfiguration, scalesData:Scales, tempsData:Temps}
}