
import React from "react";
import { CheckBox, CheckStyles, ProgressCircle, ReportShortCut, Widget } from "../.."
import { colors, fonts, useMediaQuery } from "../../../Styles";

import { TestData as ReportData } from "../../../Assets/TestData/reportTemplates";
import { useCalendarData } from "../../../Assets/TestData/lf_dashboard";
import { useTranslation } from "react-i18next";
/**
 * Farrowing
 * @returns A widget widt farrowing calendar.
 */
export default function Farrowing() {
  const {t} = useTranslation();
  const CalendarData = useCalendarData();

  const tablet = useMediaQuery(`(min-width: 601px) and (max-width: 1021px)`);

  const getReportName = (reportId: number) => {
    const report = ReportData.find(template => template.reportTemplateNo === reportId);
    return report ? report.name : "";
  };

  return(
    <div style={{display: "flex", flexDirection: "column", width: tablet? "100%" : "fit-content", gap: "10px"}}>
      <Widget
        width="100%"
        header={<p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("reports.farrowing")}</p>}
        onSave={() => (console.log("save"))} // TODO
        content={
          <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-between", padding: "20px"}}>
             {CalendarData.map((event, eventIndex) =>
              event.progressCircle
                .filter(progress => progress.show) 
                .map((progress, progressIndex) => (
                  <div style={{ minWidth: "170px" }} key={`${eventIndex}-${progressIndex}`}>
                    <ProgressCircle
                      header={progress.header}
                      unit={progress.unit}
                      progressed={progress.progresed}
                      total={progress.total}
                    />
                  </div>
                ))
            )}
          </div>
        }
        editContent={
          <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "10px", padding: "20px"}}>
           {CalendarData.map((event, eventIndex) =>
              event.progressCircle.map((progress, progressIndex) => (
                <div style={{ width: "fit-content" }} key={`${eventIndex}-${progressIndex}`}>
                  <CheckBox
                    text={progress.header}
                    isChecked={progress.show}
                    checkStyle={CheckStyles.grey}
                  />
                </div>
              ))
            )}
          </div>
        }
      />
      <div style={{display: "flex", flexWrap: "wrap", width: tablet? "100%" : "634px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "10px"}}>
      {CalendarData.map((event, eventIndex) =>
          event.report?.map((reportId, reportIndex) => (
            <ReportShortCut
              key={`${eventIndex}-${reportIndex}`}
              //header={getReportName(reportId)}
              header={event.extra&&event.extra[reportIndex]?event.extra[reportIndex].text: getReportName(reportId)}
              amount={event.extra&&event.extra[reportIndex]?event.extra[reportIndex].number: 0}
              onClick={() => console.log(`Report ${reportId} clicked`)}
            />
          ))
        )}
      </div>
      
    </div>
  )
}