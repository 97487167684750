import React, { CSSProperties, forwardRef, useImperativeHandle, useMemo, useState } from "react"
import { StyledTable, StyledHeadCell, StyledBodyCell } from "baseui/table-grid"
import { Button, ButtonTypes } from "../../Components"
import { colors, css, icons } from "../../Styles"
import {Data, TableStyles_Types, CreateTableStyle } from "../../Definitions/"
import StyledTableCell from "./CellTypes/styledTableCell"

type ActionTypes = "Nav"|"Select"|"Edit"


interface TableList_Props {
  data: Data[];
  content: string[];
  bottom?: (string|React.JSX.Element)[];
  tableType?: TableStyles_Types | string,
  overrides?: {
    root?: CSSProperties,
    columnTemplate?: string,
    headerStyle?: CSSProperties|(CSSProperties|null|undefined)[],
    addEmptyRows?: number,
  };
  actions?: ActionTypes[],
  action?: (action:ActionTypes, data:any) => void
}

export interface TableList_PropHandles {
  changed: {row:number,col:number, value:any}[],
}

/**
 * Table List Container
 * @param data the data for the list.
 * @param content column name for each column. Displayed in the first row.
 * @param bottom a fixed row at the bottom that can display units for the desired rows..
 * @param tableType styles the three components of the table, namely headCellStyle, bodyCellStyle, bottomCellStyle, as well as the overall tableStyle.
 * @param overrides specific style or component overrides for the table, but can also define the columnTemplate and the minimum number of rows to be displayed.
 * @param actions defines a button and its type for each row, e.g., 'Nav', which allows a row to be used as navigation to a new page.
 * @param action defines the function for the action on the row if it has an action (actions).
 * @returns A table with the specified content and listed data.
 */
const _TableList:React.ForwardRefRenderFunction<TableList_PropHandles, TableList_Props> = ({ data, content, bottom, tableType, overrides, actions, action }: TableList_Props, ref) => {
  const [ChangedValues] = useState<{row:number,col:number, value:any}[]>([])
  
  useImperativeHandle(ref, ()=>({
    changed: ChangedValues,
  }))

  const _root = useMemo<CSSProperties>(() => {
    return Object.assign(
      overrides && overrides.root ? overrides.root : { ...css.tableList_root }
    )
  }, [overrides]);

  // Head cell data
  const {_StyledHeadCell, gridTemplateColumns} = useMemo(() => {
    var rows:string[] = actions?[""]:[] // content for actions row
    rows = rows.concat(content)

    var gridTemplateColumns = rows.reduce((acc, item) => acc + "1fr ", "");
    if (overrides && overrides.columnTemplate) {
      gridTemplateColumns = actions? "50px " : "" // column with for actions row
      gridTemplateColumns += overrides.columnTemplate
    }
    var view = (
      <div role="row" style={{ display: "contents" }}>
{rows.map((header, index) => {
          let { headCellStyle } = CreateTableStyle(index, tableType);
          if (overrides && overrides.headerStyle) {
            if (Array.isArray(overrides.headerStyle)) {
              if (overrides.headerStyle[index]) {
                headCellStyle = {...headCellStyle, ...overrides.headerStyle[index]}
              }
            } else {
              headCellStyle = {...headCellStyle, ...overrides.headerStyle}
            }
          }
          return (
            <StyledHeadCell
              key={index}
              style={headCellStyle as CSSProperties}
            >
              {header}
            </StyledHeadCell>
          );
        })}
      </div>
    );
    return {_StyledHeadCell:view, gridTemplateColumns}
  }, [actions, content, overrides, tableType]);

  // Body cell data
  const _StyledBodyCell = useMemo(() => {
    // Clone the original data to avoid mutating it
    const rows = [...data];

    // Add empty rows based on the `fillPage` number if provided
    if (overrides?.addEmptyRows) {
      const emptyRowCount = overrides.addEmptyRows;
      for (let i = 0; i < emptyRowCount; i++) {
        rows.push(new Array(content.length).fill(""));
      }
    }
    const { bodyCellStyle } = CreateTableStyle(0, tableType);

    return rows.map((_data, rowIndex) => {
      
      const cellStyle = {
        ...bodyCellStyle(0),
        backgroundColor: rowIndex % 2 ? colors.greyLightPrimary : colors.greyLightSecondary,
      };
  
      
      // Make every second row a different color
      var rowData = _data.map((cellData, columnIndex) => (StyledTableCell({cellData, canEdit: true}))).map((cell, columnIndex) => {
        return (
          <StyledBodyCell key={columnIndex} style={cellStyle as CSSProperties} $striped={rowIndex % 2 ? true : false}>
            {cell}
          </StyledBodyCell>
        );
      });
      
      // Action button to be displayed at the start of each row
      if (actions) {
        const cellStyle = {
          ...bodyCellStyle(0),
          backgroundColor: rowIndex % 2 ? colors.greyLightPrimary : colors.greyLightSecondary,
        }
        var ActionsRow: React.JSX.Element[] = []
        if (actions.includes("Nav")) {
          ActionsRow.push(
            <StyledBodyCell
              style={cellStyle as CSSProperties}
              $striped={rowIndex % 2 ? true : false}
            > 
              <Button 
                content="" onClick={() => {
                  if (action) action("Nav", rowIndex)
                }}
                startEnhancer={icons.goToArrow}
                overrides={{
                  buttonType: ButtonTypes.textButton
                }}
              />
            </StyledBodyCell>
          )
        }
        rowData = [...ActionsRow ,...rowData]
      }
      
      return (
        <div key={rowIndex} role="row" style={{ display: "contents" }}>
          {rowData}
        </div>
      );
    });
  }, [data, overrides?.addEmptyRows, content.length, tableType, actions, action]);

    // Bottom cell data
    const _StyledBottomCell = useMemo(() => {
      if (!bottom) return null;
  
      const { bottomCellStyle } = CreateTableStyle(0, tableType);
      return (
        <div role="row" style={{ display: "contents" }}>
          {bottom.map((bottomItem, index) => (
            <StyledHeadCell
              key={index}
              style={bottomCellStyle as CSSProperties}
            >
              {bottomItem}
            </StyledHeadCell>
          ))}
        </div>
      );
    }, [bottom, tableType]);

  const { tableStyle } = CreateTableStyle(0, tableType);
  return (
    <div style={_root}>
      <StyledTable
       style={tableStyle as CSSProperties}
        role="grid"
        $gridTemplateColumns={gridTemplateColumns}
      >
        {_StyledHeadCell}
        {_StyledBodyCell}
        {_StyledBottomCell}
      </StyledTable>
    </div>
  );
}
const TableListEdit = forwardRef(_TableList)
export default TableListEdit