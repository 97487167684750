//import React from "react";
import { NavigateFunction, Route, RouteObject } from "react-router-dom";
import { Autopig, Freeda_lf, Freeda_lf_sections, Freeda_lf_settings } from "../../../Containers/index"
import TabMenu from "../../Menus/tabMenu";

/* eslint-disable */
export enum Pages {
  home="./",

  autopig="autopig",

  freeda_lf="lf 1",
  freeda_lf2="lf 2",
  freeda_lf_sections="sections",
  freeda_lf_section="section",
  freeda_lf_settings="settings",

  freeda_esf="system 3",
  freeda_ppt="system 4",
  freeda_sorting="system 5"
}
/* eslint-enable */

export const Router = [{
  // element: <TabMenu main={Pages.home} contexts={[]} />,
  element: null, 
  children:[ //...SystemMenuRouter,
    { path:`/${Pages.autopig}`, element: <Autopig/> },
    { path:`/${Pages.freeda_lf}`, element:<TabMenu main={Pages.freeda_lf} overrides={{base_alternative:Pages.home}} contexts={[ Pages.freeda_lf_sections, Pages.freeda_lf_settings ]} />,
      children:[
        { index:true, path:`/${Pages.freeda_lf}/*`, element:<Freeda_lf/> },
        { path:`/${Pages.freeda_lf}/${Pages.freeda_lf_sections}`, element:<Freeda_lf_sections/> },
        { path:`/${Pages.freeda_lf}/${Pages.freeda_lf_settings}`, element:<Freeda_lf_settings/> }
      ]
    },
    { path:`/${Pages.freeda_lf2}`, element:<TabMenu main={Pages.freeda_lf2} overrides={{base_alternative:Pages.home}} contexts={[ Pages.freeda_lf_sections, Pages.freeda_lf_settings ]} />,
      children:[
        { index:true, path:`/${Pages.freeda_lf2}/*`, element:<Freeda_lf/> },
        { path:`/${Pages.freeda_lf2}/${Pages.freeda_lf_sections}`, element:<Freeda_lf_sections/> },
        { path:`/${Pages.freeda_lf2}/${Pages.freeda_lf_settings}`, element:<Freeda_lf_settings/> }
      ]
    },
  ]
}] as RouteObject[]

export const NavigationFunction = (Navigator: NavigateFunction, navigation_page:Pages, navigation_sub?:Pages) => {
  switch (navigation_page) {
    case Pages.home:
      Navigator(Pages.home, {state:{system_index:0}})
    break
    case Pages.autopig:
      Navigator(Pages.autopig, {state:{system_index:1}})
    break
    case Pages.freeda_lf:
      switch (navigation_sub) {
        case Pages.freeda_lf_sections:
          Navigator(Pages.freeda_lf_sections, {state:{system_index:0, tab:1}})
        break
        case Pages.freeda_lf_settings:
          Navigator(Pages.freeda_lf_settings, {state:{system_index:0, tab:2}})
        break
        default:
          Navigator(Pages.freeda_lf, {state:{system_index:0, tab:0}})
      }
    break
    case Pages.freeda_lf2:
      switch (navigation_sub) {
        case Pages.freeda_lf_sections:
          Navigator(Pages.freeda_lf_sections, {state:{system_index:1, tab:1}})
        break
        case Pages.freeda_lf_settings:
          Navigator(Pages.freeda_lf_settings, {state:{system_index:1, tab:2}})
        break
        default:
          Navigator(Pages.freeda_lf2, {state:{system_index:1, tab:0}})
      }
    break
    default:
      console.log("Error page not found (404)???, [SystemMenu]")
  }
}