import React, { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next"
import { Button, ButtonTypes, Modal, PageHeader, PenModal, Section_Attention, Section_Basic, Section_RemainingToday, Section_RemainingYesterday, SectionModal, Toggle } from "../../../Components"
import { colors, fonts, frames, icons } from '../../../Styles';

import { getSectionById, Sections as Sections1, Sections2 } from '../../../Assets/TestData/sectionsData';
import { SectionBasicTypes } from '../../../Components/LocationFeeder/Section/sectionBasic';
import { SystemData } from '../../../Assets/TestData/systems';
import { useLocation } from 'react-router-dom'

export default function FreedaLocationFeeder() {
  const {t} = useTranslation()
  const [isHoveredSection, setHoveredSection] = useState<number | null>(null);
  const [activeSectionId, setActiveSectionId] = useState<number | null>(null);
  const [activePenId, setActivePenId] = useState<number | null>(null);
  const [isRemainingToday, setRemainingToday] = useState<boolean>(true) // Section Remaining Today
  const [isRemainingYesterday, setRemainingYesterday] = useState<boolean>(false) // Section Remaining Yesterday
  const [attention, setAttention] = useState<boolean>(false) // Section Attentions

  // System Name
  const { systemName } = useMemo(() => {
    const systemData = SystemData.find((system) => system.systemNo === 2);
    return {
      systemName: systemData?.name || "",
    };
  }, []);

  // Extract section IDs from Sections data
  let location = useLocation()
  const Sections = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?Sections2:Sections1:Sections1
  const sectionIds = Sections.map(section => section.id);

  // Save first sectionId and lastsection Id
  const firstSectionId = sectionIds[0];
  const lastSectionId = sectionIds[sectionIds.length - 1];

  // #region | Sections Functionality (toggles, section-click)
  // Set the clicked sectionId as active
  const handleSectionClick = (sectionId: number) => {
    setActiveSectionId(sectionId); 
    setActivePenId(null);
  };

  // Toggle Btn Remaining Today
  const toggleRemainingToday = () => {
    if (isRemainingToday) {
      setRemainingToday(false); // If already active, set it to false
    } else {
      setRemainingToday(true);
      setRemainingYesterday(false);
      setAttention(false);
    }
  };
  
  // Toggle Btn Remaining Yesterday
  const toggleRemainingYesterday = () => {
    if (isRemainingYesterday) {
      setRemainingYesterday(false); // If already active, set it to false
    } else {
      setRemainingYesterday(true);
      setRemainingToday(false);
      setAttention(false);
    }
  };

  // Toggle Btn Attentions
  const toggleAttentions = () => {
    if (attention) {
      setAttention(false); // If already active, set it to false
    } else {
      setAttention(true);
      setRemainingYesterday(false);
      setRemainingToday(false);
      
    }
  };
  // #endregion

  // #region | Modal Functionality (next/previous btns, pen-click)
  // Function to handle pen selection from SectionModal
  function handlePenSelected(penId: number) {
    setActivePenId(penId);  // Set the active pen to open the PenModal
  }

  // Next Section
  const goToNext = () => {
    if (activeSectionId !== null) {
      const currentIndex = sectionIds.indexOf(activeSectionId);
      const nextIndex = currentIndex + 1 >= sectionIds.length ? 0 : currentIndex + 1; // Wrap around to first section
      setActiveSectionId(sectionIds[nextIndex]);
      setActivePenId(null); // Reset active pen when changing sections
    }
  };

  // Previous Section
  const goToPrevious = () => {
    if (activeSectionId !== null) {
      const currentIndex = sectionIds.indexOf(activeSectionId);
      const previousIndex = currentIndex - 1 < 0 ? sectionIds.length - 1 : currentIndex - 1; // Wrap around to last section
      setActiveSectionId(sectionIds[previousIndex]);
      setActivePenId(null); // Reset active pen when changing sections
    }
  };

  // Next Pen
  function getNextPen(sectionId: number, currentPenId: number) {
    const section = getSectionById(sectionId);
    if (!section) return null;
  
    const currentPenIndex = section.pens.findIndex(pen => pen.penNo === currentPenId);
    const nextPenIndex = (currentPenIndex + 1) % section.pens.length;
  
    return section.pens[nextPenIndex];
  }
  const handleNextPen = () => {
    if (activeSectionId !== null && activePenId !== null) {
      const nextPen = getNextPen(activeSectionId, activePenId);
      if (nextPen) {
        setActivePenId(nextPen.penNo);
      }
    }
  };

  // Previous Pen
  function getPreviousPen(sectionId: number, currentPenId: number) {
    const section = getSectionById(sectionId);
    if (!section) return null;
  
    const currentPenIndex = section.pens.findIndex(pen => pen.penNo === currentPenId);
    const previousPenIndex = (currentPenIndex - 1 + section.pens.length) % section.pens.length;
  
    return section.pens[previousPenIndex];
  }
  const handlePreviousPen = () => {
    if (activeSectionId !== null && activePenId !== null) {
      const previousPen = getPreviousPen(activeSectionId, activePenId);
      if (previousPen) {
        setActivePenId(previousPen.penNo);
      }
    }
  };

  // Go to previous label
  const previousLabel = !activePenId&&activeSectionId?
  t("buttons.prev") + " " + t("locationFeeder.sections.section")
  : activePenId ?
  t("buttons.prev") + " " + t("locationFeeder.penPopup.pen")
  : ""

  // Go to next label
  const nextLabel = !activePenId&&activeSectionId?
  t("buttons.next") + " " + t("locationFeeder.sections.section")
  : activePenId ?
  t("buttons.next") + " " + t("locationFeeder.penPopup.pen")
  : ""
  //#endregion

  // #region | Modal Components (btns)
  const prevNextBtns = 
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px", height: "20px" }}>
      <div style={{ height: "20px", display: "flex" }}>
        <Button 
          content={previousLabel}
          onClick={activePenId? handlePreviousPen : goToPrevious }
          startEnhancer={icons.goLeftArrow}
          overrides={{ 
            buttonType: ButtonTypes.textButtonSmall,
            disableActive: true
          }}
        />
      </div> 
      <div style={{ backgroundColor: colors.blueDarkSecondary, height: "20px", width: "1.5px", margin: "0 auto" }}/>
      <div style={{ height: "20px", display: "flex" }}>
        <Button 
          content={nextLabel}
          onClick={activePenId? handleNextPen : goToNext}
          endEnhancer={icons.goRightArrow}
          overrides={{ 
            buttonType: ButtonTypes.textButtonSmall,
            disableActive: true
          }}
        />
      </div>
    </div>
  // #endregion

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <PageHeader topHeader={systemName} subHeader={t("locationFeeder.sections.sections")}/>
      </frames._page_inner_header>
      <frames._page_inner_content>
        <frames.Grid_oneRow>
          <frames._grid_oneRow_top>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", columnGap: "30px", flexWrap: "wrap"}}>
              <p style={{ ...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary }}>{t("locationFeeder.sections.section")} {firstSectionId} {t("locationFeeder.sections.to")} {lastSectionId}</p>
              <Toggle label={t("feed.remaining.today")} icon={icons.today_white} onChange={() => toggleRemainingToday()} isChecked={isRemainingToday? true : false}/> 
              <Toggle label={t("feed.remaining.yesterday")} icon={icons.yesterday_white} onChange={() => toggleRemainingYesterday()} isChecked={isRemainingYesterday? true : false}/>
              <Toggle label={t("attentions.attentions")} icon={icons.attentionType} onChange={() => toggleAttentions()} isChecked={attention? true : false}/>
            </div>
          </frames._grid_oneRow_top>
          <frames._grid_oneRow_content style={{
            display: "flex",
            justifyContent: "start",
            gap: "20px",
            padding: "20px",
            flexWrap: "wrap"
          }}>
            {sectionIds.map((sectionId) => (
              <div key={sectionId} 
                onClick={() => handleSectionClick(sectionId)}
                onMouseEnter={() => setHoveredSection(sectionId)}
                onMouseLeave={() => setHoveredSection(null)}
                style={{ 
                  filter:  isHoveredSection === sectionId ? 'opacity(0.5)' : 'opacity(1)',
                  cursor: "pointer",
                  transition: 'filter 0.3s',
                  width: "fit-content",
                  height: "fit-content"
                }} >
                  { isRemainingToday?
                    <Section_RemainingToday
                    sectionId={sectionId}/>
                  : isRemainingYesterday?
                    <Section_RemainingYesterday 
                    sectionId={sectionId}/>
                  : attention?
                    <Section_Attention
                    sectionId={sectionId}/>
                  : <Section_Basic 
                    sectionId={sectionId} 
                    sectionType={SectionBasicTypes.basicPen}
                  />
                  }
              </div>
            ))}
          </frames._grid_oneRow_content>
        </frames.Grid_oneRow>
      </frames._page_inner_content>

      {/* Section and pen Modals */}
      <Modal 
        isModalOpen={!!activeSectionId || !!activePenId}
        modalHeader={ activePenId? t("locationFeeder.penPopup.pen")+" "+`${activePenId}` : t("locationFeeder.sections.section")+" "+`${activeSectionId}` }
        modalSubHeader={ activePenId? "System Navn X"+" "+t("locationFeeder.sections.section")+" "+`${activeSectionId}` : `System Name X` } // TODO MAJA: System navn
        onCloseBtn="close"
        overrides={{
          isPrimaryComponent: false,
          root: { padding: "40px 5px 5px 5px" },
          background: colors.greyLightTertiary
        }}
        onClose={() => {
          setActiveSectionId(null)
          if (activePenId) setActivePenId(null)
        }}
        goBackBtn={activePenId? t("buttons.goBackBtn") : undefined}
        onGoBack={activePenId?() => setActivePenId(null) : undefined}
        modalContent={ <> {prevNextBtns} </> }
      >
        <>
          { !activePenId&&activeSectionId 
          ? <SectionModal activeSectionId={activeSectionId} onPenSelected={handlePenSelected}/> // SECTION POPUP
          : activePenId
          ? <PenModal activePenId={activePenId}/> // PEN POPUP
          : null }
        </>
      </Modal>
    </frames.Page_inner>
  )
} 