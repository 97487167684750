import { ReactElement, useState } from "react"
import { colors, fonts, icons } from "../../Styles"
import { Button, ButtonList, ButtonTypes } from ".."
import { useTranslation } from "react-i18next"

interface Widget_Props {
    width?: string
    height?: string
    header: ReactElement // Add padding: "20px" manually
    content: ReactElement // Add padding: "20px" manually
    editContent?: ReactElement // Add padding: "20px" manually
    bgColor?: string
    bannerContent?: string
    onSave?:() => void
}
/**
 * Widget
 * @param width overrides the default width (fit-content).
 * @param height overrides the default height (fit-content).
 * @param header elements for header-area goes here.
 * @param content elements for content-area goes here.
 * @param editContent elements for content-area in editmode goes here.
 * @param bgColor overrides the default backgroundcolor (greyLightTertiary).
 * @param bannerContent elements for banner-area goes here.
 * @param onSave functionality on "save btn". 
 * @returns a basic frame for widgets
 */
export default function Widget({width, height, header, content, editContent, bgColor, bannerContent, onSave}:Widget_Props) {
  const {t} = useTranslation()
  const [isEditing, setIsEditing] = useState(false)

  const handleSaveClick = () => {
    setIsEditing(!isEditing)
    if (onSave) onSave()
  }

  const handleCancelClick = () => {
    setIsEditing(!isEditing)
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const _width = width? width : "fit-content"

  const _height = height? height : "fit-content"

  const _bgColor = bgColor? bgColor : colors.greyLightTertiary

  const _bannerContent = 
      <div style={{height: "30px", width: "100%", backgroundColor: colors.greyLightSecondary, display: "flex", alignItems: "center", justifyContent: "start"}}>
          <p style={{ ...fonts.header, ...fonts.fontSize3, color: colors.greyPrimary, margin: "0px 20px"}}>{bannerContent}</p>
      </div>

  return (
    <div style={{display: "flex", height: _height, width: _width, flexDirection: "column"}}>
      <div style={{width: "100%", display: "flex", justifyContent: "end"}}>
        {editContent? 
          <div style={{margin: "0px 20px 0px 0px"}}>
            { !isEditing 
              ? <Button content={t("buttons.edit")} startEnhancer={icons.edit_white} overrides={{ buttonType: ButtonTypes.tableButton, disableActive: true}} onClick={handleEditClick}/>
              : <ButtonList 
                  content={[t("buttons.saveBtn"), t("buttons.cancelBtn")]} 
                  onClick={(i => {
                    if (i === 0) {handleSaveClick()}
                    else if (i === 1) {handleCancelClick()}
                  })}
                  startEnhancers={[icons.check_white, icons.close]}
                  overrides={{
                    button:{ buttonType: ButtonTypes.tableButton, disableActive: true }
                  }} 
                />
            }   
          </div>
        : <></>}
      </div>
      <div style={{ borderRadius: "10px", border: "5px solid"+colors.greyPrimary, height: _height}}>
        <div style={{ borderRadius: "5px 5px 0px 0px", height: "50px", width: "auto", backgroundColor: colors.greyPrimary, display: "flex", alignItems: "center", justifyContent: "center"}}>
          {header} 
        </div>
        {bannerContent
          ? _bannerContent
          : <></>
        }
        <div style={{ borderRadius: "0px 0px 5px 5px", height: bannerContent? "calc(100% - 80px)" : "calc(100% - 50px)", width: "auto", backgroundColor: _bgColor, display: "flex", justifyContent: "center"}}>
          {isEditing ? (
            <>{editContent}</>
          ) : (
            <>{content}</>
          )}
        </div>
      </div>
    </div>
  )
}