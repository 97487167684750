import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypes, Modal, ReportModalLvl1, ReportModalLvl2, ReportModalLvl3, ProgressSteps } from "../..";
import { icons } from "../../../Styles";

import { ReportTemplate } from "../../../Assets/TestData/reportTemplates";


interface ReportModal_Props {
  isModalOpen: boolean
  closeModal: () => void,
  report?: ReportTemplate,
  newTemplate?: boolean
}

export default function ReportModal({isModalOpen, closeModal, report, newTemplate}:ReportModal_Props) {
  const {t} = useTranslation()
  const [currentReportLevel, setCurrentReportLevel] = useState(1);

  // Function to handle New Report Modal based on level
  const showNewReport = (level: 0 | 1 | 2 | 3) => {
    setCurrentReportLevel(level);
  };

  // Reset state when modal opens
  useEffect(() => {
    if (isModalOpen) {
      setCurrentReportLevel(1); // Reset to Main Info Modal
    }
  }, [isModalOpen]);

  // Function to handle "Go Back"
  const onGoBack = () => {
    if (currentReportLevel === 3) {
      showNewReport(2); // Go back to Level 2
    } else if (currentReportLevel === 2) {
      showNewReport(1); // Go back to Level 1
    }
  }
    
  return (
    <Modal 
      isModalOpen={isModalOpen}
      overrides={{ 
        isPrimaryComponent: true 
      }}
      modalHeader={newTemplate || (newTemplate&&report) ? t("reports.newTemplate") : t("reports.newReport")}
      modalSubHeader={report? t("reports.fromTemplate")+" "+report?.reportTemplateNo+": "+report?.name : ""} 
      onCloseBtn="close"
      onClose={closeModal}
      goBackBtn={currentReportLevel === 2 || currentReportLevel === 3 ? t("buttons.goBackBtn") : ""}
      onGoBack={onGoBack}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "40px"}}>
        <ProgressSteps titles={[t("reports.dataLevel"), t("reports.filterParameter"), t("reports.dataParameter")]} icons={[icons.progressStep1, icons.progressStep2, icons.progressStep3]} opacityValues={currentReportLevel === 1 ? [1, 0.5, 0.5] : currentReportLevel === 2 ? [0.5, 1, 0.5] : [0.5, 0.5, 1] }/>
        
        {currentReportLevel === 1 && <ReportModalLvl1 report={report} template={newTemplate}/>} {/* Main Info Modal */}
        {currentReportLevel === 2 && <ReportModalLvl2 report={report}/>} {/* Filter Parameter Modal */}
        {currentReportLevel === 3 && <ReportModalLvl3 report={report}/>} {/* Data Parameter Modal */}
        
        <Button
          content={currentReportLevel === 1||currentReportLevel === 2 ? t("buttons.next") : t("buttons.createBtn")+" "+t("reports.report")}
          overrides={{
            buttonType: ButtonTypes.tertiaryButton
          }}
          onClick={currentReportLevel === 1 ? () => showNewReport(2) : currentReportLevel === 2 ? () => showNewReport(3) : () => closeModal() } // TODO CTO: On closeModal() also save to db/create report
        />
      </div>
    </Modal>
  );
}