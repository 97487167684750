import react, { CSSProperties, useState } from 'react'
import { ProgressBar } from "../.."
import { colors, fonts } from "../../../Styles"
import { useTranslation } from 'react-i18next';

import { TestData as PenData} from '../../../Assets/TestData/lf_Data';
import { TestData as AnimalData, TestCalculate_Progresse } from '../../../Assets/TestData/animalData'

interface PenRemainingYesterday_Props {
  penNo: number
  overrides?:{
    baseStyle?: CSSProperties
  }
  hasHoverEffekt?: boolean
  onClick?: () => void
}
/**
 * Pen_RemainingYesterday
 * @param penNo Unique identifier for each pen.
 * @param penType Basic, advanced and single. Each renders different content of animal data.
 * @param overrides Style overrides for the pen.
 * @param hasHoverEffekt Boolean that, if true, adds a hover effect to the pen.
 * @param onClick Dynamic onClick eventhandler.
 * @returns a pen with varying content of animal data for Freeda Location Feeder systems.
 */
export default function Pen_RemainingYesterday({ penNo, hasHoverEffekt, onClick }: PenRemainingYesterday_Props) {
  const {t} = useTranslation()
  const [isHovered, setIsHovered] = useState(false);

  // Find the pen and related data
  const pen = PenData.find(item => item.penNo === penNo)

  // Find the pen animal and related data
  const animal = AnimalData.find(item => item.animalNo === pen?.animal);

  // Handle progress bar data
  const progressBarTotal = animal?.totalFood;
  const progressBarProgressed = animal?TestCalculate_Progresse(animal, true):0;

  // Content to parse in the div
  const baseContent = 
    <>
      { animal 
      
        ? <ProgressBar isPrimaryBar={false} text={animal.animalNo} total={progressBarTotal} progressed={progressBarProgressed} />
        : <span style={{opacity: "0.5"}}>{t("locationFeeder.penPopup.emptyPen")}</span>
      }
    </>
  // #endregion

  // Apply hover effect only if hasHoverEffekt is true
  const handleMouseEnter = () => {
    if (hasHoverEffekt && animal) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (hasHoverEffekt && animal) {
      setIsHovered(false);
    }
  };

  return (
    <div 
      onClick={animal? onClick : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        width: "80px",
        height: "30px",
        cursor: hasHoverEffekt && animal? "pointer" : "",
      }}
    >
      <div style={{
        width: "100%",
        height: "100%",
        textAlign: 'center',
        alignContent: "center",
        backgroundColor: colors.greyLightSecondary,
        filter: isHovered ? 'opacity(0.5)' : 'opacity(1)',
        transition: 'filter 0.3s',
        color: colors.greyDarkPrimary,
        ...fonts.paragraph,
        ...fonts.fontSize4,
      }}>
        {baseContent}
      </div>
    </div>
  );
}