import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonList, ButtonTypes, PageHeader, ReportModal, ReportTemplateCard, BigButton } from "../../../Components";
import { css, frames, mediaQueriesNumbers, useMediaQuery } from "../../../Styles";

import { TestData as ReportTemplatesData } from "../../../Assets/TestData/reportTemplates";

export default function Reports() {
  const {t} = useTranslation()
  const [templateMode, setTemplateMode] = useState<boolean>(false) 
  const [reportModal, setReportModal] = useState(false)
  const [copyTemplateMode, setCopyTemplateMode] = useState(false)
  const [selectedReport, setSelectedReport] = useState<number|undefined>(undefined)
  const pageHeaderResponsive = useMediaQuery(`(max-width: ${mediaQueriesNumbers.pageHeaderResponsive2elementsMax}px)`);

  // Handle ReportTemplateCard select
  const handleReportSelect = (id:number) => {
    setSelectedReport(id) // set report id
    copyTemplateMode 
      ? copyTemplateFlow() // if in copy template mode, start copy template flow on report select
      : setReportModal(true) // else open report modal on report select
  }

  // Handle report modal close
  const handleCloseBtn = () => {
    setReportModal(false) // Close report modal
    setSelectedReport(undefined) // Reset selected report
    if (templateMode) {
      setTemplateMode(false); // If modal is "newTemplate === true" then set it to false
    }
  }

  // Copy template flow
  const copyTemplateFlow = () => {
    setReportModal(true); // Open report modal
    setTemplateMode(true); // Set modal prop "newTemplate === true"
    setCopyTemplateMode(false) // Remove bg overlay
  }

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between"}}>
          <PageHeader topHeader={t("reports.report")} subHeader={t("overview")}/>
          {copyTemplateMode 
            ? <ButtonList content={[t("buttons.cancelBtn")]} 
                onClick={(i => {
                  if (i === 0) {setCopyTemplateMode(false)}
                })}
                overrides={{button: {buttonType: pageHeaderResponsive ? ButtonTypes.quaternaryButton : ButtonTypes.tertiaryButton, disableActive: true, baseButton: {zIndex: "10"} }}}
              />
            : <ButtonList content={[t("reports.addTemplate"), t("reports.copyTemplate")]} 
                onClick={(i => {
                  if (i === 0) {setTemplateMode(true); setReportModal(true);} // TODO CTO: Save to db and swith section edit
                  else if (i === 1) {setCopyTemplateMode(true)}
                })}
                overrides={{button: {buttonType: pageHeaderResponsive ? ButtonTypes.quaternaryButton : ButtonTypes.tertiaryButton, disableActive: true }}}
              />
          }
        </div>
      </frames._page_inner_header>
      <frames._page_inner_content>
        {/* Wrap of Reports Cards */}
        <frames._page_inner_content_scroll>
          <frames.CardsColumnCount>
            {/* Create Report Btn */}
            <BigButton title={t("buttons.createBtn")+" "+t("reports.report")} subTitle={t("reports.createNewReport")} onClick={() => setReportModal(true)}/>
            {/* Backdrop filter */}
            {copyTemplateMode
            ? <div style={{...css.backdropFilter}}/>
            : undefined }
            {/* Report Templates */}
            {ReportTemplatesData.map((report) => (
              <ReportTemplateCard key={report.reportTemplateNo} reportId={report.reportTemplateNo} onReportSelect={handleReportSelect}/>
            ))}
          </frames.CardsColumnCount>
        </frames._page_inner_content_scroll>
      </frames._page_inner_content>

      <ReportModal 
        isModalOpen={reportModal} 
        closeModal={handleCloseBtn} 
        report={selectedReport ? ReportTemplatesData.find(report => report.reportTemplateNo === selectedReport) : undefined }
        newTemplate={templateMode ? true : false}
      />

    </frames.Page_inner>
  )
}