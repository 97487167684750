//import { client } from '../../api/client'
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Graph_QL, DynamicDataObk } from '../../';
import { GET_LOGS, GetLogsArgs, LogItem, LogsResponse } from "../device_QlCalls"

export type Log_Item = DynamicDataObk<LogItem[]>
const dataAdapter = createEntityAdapter({
  selectId: (item:Log_Item) => Graph_QL.ObjToString(item.con),
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
})

const LogSlice = createSlice({
  name: 'device/log', initialState:dataAdapter.getInitialState<Log_Item[]>([]),
  reducers: {
    added: (state, {payload}:PayloadAction<Log_Item[]>) => {
      payload.forEach(entries => {
        dataAdapter.upsertOne(state, {con:entries.con, data:entries.data.map(e => {
          var entry = {...e, timestamp:e.timestamp}
          if (typeof e.timestamp === "string") entry.timestamp = new Date(parseInt(e.timestamp))
          else if (typeof entry.timestamp === "number") entry.timestamp = new Date(e.timestamp)
          entry.timestamp = entry.timestamp.getTime()
          delete entry.__typename
          return entry
        })})
      })
    },
    deleted: (state, {payload}:PayloadAction<Log_Item[]|Graph_QL.Connection[]>) => {
      payload.forEach(entries => {
        //For each reference: check if exists..
        var entriesCon = Object.hasOwn(entries, "con")?(entries as Log_Item).con:(entries as Graph_QL.Connection).connection
        var Current = entriesCon?state.ids.find(e => e === Graph_QL.ObjToString(entriesCon)):undefined
        if (Current) {
          //if reference does not contain singular data entry reference, delete the full entries store
          if (Object.hasOwn(entries, "data")) {
            //else go through each singular data entry referance 
            (entries as Log_Item).data.forEach(entry => {
              //if data entry reference exists: delete it
              var Data_Current = state.entities[Current+''].data.findIndex(e => e.id === entry.id)
              if (~Data_Current)state.entities[Current+''].data.splice(Data_Current, 1)
            })
            //if data entries container is empty: delete the entries store
            if ((entries as Log_Item).data.length === 0) dataAdapter.removeOne(state, Current)
          }
          else dataAdapter.removeOne(state, Current)
        }
      })
      //Do not remove all in deleted... use intentional clear
    },
    clear: (state, {payload}:PayloadAction<Graph_QL.Connection[]|undefined>) => {
      if (payload) dataAdapter.removeMany(state, payload.map(e => e.toString()))
      else dataAdapter.removeAll(state)
    }
  }
})

//const { actions, reducer } = ActiveSlice
//export const { added, deleted } = LogSlice.actions
export const LogActions = LogSlice.actions
export default LogSlice.reducer