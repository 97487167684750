import { configureStore } from '@reduxjs/toolkit'
import { combineReducers, Dispatch } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { PersistPartial } from 'redux-persist/lib/persistReducer'

//#region Stores
import ProgramReducer, { ProgramStates } from './StateHandlers/program-slice' //Cookie & Theme
//User & Rights

//Generanl Data
import { default as gqlReducer } from '../Services/Agrisys_GqlDataCaller/gqlData_Reducer' 

//Specefic Data
import ActiveReducer, { ActiveStates, resetState as ActiveReset } from './StateHandlers/active-slice' 
//#endregion

//#region Reducer setups
/*export interface RootStates {
  Program: EntityState<unknown> & ProgramStates & PersistPartial,
  //activeData: EntityState<unknown> & ActiveStates,
  //graphQlData: EntityState<unknown> & GraphQlStates,

  //AppDatas:{Test: FBActivityS,Sections: FBSectionsS}
}*/

const appReducer = combineReducers/*<RootStates>*/({
  Program: ProgramReducer,
  activeData: ActiveReducer,
  graphQlData: gqlReducer
  //AppDatas: combineReducers({ Sections: FBSectionsR, })
})
//#endregion

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
        ],
        ignoredPaths: [],
      },
      thunk: {
        extraArgument: {
          //myCustomApiService ???
        },
      },
    })/*.concat(ConsysApi_QL.middleware)*/
})

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: appReducer,
    preloadedState
  })
}
export let persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof setupStore>

export type StoreAppDispatch = typeof store.dispatch
export type RootStates_T = (RootState&PersistPartial)

export const useAppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export enum ClearLevels {
  All, User, Farm, Specefic
}
//actionTypes.REMOVE = remove ?? mabye
export const ClearAllData = (dispatch:Dispatch<any>, level:ClearLevels=ClearLevels.All) => {
  //reset Active-Data
  dispatch(ActiveReset('all'))

  //TODO: update clear list
}

export default store