import { FeedCurveInfo } from "../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall";
import { Condition } from "./conditions";

  //   export enum AttentionTypes {
  //   Farrowing,
  //   Weaning,
  //   PregnanyCheck,
  //   NoFood,
  //   FeedBalance,
  //   FeedingDisabled
  // }

  export const TestCalculate_Progresse = (animalData:Animals, yesterday:boolean=false):number => {
    var consumption = yesterday?animalData.consumptionYesterday:animalData.consumption
    if (!consumption || consumption === undefined) {
      var total = yesterday?animalData.totalFoodYesterday:animalData.totalFood
      if (!total || total === undefined) total = 0;

      if (yesterday && animalData.remainingYesterday) {
        consumption = total - animalData.remainingYesterday
      } 
      else if (!yesterday && animalData.remainingToday) {
        consumption = total - animalData.remainingToday
      }
    }
    if (!consumption || consumption === undefined) consumption = 0;

    var res = Math.floor((consumption*10))/10;
    return res;
  }

  export enum Location {
    FarrowingUnit = "Farrowing Unit",
    WeanerUnit = "Weaner Unit"
  }
  
  export enum AnimalTypes {
    Duroc = "Duroc",
    Yorkshire = "Yorkshire"
  }

  export enum Milestone {
    Insemination="Insemination",
    Weaning="Weaning",
    Farrowing="Farrowing",
    MovedFarrowing="Moved to farrowing",
  }

  export enum Group {
    Group1="42 42",
    Group2="43 43",
    Group3="41 41",
    Group32="32 32",
    Group33="33 33",
    Group34="34 34",
    Group35="35 35"
  }

  // export type FeedCorrection = {
  //   amount?: number;
  //   skipped?: boolean;
  // };
  
  export type Animals = {
    animalNo: number;
    pen?: number;
    responderNo: number;
    lifeNo: number;
    lastDetected?: number;
    location?: Location;
    remainingToday?: number;
    remainingYesterday?: number;
    totalFood?: number; // Skal komme fra foderplan eller?
    consumption?: number;
    consumptionYesterday?: number;
    totalFoodYesterday?: number; // Skal komme fra foderplan eller?
    condition: Condition['conditionNo'];
    feedPlan?: FeedCurveInfo['id'];
    group: Group;
    animalType?: AnimalTypes;
    latestMilestone?: Milestone;
    milestoneDay?: number;
    cycleDayTotal?: number;
    feedCorrection?: number;
  };
  
  export const TestData: Animals[] = [
    {
      animalNo: 3002,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322188,
      lifeNo: 5581019914,
      consumption: 1.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 20
    },
    {
      animalNo: 3003,
      pen: 303,
      location: Location.FarrowingUnit,
      responderNo: 98400000322384,
      lifeNo: 5581019745,
      consumption: 1.4,
      totalFood: 2.6,
      consumptionYesterday: 2.5,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: -10
    },
    {
      animalNo: 3004,
      pen: 304,
      location: Location.FarrowingUnit,
      responderNo: 98400000322471,
      lifeNo: 5581019567,
      consumption: 1.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3005,
      pen: 305,
      location: Location.FarrowingUnit,
      responderNo: 98400000322707,
      lifeNo: 5581019597,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3006,
      pen: 306,
      location: Location.FarrowingUnit,
      responderNo: 98400000322862,
      lifeNo: 5581019505,
      consumption: 1.7,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3007,
      pen: 307,
      location: Location.FarrowingUnit,
      responderNo: 98400000322986,
      lifeNo: 5581019755,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3008,
      pen: 308,
      location: Location.FarrowingUnit,
      responderNo: 98400000322987,
      lifeNo: 5581019666,
      consumption: 1.3,
      totalFood: 2.8,
      consumptionYesterday: 2.4,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3009,
      pen: 309,
      location: Location.FarrowingUnit,
      responderNo: 98400000322866,
      lifeNo: 5581019528,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3010,
      pen: 310,
      location: Location.FarrowingUnit,
      responderNo: 98400000322655,
      lifeNo: 5581019631,
      consumption: 1.6,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3011,
      pen: 311,
      location: Location.FarrowingUnit,
      responderNo: 98400000322150,
      lifeNo: 5581019969,
      consumption: 2.7,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2,
      feedCorrection: 0
    },
    {
      animalNo: 3012,
      pen: 312,
      location: Location.FarrowingUnit,
      responderNo: 98400000322005,
      lifeNo: 5581019197,
      consumption: 1.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3013,
      pen: 313,
      location: Location.FarrowingUnit,
      responderNo: 98400000322408,
      lifeNo: 5581019518,
      consumption: 0.9,
      totalFood: 2.6,
      consumptionYesterday: 2.3,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3014,
      pen: 314,
      location: Location.FarrowingUnit,
      responderNo: 98400000322357,
      lifeNo: 5581019083,
      consumption: 2.0,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3015,
      pen: 315,
      location: Location.FarrowingUnit,
      responderNo: 98400000322524,
      lifeNo: 5581019973,
      consumption: 1.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3016,
      pen: 316,
      location: Location.FarrowingUnit,
      responderNo: 98400000322362,
      lifeNo: 5581019791,
      consumption: 1.7,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3017,
      pen: 317,
      location: Location.FarrowingUnit,
      responderNo: 98400000322508,
      lifeNo: 5581019257,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3018,
      pen: 318,
      location: Location.FarrowingUnit,
      responderNo: 98400000322954,
      lifeNo: 5581019494,
      consumption: 0.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3019,
      pen: 319,
      location: Location.FarrowingUnit,
      responderNo: 98400000322891,
      lifeNo: 5581019718,
      consumption: 2.8,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3020,
      pen: 320,
      location: Location.FarrowingUnit,
      responderNo: 98400000322590,
      lifeNo: 5581019681,
      consumption: 0.9,
      totalFood: 2.6,
      consumptionYesterday: 2.3,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3021,
      pen: 321,
      location: Location.FarrowingUnit,
      responderNo: 98400000322666,
      lifeNo: 5581019211,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3022,
      pen: 322,
      location: Location.FarrowingUnit,
      responderNo: 98400000322198,
      lifeNo: 5581019162,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3023,
      pen: 323,
      location: Location.FarrowingUnit,
      responderNo: 98400000322741,
      lifeNo: 5581019233,
      consumption: 1.9,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3024,
      pen: 324,
      location: Location.FarrowingUnit,
      responderNo: 98400000322177,
      lifeNo: 5581019033,
      consumption: 1.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3025,
      pen: 325,
      location: Location.FarrowingUnit,
      responderNo: 98400000322084,
      lifeNo: 5581019907,
      consumption: 0.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3026,
      pen: 326,
      location: Location.FarrowingUnit,
      responderNo: 98400000322701,
      lifeNo: 5581019695,
      consumption: 1,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3027,
      pen: 327,
      location: Location.FarrowingUnit,
      responderNo: 98400000322233,
      lifeNo: 5581019256,
      consumption: 1.7,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3028,
      pen: 328,
      location: Location.FarrowingUnit,
      responderNo: 98400000322981,
      lifeNo: 5581019666,
      consumption: 0,
      totalFood: 2.6,
      consumptionYesterday: 0,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3029,
      pen: 329,
      location: Location.FarrowingUnit,
      responderNo: 98400000322439,
      lifeNo: 5581019495,
      consumption: 1.8,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3030,
      pen: 330,
      location: Location.FarrowingUnit,
      responderNo: 98400000322750,
      lifeNo: 5581019015,
      consumption: 2.3,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3031,
      pen: 331,
      location: Location.FarrowingUnit,
      responderNo: 98400000322168,
      lifeNo: 5581019772,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.4,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3032,
      pen: 332,
      location: Location.FarrowingUnit,
      responderNo: 98400000322590,
      lifeNo: 5581019553,
      consumption: 1.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3033,
      pen: 333,
      location: Location.FarrowingUnit,
      responderNo: 98400000322360,
      lifeNo: 5581019802,
      consumption: 1.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3034,
      pen: 334,
      location: Location.FarrowingUnit,
      responderNo: 98400000322697,
      lifeNo: 5581019294,
      consumption: 1.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3035,
      pen: 335,
      location: Location.FarrowingUnit,
      responderNo: 98400000322116,
      lifeNo: 5581019084,
      consumption: 2.6,
      totalFood: 2.6,
      consumptionYesterday: 1.1,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3036,
      pen: 336,
      location: Location.FarrowingUnit,
      responderNo: 98400000322702,
      lifeNo: 5581019284,
      consumption: 1.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3037,
      pen: 337,
      location: Location.FarrowingUnit,
      responderNo: 98400000322433,
      lifeNo: 5581019988,
      consumption: 1.2,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3038,
      pen: 338,
      location: Location.FarrowingUnit,
      responderNo: 98400000322777,
      lifeNo: 5581019955,
      consumption: 2.3,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3039,
      pen: 339,
      location: Location.FarrowingUnit,
      responderNo: 98400000322042,
      lifeNo: 5581019921,
      consumption: 2.3,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3040,
      pen: 340,
      location: Location.FarrowingUnit,
      responderNo: 98400000322491,
      lifeNo: 5581019239,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3041,
      pen: 341,
      location: Location.FarrowingUnit,
      responderNo: 98400000322287,
      lifeNo: 5581019118,
      consumption: 1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3042,
      pen: 342,
      location: Location.FarrowingUnit,
      responderNo: 98400000322140,
      lifeNo: 5581019931,
      consumption: 0.9,
      totalFood: 2.8,
      consumptionYesterday: 1.4,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3043,
      pen: 343,
      location: Location.FarrowingUnit,
      responderNo: 98400000322552,
      lifeNo: 5581019364,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3044,
      pen: 344,
      location: Location.FarrowingUnit,
      responderNo: 98400000322790,
      lifeNo: 5581019095,
      consumption: 1.3,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3045,
      pen: 345,
      location: Location.FarrowingUnit,
      responderNo: 98400000322527,
      lifeNo: 5581019415,
      consumption: 2.4,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3046,
      pen: 346,
      location: Location.FarrowingUnit,
      responderNo: 98400000322088,
      lifeNo: 5581019836,
      consumption: 1.5,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3047,
      pen: 347,
      location: Location.FarrowingUnit,
      responderNo: 98400000322554,
      lifeNo: 5581019145,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3048,
      pen: 348,
      location: Location.FarrowingUnit,
      responderNo: 98400000322935,
      lifeNo: 5581019066,
      consumption: 1.6,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3049,
      pen: 349,
      location: Location.FarrowingUnit,
      responderNo: 98400000322912,
      lifeNo: 5581019460,
      consumption: 1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3050,
      pen: 350,
      location: Location.FarrowingUnit,
      responderNo: 98400000322476,
      lifeNo: 5581019182,
      consumption: 2.5,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3051,
      pen: 351,
      location: Location.FarrowingUnit,
      responderNo: 98400000322733,
      lifeNo: 5581019929,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.3,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3052,
      pen: 352,
      location: Location.FarrowingUnit,
      responderNo: 98400000322582,
      lifeNo: 5581019485,
      consumption: 1.6,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3053,
      pen: 353,
      location: Location.FarrowingUnit,
      responderNo: 98400000322375,
      lifeNo: 5581019566,
      consumption: 0.8,
      totalFood: 2.6,
      consumptionYesterday: 1.4,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3054,
      pen: 354,
      location: Location.FarrowingUnit,
      responderNo: 98400000322465,
      lifeNo: 5581019830,
      consumption: 2.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3055,
      pen: 355,
      location: Location.FarrowingUnit,
      responderNo: 98400000322273,
      lifeNo: 5581019419,
      consumption: 2.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3056,
      pen: 356,
      location: Location.FarrowingUnit,
      responderNo: 98400000322471,
      lifeNo: 5581019657,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3057,
      pen: 357,
      location: Location.FarrowingUnit,
      responderNo: 98400000322696,
      lifeNo: 5581019682,
      consumption: 2.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3058,
      pen: 358,
      location: Location.FarrowingUnit,
      responderNo: 98400000322173,
      lifeNo: 5581019331,
      consumption: 1.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3059,
      pen: 359,
      location: Location.FarrowingUnit,
      responderNo: 98400000322894,
      lifeNo: 5581019692,
      consumption: 2.5,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3060,
      pen: 360,
      location: Location.FarrowingUnit,
      responderNo: 98400000322277,
      lifeNo: 5581019257,
      consumption: 2.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3061,
      pen: 361,
      location: Location.FarrowingUnit,
      responderNo: 98400000322930,
      lifeNo: 5581019244,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3062,
      pen: 362,
      location: Location.FarrowingUnit,
      responderNo: 98400000322029,
      lifeNo: 5581019951,
      consumption: 1.6,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3063,
      pen: 363,
      location: Location.FarrowingUnit,
      responderNo: 98400000322808,
      lifeNo: 5581019641,
      consumption: 1.5,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3064,
      pen: 364,
      location: Location.FarrowingUnit,
      responderNo: 98400000322015,
      lifeNo: 5581019878,
      consumption: 2.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3065,
      pen: 365,
      location: Location.FarrowingUnit,
      responderNo: 98400000322191,
      lifeNo: 5581019623,
      consumption: 1.5,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3066,
      pen: 366,
      location: Location.FarrowingUnit,
      responderNo: 98400000322495,
      lifeNo: 5581019011,
      consumption: 1.6,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3067,
      pen: 367,
      location: Location.FarrowingUnit,
      responderNo: 98400000322377,
      lifeNo: 5581019496,
      consumption: 1.7,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3068,
      pen: 368,
      location: Location.FarrowingUnit,
      responderNo: 98400000322932,
      lifeNo: 5581019502,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3069,
      pen: 369,
      location: Location.FarrowingUnit,
      responderNo: 98400000322788,
      lifeNo: 5581019363,
      consumption: 0.9,
      totalFood: 2.6,
      consumptionYesterday: 0.8,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3070,
      pen: 370,
      location: Location.FarrowingUnit,
      responderNo: 98400000322801,
      lifeNo: 5581019353,
      consumption: 1,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3071,
      pen: 371,
      location: Location.FarrowingUnit,
      responderNo: 98400000322535,
      lifeNo: 5581019969,
      consumption: 2.4,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3072,
      pen: 372,
      location: Location.FarrowingUnit,
      responderNo: 98400000322364,
      lifeNo: 5581019813,
      consumption: 0.9,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3073,
      pen: 373,
      location: Location.FarrowingUnit,
      responderNo: 98400000322560,
      lifeNo: 5581019719,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3074,
      pen: 374,
      location: Location.FarrowingUnit,
      responderNo: 98400000322703,
      lifeNo: 5581019486,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3075,
      pen: 375,
      location: Location.FarrowingUnit,
      responderNo: 98400000322205,
      lifeNo: 5581019250,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3076,
      pen: 376,
      location: Location.FarrowingUnit,
      responderNo: 98400000322208,
      lifeNo: 5581019848,
      consumption: 0.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3077,
      pen: 377,
      location: Location.FarrowingUnit,
      responderNo: 98400000322691,
      lifeNo: 5581019957,
      consumption: 0.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3078,
      pen: 378,
      location: Location.FarrowingUnit,
      responderNo: 98400000322747,
      lifeNo: 5581019545,
      consumption: 2.3,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3079,
      pen: 379,
      location: Location.FarrowingUnit,
      responderNo: 98400000322964,
      lifeNo: 5581019306,
      consumption: 1.1,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3080,
      pen: 380,
      location: Location.FarrowingUnit,
      responderNo: 98400000322067,
      lifeNo: 5581019061,
      consumption: 1.4,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3081,
      pen: 381,
      location: Location.FarrowingUnit,
      responderNo: 98400000322808,
      lifeNo: 5581019103,
      consumption: 1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3082,
      pen: 382,
      location: Location.FarrowingUnit,
      responderNo: 98400000322147,
      lifeNo: 5581019076,
      consumption: 2.3,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3083,
      pen: 383,
      location: Location.FarrowingUnit,
      responderNo: 98400000322303,
      lifeNo: 5581019762,
      consumption: 1.3,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3084,
      pen: 384,
      location: Location.FarrowingUnit,
      responderNo: 98400000322648,
      lifeNo: 5581019729,
      consumption: 1.5,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3085,
      pen: 385,
      location: Location.FarrowingUnit,
      responderNo: 98400000322221,
      lifeNo: 5581019834,
      consumption: 0.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3086,
      pen: 386,
      location: Location.FarrowingUnit,
      responderNo: 98400000322030,
      lifeNo: 5581019477,
      consumption: 1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3087,
      pen: 387,
      location: Location.FarrowingUnit,
      responderNo: 98400000322167,
      lifeNo: 5581019788,
      consumption: 2.2,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3088,
      pen: 388,
      location: Location.FarrowingUnit,
      responderNo: 98400000322693,
      lifeNo: 5581019652,
      consumption: 1.5,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3089,
      pen: 389,
      location: Location.FarrowingUnit,
      responderNo: 98400000322637,
      lifeNo: 5581019909,
      consumption: 2.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3090,
      pen: 390,
      location: Location.FarrowingUnit,
      responderNo: 98400000322008,
      lifeNo: 5581019549,
      consumption: 1.6,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3091,
      pen: 391,
      location: Location.FarrowingUnit,
      responderNo: 98400000322219,
      lifeNo: 5581019062,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3092,
      pen: 392,
      location: Location.FarrowingUnit,
      responderNo: 98400000322956,
      lifeNo: 5581019178,
      consumption: 1.4,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3093,
      pen: 393,
      location: Location.FarrowingUnit,
      responderNo: 98400000322000,
      lifeNo: 5581019302,
      consumption: 2.6,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3094,
      pen: 394,
      location: Location.FarrowingUnit,
      responderNo: 98400000322900,
      lifeNo: 5581019308,
      consumption: 1.8,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3095,
      pen: 395,
      location: Location.FarrowingUnit,
      responderNo: 98400000322323,
      lifeNo: 5581019544,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3096,
      pen: 396,
      location: Location.FarrowingUnit,
      responderNo: 98400000322107,
      lifeNo: 5581019236,
      consumption: 2.1,
      totalFood: 2.8,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3097,
      pen: 397,
      location: Location.FarrowingUnit,
      responderNo: 98400000322577,
      lifeNo: 5581019581,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3098,
      pen: 398,
      location: Location.FarrowingUnit,
      responderNo: 98400000322101,
      lifeNo: 5581019164,
      consumption: 2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3099,
      pen: 399,
      location: Location.FarrowingUnit,
      responderNo: 98400000322508,
      lifeNo: 5581019751,
      consumption: 2.2,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3100,
      pen: 400,
      location: Location.FarrowingUnit,
      responderNo: 98400000322869,
      lifeNo: 5581019279,
      consumption: 0.9,
      totalFood: 2.8,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3101,
      pen: 401,
      location: Location.FarrowingUnit,
      responderNo: 98400000322144,
      lifeNo: 5581019923,
      consumption: 1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3102,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322670,
      lifeNo: 5581019777,
      consumption: 1.1,
      totalFood: 2.6,
      consumptionYesterday: 2.6,
      totalFoodYesterday: 2.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group32, 
      latestMilestone: Milestone.MovedFarrowing,
      milestoneDay: 2
    },
    {
      animalNo: 3103,
      pen: 403,
      location: Location.FarrowingUnit,
      responderNo: 98400000322576,
      lifeNo: 5581019290,
      consumption: 2.5,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3104,
      pen: 404,
      location: Location.FarrowingUnit,
      responderNo: 98400000322873,
      lifeNo: 5581019473,
      consumption: 2.8,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3105,
      pen: 405,
      location: Location.FarrowingUnit,
      responderNo: 98400000322450,
      lifeNo: 5581019356,
      consumption: 1.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3106,
      pen: 406,
      location: Location.FarrowingUnit,
      responderNo: 98400000322345,
      lifeNo: 5581019173,
      consumption: 3.3,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3107,
      pen: 407,
      location: Location.FarrowingUnit,
      responderNo: 98400000322416,
      lifeNo: 5581019369,
      consumption: 2.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3108,
      pen: 408,
      location: Location.FarrowingUnit,
      responderNo: 98400000322455,
      lifeNo: 5581019283,
      consumption: 2,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3109,
      pen: 409,
      location: Location.FarrowingUnit,
      responderNo: 98400000322302,
      lifeNo: 5581019837,
      consumption: 1.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3110,
      pen: 410,
      location: Location.FarrowingUnit,
      responderNo: 98400000322515,
      lifeNo: 5581019110,
      consumption: 3.1,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3111,
      pen: 411,
      location: Location.FarrowingUnit,
      responderNo: 98400000322087,
      lifeNo: 5581019628,
      consumption: 2.2,
      totalFood: 3.7,
      consumptionYesterday: 2.5,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3112,
      pen: 412,
      location: Location.FarrowingUnit,
      responderNo: 98400000322404,
      lifeNo: 5581019068,
      consumption: 3.9,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3113,
      pen: 413,
      location: Location.FarrowingUnit,
      responderNo: 98400000322033,
      lifeNo: 5581019439,
      consumption: 1.7,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3114,
      pen: 414,
      location: Location.FarrowingUnit,
      responderNo: 98400000322190,
      lifeNo: 5581019536,
      consumption: 1.2,
      totalFood: 3.5,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3115,
      pen: 415,
      location: Location.FarrowingUnit,
      responderNo: 98400000322162,
      lifeNo: 5581019258,
      consumption: 2.1,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3116,
      pen: 416,
      location: Location.FarrowingUnit,
      responderNo: 98400000322284,
      lifeNo: 5581019348,
      consumption: 2.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3117,
      pen: 417,
      location: Location.FarrowingUnit,
      responderNo: 98400000322210,
      lifeNo: 5581019891,
      consumption: 0.8,
      totalFood: 3.7,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3118,
      pen: 418,
      location: Location.FarrowingUnit,
      responderNo: 98400000322195,
      lifeNo: 5581019311,
      consumption: 2.7,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3119,
      pen: 419,
      location: Location.FarrowingUnit,
      responderNo: 98400000322764,
      lifeNo: 5581019857,
      consumption: 1.5,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3120,
      pen: 420,
      location: Location.FarrowingUnit,
      responderNo: 98400000322575,
      lifeNo: 5581019446,
      consumption: 1.7,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3121,
      pen: 421,
      location: Location.FarrowingUnit,
      responderNo: 98400000322401,
      lifeNo: 5581019386,
      consumption: 2.9,
      totalFood: 3.5,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3122,
      pen: 422,
      location: Location.FarrowingUnit,
      responderNo: 98400000322161,
      lifeNo: 5581019006,
      consumption: 1.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3123,
      pen: 423,
      location: Location.FarrowingUnit,
      responderNo: 98400000322042,
      lifeNo: 5581019752,
      consumption: 2.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3124,
      pen: 424,
      location: Location.FarrowingUnit,
      responderNo: 98400000322467,
      lifeNo: 5581019967,
      consumption: 1.8,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3125,
      pen: 425,
      location: Location.FarrowingUnit,
      responderNo: 98400000322215,
      lifeNo: 5581019827,
      consumption: 3.0,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3126,
      pen: 426,
      location: Location.FarrowingUnit,
      responderNo: 98400000322165,
      lifeNo: 5581019812,
      consumption: 2.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3127,
      pen: 427,
      location: Location.FarrowingUnit,
      responderNo: 98400000322236,
      lifeNo: 5581019678,
      consumption: 1.7,
      totalFood: 3.7,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3128,
      pen: 428,
      location: Location.FarrowingUnit,
      responderNo: 98400000322190,
      lifeNo: 5581019286,
      consumption: 2.1,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3129,
      pen: 429,
      location: Location.FarrowingUnit,
      responderNo: 98400000322006,
      lifeNo: 5581019013,
      consumption: 2.1,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3130,
      pen: 430,
      location: Location.FarrowingUnit,
      responderNo: 98400000322453,
      lifeNo: 5581019346,
      consumption: 3.2,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3131,
      pen: 431,
      location: Location.FarrowingUnit,
      responderNo: 98400000322343,
      lifeNo: 5581019240,
      consumption: 1.5,
      totalFood: 3.5,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3132,
      pen: 432,
      location: Location.FarrowingUnit,
      responderNo: 98400000322885,
      lifeNo: 5581019834,
      consumption: 3.2,
      totalFood: 3.5,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3133,
      pen: 433,
      location: Location.FarrowingUnit,
      responderNo: 98400000322139,
      lifeNo: 5581019148,
      consumption: 2.8,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3134,
      pen: 434,
      location: Location.FarrowingUnit,
      responderNo: 98400000322016,
      lifeNo: 5581019985,
      consumption: 1.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3135,
      pen: 435,
      location: Location.FarrowingUnit,
      responderNo: 98400000322797,
      lifeNo: 5581019789,
      consumption: 1.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3136,
      pen: 436,
      location: Location.FarrowingUnit,
      responderNo: 98400000322383,
      lifeNo: 5581019310,
      consumption: 2.5,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3137,
      pen: 437,
      location: Location.FarrowingUnit,
      responderNo: 98400000322149,
      lifeNo: 5581019425,
      consumption: 1.6,
      totalFood: 4.1,
      consumptionYesterday: 2.1,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3138,
      pen: 438,
      location: Location.FarrowingUnit,
      responderNo: 98400000322305,
      lifeNo: 5581019661,
      consumption: 3.0,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3139,
      pen: 439,
      location: Location.FarrowingUnit,
      responderNo: 98400000322924,
      lifeNo: 5581019286,
      consumption: 1.9,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3140,
      pen: 440,
      location: Location.FarrowingUnit,
      responderNo: 98400000322386,
      lifeNo: 5581019860,
      consumption: 2.2,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3141,
      pen: 441,
      location: Location.FarrowingUnit,
      responderNo: 98400000322485,
      lifeNo: 5581019944,
      consumption: 2.3,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3142,
      pen: 442,
      location: Location.FarrowingUnit,
      responderNo: 98400000322475,
      lifeNo: 5581019229,
      consumption: 2.7,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3143,
      pen: 443,
      location: Location.FarrowingUnit,
      responderNo: 98400000322803,
      lifeNo: 5581019921,
      consumption: 3.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3144,
      pen: 444,
      location: Location.FarrowingUnit,
      responderNo: 98400000322303,
      lifeNo: 5581019638,
      consumption: 3.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3145,
      pen: 445,
      location: Location.FarrowingUnit,
      responderNo: 98400000322782,
      lifeNo: 5581019315,
      consumption: 1.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3146,
      pen: 446,
      location: Location.FarrowingUnit,
      responderNo: 98400000322977,
      lifeNo: 5581019542,
      consumption: 0.9,
      totalFood: 3.7,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3147,
      pen: 447,
      location: Location.FarrowingUnit,
      responderNo: 98400000322191,
      lifeNo: 5581019220,
      consumption: 2,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3148,
      pen: 448,
      location: Location.FarrowingUnit,
      responderNo: 98400000322243,
      lifeNo: 5581019673,
      consumption: 2.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3149,
      pen: 449,
      location: Location.FarrowingUnit,
      responderNo: 98400000322679,
      lifeNo: 5581019622,
      consumption: 3.7,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3150,
      pen: 450,
      location: Location.FarrowingUnit,
      responderNo: 98400000322111,
      lifeNo: 5581019153,
      consumption: 1.5,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3151,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322013,
      lifeNo: 5581019991,
      consumption: 2,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3152,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322396,
      lifeNo: 5581019834,
      consumption: 2.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3153,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322265,
      lifeNo: 5581019725,
      consumption: 0,
      totalFood: 3.5,
      consumptionYesterday: 0,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3154,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322035,
      lifeNo: 5581019220,
      consumption: 2.8,
      totalFood: 3.5,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3155,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322326,
      lifeNo: 5581019839,
      consumption: 3.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3156,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322356,
      lifeNo: 5581019284,
      consumption: 1.4,
      totalFood: 3.7,
      consumptionYesterday: 2.9,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3157,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322893,
      lifeNo: 5581019159,
      consumption: 2.6,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3158,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322197,
      lifeNo: 5581019692,
      consumption: 2,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3159,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322064,
      lifeNo: 5581019899,
      consumption: 1.1,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3160,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322029,
      lifeNo: 5581019395,
      consumption: 1.5,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3161,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322709,
      lifeNo: 5581019577,
      consumption: 2.6,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3162,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322275,
      lifeNo: 5581019833,
      consumption: 3.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3163,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322388,
      lifeNo: 5581019613,
      consumption: 2.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3164,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322795,
      lifeNo: 5581019228,
      consumption: 1.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3165,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322493,
      lifeNo: 5581019955,
      consumption: 0,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3166,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322902,
      lifeNo: 5581019082,
      consumption: 1.6,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3167,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322873,
      lifeNo: 5581019698,
      consumption: 1.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3168,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322098,
      lifeNo: 5581019451,
      consumption: 2.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3169,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322962,
      lifeNo: 5581019021,
      consumption: 3.4,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3170,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322729,
      lifeNo: 5581019710,
      consumption: 2.5,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3171,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322170,
      lifeNo: 5581019820,
      consumption: 3.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3172,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322276,
      lifeNo: 5581019138,
      consumption: 3.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3173,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322428,
      lifeNo: 5581019294,
      consumption: 1.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3174,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322111,
      lifeNo: 5581019195,
      consumption: 3.8,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3175,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322023,
      lifeNo: 5581019720,
      consumption: 3.2,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3176,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322159,
      lifeNo: 5581019039,
      consumption: 2,
      totalFood: 3.5,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3177,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322252,
      lifeNo: 5581019995,
      consumption: 3.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3178,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322032,
      lifeNo: 5581019000,
      consumption: 2,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3179,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322085,
      lifeNo: 5581019726,
      consumption: 2.5,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3180,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322607,
      lifeNo: 5581019512,
      consumption: 2.3,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3181,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322135,
      lifeNo: 5581019120,
      consumption: 1.5,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3182,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322746,
      lifeNo: 5581019920,
      consumption: 2.6,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3183,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322917,
      lifeNo: 5581019661,
      consumption: 2.2,
      totalFood: 3.5,
      consumptionYesterday: 3.2,
      totalFoodYesterday: 3.2,
      condition: 4,
      feedPlan: 3,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3184,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322707,
      lifeNo: 5581019461,
      consumption: 2.5,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3185,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322473,
      lifeNo: 5581019377,
      consumption: 2.1,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3186,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322479,
      lifeNo: 5581019412,
      consumption: 2.2,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3187,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322988,
      lifeNo: 5581019220,
      consumption: 2.8,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3188,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322045,
      lifeNo: 5581019650,
      consumption: 2.1,
      totalFood: 3.7,
      consumptionYesterday: 0,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3189,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322519,
      lifeNo: 5581019446,
      consumption: 1.9,
      totalFood: 4.1,
      consumptionYesterday: 3.7,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3190,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322934,
      lifeNo: 5581019967,
      consumption: 3.1,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3191,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322488,
      lifeNo: 5581019392,
      consumption: 1.6,
      totalFood: 4.1,
      consumptionYesterday: 2.8,
      totalFoodYesterday: 3.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3192,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322976,
      lifeNo: 5581019212,
      consumption: 2.9,
      totalFood: 3.7,
      consumptionYesterday: 3.4,
      totalFoodYesterday: 3.4,
      condition: 1,
      feedPlan: 1,
      group: Group.Group33, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 3
    },
    {
      animalNo: 3193,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322481,
      lifeNo: 5581019034,
      consumption: 4.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3194,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322946,
      lifeNo: 5581019476,
      consumption: 2.7,
      totalFood: 8.1,
      consumptionYesterday: 6.7,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3195,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322203,
      lifeNo: 5581019141,
      consumption: 4.2,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3196,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322640,
      lifeNo: 5581019625,
      consumption: 5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3197,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322200,
      lifeNo: 5581019136,
      consumption: 5.4,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3198,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322951,
      lifeNo: 5581019732,
      consumption: 6.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3199,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322978,
      lifeNo: 5581019045,
      consumption: 6.9,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3200,
      pen: 402,
      location: Location.FarrowingUnit,
      responderNo: 98400000322080,
      lifeNo: 5581019181,
      consumption: 3.9,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3201,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322188,
      lifeNo: 5581019789,
      consumption: 1.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 4,
      feedPlan: 3,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3202,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322164,
      lifeNo: 5581019472,
      consumption: 6.7,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3203,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322747,
      lifeNo: 5581019096,
      consumption: 7.8,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3204,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322805,
      lifeNo: 5581019562,
      consumption: 5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3205,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322566,
      lifeNo: 5581019873,
      consumption: 3.3,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3206,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322582,
      lifeNo: 5581019365,
      consumption: 3.4,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3207,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322196,
      lifeNo: 5581019732,
      consumption: 5.6,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3208,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322608,
      lifeNo: 5581019004,
      consumption: 0,
      totalFood: 8,
      consumptionYesterday: 0,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3209,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322491,
      lifeNo: 5581019222,
      consumption: 6.5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3210,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322634,
      lifeNo: 5581019064,
      consumption: 7.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3211,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322351,
      lifeNo: 5581019216,
      consumption: 5.7,
      totalFood: 8,
      consumptionYesterday: 4.5,
      totalFoodYesterday: 7.7,
      condition: 4,
      feedPlan: 3,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3212,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322741,
      lifeNo: 5581019553,
      consumption: 4.3,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3213,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322581,
      lifeNo: 5581019922,
      consumption: 7.4,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3214,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322717,
      lifeNo: 5581019096,
      consumption: 3.4,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3215,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322553,
      lifeNo: 5581019183,
      consumption: 4.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3216,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322639,
      lifeNo: 5581019996,
      consumption: 4.8,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3217,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322869,
      lifeNo: 5581019028,
      consumption: 4.9,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3218,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322935,
      lifeNo: 5581019093,
      consumption: 6.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3219,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322995,
      lifeNo: 5581019036,
      consumption: 5.5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3220,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322092,
      lifeNo: 5581019328,
      consumption: 3.3,
      totalFood: 8,
      consumptionYesterday: 6.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3221,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322792,
      lifeNo: 5581019596,
      consumption: 4,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3222,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322838,
      lifeNo: 5581019335,
      consumption: 6.3,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3223,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322171,
      lifeNo: 5581019702,
      consumption: 4.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 4,
      feedPlan: 3,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3224,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322974,
      lifeNo: 5581019038,
      consumption: 4.5,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3225,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322252,
      lifeNo: 5581019754,
      consumption: 3.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3226,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322218,
      lifeNo: 5581019389,
      consumption: 4.9,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3227,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322845,
      lifeNo: 5581019363,
      consumption: 5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3228,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322684,
      lifeNo: 5581019451,
      consumption: 3.3,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3229,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322748,
      lifeNo: 5581019704,
      consumption: 7.9,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3230,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322700,
      lifeNo: 5581019045,
      consumption: 4.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3231,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322432,
      lifeNo: 5581019246,
      consumption: 5.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3232,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322166,
      lifeNo: 5581019427,
      consumption: 5.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3233,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322445,
      lifeNo: 5581019575,
      consumption: 7.1,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3234,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322718,
      lifeNo: 5581019487,
      consumption: 6.8,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3235,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322055,
      lifeNo: 5581019696,
      consumption: 7.9,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3236,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322778,
      lifeNo: 5581019517,
      consumption: 4.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3237,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322066,
      lifeNo: 5581019185,
      consumption: 7.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3238,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322008,
      lifeNo: 5581019694,
      consumption: 5.8,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3239,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322671,
      lifeNo: 5581019710,
      consumption: 5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3240,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322837,
      lifeNo: 5581019613,
      consumption: 3.7,
      totalFood: 8,
      consumptionYesterday: 5.8,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3241,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322145,
      lifeNo: 5581019325,
      consumption: 5.3,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3242,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322779,
      lifeNo: 5581019624,
      consumption: 5.8,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3243,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322280,
      lifeNo: 5581019718,
      consumption: 2.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 4,
      feedPlan: 3,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3244,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322004,
      lifeNo: 5581019203,
      consumption: 4.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3245,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322530,
      lifeNo: 5581019168,
      consumption: 7.8,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3246,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322510,
      lifeNo: 5581019173,
      consumption: 3.5,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3247,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322266,
      lifeNo: 5581019946,
      consumption: 3.6,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3248,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322489,
      lifeNo: 5581019585,
      consumption: 6.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3249,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322019,
      lifeNo: 5581019170,
      consumption: 3.0,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3250,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322064,
      lifeNo: 5581019283,
      consumption: 3.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3251,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322075,
      lifeNo: 5581019352,
      consumption: 3.6,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3252,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322841,
      lifeNo: 5581019235,
      consumption: 5.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3253,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322339,
      lifeNo: 5581019313,
      consumption: 7.9,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3254,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322673,
      lifeNo: 5581019569,
      consumption: 4.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3255,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322554,
      lifeNo: 5581019338,
      consumption: 4.6,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 4,
      feedPlan: 3,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3256,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322828,
      lifeNo: 5581019325,
      consumption: 0,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3257,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322448,
      lifeNo: 5581019067,
      consumption: 3.4,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3258,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322648,
      lifeNo: 5581019752,
      consumption: 7.5,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3259,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322374,
      lifeNo: 5581019269,
      consumption: 6.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3260,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322542,
      lifeNo: 5581019088,
      consumption: 4.8,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3261,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322342,
      lifeNo: 5581019843,
      consumption: 6.3,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 4,
      feedPlan: 3,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3262,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322563,
      lifeNo: 5581019870,
      consumption: 7.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3263,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322815,
      lifeNo: 5581019736,
      consumption: 6,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3264,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322969,
      lifeNo: 5581019065,
      consumption: 7.1,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3265,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322232,
      lifeNo: 5581019009,
      consumption: 4.9,
      totalFood: 8.1,
      consumptionYesterday: 7.8,
      totalFoodYesterday: 7.8,
      condition: 2,
      feedPlan: 2,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3266,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322544,
      lifeNo: 5581019234,
      consumption: 7.1,
      totalFood: 8,
      consumptionYesterday: 6.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3267,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322899,
      lifeNo: 5581019684,
      consumption: 4.7,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3268,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322300,
      lifeNo: 5581019966,
      consumption: 4.2,
      totalFood: 8,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group34, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 14
    },
    {
      animalNo: 3269,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322770,
      lifeNo: 5581019374,
      consumption: 6.6,
      totalFood: 8.6,
      consumptionYesterday: 7.7,
      totalFoodYesterday: 7.7,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3270,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322480,
      lifeNo: 5581019613,
      consumption: 4.8,
      totalFood: 8.7,
      consumptionYesterday: 8.7,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3271,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322950,
      lifeNo: 5581019681,
      consumption: 4.6,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3272,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322182,
      lifeNo: 5581019787,
      consumption: 3.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3273,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322481,
      lifeNo: 5581019373,
      consumption: 4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3274,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322713,
      lifeNo: 5581019457,
      consumption: 5,
      totalFood: 8.7,
      consumptionYesterday: 8.7,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3275,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322279,
      lifeNo: 5581019993,
      consumption: 5.6,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3276,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322035,
      lifeNo: 5581019514,
      consumption: 7.7,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3277,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322184,
      lifeNo: 5581019675,
      consumption: 2.6,
      totalFood: 8.7,
      consumptionYesterday: 5.3,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3278,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322290,
      lifeNo: 5581019186,
      consumption: 6.7,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3279,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322850,
      lifeNo: 5581019383,
      consumption: 7.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3280,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322126,
      lifeNo: 5581019777,
      consumption: 8.3,
      totalFood: 8.7,
      consumptionYesterday: 8.7,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3281,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322638,
      lifeNo: 5581019060,
      consumption: 2.9,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3282,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322337,
      lifeNo: 5581019654,
      consumption: 3.0,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3283,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322160,
      lifeNo: 5581019738,
      consumption: 4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3284,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322380,
      lifeNo: 5581019211,
      consumption: 7.8,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3285,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322553,
      lifeNo: 5581019797,
      consumption: 6.9,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3286,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322820,
      lifeNo: 5581019456,
      consumption: 4.3,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3287,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322787,
      lifeNo: 5581019233,
      consumption: 4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3288,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322956,
      lifeNo: 5581019691,
      consumption: 2.9,
      totalFood: 8.6,
      consumptionYesterday: 5.8,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3289,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322439,
      lifeNo: 5581019375,
      consumption: 7.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3290,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322724,
      lifeNo: 5581019374,
      consumption: 4.6,
      totalFood: 8.7,
      consumptionYesterday: 8.7,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3291,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322184,
      lifeNo: 5581019123,
      consumption: 4.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3292,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322486,
      lifeNo: 5581019456,
      consumption: 5,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3293,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322094,
      lifeNo: 5581019741,
      consumption: 3.0,
      totalFood: 8.7,
      consumptionYesterday: 8.7,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3294,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322088,
      lifeNo: 5581019873,
      consumption: 2.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3295,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322642,
      lifeNo: 5581019195,
      consumption: 5.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 4,
      feedPlan: 3,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3296,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322091,
      lifeNo: 5581019286,
      consumption: 5.7,
      totalFood: 8.7,
      consumptionYesterday: 8.7,
      totalFoodYesterday: 8.7,
      condition: 2,
      feedPlan: 2,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3297,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322286,
      lifeNo: 5581019282,
      consumption: 8.3,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3298,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322853,
      lifeNo: 5581019771,
      consumption: 5.4,
      totalFood: 8.6,
      consumptionYesterday: 8.6,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    },
    {
      animalNo: 3299,
      pen: 302,
      location: Location.FarrowingUnit,
      responderNo: 98400000322110,
      lifeNo: 5581019086,
      consumption: 4.1,
      totalFood: 8.6,
      consumptionYesterday: 7.3,
      totalFoodYesterday: 8.6,
      condition: 1,
      feedPlan: 1,
      group: Group.Group35, 
      latestMilestone: Milestone.Farrowing,
      milestoneDay: 25
    }
  ]

  export const TestData2: Animals[] = [
    { 
      animalNo: 1236, 
      // pen: 301, 
      responderNo: 984000003224226, 
      lifeNo: 5581019239, 
      lastDetected: 3, 
      location: Location.FarrowingUnit,
      remainingToday: 1, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50
    },
    { 
      animalNo: 2347, 
      // pen: 302, 
      responderNo: 984000003224227, 
      lifeNo: 5581019240, 
      lastDetected: 2, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 5, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 2, 
      cycleDayTotal: 50,
      feedCorrection: 20
    },      
    { 
      animalNo: 3458, 
      // pen: 303, 
      responderNo: 984000003224228, 
      lifeNo: 5581019241, 
      lastDetected: 4, 
      location: Location.FarrowingUnit,
      remainingToday: 3.7, 
      remainingYesterday: 2.5, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1, 
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 3, 
      cycleDayTotal: 50, 
      feedCorrection: -10
    },
    { 
      animalNo: 4569, 
      // pen: 304, 
      responderNo: 984000003224229, 
      lifeNo: 5581019242, 
      lastDetected: 1, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 5, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1, 
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50,
      feedCorrection: 15
    },
    { 
      animalNo: 5670, 
      // pen: 305, 
      responderNo: 984000003224230, 
      lifeNo: 5581019243, 
      lastDetected: 5, 
      location: Location.FarrowingUnit,
      remainingToday: 2.5, 
      remainingYesterday: 5, 
      totalFood: 5, 
      condition: 5, 
      feedPlan: 2,
      group: Group.Group1, 
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 6781, 
      pen: 306, 
      responderNo: 984000003224231, 
      lifeNo: 5581019244, 
      lastDetected: 7, 
      location: Location.FarrowingUnit,
      remainingToday: 1, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 1, 
      cycleDayTotal: 50
    },
    { 
      animalNo: 7892, 
      pen: 307, 
      responderNo: 984000003224232, 
      lifeNo: 5581019245, 
      lastDetected: 8, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 8903, 
      pen: 308, 
      responderNo: 984000003224233, 
      lifeNo: 5581019246, 
      lastDetected: 9, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1, 
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 9014, 
      pen: 309, 
      responderNo: 984000003224234, 
      lifeNo: 5581019247, 
      lastDetected: 2, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 1124, 
      pen: 310, 
      responderNo: 984000003224235, 
      lifeNo: 5581019248, 
      lastDetected: 4, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 2235, 
      pen: 311, 
      responderNo: 984000003224236, 
      lifeNo: 5581019249, 
      lastDetected: 1, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 3346, 
      pen: 312, 
      responderNo: 984000003224237, 
      lifeNo: 5581019250, 
      lastDetected: 3, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 4457, 
      pen: 313, 
      responderNo: 984000003224238, 
      lifeNo: 5581019251, 
      lastDetected: 6, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1, 
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 5568, 
      pen: 314, 
      responderNo: 984000003224239, 
      lifeNo: 5581019252, 
      lastDetected: 5, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1, 
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 6679, 
      pen: 315, 
      responderNo: 984000003224240, 
      lifeNo: 5581019253, 
      lastDetected: 8, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 7780, 
      pen: 316, 
      responderNo: 984000003224241, 
      lifeNo: 5581019254, 
      lastDetected: 7, 
      location: Location.FarrowingUnit,
      remainingToday: 1, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 8891, 
      pen: 317, 
      responderNo: 984000003224242, 
      lifeNo: 5581019255, 
      lastDetected: 4, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 9902, 
      pen: 318, 
      responderNo: 984000003224243, 
      lifeNo: 5581019256, 
      lastDetected: 5, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 1013, 
      pen: 319, 
      responderNo: 984000003224244, 
      lifeNo: 5581019257, 
      lastDetected: 6, 
      location: Location.FarrowingUnit, 
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 2124, 
      pen: 320, 
      responderNo: 984000003224245, 
      lifeNo: 5581019258, 
      lastDetected: 7, 
      location: Location.FarrowingUnit, 
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 5, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 3235, 
      pen: 321, 
      responderNo: 984000003224246, 
      lifeNo: 5581019259, 
      lastDetected: 8, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 4346, 
      pen: 322, 
      responderNo: 984000003224247, 
      lifeNo: 5581019260, 
      lastDetected: 9, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 5457, 
      pen: 323, 
      responderNo: 984000003224248, 
      lifeNo: 5581019261, 
      lastDetected: 1, 
      location: Location.FarrowingUnit, 
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 6568, 
      pen: 324, 
      responderNo: 984000003224249, 
      lifeNo: 5581019262, 
      lastDetected: 2, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 7679, 
      pen: 325, 
      responderNo: 984000003224250, 
      lifeNo: 5581019263, 
      lastDetected: 3, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 8780, 
      pen: 326, 
      responderNo: 984000003224251, 
      lifeNo: 5581019264, 
      lastDetected: 4, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 9891, 
      pen: 327, 
      responderNo: 984000003224252, 
      lifeNo: 5581019265, 
      lastDetected: 5, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 1002, 
      pen: 328, 
      responderNo: 984000003224253, 
      lifeNo: 5581019266, 
      lastDetected: 6, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 2113, 
      pen: 329, 
      responderNo: 984000003224254, 
      lifeNo: 5581019267, 
      lastDetected: 7, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 3224, 
      pen: 330, 
      responderNo: 984000003224255, 
      lifeNo: 5581019268, 
      lastDetected: 8, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 4335, 
      pen: 331, 
      responderNo: 984000003224256, 
      lifeNo: 5581019269, 
      lastDetected: 9, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    { 
      animalNo: 5446, 
      pen: 332, 
      responderNo: 984000003224257, 
      lifeNo: 5581019270, 
      lastDetected: 1, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Duroc, 
      latestMilestone: Milestone.Weaning, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 6557, 
      pen: 333, 
      responderNo: 984000003224258, 
      lifeNo: 5581019271, 
      lastDetected: 2, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 7668, 
      pen: 334, 
      responderNo: 984000003224259, 
      lifeNo: 5581019272, 
      lastDetected: 3, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 8779, 
      pen: 335, 
      responderNo: 984000003224260, 
      lifeNo: 5581019273, 
      lastDetected: 4, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 9880, 
      pen: 336, 
      responderNo: 984000003224261, 
      lifeNo: 5581019274, 
      lastDetected: 5, 
      location: Location.FarrowingUnit,
      remainingToday: 1, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 1991, 
      pen: 337, 
      responderNo: 984000003224262, 
      lifeNo: 5581019275, 
      lastDetected: 6, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1, 
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 3112, 
      pen: 338, 
      responderNo: 984000003224263, 
      lifeNo: 5581019276, 
      lastDetected: 7, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 4223, 
      pen: 339, 
      responderNo: 984000003224264, 
      lifeNo: 5581019277, 
      lastDetected: 8, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 5334, 
      pen: 340, 
      responderNo: 984000003224265, 
      lifeNo: 5581019278, 
      lastDetected: 9, 
      location: Location.FarrowingUnit,
      remainingToday: 1, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 6445, 
      pen: 341, 
      responderNo: 984000003224266, 
      lifeNo: 5581019279, 
      lastDetected: 1, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 7556, 
      pen: 342, 
      responderNo: 984000003224267, 
      lifeNo: 5581019280, 
      lastDetected: 2, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 8667, 
      pen: 343, 
      responderNo: 984000003224268, 
      lifeNo: 5581019281, 
      lastDetected: 3, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 9778, 
      pen: 344, 
      responderNo: 984000003224269, 
      lifeNo: 5581019282, 
      lastDetected: 4, 
      location: Location.FarrowingUnit,
      remainingToday: 1, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 1, 
      feedPlan: 2, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 1889, 
      pen: 345, 
      responderNo: 984000003224270, 
      lifeNo: 5581019283, 
      lastDetected: 5, 
      location: Location.FarrowingUnit,
      remainingToday: 2, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 2, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 3, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 2990, 
      pen: 346, 
      responderNo: 984000003224271, 
      lifeNo: 5581019284, 
      lastDetected: 6, 
      location: Location.FarrowingUnit,
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 1, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 3111, 
      pen: 347, 
      responderNo: 984000003224272, 
      lifeNo: 5581019285, 
      lastDetected: 7, 
      location: Location.FarrowingUnit,
      remainingToday: 4, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 4, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Insemination, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    },
    {
      animalNo: 4222, 
      pen: 348, 
      responderNo: 984000003224273, 
      lifeNo: 5581019286, 
      lastDetected: 8,
      location: Location.FarrowingUnit, 
      remainingToday: 3, 
      remainingYesterday: 4, 
      totalFood: 5, 
      condition: 3, 
      feedPlan: 1, 
      group: Group.Group1,
      animalType: AnimalTypes.Yorkshire, 
      latestMilestone: Milestone.Farrowing, 
      milestoneDay: 2, 
      cycleDayTotal: 50 
    }        
  ]