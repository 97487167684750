import { CellContext } from "@tanstack/react-table";
import { useState } from "react";
import { EditAbleCell, StyledTableCellProps } from "./styledTableCell";
import { IdentifiableRow } from "../reactiveTableRenderer";
import SelectField from "../../BaseComponents/select";
import { SelectFieldOption_Values } from "../../../Definitions";

interface ListOptions {
  options: SelectFieldOption_Values[]
}

export const ListTableCell = <T extends IdentifiableRow,> ({getValue, row, table, column, canEdit, staticColumn, options}: CellContext<T, string | undefined> & EditAbleCell & ListOptions) => {
  const initialValue = getValue();

  const [value, setValue] = useState(initialValue);
  const key = `${row.index}-${column.id}`;

  const onChange = (e: string) => {
    setValue(e);
    if (value !==initialValue) {
      table.options.meta?.updateData(row.original.id, column.id, value);
    }
  }


  return <ListStringCell options={options} staticColumn={staticColumn} key={key} keyPass={key+"input"} onChange={onChange} canEdit={canEdit} cellData={{value}} />
}


const ListStringCell = ({onChange, canEdit, cellData, keyPass, staticColumn, options}: StyledTableCellProps & EditAbleCell & ListOptions) => {
  if (canEdit) {
    if (staticColumn) { 
      return (
        <div style={{ ...cellData.style, opacity: 0.5  }} key={keyPass}>
          {String(cellData.value) ?? ''}
        </div>
      );
    } else {
      return <SelectField
        options={options}
        defaultValue={cellData.value}
        context={String(cellData.value)}
        fieldStyle="tableEdit" 
        fieldType="tableEditField" 
        textStart={true} 
        isTableEditField={true}
        maxWidthEditField
        onChange={onChange}
        key={keyPass}
      />;
    }
  }

  return (cellData.value)

}