import * as React from "react";
import { DatePicker } from "baseui/datepicker";
import { colors, fonts, icons } from "../../Styles";

interface DateField_Props {
  value: Date
  onChange: (params: {date: Date }) => void
}

export default function DateField({ value, onChange }:DateField_Props) {

  return (
    <div style={{ position: "relative"}}>
      {/* startEnhancer */}
      <img alt="start_icn" src={icons.calendar}
        style={{
          width: "25px",
          height: "25px",
          position: "absolute",
          zIndex: "2",
          top: "2.5px",
          left: "22px"
        }}
      />
      {/* DatePicker */}
      <DatePicker
        value={value}
        onChange={({ date }) => {
          const selectedDate = Array.isArray(date) ? date[0] : date; // Get the first date if it's an array
          if (selectedDate instanceof Date) {
            onChange({ date: selectedDate }); // Call onChange with a single Date
          }
        }}
        // displayValueAtRangeIndex={0}
        formatString="dd/MM/yyyy"
        placeholder="dd/MM/yyyy"
        overrides={{
          // Input field
          Input: {
            props: {
              overrides: {
                Root: { style: () => Object.assign({}, {
                  backgroundColor: colors.blueDarkSecondary,
                  width: "280px",
                  height: "30px",
                  transition: "0.1s",
                  // outline: isFocused ? `2px solid ${isError?colors.systemRed:colors.greyLightTertiary}` : isError?colors.systemRed:"none",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "unset",
                  border: "none",
                  zIndex: "1",
                  cursor: "pointer"
                } as React.CSSProperties )},   
                Input: { style: () => Object.assign({}, { 
                  ...fonts.paragraph, 
                  ...fonts.fontSize3, 
                  color: colors.greyLightTertiary,
                  paddingLeft: "2px",
                  transition: "0.1s",
                  cursor: "pointer",
                  '::placeholder': { color: colors.greyLightTertiary, opacity:"30%" }
                } as React.CSSProperties )},
                InputContainer: { style: () => Object.assign({}, {
                  backgroundColor: colors.blueDarkSecondary,
                  padding: "0px 0px 0px 60px",
                  cursor: "pointer"
                } as React.CSSProperties )}, 
                StartEnhancer: { style: () => Object.assign({}, {
                  padding: "0px 8px"
                } as React.CSSProperties )},
              }
            }
          },

          // Calender Popover
          Popover: {
            props: {
              overrides: {
                Body: { style: () => Object.assign({}, {
                  borderRadius: "10px",
                } as React.CSSProperties )},
                Inner: { style: () => Object.assign({}, {
                  backgroundColor: colors.blueDarkPrimary,
                  borderRadius: "10px"
                } as React.CSSProperties )},
              }
            }
          },

          // Calender (Popover Inner)
          Root: { style: () => Object.assign({}, {
            backgroundColor: colors.greyDarkPrimary,
            borderRadius: "10px",
            margin: "3px",
          } as React.CSSProperties )},
          CalendarContainer: { style: () => Object.assign({}, {
            paddingTop: "0px",
          } as React.CSSProperties )}, 
          CalendarHeader: { style: () => Object.assign({}, {
            backgroundColor: colors.transparent,
          } as React.CSSProperties )}, 
          MonthHeader: { style: () => Object.assign({}, {
            backgroundColor: colors.transparent,
            ...fonts.header
          } as React.CSSProperties )},
          WeekdayHeader: { style: () => Object.assign({}, {
            color: colors.greyLightTertiary, 
            ...fonts.fontSize3
          } as React.CSSProperties )}, 
          Day: { style: () => Object.assign({}, {
            ...fonts.paragraph, 
            ...fonts.fontSize3, 
            color: colors.greyLightTertiary,
            paddingBottom: "16px",
            paddingTop: "16px",
            '::after': { backgroundColor: colors.transparent}
          } as React.CSSProperties )}, 

          // Month and Year Selector
          MonthYearSelectButton: { style: () => Object.assign({}, {
            backgroundColor: colors.greyDarkSecondary,
            borderRadius: "10px",
            padding: "5px 5px 5px 10px",
            ...fonts.paragraph, 
            ...fonts.fontSize3, 
            color: colors.greyLightTertiary,
            width: "120px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: "20"
          } as React.CSSProperties )}, 
          MonthYearSelectIconContainer: { style: () => Object.assign({}, {
            margin: "0px"
          } as React.CSSProperties )}, 
          MonthYearSelectPopover: {
            props: {
              overrides: {
                Body: { style: () => Object.assign({}, {
                  marginTop: "0px",
                  boxShadow: "none",
                  zIndex: "10",
                  top: "-8px",
                  backgroundColor: colors.greyDarkSecondary,
                  borderRadius: "0px 0px 10px 10px"
                } as React.CSSProperties )},
                Inner: { style: () => Object.assign({}, {
                  backgroundColor: colors.greyDarkSecondary,
                  boxShadow: "none",
                  paddingTop: "10px",
                  borderRadius: "0px 0px 10px 10px"
                } as React.CSSProperties )},
              }
            }
          },
          MonthYearSelectStatefulMenu: { 
            props: { 
              overrides: { 
                List: { style: () => Object.assign({}, {
                  backgroundColor: colors.greyDarkSecondary,
                  width: "120px",
                  marginTop: "0px",
                  boxShadow: "none",
                  borderRadius: "0px 0px 10px 10px",
                  scrollbarColor: colors.greyDarkPrimary+colors.transparent,
                  scrollbarWidth: "thin"
                } as React.CSSProperties )},
                ListItem: { 
                  style: ({ $isHighlighted  }: { $isHighlighted ?: boolean }) => ({
                    ...fonts.paragraph, 
                    ...fonts.fontSize3, 
                    color: colors.greyLightTertiary,
                    backgroundColor: $isHighlighted  ? colors.greyDarkPrimary : colors.transparent,
                    borderRadius: $isHighlighted ? "10px" : "0px",
                    margin: $isHighlighted ? "0px 5px" : "0px",
                    paddingLeft: $isHighlighted ? "11px" : "16px",
                    paddingRight: $isHighlighted ? "11px" : "16px"
                  })
                }
              }
            } 
          }
        }}
      />
    </div>
  );
}
