import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors, css, fonts, icons, Units, useUnitsComponent } from "../../../Styles";
import { Activations, AddAnimalToPen, Button, ButtonList, ButtonTypes, FeedCurveViewer, Pen_Basic, Pen_Edit, ProgressCircle, StatusDisplay, StatusDisplayList } from "../../";
import { AttentionTypes, FeederStatus, penTypes } from "../../../Definitions/valueTypes";
import { _attentionColor, _feederColor } from "../../../Definitions/valueColors";

import { getPenById } from "../../../Assets/TestData/lf_Data";
import { TestData as ComponentData } from '../../../Assets/TestData/componentsData';
import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';
import Last10DaysViewer, { TestData } from "../../curveViewer/animalGraph";
import { Animals, TestCalculate_Progresse } from "../../../Assets/TestData/animalData";

interface penModal_Props {
  activePenId?: number
}
/**
 * PenModal
 * @param activePenId The pen number.
 * @returns A "div" to place in a modal to show all content from a Location Feeder Pen.
 */
export default function PenModal({activePenId}:penModal_Props) {
  const {t} = useTranslation();
  const getUnit = useUnitsComponent();  

  const [showLastDays, setShowLastDays] = useState<boolean>(false); // Pen Modal Btn "Last 10 Days"
  const [showFeedPlan, setShowFeedPlan] = useState<boolean>(false); // Pen Modal Btn "Feed Plan"
  const [penEditMode, setPenEditMode] = useState<boolean>(false); // Pen Modal PenEdit Btn
  const [showAddAnimalToPenModal, setAddAnimalToPenModal] = useState(false) // Add Animal Modal
  
  // #region | Pen Modal Functionality
  // Pen Modal PenEdit Btn
  const switchPenEditMode = () => {
    setPenEditMode(!penEditMode)
  }

  // Pen Modal Add Animal To Pen Btn
  const handleEmptyPenBtn = () => {
    setAddAnimalToPenModal(true);
  }

  // Pen Modal Btn "Last 10 Days"
  const handleLastDaysClick = () => {
    setShowLastDays(true)
    setShowFeedPlan(false)
  }

  // Pen Modal Btn "Feed Plan"
  const handleFeedPlanClick = () => {
    setShowFeedPlan(true)
    setShowLastDays(false)
  }

  // Always set "setShowLastDays" to true and "setSwitchPenMode" to false when pen modal (activePenId) is "closed"
  useEffect(() => {
    if (!activePenId) {
      handleFeedPlanClick();
    }
  }, [activePenId])
  // #endregion

  // #region | Pen Modal Data
  const activePen = activePenId ? getPenById(activePenId) : null;

  const animal = activePen
    ? activePen.animal?.animalNo
    : 0;

  const animalFoodTotal = activePenId
    ? activePen?.animal?.totalFood
    : 0;

  const animalFoodProgressed = activePenId && 
    activePen&&activePen.animal?TestCalculate_Progresse(activePen.animal)
    : 0;

  const feedComponent = ComponentData;

  const feedPlan = activePen
    ? activePen.animal?.feedPlan
    : 0;

    
  const feedPlanData = useMemo(() => {
    return FeedCurveData.find((curve) => curve.id === feedPlan);
  }, [feedPlan]);

  const feeder = activePen
    ? activePen.feederStatus
    : "";

  const feederColor: FeederStatus = feeder ? feeder as FeederStatus : FeederStatus.off;

  const attention = activePen
    ? activePen.attention
    : "";

  const attentionColor: AttentionTypes = attention ? attention as AttentionTypes : AttentionTypes.neutral

  const hasActivator = activePen
    ? activePen.activator
    : false

  const motor = activePen
    ? activePen.motor
    : 0;
  // #endregion

  // #region | Pen Modal Components (Btns, Seperator)
  // Btns for "Pen" and "PenEdit"
  const renderPenButtons = (Mode: 'isEditMode' | 'isShowMode') => {
    const content = Mode === 'isEditMode' 
      ? [t("buttons.saveBtn"), t("buttons.cancelBtn")] 
      : [t("buttons.edit")];
  
    const onClick = (i: number) => {
      if (Mode === 'isShowMode' && i === 0) {
        switchPenEditMode();
      } else if (Mode === 'isEditMode' && i === 1) {
        switchPenEditMode();
      }
    };
  
    const startEnhancers = Mode === 'isEditMode'
      ? [icons.check, icons.close]
      : [icons.edit];
  
    return (
      <ButtonList 
        content={content}
        onClick={onClick}
        startEnhancers={startEnhancers}
        overrides={{
          button: { buttonType: ButtonTypes.secondaryTableButton, disableActive: true }
        }}
      />
    );
  };

  // Seperator Line for Pen Modal
  const seperatorLine = <div style={{width: "90%", height: "1.5px", backgroundColor: colors.greyPrimary, margin: "20px auto"}}/>
  // #endregion

  return (
    <div style={{backgroundColor: colors.greyLightTertiary, borderRadius: "0px 0px 10px 10px", padding: "30px 20px" }}>
      <div style={{ ...css.modalTop }}>
        {/* Activator, Feeder, Motor */}
        <StatusDisplayList 
        headers={["Attention", t("locationFeeder.penPopup.feeder"), t("locationFeeder.penPopup.motor")]} 
        values={["• " + attention ,"• " + feeder, motor + " mA"]}              
        icons={[icons.attentionType, icons.feederLF, icons.motorLF]}
        valueColor={[ _attentionColor(attentionColor), _feederColor(feederColor), colors.greyDarkPrimary]}
        />
        {/* Fed Today */}
        <ProgressCircle header={t("feed.fed.today")}
        total={animalFoodTotal ?? 0} 
        progressed={animalFoodProgressed ?? 0} 
        unit={getUnit(Units.kG_grey)} 
        />
        {/* Fed During Period */}
        <ProgressCircle header={t("feed.fed.period")} 
        total={animalFoodTotal ?? 0} 
        progressed={animalFoodProgressed ?? 0}
        unit={getUnit(Units.kG_grey)}
        />
      </div>
      
      {seperatorLine}

      {/* Activations, Last Activation */}
      {hasActivator? <>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "40px"}}>
          <StatusDisplay
            header={t("locationFeeder.penPopup.activator")} 
            value={"XXXXXX"}              
            icon={icons.activatorLF}
            valueColor={colors.greyDarkPrimary}
          />
          < Activations penId={activePenId ?? 0} /> 
        </div> 
        {seperatorLine}
      </> : <></>}

      <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
        {animal? <>
          {/* Animal in pen */}
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "5px"}}>
            <img src={icons.animalsColor} style={{ width: "40px" }} alt="icon" />
            <div>
              <p style={{...fonts.header, ...fonts.fontSize4, color: colors.greyPrimary }}>{t("locationFeeder.penPopup.inThisPen")}</p>
              <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary }}>{t("animal.animal")+" "+animal}</p>
            </div>
          </div>

          {/* Animal btns (FeedPlan + Last 10 days) */}
          <div style={{margin: "10px 0px", display: "flex", justifyContent: "center"}}>
            <ButtonList content={[t("locationFeeder.penPopup.lastDays"), t("feed.feedPlan.feedPlan")]} 
              overrides={{
                button: { buttonType: ButtonTypes.secondaryButton }
              }}
              onClick={(i) => {
                if (i === 0) {
                  handleLastDaysClick()
                } else if (i === 1) {
                  handleFeedPlanClick()
                }
            }}/>
          </div>

          {/* Pen/PenEdit based on PenMode */}
          <div>
            <div style={{display: "flex", flexDirection: "row", padding: "0px 20px 0px 16px", justifyContent: penEditMode ? "end" : "space-between", alignItems: "end"}}>
              {penEditMode? <></> : <p style={{ ...css.penHeaders }}>{t("locationFeeder.penPopup.penValueHeaders")}</p>}
              {penEditMode? renderPenButtons('isEditMode') : renderPenButtons('isShowMode')}
            </div>
            <div style={{borderRadius: "10px", overflow: "hidden"}}>
              {penEditMode
                ? <Pen_Edit penNo={activePenId ?? 0} penType={penTypes.single}/> 
                : <Pen_Basic penNo={activePenId ?? 0} penType={penTypes.single}/>
              }
            </div>
          </div>
                  
          <div>
            {/* PEN MODAL ANIMAL FEED PLAN */}
            { showFeedPlan? 
              <>      
                {/* FeedCurveViewer based on feedPlanData */}
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                  {feedPlanData ? (
                  <FeedCurveViewer data={feedPlanData} feedComponents={feedComponent} showComponentLabels={true} showMilestoneLabels={true}/>
                  ) : (
                  <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyDarkPrimary,}}>No feed curve found</p> // Returner en tom div, hvis der ikke er nogen feedPlan
                  )}
                </div>
              </>         
            : // PEN MODAL ANIMAL LAST 10 DAYS
            <> 
              <div style={{marginTop: "20px"}}>
                <Last10DaysViewer data={TestData}/>
              </div> 
            </>
            }
          </div>
          </> : <>
            <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary, marginBottom: "10px" }}>{"Empty Pen"}</p>
            <Button content={'Add animal to pen'} overrides={{buttonType: ButtonTypes.tertiaryButton}} onClick={handleEmptyPenBtn}/>
            {/* Add Animal Modal */}
            <AddAnimalToPen isModalOpen={showAddAnimalToPenModal} activePen={activePenId ?? 0} goBack={() => setAddAnimalToPenModal(false)} />
          </>
        }
      </div>
    </div>
  )
}