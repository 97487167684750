import { AttentionTypes, FeederStatus } from '../../Definitions/valueTypes';
import { Animals, AnimalTypes, Milestone, TestData as AnimalData } from './animalData'; // Adjust the import path as needed

export type Activation = {
    date: number;
    amount: number;
  };

  export type Skipped = {
    forNow?: boolean;
    standard?: number;
    startDate?: string;
    endDate?: string;
    startTime?: string;
    endTime?: string;
  };

export type Pens = {
  penNo: number,
  animal?: Animals["animalNo"],
  activator?: boolean,
  lastActivated?: number,
  activationsToday?: number,
  activationsPast?: Activation[],
  feeding: boolean,
  motor: number,
  feederStatus: FeederStatus,
  skip?: Skipped
  attention: AttentionTypes 
  error?: string
};
  
export const TestData: Pens[] = [
  {
    penNo: 300,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 301,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 302,
    animal: 3002,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 303,
    animal: 3003,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 304,
    animal: 3004,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 196,
    feederStatus: FeederStatus.error,
    attention: AttentionTypes.alert,
    error: "Motor Error"
  },
  {
    penNo: 305,
    animal: 3005,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 20,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.warning,
    error: "Motor Error"
  },
  {
    penNo: 306,
    animal: 3006,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 307,
    animal: 3007,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 308,
    animal: 3008,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 309,
    animal: 3009,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 310,
    animal: 3010,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 311,
    animal: 3011,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 312,
    animal: 3012,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 313,
    animal: 3013,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 314,
    animal: 3014,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 315,
    animal: 3015,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 316,
    animal: 3016,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 317,
    animal: 3017,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 318,
    animal: 3018,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 319,
    animal: 3019,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 320,
    animal: 3020,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 321,
    animal: 3021,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 322,
    animal: 3022,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 323,
    animal: 3023,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 324,
    animal: 3024,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 325,
    animal: 3025,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 326,
    animal: 3026,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 327,
    animal: 3027,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 328,
    animal: 3028,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 329,
    animal: 3029,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 330,
    animal: 3030,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 331,
    animal: 3031,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 332,
    animal: 3032,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 333,
    animal: 3033,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 334,
    animal: 3034,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 335,
    animal: 3035,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 336,
    animal: 3036,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 337,
    animal: 3037,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 338,
    animal: 3038,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 339,
    animal: 3039,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 340,
    animal: 3040,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 341,
    animal: 3041,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 342,
    animal: 3042,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 343,
    animal: 3043,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 344,
    animal: 3044,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 345,
    animal: 3045,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 346,
    animal: 3046,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 347,
    animal: 3047,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 348,
    animal: 3048,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 349,
    animal: 3049,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 350,
    animal: 3050,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 351,
    animal: 3051,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 352,
    animal: 3052,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 353,
    animal: 3053,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 354,
    animal: 3054,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 355,
    animal: 3055,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 356,
    animal: 3056,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 357,
    animal: 3057,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 358,
    animal: 3058,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 359,
    animal: 3059,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 360,
    animal: 3060,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 361,
    animal: 3061,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 362,
    animal: 3062,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 363,
    animal: 3063,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 364,
    animal: 3064,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 365,
    animal: 3065,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 366,
    animal: 3066,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 367,
    animal: 3067,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 368,
    animal: 3068,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 369,
    animal: 3069,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 370,
    animal: 3070,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 371,
    animal: 3071,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 372,
    animal: 3072,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 373,
    animal: 3073,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 374,
    animal: 3074,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 375,
    animal: 3075,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 376,
    animal: 3076,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 377,
    animal: 3077,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 378,
    animal: 3078,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 379,
    animal: 3079,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 380,
    animal: 3080,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 381,
    animal: 3081,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 382,
    animal: 3082,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 383,
    animal: 3083,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 384,
    animal: 3084,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 385,
    animal: 3085,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 386,
    animal: 3086,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 387,
    animal: 3087,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 388,
    animal: 3088,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 389,
    animal: 3089,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 390,
    animal: 3090,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 391,
    animal: 3091,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 392,
    animal: 3092,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 393,
    animal: 3093,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 394,
    animal: 3094,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 395,
    animal: 3095,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 396,
    animal: 3096,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 397,
    animal: 3097,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 398,
    animal: 3098,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 399,
    animal: 3099,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 400,
    animal: 3100,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 401,
    animal: 3101,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 402,
    animal: 3102,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 403,
    animal: 3103,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 404,
    animal: 3104,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 405,
    animal: 3105,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 406,
    animal: 3106,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 407,
    animal: 3107,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 408,
    animal: 3108,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 409,
    animal: 3109,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 410,
    animal: 3110,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 411,
    animal: 3111,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 412,
    animal: 3112,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 413,
    animal: 3113,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 414,
    animal: 3114,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 415,
    animal: 3115,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 416,
    animal: 3116,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 417,
    animal: 3117,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 418,
    animal: 3118,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 419,
    animal: 3118,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 420,
    animal: 3120,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 421,
    animal: 3121,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 422,
    animal: 3122,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 423,
    animal: 3123,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 424,
    animal: 3124,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 425,
    animal: 3125,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 426,
    animal: 3126,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 427,
    animal: 3127,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 428,
    animal: 3128,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 429,
    animal: 3129,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 430,
    animal: 3130,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 431,
    animal: 3131,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 432,
    animal: 3132,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 433,
    animal: 3133,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 434,
    animal: 3134,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 435,
    animal: 3135,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 436,
    animal: 3136,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 437,
    animal: 3137,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 438,
    animal: 3138,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 439,
    animal: 3139,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 440,
    animal: 3140,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 441,
    animal: 3141,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 442,
    animal: 3142,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 443,
    animal: 3143,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 444,
    animal: 3144,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 445,
    animal: 3145,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 446,
    animal: 3146,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 447,
    animal: 3147,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 448,
    animal: 3148,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 449,
    animal: 3149,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 450,
    animal: 3150,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 451,
    animal: 3151,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 452,
    animal: 3152,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 453,
    animal: 3153,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 454,
    animal: 3154,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 455,
    animal: 3155,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 456,
    animal: 3156,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 457,
    animal: 3157,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 458,
    animal: 3158,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 459,
    animal: 3159,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },{
    penNo: 460,
    animal: 3160,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 461,
    animal: 3161,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 462,
    animal: 3162,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 463,
    animal: 3163,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 464,
    animal: 3164,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 465,
    animal: 3165,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 466,
    animal: 3166,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 467,
    animal: 3167,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 468,
    animal: 3168,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 469,
    animal: 3169,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 470,
    animal: 3170,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 471,
    animal: 3171,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 472,
    animal: 3172,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 473,
    animal: 3173,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 474,
    animal: 3174,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 475,
    animal: 3175,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 476,
    animal: 3176,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 477,
    animal: 3177,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 478,
    animal: 3178,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 479,
    animal: 3179,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 480,
    animal: 3180,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 481,
    animal: 3181,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 482,
    animal: 3182,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 483,
    animal: 3183,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 484,
    animal: 3184,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 485,
    animal: 3185,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 486,
    animal: 3186,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 487,
    animal: 3187,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 488,
    animal: 3188,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 489,
    animal: 3189,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 490,
    animal: 3190,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 491,
    animal: 3191,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 492,
    animal: 3192,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 493,
    animal: 3193,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 494,
    animal: 3194,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 495,
    animal: 3195,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 496,
    animal: 3196,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 497,
    animal: 3197,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 498,
    animal: 3198,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 499,
    animal: 3199,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 500,
    animal: 3200,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 501,
    animal: 3201,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 502,
    animal: 3202,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 503,
    animal: 3203,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 504,
    animal: 3204,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 505,
    animal: 3205,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 506,
    animal: 3206,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 507,
    animal: 3207,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 508,
    animal: 3208,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 509,
    animal: 3209,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 510,
    animal: 3210,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 511,
    animal: 3211,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 512,
    animal: 3212,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 513,
    animal: 3213,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 514,
    animal: 3214,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 515,
    animal: 3215,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 516,
    animal: 3216,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 517,
    animal: 3217,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 518,
    animal: 3218,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 519,
    animal: 3219,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 520,
    animal: 3220,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 521,
    animal: 3221,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 522,
    animal: 3222,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 523,
    animal: 3223,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 524,
    animal: 3224,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 525,
    animal: 3225,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 526,
    animal: 3226,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 527,
    animal: 3227,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 528,
    animal: 3228,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 529,
    animal: 3229,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 530,
    animal: 3230,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 531,
    animal: 3231,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 532,
    animal: 3232,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 533,
    animal: 3233,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 534,
    animal: 3234,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 535,
    animal: 3235,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 536,
    animal: 3236,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 537,
    animal: 3237,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 538,
    animal: 3238,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 539,
    animal: 3239,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 540,
    animal: 3240,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 541,
    animal: 3241,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 542,
    animal: 3242,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 543,
    animal: 3243,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 544,
    animal: 3244,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 545,
    animal: 3245,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 546,
    animal: 3246,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 547,
    animal: 3247,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 548,
    animal: 3248,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 549,
    animal: 3249,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 550,
    animal: 3250,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 551,
    animal: 3251,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 552,
    animal: 3252,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 553,
    animal: 3253,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 554,
    animal: 3254,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 555,
    animal: 3255,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 556,
    animal: 3256,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 557,
    animal: 3257,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 558,
    animal: 3258,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 559,
    animal: 3259,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 560,
    animal: 3260,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 561,
    animal: 3261,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 562,
    animal: 3262,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 563,
    animal: 3263,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 564,
    animal: 3264,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 565,
    animal: 3265,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 566,
    animal: 3266,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 567,
    animal: 3267,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 568,
    animal: 3268,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 569,
    animal: 3269,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 570,
    animal: 3270,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 571,
    animal: 3271,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 572,
    animal: 3272,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 573,
    animal: 3273,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 574,
    animal: 3274,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 575,
    animal: 3275,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 576,
    animal: 3276,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 577,
    animal: 3277,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 578,
    animal: 3278,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 579,
    animal: 3279,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 580,
    animal: 3280,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 581,
    animal: 3281,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 582,
    animal: 3282,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 583,
    animal: 3283,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 584,
    animal: 3284,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 585,
    animal: 3285,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 586,
    animal: 3286,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 587,
    animal: 3287,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 588,
    animal: 3288,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 589,
    animal: 3289,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 590,
    animal: 3290,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 591,
    animal: 3291,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 592,
    animal: 3292,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 593,
    animal: 3293,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 594,
    animal: 3294,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 595,
    animal: 3295,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 596,
    animal: 3296,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 597,
    animal: 3297,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 598,
    animal: 3298,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 599,
    animal: 3299,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 600,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 601,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 602,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 603,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 604,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 605,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 606,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 607,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 608,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 609,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 610,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 611,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 612,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 613,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 614,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 615,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 616,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 617,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 618,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 619,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  }
]

export const TestData2: Pens[] = [
  {
    penNo: 300,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 301,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 302,
    animal: 3002,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 303,
    animal: 3003,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 304,
    animal: 3004,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 196,
    feederStatus: FeederStatus.error,
    attention: AttentionTypes.alert,
    error: "Motor Error"
  },
  {
    penNo: 305,
    animal: 3005,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 20,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.warning,
    error: "Motor Error"
  },
  {
    penNo: 306,
    animal: 3006,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 307,
    animal: 3007,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 308,
    animal: 3008,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 309,
    animal: 3009,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 310,
    animal: 3010,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 311,
    animal: 3011,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 312,
    animal: 3012,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 313,
    animal: 3013,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 314,
    animal: 3014,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 315,
    animal: 3015,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 316,
    animal: 3016,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 317,
    animal: 3017,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 318,
    animal: 3018,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 319,
    animal: 3019,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 320,
    animal: 3020,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 321,
    animal: 3021,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 322,
    animal: 3022,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 323,
    animal: 3023,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 324,
    animal: 3024,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 325,
    animal: 3025,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 326,
    animal: 3026,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 327,
    animal: 3027,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 328,
    animal: 3028,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 329,
    animal: 3029,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 330,
    animal: 3030,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 331,
    animal: 3031,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 332,
    animal: 3032,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 333,
    animal: 3033,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 334,
    animal: 3034,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 335,
    animal: 3035,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 336,
    animal: 3036,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 337,
    animal: 3037,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 338,
    animal: 3038,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 339,
    animal: 3039,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 340,
    animal: 3040,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 341,
    animal: 3041,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 342,
    animal: 3042,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 343,
    animal: 3043,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 344,
    animal: 3044,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 345,
    animal: 3045,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 346,
    animal: 3046,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 347,
    animal: 3047,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 348,
    animal: 3048,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 349,
    animal: 3049,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 350,
    animal: 3050,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 351,
    animal: 3051,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 352,
    animal: 3052,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 353,
    animal: 3053,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 354,
    animal: 3054,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 355,
    animal: 3055,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 356,
    animal: 3056,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 357,
    animal: 3057,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 358,
    animal: 3058,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 359,
    animal: 3059,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 360,
    animal: 3060,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 361,
    animal: 3061,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 362,
    animal: 3062,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 363,
    animal: 3063,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 364,
    animal: 3064,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 365,
    animal: 3065,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 366,
    animal: 3066,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 367,
    animal: 3067,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 368,
    animal: 3068,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 369,
    animal: 3069,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 370,
    animal: 3070,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 371,
    animal: 3071,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 372,
    animal: 3072,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 373,
    animal: 3073,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 374,
    animal: 3074,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 375,
    animal: 3075,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 376,
    animal: 3076,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 377,
    animal: 3077,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 378,
    animal: 3078,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 379,
    animal: 3079,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 380,
    animal: 3080,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 381,
    animal: 3081,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 382,
    animal: 3082,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 383,
    animal: 3083,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 384,
    animal: 3084,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 385,
    animal: 3085,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 386,
    animal: 3086,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 387,
    animal: 3087,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 388,
    animal: 3088,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 389,
    animal: 3089,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  },
  {
    penNo: 390,
    animal: 3090,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.off,
    attention: AttentionTypes.neutral
  },{
    penNo: 391,
    animal: 3091,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    feeding: false,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral
  },
  {
    penNo: 392,
    animal: 3092,
    activator: true,
    lastActivated: 30,
    activationsToday: 36,
    feeding: true,
    motor: 50,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good
  },
  {
    penNo: 393,
    animal: 3093,
    activator: true,
    lastActivated: 60,
    activationsToday: 98,
    feeding: true,
    motor: 60,
    feederStatus: FeederStatus.dosing,
    attention: AttentionTypes.good,
  },
  {
    penNo: 394,
    animal: 3094,
    activator: true,
    lastActivated: 120,
    activationsToday: 1,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 395,
    animal: 3095,
    activator: true,
    lastActivated: 180,
    activationsToday: 56,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.idle,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 396,
    animal: 3096,
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "10:00"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 397,
    animal: 3097,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.paused,    
    skip: {
      startDate: "05/11/2022",
      endDate: "30/11/2022",
      startTime: "11:30",
      endTime: "12:30"
    },
    attention: AttentionTypes.neutral
  },
  {
    penNo: 398,
    animal: 3098,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 0,
    feederStatus: FeederStatus.calibration,
    attention: AttentionTypes.neutral,
  },
  {
    penNo: 399,
    animal: 3099,
    lastActivated: 0,
    activationsToday: 0,
    activationsPast: [],
    activator: true,
    feeding: true,
    motor: 55,
    feederStatus: FeederStatus.measuring,
    attention: AttentionTypes.good,
  }
]

// Function to get a section by ID
export function getPenById(penId: number) {
  // Find the section by ID
  const pen = TestData.find(pen => pen.penNo === penId);
  
  // If the section is not found, return null
  if (!pen) return null;

  // Get detailed animal data
  const animalData = AnimalData.find(animal => animal.animalNo === pen.animal);

  return {
    ...pen,
    animal: animalData ? animalData : null
  };
}