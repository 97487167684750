import react, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { penTypes } from '../../../Definitions/valueTypes'
import { css, icons } from '../../../Styles'

import { getSectionById } from '../../../Assets/TestData/sectionsData'
import { Button, ButtonList, ButtonTypes, Pen_Edit } from '../..'

interface Section_Props {
  sectionId: number
  onSaveBtn?: () => void
  onCancelBtn?: () => void
}
/**
 * Section
 * @param sectionId Unique identifier for each section.
 * @param onSaveBtn OnClick event to handle Save btn.
 * @param onCancelBtn OnClick event to handle Cancel btn.
 * @returns a section of pens for Freeda Location Feeder systems.
 */
export default function Section_Edit({ sectionId, onSaveBtn, onCancelBtn }: Section_Props) {
  const {t} = useTranslation()
  const section = getSectionById(sectionId);
  const [animalsToEdit, setAnimalsToEdit] = useState<string[]>([]);
  const [checkedPens, setCheckedPens] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  function CancelBtnClick() {
    if (onCancelBtn) onCancelBtn()
  }

  function SaveBtnClick() {
    if (onSaveBtn) onSaveBtn()
  }

  // Error throwback if no section number excists
  if (!section) {
    return <div>Section {sectionId} not found</div>;
  }

    // Handle checking/unchecking pens
    const handlePenCheck = (penNo: number, isChecked: boolean) => {
      setCheckedPens(prevChecked => {
        const updatedPens = isChecked ? [...prevChecked, penNo] : prevChecked.filter(p => p !== penNo);
        
        const newAnimalNos = section?.pens
            .filter(pen => updatedPens.includes(pen.penNo) && pen.animal)
            .map(pen => pen.animal?.animalNo?.toString()) // Convert animalNo to string
            .filter((animalNo): animalNo is string => animalNo !== undefined) || []; // Filter out undefined
  
        setAnimalsToEdit(newAnimalNos); // Update to array
        return updatedPens;
      });
    };
  
  // Handle checking/unchecking all pens
  const handleCheckAllPens = () => {
    if (isAllSelected) {
      setCheckedPens([]);
      setAnimalsToEdit([]);
    } else { // if single animal is already checked and "select all" is clicked
      const updatedPens = section?.pens
        .filter(pen => pen.animal)
        .map(pen => pen.penNo) || [];
        
      const newAnimalNos = section?.pens
        .filter(pen => pen.animal)
        .map(pen => pen.animal?.animalNo?.toString())
        .filter((animalNo): animalNo is string => animalNo !== undefined) || [];
        
      setCheckedPens(updatedPens);
      setAnimalsToEdit(newAnimalNos);
    }
    setIsAllSelected(!isAllSelected);
  };

  const editModeBtns = 
  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
    <Button content={!isAllSelected? "Select All" : "Deselect All"} startEnhancer={icons.check_green} overrides={{ buttonType: ButtonTypes.secondaryTableButton, disableActive: true}} onClick={handleCheckAllPens}/>
    <ButtonList 
      content={[t("buttons.saveBtn"), t("buttons.cancelBtn")]} 
      onClick={(i => {
        if (i === 0) {SaveBtnClick()} // TODO CTO: Save to db and swith section edit
        else if (i === 1) {CancelBtnClick()}
      })}
      startEnhancers={[icons.check, icons.close]}
      overrides={{
        button:{ buttonType: ButtonTypes.secondaryTableButton, disableActive: true}
      }} 
    />
  </div>

  const renderContent = () => {
    return section.pens.map(pen => (
      <Pen_Edit
        key={pen.animal?.animalNo}
        penNo={pen.penNo}
        penType={penTypes.advanced}
        onCheck={(isChecked) => handlePenCheck(pen.penNo, isChecked)}
        isChecked={checkedPens.includes(pen.penNo)}
      />
    ));
  }

  return (
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "end", padding: "0px 20px" }}>
          {editModeBtns}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "", width: "fit-content", gap: "10px", borderRadius: "10px", overflow: "hidden" }}>
          {renderContent()}
        </div>
      </div>
  );
}