export enum ListStyle_Types {
    horizontal = "horizontal",
    vertical = "vertical",
    verticalProMax = "verticalProMax",
    group = "group",
    none = "none"
  }
  
  export const CreateListStyle = (listStyle?:ListStyle_Types|string) => {
    let seperatorPosition;
    let listDirection;
  
    switch (listStyle) {
      case 'vertical':
        seperatorPosition = {  height: "10px", width: "-webkit-fill-available" }
        listDirection = { display: "flex", flexDirection: "column" }
      break;
      case 'verticalProMax':
        seperatorPosition = {  height: "15px", width: "-webkit-fill-available" }
        listDirection = { display: "flex", flexDirection: "column" }
      break;
      case 'group':
        seperatorPosition = {  display: "none" }
        listDirection = { display: "flex", flexWrap: "wrap", gap: "5px", justifyContent: "center" }
      break;
      case 'horizontal':
        default:
        seperatorPosition = { height: "-webkit-fill-available", width: "5px" };
        listDirection = { display: "flex", flexDirection: "row", flexWrap: "flex" }
      break;
    }
    return {
        seperatorPosition, listDirection
    }
  }
  
  // export default CreateListStyle

// Eksempel på brug (Maja) - se evt. select.tsx og input.tsx
//
// Import af definition:
// import CreateListStyle, { ListStyle_Types } from "../../Definitions/listStyles";
// 
// Indsæt under props for det pågældende component:
// listStyle?: ListStyle_Types|string,
//
// oprettelse af const på det enkelte component til brug i kald:
// const { seperatorPosition, listDirection } = useMemo(() => CreateListStyle(listStyle), [listStyle]);
//
// Kald på det enkelte component på forskellige div'er, hvor stylingen ønskes:
// <div style={seperatorPosition as React.CSSProperties}/>
// <div style={listDirection as React.CSSProperties}>