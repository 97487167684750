import { useState } from 'react'

import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../Store'
import { setCookieState, CookieStates } from '../Store/StateHandlers/program-slice'

import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  ModalButton, FocusOnce,
} from 'baseui/modal';

export interface CookieModal_Props {
}

//Is state Self handling... could be ref'ed..
const CookieModal = ({ }: CookieModal_Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch()
  
  const CookieState = useAppSelector(({Program}) => Program.CookieLevel)
  const [CookieModal, setCookieModal] = useState(CookieState>=CookieStates.FirstInit?true:false)

  const changeCookieState = (level:number) => {
    if (level !== CookieState) dispatch(setCookieState({level}))
    setCookieModal(false)
  }

  return (
    <Modal onClose={() => changeCookieState(CookieState)} isOpen={CookieModal} >
      <FocusOnce>
        <ModalHeader>{t("cookie.header")}</ModalHeader>
      </FocusOnce>
      <ModalBody>
        <div style={{whiteSpace: 'pre-wrap'}}>{t("cookie.context")}</div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={() => changeCookieState(CookieStates.Necessary)}>
          {t("cookie.accept")}
        </ModalButton>
        <ModalButton style={{width:190}} autoFocus onClick={() => changeCookieState(CookieStates.All)}>
          {t("cookie.acceptAll")}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default CookieModal
