import { LanguageSelector } from "../../Components";
import { frames, logos, useMediaQuery } from "../../Styles"
import { Outlet } from "react-router-dom";

const DefLayout = () => {
  const mobile = useMediaQuery(`(max-width: 600px)`);

  return (
    <frames.DecorBg>
      <frames.AbsoluteTopRight>
        <LanguageSelector overrides={{ isPrimaryComponent: false }}/>
      </frames.AbsoluteTopRight>
      <frames.Def>
        <img
          alt="Freeda Logo"
          src={logos.whiteFreedaLogo} 
          style={{
            width: !mobile? "600px" : "80%",
            marginBottom: "20px"
          }}
        />

        <Outlet />
      
      </frames.Def>
    </frames.DecorBg>  
  )
}
 
export default DefLayout