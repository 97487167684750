import { EntityState } from "@reduxjs/toolkit"
import Graph_QL, { DynamicDataObk } from "."

export const filterByConnection = <T,>(data:EntityState<DynamicDataObk<T>, string>, filter?:Graph_QL.MultiConnection) => {
  return Object.values(data.entities).reduce((acc, value) => {
    if (value.con) {
      var ConId = Graph_QL.ObjToString(value.con)
      if (!filter || filter.cons.some(e => e.toString() === ConId)) { //Sort on specified Domains
        acc[ConId] = value
      }
    }
    return acc
  },{} as Record<string, DynamicDataObk<T>>)
}
