import { CSSProperties } from "react"
import { colors } from "../../../Styles"
import { ColumnPinningPosition } from "@tanstack/react-table"

export const LEFT_FROZEN_TABLE_WIDTH = 120;
export const RIGHT_FROZEN_TABLE_WIDTH = 100;

export const getRemoveRowActionStyle = (id: string): CSSProperties | undefined => {
  if (id === "removeRowActionColumn") {
    return {minWidth: "40px", maxWidth: "40px", padding: "0px 8px" }
  }
} 

export const getFrozenColumnStyle = (isPinned: ColumnPinningPosition, isLastColumn: boolean, isFirstColumn: boolean, cellIndex: number, rowLength: number, isHeader: boolean = false) => {
  if (isPinned) {
    return {
      zIndex: isPinned ? 2 + +isHeader : 1 + +isHeader,
      position: isPinned ? 'sticky' : 'relative',
      left: isPinned === "left" ? `${cellIndex * LEFT_FROZEN_TABLE_WIDTH}px` : "auto",
      right: isPinned === "right" ? `${(rowLength-1-cellIndex) * RIGHT_FROZEN_TABLE_WIDTH}px` : "auto",
      borderRight: isLastColumn ? `2px solid ${colors.greySecondary}` : "none",
      borderLeft: isFirstColumn ? `2px solid ${colors.greySecondary}` : "none",
      minWidth: "auto",

    }
  }
}