import react, { useEffect, useMemo, useState } from "react";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { colors, fonts } from "../../Styles";

interface Toggle_Props {
  isChecked?: boolean,
  label: string
  icon: string
  onChange?: (e:boolean) => void;
}
/**
 * Toggle
 * @param label text to display before the actual button.
 * @param icon icon to display inside the toggle switch.
 * @param onToggle function to run when checked=true.
 * @returns a BaseUi checkbox with checkmarkType=toggle, build from content param. 
 */
export default function Toggle({isChecked, label, icon, onChange}:Toggle_Props) {
  // const [checked, setChecked] = useState(isChecked?isChecked:false);
  const [checked, setChecked] = useState(isChecked || false);

  useEffect(() => {
    setChecked(isChecked || false);
  }, [isChecked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}     
      checkmarkType={STYLE_TYPE.toggle}
      overrides={{
        Root: { style: () => Object.assign({}, {
          alignItems: "center",
          width: "fit-content"
        } as React.CSSProperties )},
        Toggle: { style: () => Object.assign({}, {
          height: "23px",
          width: "23px",
          backgroundColor: colors.greySecondary,
          backgroundImage: `url(${icon})`,
          backgroundSize: "16px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          transform: !checked ? "rotate(-179.9deg)" : "translateX(23px)", 
          transition: "0.5s ease-in-out",
          boxShadow: "none"
        } as React.CSSProperties )},
        ToggleTrack: { style: () => Object.assign({}, {
          backgroundColor: !checked ? colors.systemRed : colors.systemGreen,
          height: "23px",
          width: "46px",
          margin: "0px",
          borderRadius: "20px",
          border: "2px solid",
          borderColor: !checked ? colors.systemRed : colors.systemGreen,
          outline: "2px solid",
          outlineColor: colors.greySecondary,
        } as React.CSSProperties )},
      }}
    >        
      <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary }}>{label}</p>
    </Checkbox>
  );
}