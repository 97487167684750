import { AttentionTypes } from "../../Definitions";
import { SystemTypes } from "../../Definitions/valueTypes";

  export type System = {
    systemNo: number; //DataTYPE ID, static
    name: string; 
    attention: AttentionTypes
    type: SystemTypes
  };
  
  export const SystemData: System[] = [
    // {
    //   systemNo: 0,
    //   name: "AutoPig",
    //   attention: AttentionTypes.good,
    //   type: SystemTypes.AutoPig
    // },
    {
      systemNo: 1,
      name: "Farrowing 1",
      attention: AttentionTypes.good,
      type: SystemTypes.LocationFeeder
    },
    {
      systemNo: 2,
      name: "Farrowing 2",
      attention: AttentionTypes.good,
      type: SystemTypes.LocationFeeder
    },
    {
      systemNo: 3,
      name: "Dynamic Section",
      attention: AttentionTypes.neutral,
      type: SystemTypes.WalkThroughIn
    },
    {
      systemNo: 4,
      name: "Pig performance tester",
      attention: AttentionTypes.alert,
      type: SystemTypes.PPT
    },
    {
      systemNo: 5,
      name: "Sorting unit",
      attention: AttentionTypes.warning,
      type: SystemTypes.Sorting
    }
  ]

