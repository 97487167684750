import react, { useEffect, useState } from 'react'
import { VictoryPie, VictoryContainer } from 'victory'
import { colors, fonts } from '../../Styles'
import { progressColor } from '../../Definitions/'

// #region | ProgressCircle Component
interface ProgressCircle_Props {
  header?: string
  total: number
  progressed: number
  unit?: JSX.Element
}
/**
 * ProgressCircle
 * @param header text to display above the ProgressCircle.
 * @param total total value as number.
 * @param progressed progressed value as number.
 * @param unit text to display below the ProgressCirlce, e.g. {units.kg}
 * @returns a VictoryPie with innerRadius, animation, and label. 
 */
export default function ProgressCircle({ header, total = 0, progressed = 0, unit }: ProgressCircle_Props) {
  const [animatedProgress, setAnimatedProgress] = useState(0);

  const progressedPercentage = total > 0 ? (progressed / total) * 100 : 0;

  useEffect(() => {
    // Reset animation if total or progressed values change
    setAnimatedProgress(0);

    const animationDuration = 1000; // 1 second for animation
    const startTime = Date.now();

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / animationDuration, 1);
      setAnimatedProgress(progress * progressedPercentage);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    animate();
  }, [total, progressed]);

  // if (total === 0) {
  //   return <div>No data</div>;
  // }

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px"}}>
      
      {/* Header */}
      <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{header}</p>

      {/* Circle */}
      <div style={{ width: "100px", height: "100px", position: "relative" }}>
        <div style={{ height: '100px', width: '100px', position: 'relative', borderRadius: "50%", overflow: "hidden" }}>
          <VictoryPie
            data={[
              { x: 1, y: animatedProgress },
              { x: 2, y: 100 - animatedProgress }
            ]}
            height={100}
            width={100}
            innerRadius={40}
            standalone={true}
            colorScale={[progressColor(progressedPercentage), colors.greyTertiary]}
            labels={() => null}
            containerComponent={<VictoryContainer responsive={false} />}
            padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          />
        </div>
        
        {/* Progress/total labels */}
        <div
          style={{ 
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            ...fonts.header,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <p style={{ ...fonts.fontSize1, color: progressColor(progressedPercentage), margin: "0px" }}>{`${progressed}`}</p>
            <p style={{ ...fonts.fontSize4, color: colors.greyPrimary, margin: "0px" }}>{`/ ${total}`}</p>
          </div>
        </div>
      </div>

      {/* Progress unit */}
      {unit}

    </div>
  );
}
// ---------------------------------------------------
//#endregion