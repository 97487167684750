import { useState, useEffect } from 'react';
import { colors, frames, icons } from "../../Styles";
import { Outlet } from "react-router-dom";
import LogoDisplay from "../../Components/logoDisplay";
import { mediaQueriesNumbers } from '../../Styles';
import ToolsMenu from '../Menus/toolsMenu';
import { useTranslation } from 'react-i18next';

const ToolspanelLayout = () => {
  const [showComponents, setShowComponents] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const {t} = useTranslation()

  // To show or not to show function
  const toggleComponents = () => {
    setShowComponents(!showComponents);
  };

  // Definition of "isTablet"
  const handleMediaQueries = () => {
    setIsTablet(window.innerWidth <= mediaQueriesNumbers.tabletMax);
    if (window.innerWidth >= mediaQueriesNumbers.tabletMax) {
      setShowComponents(true);
    } else {
      setShowComponents(false);
    }
  };

  // Check window size
  useEffect(() => {
    window.addEventListener('resize', handleMediaQueries);
    handleMediaQueries();

    return () => {
      window.removeEventListener('resize', handleMediaQueries);
    };
  }, []);

  return (
    <div>
      {isTablet && ( // Only shows button if screen width is equal to tablet
        <div>
          {!showComponents && ( // Only shows button if showComponents is false
            <button onClick={toggleComponents} style={{ display: "flex", alignItems: "center", justifyContent:"center", 
              position: "absolute", top: "20px", right: "0px", width:"45px", height:"45px", backgroundColor: `${colors.greyPrimary}80`, 
              border: "2px solid", borderColor: colors.greyLightPrimary, borderRight: "0", borderRadius: "10px 0 0 10px", cursor: "pointer" }} 
            >
              <img src={icons.goLeftArrow} style={{width: "25px"}} alt="Go back"/>
            </button>
          )}
        </div>
      )}
      <frames.ToolsPanel>
        {showComponents && ( // Only shows components if showComponents is true (showComponents is always true on page load except for when screen size is equal to tablet)
          <>
          {isTablet && ( // Only shows overlay if screen width is equal to tablet
            <div onClick={toggleComponents} style={{ backgroundColor: "black", opacity: "0.5", position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: "999" }}/>
          )}
            <LogoDisplay goBackBtn={t("buttons.goBackBtn")} overrides={{ gridArea: "logoDisplay" }} />
            <ToolsMenu overrides={{ gridArea: "globalMenu" }} />
          </>
        )}
        <div style={{ gridArea: "page_outer" }}>
          <Outlet />
        </div>
      </frames.ToolsPanel>
    </div>
  );
};

export default ToolspanelLayout;
