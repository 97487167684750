import { useMemo } from "react"
import { colors, css, fonts } from "../../Styles"

// #region | NumberDisplay Component
interface numberDisplay_Props {
  header?: string
  value: number | string
  unit: string
}
/**
 * NumberDisplay
 * @param header header to display above the single numberDisplay component.
 * @param value the value of the number to be displayed.
 * @param unit the unit of the value.
 * @returns a nice way to display a value/number. 
 */
export default function NumberDisplay ({header, value, unit}:numberDisplay_Props) {

  return (
    <div>
      <p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary}}>{header}</p>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <p style={{...fonts.header, ...fonts.fontSize1, color: colors.greyDarkPrimary,}}>{value}</p>
        <div style={{ width: "20px", height: "1px", backgroundColor: colors.greyDarkPrimary }}/>
        <p style={{...css.unitsFontstyle, marginTop: "5px"}}>{unit}</p>
      </div> 
    </div>
  )
}
// ---------------------------------------------------
//#endregion

// #region | NumberDisplayList Component
interface NumberDisplayListProps {
  header?: string
  headers?: string[]
  values: (number|string)[]
  units: string[]
  gridTemplate?: string
}
/**
 * NumberDisplayList
 * @param header header to display above the entire list of NumberDisplay components.
 * @param headers array of headers to display one header above every single NumberDisplay component.
 * @param values array of values to be displayed.
 * @param units array of units - defines the amount of NumberDisplay components to be displayed in the list.
 * @returns a nice way to display a list of values/numbers. 
 */
export function NumberDisplayList ({ header, headers = [], values = [], units, gridTemplate }: NumberDisplayListProps) {

  const _gridTemplate = useMemo(() => gridTemplate || "'1fr 1fr' '1fr 1fr'", [gridTemplate]);

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      {header && (
        <p style={{ ...fonts.header, ...fonts.fontSize3, color: colors.greyDarkPrimary, paddingBottom: "10px" }}>
          {header}
        </p>
      )}
      <div style={{display: "grid", gridTemplateAreas: _gridTemplate, height: "100%", textAlign: "center", gap: "22px 20px" }}>
        {units.map((unit, index) => (
            <NumberDisplay
              key={index}
              header={headers[index] ?? ""} // Default header to empty string if header is not defined
              value={values[index] ?? 0} // Default value to 0 if values is not defined
              unit={unit}
            />
        ))}
      </div>
    </div>
  )
}
// ---------------------------------------------------
//#endregion
