import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';

import { ButtonList, ButtonTypes, PageHeader, SelectField } from "../../../Components";
import { frames, useMediaQuery } from "../../../Styles";
import { FieldStyle_Types } from "../../../Definitions";

import { ArchivedReport, TestData as ArchivedReportData } from "../../../Assets/TestData/reports";


export default function Report() {
  const {t} = useTranslation()
  let location = useLocation();

  const [selectedReportId, setSelectedReportId] = useState<number>();
  const [showReport, setShowReport] = useState<ArchivedReport | undefined>(undefined);
  const pageHeaderResponsive = useMediaQuery(`(max-width: 1481px)`);

  // Set the selected feed curve based on the ID
  const setFeedCurve = (id: number) => {
    var curve = ArchivedReportData.find(report => report.reportNo === id);
    setShowReport(curve);
  };

    // Get options from feedCurve Data and return the first to view if there isn't any selected from the feedCurves page.
    const reportOptions = useMemo(() => {
      const options = ArchivedReportData.map(report => ({
        label: report.name,
        id: report.reportNo
      }));
      if (!showReport && location.state?.params?.selected) {
        setFeedCurve(location.state.params.selected);
      } else if (!showReport && options.length) {
        setFeedCurve(options[0].id);
      }
      return options;
    }, [showReport, location.state]);
  
    useEffect(() => {
      if (location.state?.params?.selected) {
        setFeedCurve(location.state.params.selected);
      }
    }, [location.state]);

  // const selectedArchivedReportData = ArchivedReportData.find((report) => report.reportNo === selectedReportId)

  // useEffect(()=> {
  //   setShowReport(selectedArchivedReportData)
  // },[ArchivedReportData, selectedReportId])

  // const reportOptions = useMemo(() => {
  //   const options = ArchivedReportData.map(report => ({
  //     label: report.name,
  //     id: report.reportNo
  //   }));
  //   if (!showReport && location.state?.params?.selected) {
  //     setSelectedReportId(location.state.params.selected);
  //   } else if (!showReport && options.length) {
  //     setSelectedReportId(options[0].id);
  //   }
  //   return options;
  // }, [ArchivedReportData, showReport, location.state.params.selected]);

  // useEffect(() => {
  //   if (location.state?.params?.selected && !showReport && ArchivedReportData) {
  //     setSelectedReportId(location.state.params.selected);
  //   }
  // }, [ArchivedReportData, location.state, selectedReportId, showReport]);

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between", gap: "20px"}}>
          <div>
            <PageHeader topHeader={t("reports.report")}/>
            <SelectField 
              defaultValue={showReport?.reportNo}
              options={reportOptions}
              fieldStyle={FieldStyle_Types.darkGreyInv} 
              textStart={true}
              placeholder={t("select")+"..."}
              onChange={(e) => {
                setSelectedReportId(parseInt(String(e[0].id)));
              }}
            />
          </div>
          <ButtonList content={[t("buttons.edit")+" "+t("reports.report"), t("reports.unArchiveReport"), t("reports.saveAsTemplate")]} overrides={{button: {buttonType: pageHeaderResponsive ? ButtonTypes.quaternaryButton : ButtonTypes.tertiaryButton, disableActive: true }}}/>
        </div>
      </frames._page_inner_header>
      <frames._page_inner_content>
      
      </frames._page_inner_content>

    </frames.Page_inner>
  )
}