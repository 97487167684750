import { createSlice } from '@reduxjs/toolkit'
//import { navigationRef } from '../../Navigators'
import { PersistConfig, persistReducer, REHYDRATE, PERSIST
  //FLUSH, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import i18n from 'i18next'

export enum CookieStates {
  FirstInit = 0,
  Necessary = 1,
  All = 2
}

export interface ProgramStates {
  CookieLevel: number,
  Language: {label:string, id:string}|undefined,
  IsQuickApp: boolean
  User: string|undefined
}

//const ProgramAdapter = createEntityAdapter()
const initialState:ProgramStates = {
  CookieLevel: 0,
  Language: undefined,
  IsQuickApp: false,
  User: undefined
}

const ProgramSlice = createSlice({
  name: 'Program',
  initialState,
  reducers: {
    setCookieState: (state, action) => {
      if (action.payload && action.payload !== undefined && action.payload !== null) {
        state.CookieLevel = action.payload
      }
    },
    setLanguage: (state, action) => {
      if (action.payload && action.payload !== undefined && action.payload !== null) {
        state.Language = action.payload
      }
      if (state.Language) {
        i18n.changeLanguage(state.Language.id)
      }
    },
    setIsQuickApp: (state, action) => {
      if (action.payload && action.payload.data !== undefined && action.payload.data !== null) {
        state.IsQuickApp = action.payload.data
      }
    },
    setUser: (state, action) => {
      if (action.payload && action.payload.data !== null) {
        state.User = action.payload.data
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      if (action) {
        var {key, payload} = action as {key?:string, payload?:ProgramStates}
        if (key && payload && key === ProgramSlice.name) {
          if (payload.Language) {
            i18n.changeLanguage(payload.Language.id)
          }
        }
      }
    })
  }
})

const ProgramPersistConfig:PersistConfig<ProgramStates, any, any, any> = {
  key: ProgramSlice.name,
  storage: storage,
  blacklist: [],
  //whitelist: ['firstRun', 'CookieLevel', 'Language']
}

const { actions, reducer } = ProgramSlice
const presistProgram = persistReducer(ProgramPersistConfig, reducer)
export const { setCookieState, setLanguage, setIsQuickApp } = actions
export default presistProgram
