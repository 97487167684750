import { css, frames, icons } from "../../Styles/index"
import { useTranslation } from "react-i18next"
import "../../Translations/index"
import { ButtonList } from "../../Components"
import { NavigationFunction, Pages } from "../Routers/CloudPanel/globalMenu_Router"
import { useLocation, useNavigate } from "react-router"
import "../../Components/languageSelector"
import { StyleObject } from "styletron-react"
import { useMemo } from "react"
import { ButtonTypes } from "../../Components/BaseComponents/button"

interface GlobalMenu_Props {
  overrides?:{
      gridArea?: string
  }
}

export default function GlobalMenu({overrides}:GlobalMenu_Props) {
  const navigator = useNavigate()
  const {t} = useTranslation()
  
  const BtnFunc_Router = (btn_Index:number) => {
    switch (btn_Index) {
      case 0: // Navigate Def
        NavigationFunction(navigator, Pages.dashboard)
        break
      case 1: // Navigate Def
        NavigationFunction(navigator, Pages.kpi)
        break
      case 2:
        NavigationFunction(navigator, Pages.feedCurves)
        break
      case 3:
        NavigationFunction(navigator, Pages.silos)
        break
      case 4:
        NavigationFunction(navigator, Pages.animals)
        break
      case 5:
        NavigationFunction(navigator, Pages.reports)
        break
      case 6:
        NavigationFunction(navigator, Pages.logbook)
        break
      case 7:
        NavigationFunction(navigator, Pages.globalSettings)
        break
      default:
        alert("Error ???")
    }
  }

  const _Root = useMemo<StyleObject>(() => {
    return Object.assign(
        {} as StyleObject, //default stlye
        overrides&&overrides.gridArea? {gridArea:overrides.gridArea} : {}
    )
  },[overrides?.gridArea])

  return (
    <frames.GlobalMenu $style={_Root}>
      <ButtonList content={[t("globalMenu.dashboard"), t("globalMenu.kpi"), t("globalMenu.feedCurves"), t("globalMenu.silos"), t("globalMenu.animals"), t("globalMenu.reports"), t("globalMenu.logBook"), t("globalMenu.globalSettings")]}
        onClick={(e) => BtnFunc_Router(e)}
        startEnhancers={[
          {def:icons.dashboard, active:icons.dashboardInv}, 
          {def:icons.kpi, active:icons.kpiInv}, 
          {def:icons.feedCurves, active:icons.FeedCurvesInv}, 
          {def:icons.silos, active:icons.silosInv}, 
          {def:icons.animals, active:icons.animalsInv}, 
          {def:icons.reports, active:icons.reportsInv}, 
          {def:icons.logBook, active:icons.logBookInv}, 
          {def:icons.globalSettings, active:icons.globalSettingsInv}
        ]}
        overrides={{
          disabled: [0,3,6,7],
          button: {
            buttonType: ButtonTypes.globalMenuButton,
          },
          useNavigationControl: "global_index"
        }}
        listStyle="group"
      />
    </frames.GlobalMenu>
  )

}
