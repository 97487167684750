import { CSSProperties } from "react"
import { colors } from "../Styles"

export enum FieldStyle_Types {
    tableEdit = "tableEdit",
    darkGrey = "darkGrey",
    darkGreyInv = "darkGreyInv",
    lightGrey = "lightGrey",
    blue = "blue",
    popoverDropdown = "popoverDropdown"
  }
  
export const CreateFieldStyle = (fieldStyle?: FieldStyle_Types|string) => {
  let color = ""
  let colorHover = ""
  let dropdownColor = ""
  let scrollbarColor = ""

  switch (fieldStyle) {
    case 'tableEdit':
      color = colors.greyTertiary
      dropdownColor = colors.greyTertiary
      colorHover = colors.greySecondary
    break
    case 'darkGrey':
      color = colors.greyDarkSecondary
      dropdownColor = colors.greyDarkSecondary
      colorHover = colors.greyDarkPrimary
    break
    case 'darkGreyInv':
      color = colors.greyDarkPrimary
      dropdownColor = colors.greyDarkPrimary
      colorHover = colors.greyDarkSecondary
    break
    case 'lightGrey':
      color = colors.greySecondary
      dropdownColor = colors.greySecondary
      colorHover = colors.greyPrimary
    break
    case 'popoverDropdown':
      color = colors.blueDarkSecondary
      dropdownColor = colors.greyDarkPrimary
      colorHover = colors.greyDarkSecondary
      scrollbarColor = colors.greyDarkSecondary
    break
    case 'blue':
    default:
      color = colors.blueDarkSecondary
      dropdownColor = colors.blueDarkSecondary
      colorHover = colors.blueDarkPrimary
      scrollbarColor = colors.blueDarkPrimary
    break
  }
  const BgColor: CSSProperties = { backgroundColor: color }
  const BgColorHover: CSSProperties = { backgroundColor: colorHover }
  const BorderColor: CSSProperties = { borderColor: colorHover}
  const BgDropdownColor: CSSProperties = { backgroundColor: dropdownColor }
  const SbColor: CSSProperties = { scrollbarColor: scrollbarColor + colors.transparent }

  return { BgColor, BgColorHover, BorderColor, BgDropdownColor, SbColor }
}

// Eksempel på brug (Maja) - se evt. select.tsx og input.tsx
//
// Import af definition:
// import CreateFieldStyle, { FieldStyle_Types } from "../../Definitions/fieldStyles"
// 
// Indsæt under props for det pågældende component:
// fieldStyle?: FieldStyle_Types | string
//
// oprettelse af const på det enkelte component inkl hover style:
// const { BgColor, BgColorHover, BorderColor } = useMemo(() => {
//   var _Style = CreateFieldStyle(fieldStyle)
//   return _Style
// }, [fieldStyle])
//
// Kald på de forskellige componenter, hvor stylingen ønskes:
// Root: { style: () => Object.assign({}, {
//   ...BgColor, 
//   width: "280px"
// } as React.CSSProperties )},