import React from 'react';
import { frames } from '../../../Styles';
import PageHeader from '../../../Components/BaseComponents/pageHeader';
import { useTranslation } from 'react-i18next';

export default function SilosSettings() {
  const {t} = useTranslation()
  
  return (
    <frames.Page_inner>
    <frames._page_inner_header>
      <PageHeader topHeader={t("globalMenu.silos")} subHeader={t("globalMenu.siloSettings")}/>
    </frames._page_inner_header>
    <frames._page_inner_content>
    </frames._page_inner_content>
    </frames.Page_inner>
  );
}