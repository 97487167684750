import react, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { Button, ButtonTypes } from "../Components";
import { colors, css, fonts, icons } from "../Styles";


interface PopupModal_Props {
  isModalOpen?:boolean,
  modalHeader:string,
  modalSubHeader?:string,
  modalContent?:JSX.Element,
  children?:JSX.Element,
  footerText?:React.ReactNode,
  
  openBtn?:string,
  openBtnIcon?:string,
  onOpen?: () => void,
  goBackBtn?:string,
  onGoBack?: () => void,
  onCancelBtn?:string,
  onCancel?: () => void,
  onCloseBtn?:string,
  onClose?: () => void,

  overrides?:{
    root?: CSSProperties,
    isPrimaryComponent?:boolean
    background?: string
  }
} 

export default function PopupModal({ isModalOpen, modalHeader, modalSubHeader, modalContent, children, footerText, goBackBtn, onCancelBtn, openBtn, openBtnIcon, onCloseBtn, onCancel, onGoBack, onOpen, onClose, overrides }:PopupModal_Props) {
  const [isOpen, setIsOpen] = useState<boolean>(isModalOpen!==undefined?isModalOpen:true)
  const [overflow, setOverflow] = useState<string>("auto");
  const modalBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isModalOpen!==undefined) setIsOpen((e) => e!==isModalOpen?isModalOpen:e)
  }, [isModalOpen])

  useEffect(() => {
    const handleHeight = () => {
      if (modalBodyRef.current) {
        const modalHeight = modalBodyRef.current.getBoundingClientRect().height;
        const maxHeight = window.innerHeight * 0.7; // 70vh
        setOverflow(modalHeight >= maxHeight ? "scroll" : "auto");
      }
    };
    const handleWidth = () => {
      if (modalBodyRef.current) {
        const modalWidth = modalBodyRef.current.getBoundingClientRect().width;
        const maxWidth = window.innerWidth * 0.7; // 70vh
        setOverflow(modalWidth >= maxWidth ? "scroll" : "auto");
      }
    };

    const handleResize = () => {
      handleHeight()
      handleWidth()
    };
    window.addEventListener("resize", handleResize); // Re-check on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen, modalContent]);

  function open() {
    if (onOpen) onOpen()
    if (isModalOpen!==undefined) setIsOpen(true)
  }

  function cancel() {
    if (onCancel) onCancel()
    if (isModalOpen!==undefined) setIsOpen(false)
  }

  function close() {
    if (onClose) onClose()
    if (isModalOpen!==undefined) setIsOpen(false)
  }

  function goBack() {
    if (onGoBack) onGoBack()
  }

  const isPrimary = overrides?.isPrimaryComponent;
  const _root = useMemo<CSSProperties>(() => {
    return Object.assign({}, isPrimary ? {...css.modal, ...css.modal_primary} : {...css.modal, ...css.modal_secondary}, overrides?.root || {});
  }, [isPrimary, overrides?.root])

  // const modalBodyBackground = overrides?.background || css.modalInner.background;

  const modalBodyBackground = overrides?.background || colors.blueDarkPrimary;

  const modalInner = isPrimary? {...css.modalInner_primary} : {...css.modalInner_secondary, overflow}

  return (
    <>
    {/* if isModalOpen=false (modal does not automatically open on page load) then create a button with "open" function */}
      {!openBtn?(<></>):(
        <Button 
          content={openBtn}
          overrides={{
            buttonType: ButtonTypes.textButton,
          }} 
          onClick={open}
          startEnhancer={openBtnIcon}
        />
      )}
      <Modal isOpen={isOpen}
        onClose={cancel}
        closeable={false}
        overrides={{
          Dialog: {
            style: () => Object.assign({}, {
              ..._root,
            } as React.CSSProperties)
          }
        }}
      > 
        <div style={{position: "absolute", left: "10px", top: "10px"}}>
        {/* Go back to former modal in flow - e.g. "onGoBack={() => { setNewState(Auth_States.Login) }}" */}
          {!goBackBtn?(<></>):(
            <Button 
              content={goBackBtn}
              startEnhancer={icons.goLeftArrow}
              overrides={{
                buttonType: ButtonTypes.textButton, 
              }} 
              onClick={goBack}/>
          )}
        </div>
        <div style={{position: "absolute", right: "10px", top: "10px"}}>
        {/* Go back to former modal in flow - e.g. "onGoBack={() => { setNewState(Auth_States.Login) }}" */}
          {!onCloseBtn?(<></>):(
            <Button 
              content=""
              startEnhancer={icons.close}
              overrides={{
                buttonType: ButtonTypes.textButton, 
              }} 
              onClick={close}
            />
          )}
        </div>
      
        <ModalHeader style={{ margin: "0", padding: "0", height: "fit-content", position: "sticky" }}>        
          <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize0, color: colors.greyLightTertiary, textAlign: "center" }}>{modalHeader}</p>
          <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize3, color: colors.greyLightTertiary, textAlign: "center", marginBottom: "30px" }}>{modalSubHeader}</p>
          <div style={{ marginTop: "10px", marginBottom: "30px" }}>{modalContent}</div>
        </ModalHeader>

        <ModalBody style={{ ...modalInner, background: modalBodyBackground, maxWidth: "70vw" }}>
          {children}
        </ModalBody>

        {footerText || onCancelBtn?
        <ModalFooter style={{ margin: "0", padding: "0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "5px", marginTop: "30px"}}>
          {!footerText?(null):(
            <p style={{ ...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary, textAlign: "center" }}>{footerText}</p>
          )}
          {!onCancelBtn?(null):(
            <Button 
              content={onCancelBtn}
              overrides={{
                buttonType: ButtonTypes.textButtonUnderlined,
              }} 
              onClick={cancel}/>
          )}
        </ModalFooter>
        : null
        }
        
      </Modal>
    </>
  );
}