import { LangFile } from './'
const Texts:LangFile = {
    noData: "No Data",
    homepagetitle: "Home Page",
    desktopPlatform: "Desktop Platform",
    operationsPlatform: "Operations Platform",
    required: "Required",
    optional: "Optional",
    overview: "Overview",
    default: "Default",
    select: "Select",
    action: "Action",
    changes: "Changes",
    unknown: "Uknown",
    search: "Search",
    filterBy: "Filter By",
    yes: "Yes",
    no: "No",
    from: "From",
    to: "To",
    settings: "Settings",
    
    languages: {
        en: "English",
        dk: "Dansk",
        es: "Español",
        de: "Deutsch",
        ma: "Mandarin"
    },

    user: {
        userName: "User Name",
        userInformations: "User Informations",
        userId: "User-ID",
        firstName: "First Name",    
        lastName: "Last Name",
        phone: "Phone",
        email: "Email",
        password: "Password",
        repeatPassword: "Repeat Password",
    },
    
    buttons: {
        goBackBtn: "Go Back",
        createBtn: "Create",
        cancelBtn: "Cancel",
        saveBtn: "Save",
        logOutBtn: "Logout",
        loginbtn: "Login",
        quickEntry: "Quick Entry",
        next: "Next",
        prev: "Previous",
        goToSiteList: "Go to Site List",
        details: "Details",
        edit: "Edit",
        add: "Add",
        view: "View",
        reset: "Reset",
        move: "Move",
        skip: "Skip",
        plan: "Plan",
        forNow: "For Now",
        startFeeding: "Start Feeding",
        standard: "Standard"
    },

    cookie:{ 
        header:"Cookies",
        context:`Agrisys A/s use cookies for the following purposes:\n`+
        `1* To support the website's functionality, for example by remembering your settings regarding cookies, preferred languages, currencies, etc.\n`+
        `2* To perform statistical analysis of your use of the website, for example by measuring what type of content you are looking at and clicking on.\n`+
        `\n`+
        `By pressing “Accept all” you accept our use of cookies.\n`+
        `Else a minimun of cookies will be used required by the service to function, for example to manage login, and data fetching from the consys platfrom.`,
        accept:"Accept necessary",
        acceptAll:"Accept All"
    },

    loginFlow: {
        login: {
            header: "Welcome Back!",
            footerText: "Not registered yet?",
            create: "Create New User",
            error: "Failed to login"
        },
        createUser: {
            header: "New User",
            error: "Failed to create user"
        },
        chooseSite: {
            header: "Choose Site",
            column1: "Farm Name",
            column2: "Manager",
            column3: "Column 3",
            column4: "Column 4"
        }
    },

    globalMenu: {
        home: "Home",
        dashboard: "Dashboard",
        kpi: "KPI",
        feedCurves: "Feed Curves",
        ComponentAlternatives: "Component Alternatives",
        mixtures: "Mixtures",
        silos: "Silos",
        siloSettings: "Silo Settings",
        animals: "Animals",
        animalGroups: "Animal Groups",
        reports: "Reports",
        logBook: "Logbook",
        globalSettings: "Settings"
    },
    global_subMenu: {
        animals:{
            animalsEdit: "Animals Edit"
        },
    },
    systemMenu: {
      
    },
    system_subMenu: {
      some:{

      },
    },

    toolsMenu: {
        tools: "Tools",
        graphQl: "Graph Ql",
        xml: "xml",
        farms: "Farms",
        users: "Users"
    },
    tools_subMenu: {
        graphQl: {
            configurations: "Configurations",
            software: "Software"
        }
    },
    locationFeeder: {
        sections: {
            section: "Section",
            sections: "Sections",
            to: "To",
        },
        sectionsPopup: {
            feedCorrections: "Current Feed Corrections",
        },
        penPopup: {
            pen: "Pen",
            activations: "Activations",
            lastActivation: "Last Activation",
            activator: "Activator",
            feeder: "Feeder",
            motor: "Motor",
            lastDays: "Last 10 days",
            inThisPen: "In This Pen",
            emptyPen: "Empty Pen",
            empty: "Empty",
            penValueHeaders: "Hold the mouse on a value to see column type"
        },
        planSkip: {
            planSkip: "Plan Skip",
            plannedSkip: "Planned Skip",
            timeSpan: "Time Span",
            timeAmount: "Time Amount",
            startDate: "Start Date",
            endDate: "End Date",
            startTime: "Start Time",
            endTime: "End Time"
        },
        overView: {
            systemControls: "System Controls",
            start: "Start",
            calibrate: "Calibrate",
            systemOn: "Turn On",
            systemOff: "Turn Off",
            emptySystem: "Empty"
        }
    },
    progressComponents: {
        inThisSystem: "In This System",
        animalsNo: "Number of Animals",
        currentProgress: "Current Progress"
    },
    feed: {
        feedPlan: {
            feedPlan: "Feed Plan",
            feedCurve: "Feed Curve",
            components: "Components",
            milestones: "Milestones",
            name: "Name",
            readyComponent: "Ready",
            priority: "Priority",
            ds: "DS",
            energy: "Energy",
            protein: "Protein"
        },
        feedCorrections: {
            feedCorrection: "Feed Correction",
            increased: "Increased",
            decreased: "Decreased",
            disabled: "Disabled",
            active: "Active",
            skipFeeding: "Skip Feeding",
            feeding: "Feeding"
        },
        remaining: {
            today: "Remaining today",
            yesterday: "Remaining Yesterday"
        },
        fed: {
            today: "Fed Today",
            period: "Fed Last Period"
        }
    },
    units: {
        total: "Total",
        kg: "KG",
        mA: "mA",
        pct: "pct",
        farrowed: "Farrowed",
        minutAgo: "Minut Ago",
        minutesAgo: "Minutes Ago",
        hour: "Hour",
        hours: "Hours",
        hourAgo: "Hour Ago",
        hoursAgo: "Hours Ago",
        timesToday: "Times Today",
        timeToday: "Time Today",
        capacity: "Capacity"
    },
    attentions: {
        attentions: "Attentions",
        problem: "Problem",
        problems: "Problems",
        warning: "Warning",
        warnings: "Warnings",
        systemAlert: "System Alert",
        systemGood: "System Running",
        systemNeutral: "System Off",
        systemWarning: "System Warning",
        goToSystem: "Go To System"
    },
    animal: {
        animal: "Animal",
        animalNo: "Animal Number",
        latestMilestone: "Last detected milestone",
        milestoneDay: "Days since last milestone",
        responderNo: "Responder Number",
        location: "Location",
        lifeNo: "Life Number",
        condition: "Condition",
        group: "Group",
        animalType: "Animal Type",
        totalCycleDay: "Total Cycle Day",
        newAnimal: "New Animal",
        noAnimal: "No Animals Selected"
    },
    reports: {
      report: "Report",
      farrowing: "Farrowing",
      farrowingsToday: "Farrowings Today",
      farrowed: "Animals Farrowed",
      template: "Template",
      addTemplate: "Add Template",
      copyTemplate: "Copy Template",
      newTemplate: "New Template",
      createNewReport: "Start a new report from scratch",
      noReportFound: "No report found",
      noDataParameterFound: "No data parameter found",
      noFilterParameterFound: "No filter parameter found",
      dataLevel: "Data Level",
      filterParameter: "Filter Parameter",
      dataParameter: "Data Parameter",
      archiveReport: "Archive Report",
      unArchiveReport: "Unarchive Report",
      saveAsTemplate: "Save as Template",
      archive: "Archive",
      archivedReport: "Archived Report",
      noStartDateFound: "No Start Date Found",
      noEndDateFound: "No End Date Found",
      reportName: "Report Name",
      newReport: "New Report",
      fromTemplate: "From Template",
      showInReport: "Show in Report"
    }
}
export default Texts