import React from 'react';
import { useTranslation } from 'react-i18next';
import { frames } from '../../Styles';
import PageHeader from '../../Components/BaseComponents/pageHeader';

export default function GlobalSettings() {
  const {t} = useTranslation()

  return (
    <frames.Page_inner>
    <frames._page_inner_header>
      <PageHeader topHeader={t("globalMenu.globalSettings")} subHeader={t("overview")}/>
    </frames._page_inner_header>
    <frames._page_inner_content>
    </frames._page_inner_content>
    </frames.Page_inner>
  );
}