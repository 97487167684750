//import React from "react";
import { NavigateFunction, RouteObject } from "react-router-dom";
import { Dashboard, Kpi, FeedCurves, Components,
  ComponentAlternatives, Mixtures, FeedCurve, Silos, SilosSettings, 
  Animals, AnimalsEdit, AnimalsGroups, AnimalsById, Reports, Report, Archive, Logbook, GlobalSettings } from "../../../Containers/index";
import TabMenu from "../../Menus/tabMenu";

/* eslint-disable */
export enum Pages {
  home="./",

  dashboard = "dashboard",

  kpi = "kpi",

  feedCurves = "feedCurves",
  components = "components",
  componentsAlternatives = "component alternatives",
  mixtures = "mixtures",
  feedCurve = "feedCurve",

  silos = "silos",
  silosSettings = "silo settings",

  animals = "animals",
  animalsEdit = "animalsedit",
  animalsGroups = "animal groups",
  animalsById = "animalsbyid",

  reports="reports",
  report="report",
  archive="archive",

  logbook="logbook",
  
  globalSettings="globalsettings",
}
/* eslint-enable */

export const Router = [{
  element: null, 
  children:[ //...GlobalMenuRouter,
    { index:true, path:`/${Pages.dashboard}`, element:<Dashboard/> }, //TODO: if memory dashboard as default.. index
    { path:`/${Pages.kpi}`, element:<TabMenu key={Pages.kpi} main={Pages.kpi} overrides={{ base_alternative: Pages.home }} contexts={[]}/>, 
      children:[
        {index: true, path:`/${Pages.kpi}/*`, element:<Kpi/>}
      ]
    }, 
    // { path:`/${Pages.dashboard}`, element:<TabMenu key={Pages.dashboard} main={Pages.dashboard} overrides={{ base_alternative: Pages.home }} contexts={[]}/>, 
    //   children:[
    //     {index: true, path:`/${Pages.dashboard}/*`, element:<Dashboard/>}
    //   ]
    // },  
    { path:`/${Pages.feedCurves}`, element:<TabMenu key={Pages.feedCurves} main={Pages.feedCurves} overrides={{base_alternative:Pages.home}} contexts={[Pages.components,Pages.componentsAlternatives,Pages.mixtures,Pages.feedCurve]} />,
      children:[
        { index:true, path:`/${Pages.feedCurves}/*`, element:<FeedCurves/>},
        { path:`/${Pages.feedCurves}/${Pages.components}`, element:<Components/>},
        { path:`/${Pages.feedCurves}/${Pages.componentsAlternatives}`, element:<ComponentAlternatives/>},
        { path:`/${Pages.feedCurves}/${Pages.mixtures}`, element:<Mixtures/>},
        { path:`/${Pages.feedCurves}/${Pages.feedCurve}`, element:<FeedCurve/>},
      ]
    },
    { path:`/${Pages.silos}`, element:<TabMenu key={Pages.silos} main={Pages.silos} overrides={{base_alternative:Pages.home}} contexts={[Pages.silosSettings]} />,
      children:[
        { index:true, path:`/${Pages.silos}/*`, element:<Silos/>},
        { path:`/${Pages.silos}/${Pages.silosSettings}`, element:<SilosSettings/>},
      ]
    },
    { path:`/${Pages.animals}`, element:<TabMenu key={Pages.animals} main={Pages.animals} overrides={{base_alternative:Pages.home}} contexts={[Pages.animalsGroups]} />,
      children:[
        { index:true, path:`/${Pages.animals}/*`, element:<Animals/>},
        { path:`/${Pages.animals}/${Pages.animalsGroups}`, element:<AnimalsGroups/>},
        { path:`/${Pages.animals}/${Pages.animalsEdit}`, element:<AnimalsEdit/>},
        { path:`/${Pages.animals}/${Pages.animalsById}`, element:<AnimalsById/>},
      ]
    },
    { path:`/${Pages.reports}`, element:<TabMenu key={Pages.reports} main={Pages.reports} overrides={{base_alternative:Pages.home}} contexts={[Pages.report, Pages.archive]} />,
      children:[
        { index:true, path:`/${Pages.reports}/*`, element:<Reports/>},
        { path:`/${Pages.reports}/${Pages.report}`, element:<Report/>},
        { path:`/${Pages.reports}/${Pages.archive}`, element:<Archive/>},
      ]
    },
    { path:`/${Pages.logbook}`, element:<TabMenu key={Pages.logbook} main={Pages.logbook} overrides={{base_alternative:Pages.home}} contexts={[]} />,
      children:[
        {index: true, path:`/${Pages.logbook}/*`, element:<Logbook/>}
      ]
    },
    { path:`/${Pages.globalSettings}`, element:<TabMenu key={Pages.globalSettings} main={Pages.globalSettings} overrides={{base_alternative:Pages.home}} contexts={[]} />,
      children:[
        {index: true, path:`/${Pages.globalSettings}/*`, element:<GlobalSettings/>}
      ] 
    }
  ]
}] as RouteObject[]

export const NavigationFunction = (Navigator: NavigateFunction, navigation_page:Pages, navigation_sub?:Pages, navArgs?:object) => {
  switch (navigation_page) { 
    case Pages.home:
      Navigator(Pages.home, {state:{global_index:1}})
    break
    case Pages.dashboard:
      Navigator(Pages.dashboard, {state:{global_index:0, tab: 0}})
    break
    case Pages.kpi:
      Navigator("../"+Pages.kpi, {state:{global_index:1, tab: 0}})
    break
    case Pages.feedCurves:
      switch (navigation_sub) {
        case Pages.components:
          Navigator(Pages.components, {state:{global_index:2, tab:1}})
        break
        case Pages.componentsAlternatives:
          Navigator(Pages.componentsAlternatives, {state:{global_index:2, tab:2}})
        break
        case Pages.mixtures:
          Navigator(Pages.mixtures, {state:{global_index:2, tab:3}})
        break
        case Pages.feedCurve:
          Navigator(Pages.feedCurve, {state:{global_index:2, tab:4, params:{...navArgs}}})
        break
        default:
          Navigator(Pages.feedCurves, {state:{global_index:2, tab:0}})
      }
    break
    case Pages.silos:
      switch (navigation_sub) {
        case Pages.silosSettings:
            Navigator(Pages.silosSettings, {state:{global_index:3, tab:1}})
          break
        default:
          Navigator(Pages.silos, {state:{global_index:3, tab:0}})
      }    
    break
    case Pages.animals:
      switch (navigation_sub) {
        case Pages.animalsGroups:
          Navigator(Pages.animalsGroups, {state:{global_index:4, tab:1}})
        break
        case Pages.animalsEdit:
          Navigator(Pages.animalsEdit, {state:{global_index:4}})
          break
        case Pages.animalsById:
          Navigator(Pages.animalsById, {state:{global_index:4}})
          break
        default:
          Navigator(Pages.animals, {state:{global_index:4, tab:0}})
      }
    break
    case Pages.reports:
      switch (navigation_sub) {
        case Pages.report:
          Navigator(Pages.report, {state:{global_index:5, tab:1}})
        break
        case Pages.archive:
          Navigator(Pages.archive, {state:{global_index:5, tab:2}})
        break
        default:
          Navigator(Pages.reports, {state:{global_index:5, tab:0}})
      }
    break
    case Pages.logbook:
      Navigator(Pages.logbook, {state:{global_index:6, tab: 0}})
    break
    case Pages.globalSettings:
      Navigator(Pages.globalSettings, {state:{global_index:7, tab: 0}})
    break
    default:
      console.log("Error page not found (404)???, [GlobalMenu]")
  }
}