import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors, fonts, icons } from "../../../Styles";
import { CheckBox, InputField, SelectField } from "../..";
import { Field_Types, FieldStyle_Types } from "../../../Definitions";
import { Value } from "baseui/select";

import { allFilterParameterTestData as filterParameterTypes, FilterDisplayType, FilterDisplayEnum, ReportTemplate } from "../../../Assets/TestData/reportTemplates";

interface Lvl2_Props {
  report?: ReportTemplate
}

interface field {
  id?: number,
  value?: number
}

export default function ReportModalLvl2({report}:Lvl2_Props) {
  const {t} = useTranslation()

  const [selectFields, setSelectFields] = useState<field[]>([]);

  useEffect(() => {
    const filterParameters = report?.filterParameter.flatMap((parameter) => (Object.entries(parameter)
      .map(([filterLabelKey, value]) => {
        const id = filterParameterTypes.find((filterType) => (filterType.label === filterLabelKey))?.id
        return {
          id,
          value,
        }
      }))) || [];
    if (filterParameters) {
      setSelectFields([...filterParameters,{id:-1}])
    }
  },[report?.filterParameter])
  
  // SelectField Options
  const filterParamOptions = filterParameterTypes.map((type) => ({label: t(`report.filterParameter.${type.label}`, {defaultValue: type.label}), id: type.id}));
  // Handle onChange on SelectField
  const handleFilterTypeChange = (index: number, value: Value) => {
    const updatedSelectFields = [...selectFields];
    if (value.length > 0) {

      if (updatedSelectFields[index].id === -1) {
        updatedSelectFields.push({id: -1})
      }
      updatedSelectFields[index] = {id: value[0].id ? +value[0].id : 0, value: undefined};
    } else {
      updatedSelectFields[index].id = undefined;
    }
    setSelectFields(updatedSelectFields);
  };

  const handleFilterValueChange = (index?: number, value?: Value) => {
    const updatedSelectFields = [...selectFields];
    if (index && value && value.length > 0) {
      updatedSelectFields[index].value = value[0].id ? +value[0].id : 0;
    }
    setSelectFields(updatedSelectFields);
  }

  // Function to remove a SelectField by index
  const handleRemoveField = useCallback((index: number) => {
    const oldSelectedFields = [...selectFields];
    oldSelectedFields.splice(index, 1)
    setSelectFields(oldSelectedFields)
  },[selectFields]);

  const renderedFields = selectFields.map((field, index) => {return (
    <div 
      key={index} 
      style={{ 
        width: "100%", 
        display: "flex", 
        flexDirection: "column", 
        gap: "10px", 
        marginBottom: index === selectFields.length - 1 ? "0px" : "20px"
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", justifyContent: "start" }}>
        <SelectField
          placeholder={t("select")+"..."}
          key={index+String(field.id)}
          textStart={true} 
          options={filterParamOptions}
          onChange={(value) => handleFilterTypeChange(index, value)}
          defaultValue={filterParamOptions.find((parameter) => {
            return parameter.id === field.id
          })?.label}
        />
        {field.id !== -1 && (
          <>
            <CheckBox text={t("reports.showInReport")}/>
            <img 
              src={icons.delete_white} 
              style={{ width: "25px", cursor: "pointer" }} 
              onClick={() => handleRemoveField(index)}
              alt="Remove filter condition"
            />
          </>
        )}
      </div>
      {/* Content based on selected filter parameter */}
      {field.id !== -1 && (
        <div style={{ width: "calc(100% - 10px)", height: "fit-content", backgroundColor: colors.greyLightSecondary, padding: "10px 10px", borderRadius: "10px" }}>
          {<FilterContent filter={field} index={index} filterParameterTypes={filterParameterTypes} handleFilterValueChange={handleFilterValueChange} />}
        </div>
      )}
    </div>
  )})
  // #endregion
  
  return(
        <div style={{ width: "600px" }}>
          <div style={{
            borderTop: "2px solid " + colors.blueDarkSecondary,
            borderBottom: "2px solid " + colors.blueDarkSecondary,
            padding: "10px 10px",
            maxHeight: "50vh",
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "thin",
            scrollbarColor: colors.blueDarkSecondary+colors.transparent,
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            flexDirection: "column",
            gap: "10px"
          }}>
            {renderedFields}
          </div>
        </div>
  )
}

const FilterContent = (props: {filter: field, index?: number, filterParameterTypes?: FilterDisplayType[], handleFilterValueChange: (index?: number, value?: Value) => void}) => {
  const {t} = useTranslation()
  const {filter, index, filterParameterTypes, handleFilterValueChange} = props;
  const filterParameter = filterParameterTypes?.find((filterParameterType) => filterParameterType.id === filter.id)
  if (!filterParameter?.displayType) {
    return null
  }
  switch (filterParameter.displayType) {
    case FilterDisplayEnum.select:
    return <>
      <div>
        <SelectField placeholder={t("select")+"..."} onChange={(e:Value) => handleFilterValueChange(index, e)} defaultValue={filterParameter.options?.find((option) => option.id === filter.value)?.label} fieldStyle={FieldStyle_Types.tableEdit} fieldType={Field_Types.secondaryField} textStart={true} options={filterParameter.options}/>
      </div>
    </>;
    case FilterDisplayEnum.range: // Location
    return <>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px"}}>
        <div>
          <p style={{ ...fonts.header, ...fonts.fontSize4, color: colors.greyPrimary, padding: "0px 0px 2px 22px" }}>{t("from")}</p>
          <InputField placeholder={"0..."} inputType="number" fieldStyle={FieldStyle_Types.tableEdit} fieldType={Field_Types.shortInputFieldSecondary} textStart={true}/>
        </div>
        <div>
          <p style={{ ...fonts.header, ...fonts.fontSize4, color: colors.greyPrimary, padding: "0px 0px 2px 22px" }}>{t("to")}</p>
          <InputField placeholder={"9999..."} inputType="number" fieldStyle={FieldStyle_Types.tableEdit} fieldType={Field_Types.shortInputFieldSecondary} textStart={true}/>
        </div>
      </div>
    </>;
    default:
    return null;
  }
};