import react, { useEffect, useRef } from 'react'
import { useNavigate } from "react-router"
import { useUser } from "../Services"
import { User_Data } from '../Services/Agrisys_GqlDataCaller/UserCalls/GQLCalls/getCalls'
import { useTranslation } from "react-i18next"
import { Button, ButtonTypes, Modal, InputFieldList, InputFieldList_PropHandle } from "../Components"
import { colors, icons, frames, fonts } from "../Styles"

interface UserInformations_Props {
  isLoginFlow?: boolean
}

export default function UserInformations() {
  const {t} = useTranslation()
  const navigator = useNavigate()

  const inputRef=useRef<InputFieldList_PropHandle>(null)
  const { userToken, getUserData, userData, updateUserData } = useUser() 

  useEffect(() => {
    if (userToken) getUserData(userToken)
  },[])

  useEffect(()=> {
    if (userData !== undefined) {
      inputRef.current?.refs.current[0].setContext(userData.email)
      inputRef.current?.refs.current[1].setContext(userData.firstname)
      inputRef.current?.refs.current[2].setContext(userData.lastname)
      inputRef.current?.refs.current[3].setContext(userData.phone)
    }
  }, [userData])

  const UpdateData = () => {
    var NewData = {
      email: inputRef.current?.refs.current[0].context,
      firstname: inputRef.current?.refs.current[1].context,
      lastname: inputRef.current?.refs.current[2].context,
      phone: inputRef.current?.refs.current[3].context,
      country:"",
      address:""
    } as User_Data
    updateUserData(NewData.firstname, NewData.lastname, NewData.phone, NewData.email, NewData.country, NewData.address)
  }
  return (
    <frames.DecorBg>
      <Modal
        modalHeader={t("user.userInformations")}
        overrides={{ 
          isPrimaryComponent: true,
        }}
        // onCancel={() => navigator(-1)}
        goBackBtn={t("buttons.goBackBtn")}
        onGoBack={() => navigator(-1)}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
          {/* TODO: Insert User ID based on the session user */}
          <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 5px 22px" }}>{t("user.userId")+":"}</p>
          <InputFieldList ref={inputRef}
            startEnhancers={[icons.user, icons.user, icons.mail, icons.phone]}
            placeholder={[t("user.lastName")+"...", t("user.firstName")+"...", t("user.email")+"...", t("user.phone")+"..."]}
            inputTypes={["text", "text", "email", "tel"]}
            isRequired={[false, false, false, false]}
            listStyle="vertical"
          />

          <Button content={t("buttons.saveBtn")} 
            // TODO: onClick={save}
            overrides={{
              buttonType: ButtonTypes.secondaryButton,
              disableActive: true,
              disableHover: true
            }}
            onClick={() => UpdateData()}
          />
        </div>
      </Modal>
      </frames.DecorBg>
  );
}