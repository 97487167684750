import { CSSProperties, JSX } from "react";
import { HeaderContext, SortDirection } from "@tanstack/react-table";
import { StyledHeadCell } from "baseui/table-grid";

import ArrowUpSquare from "../../../Assets/Icons/SVG/ArrowUpSquare";
import ArrowDownSquare from "../../../Assets/Icons/SVG/ArrowDownSquare";
import { colors, getGraphColor } from "../../../Styles/colors";
import { css, fonts } from "../../../Styles";
import { IdentifiableRow } from "../reactiveTableRenderer";
import { getFrozenColumnStyle } from "../_Tables/getTableStyles";

interface HeadCellProps {
  style: CSSProperties,
  cellValue: string|JSX.Element,
  onClick?: (event: unknown) => void;
  isSorted?: false | SortDirection;
  keyPass: string;
  colorId?: number;
}

export const HeadCell = <T extends IdentifiableRow,>({style, cellValue, onClick, keyPass, isSorted, colorId, column, table}: HeaderContext<T, unknown> & HeadCellProps) => {
  const value = colorId ? <div style={{backgroundColor: getGraphColor(colorId), ...css.colorTag, ...fonts.header, ...fonts.fontSize4, color: colors.whitePrimary}}>{cellValue}</div> : cellValue;
  const combinedStyle = {
    ...style, 
    ...getFrozenColumnStyle(column.getIsPinned(), column.getIsLastColumn("left"), column.getIsFirstColumn("right"), column.getIndex(), table.getVisibleFlatColumns().length, true),
    position: "sticky",
    cursor: onClick ? "pointer" : "default"
  } as CSSProperties
  return (
    <StyledHeadCell  style={combinedStyle} onClick={onClick} key={keyPass}>
      {value}

      {isSorted && (
        isSorted === "asc" ? <ArrowUpSquare color={colors.whitePrimary} width="24px" height="24px"/> : <ArrowDownSquare color={colors.whitePrimary} width="24px" height="24px"/> )}

    </StyledHeadCell>
  )
}