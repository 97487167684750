import { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors, fonts } from "../../../Styles";
import { CheckBox, CheckStyles, DateField, InputField, SelectField } from "../..";

import { DataLevels, ReportTemplate } from "../../../Assets/TestData/reportTemplates";

interface Lvl1_Props {
  report?: ReportTemplate
  template?: boolean
}

export default function ReportModalLvl1({report, template}:Lvl1_Props) {
  const {t} = useTranslation()

  const [isArchived, setIsArchived] = useState<number>(0); // State for archive option
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [selectedDataLevel, setSelectedDataLevel] = useState<string | undefined>(report?.dataLevel); // State for data level


  const archiveOptions = [    
    { label: t("no"), id: 0 },
    { label: t("yes"), id: 1 },
  ]
    
  // Find label in select field options corresponding to their respective ID
  const findLabelById = (id: number, options: { label: string, id: number }[]) => {
    const option = options.find(option => option.id === id);
    return option ? option.label : "";
  };
  
  // const dataLevel: DataLevel[] = ["Animal", "Group", "Location"] 

  // #region | Components
  // NAME
  const nameField = <div>
    <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("reports.reportName")}</p>
    <InputField textStart={true}/>
  </div>

  // ARCHIVE / UNARCHIVE
  const archiveSelect = <div>
    <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("reports.archiveReport")}</p>
    <SelectField 
      textStart={true} 
      options={archiveOptions}
      defaultValue={findLabelById(isArchived, archiveOptions)}
      onChange={(e) => setIsArchived(isArchived)}
    />
  </div>

  // START DATE
  const startDateField = <div>
    <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.startDate")}</p>
    <DateField value={startDate} onChange={({ date }) => setStartDate(date)} />
  </div>

  // END DATE
  const endDateFIeld = <div>
    <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("locationFeeder.planSkip.endDate")}</p>
    <DateField value={endDate} onChange={({ date }) => setEndDate(date)} />
  </div>

  // DATA LEVEL
  const dataLevelCheck = <>
    <p style={{ ...fonts.header, ...fonts.textShadow, ...fonts.fontSize4, color: colors.greyLightTertiary, padding: "0px 0px 2px 22px" }}>{t("reports.dataLevel")}</p>
    <div style={{borderTop: "2px solid"+colors.blueDarkSecondary, borderBottom: "2px solid"+colors.blueDarkSecondary, padding: "10px 0px", maxHeight: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{width: "fit-content", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: "100px", rowGap: "10px"}}>
        {DataLevels.map((param, index) => (
          <CheckBox 
            key={index} 
            text={param} 
            checkStyle={CheckStyles.blue} 
            isChecked={param === selectedDataLevel} 
            onChange={() => setSelectedDataLevel(param)}
          />
        ))}
      </div>
    </div>
  </>
  // #endregion

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "40px"}}>
        {!template? 
          <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "15px", }}>               
              {nameField}
              {archiveSelect}
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "15px", }}>
              {startDateField}
              {endDateFIeld}
            </div>
          </>
        : <></>
        }
      </div>
      <div style={{width: "600px", marginTop: template? "-57px" : "0px"}}>
        {dataLevelCheck}
      </div>
    </>
  )
}