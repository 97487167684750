import { CellContext } from "@tanstack/react-table";
import { useState } from "react";

import { EditAbleCell, StyledTableCellProps } from "./styledTableCell";
import { icons } from "../../../Styles/images";
import { CheckBox, CheckStyles } from "../..";
import { IdentifiableRow } from "../reactiveTableRenderer";

export const CheckboxTableCell = <T extends IdentifiableRow,> ({getValue, row, table, column, canEdit, staticColumn}: CellContext<T, boolean | undefined> & EditAbleCell) => {
  const initialValue = getValue();

  const [value, setValue] = useState(initialValue);
  const key = `${row.index}-${column.id}`;
  const onBlur = () => {
    if (value !==initialValue) {
      table.options.meta?.updateData(row.original.id, column.id, value)
    }
  }


  return <StyledCheckboxCell staticColumn={staticColumn} key={key} keyPass={key+"input"} onBlur={onBlur} onChange={(e: boolean) => setValue(e)} canEdit={canEdit} cellData={{value}} />
}


const StyledCheckboxCell = ({onBlur, onChange, canEdit, cellData, keyPass, staticColumn}: StyledTableCellProps & EditAbleCell) => {
  if (canEdit) {
    if (staticColumn) { 
      return (
        <div style={{ ...cellData.style, opacity: 0.5  }} key={keyPass}>
          {String(cellData.value) ?? ''}
        </div>
      );
    } else {
      return <CheckBox disabled={cellData.static}
      isTableEditField={true}
      isChecked={cellData.value as boolean}
      onChange={onChange}
      onBlur={onBlur}
      checkStyle={CheckStyles.transparent}
      key={keyPass}
    />
    }
  }

  return cellData.value ? (
    <img key={keyPass} src={icons.check_green} style={{ width: "20px" }} alt={"Checked"} />
  ) : <div key={keyPass}/>;

}