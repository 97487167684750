import * as React from "react";
import { colors, fonts } from "../../Styles";

interface ProgressStep_Props {
  title: string
  icon: string
  opacity?: number
}

export default function ProgressStep({title, icon, opacity = 1}:ProgressStep_Props) {

  return (
    <div style={{opacity, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px"}}>
      <img src={icon} style={{width: "25px", height: "25px"}} alt={"Progress Img"}/>
      <span style={{width: "100px", height:"fit-content", padding: "0px", textAlign: "center", ...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{title}</span>
    </div>
  );
}

interface ProgressSteps_Props {
  titles: string[]
  icons: string[]
  opacityValues?: number[]
}
export function ProgressSteps({titles, icons, opacityValues = [1]}:ProgressSteps_Props) {
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "center", gap: "0px", width: "fit-content" }}>
      {titles.map((_title, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <div style={{ opacity: opacityValues[index], height: "1px", width: "30px", marginTop: "25px", backgroundColor: colors.greyLightTertiary }} />
          )}
          <ProgressStep icon={icons[index]} title={titles[index]} opacity={opacityValues[index]} />
        </React.Fragment>
      ))}
    </div>
  )
}