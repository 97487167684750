export namespace Graph_QL { //Graph_Ql interfaces and rules..
  type Connection_Domain = { domain: string}
  type Connection_Device = { gate:number, device:number }
  export type ConnectionObj = Connection_Domain & Partial<Connection_Device> //Connection_Domain | Connection_Domain & Connection_Device

  interface ConnectionExtras {
    uid?:number|string, humanTime?:boolean,
    lastdays?:number, limitdays?:number
  }
  export type ConnectionParams = (ConnectionObj & ConnectionExtras)
  export type Multi_ConnectionParams = ConnectionParams|ConnectionParams[]

  export type GQLCons = Connection | MultiConnection

  export function create(con:ConnectionParams): Connection;
  export function create(con:Multi_ConnectionParams): MultiConnection;
  export function create(con:Multi_ConnectionParams): Connection|MultiConnection {
    if (Array.isArray(con)) return new _MultiConnection(con)
    else return new _Connection(con)
  }

  export function ToArr(con?:GQLCons):MultiConnection|undefined {
    if (!con) return undefined
    if (Object.hasOwn(con, "cons")) return con as MultiConnection
    else return new _MultiConnection(con.connection as Multi_ConnectionParams)
  }
  export function StringtoObj(con:string):ConnectionObj {
    var _con = con.split('_')
    var _domain = _con[0]
    var _gate:number = parseInt(_con[1])
    var _device:number = parseInt(_con[2])
    return {domain: _domain, gate:isNaN(_gate)?undefined:_gate, device:isNaN(_device)?undefined:_device} as ConnectionObj
  }
  export function ObjToString(con:ConnectionObj):string {
    var Str = con.domain + '_' + (con.gate?con.gate:'') + '_' + (con.device?con.device:'')
    return Str
  }

  export interface Connection {
    data: ConnectionParams,
    connection: ConnectionObj,
    toString: () => string
  }
  export function newConnection(con:ConnectionParams): Connection {
    return new _Connection(con)
  }

  class _Connection {
    data:ConnectionParams
    public constructor(con:ConnectionParams) {
      this.data = con
    }

    get connection() {
      return {
        domain:this.data.domain, 
        gate:this.data.gate, 
        device:this.data.device
      } as ConnectionObj
    }
    toString() {
      return ObjToString(this.data)
    }
  }

  export interface MultiConnection {
    cons:Connection[],
    //each(callbackfn: (value: Connection, index: number, array: Connection[]) => Connection, thisArg?: any): Connection[];
    connection: (index?:number) => ConnectionObj,
    toString: (index?:number) => string
  }
  export function newMultiConnection(con:Multi_ConnectionParams): MultiConnection {
    return new _MultiConnection(con)
  }
  class _MultiConnection implements MultiConnection {
    cons:Connection[] = []
    public constructor(cons:Multi_ConnectionParams) {
      var _cons = Array.isArray(cons)?cons:[cons]
      _cons.forEach((con, index) => {
        this.cons[index] = newConnection(con)
      })
    }
    //each(callbackfn: (value: Connection, index: number, array: Connection[]) => Connection, thisArg?: any): Connection[] {

    connection(index:number = 0) {
      return this.cons[index].connection
    }
    toString(index:number = 0) {
      var _con = this.cons[index]
      return _con.toString()
    }
  }
}
export default Graph_QL
//------------------------ DataCalls internal
export type StateDataObject<T> = { //DataCall Memory-Data objet {FullState: {StateData}, [Key of each state]:data}
  fullState: Record<string,T>
  //& each Key in FullState as [key]:datas
} & Record<keyof (Omit<Required<T>, "con">), (Omit<T[keyof T], "con">&Pick<Graph_QL.ConnectionObj, "domain">)[]>

export interface DynamicDataObk<DataType = any> { //Container {con:[], data:[]} 
  con:Graph_QL.ConnectionObj, data:DataType
}
type DataGetKey<Str extends string> = `get${Capitalize<Str>}`
type DataKey<Str extends string> = `${Uncapitalize<Str>}Data`

export type GqlHook_Returns<FullState, T=any> = { //DataCalls Function Structure interface...
  call:(specific?:Graph_QL.GQLCons, input?: T) => void,
  fetchMore:Awaited<(lastId:number|number[], specific?:Graph_QL.GQLCons) => void>,
  deleteEntries:(_Data?: Graph_QL.Multi_ConnectionParams|Partial<DynamicDataObk<any>>|Partial<DynamicDataObk<any[]>>)=>void,
  maintainEntries?: (_Data?: Partial<DynamicDataObk<any>>|Partial<DynamicDataObk<any[]>>)=>void,
  data:Record<string, FullState>
  //foreach Key in FullState => get[key]() => "call"
  //foreach Key in FullState => [key]Data: datas
} & Record<DataGetKey<Extract<keyof Omit<Omit<FullState, "con">, "data">, string>>,Awaited<(specific?: Graph_QL.GQLCons, lastDays?: number) => void>>
& Partial<Record<DataKey<Extract<keyof FullState, string>>, (Omit<any, "con"> & Pick<Graph_QL.ConnectionObj, "domain">)[]>>

//External Exports
export { useGetDeviceState, useGetDomainLog, useGetAutopig } from "./DeviceCalls/dataCalls"
export { useGetSections } from "./DomainCalls/dataCalls"
export { useUser } from "./UserCalls/dataCalls"
export { useFeedComponents } from "./FeedDataCalls/useFeedComponents"