import { useEffect, useMemo } from "react"
import { useApolloClient } from "@apollo/client"

import { useAppSelector, useAppDispatch } from '../../../Store'
import { gqlActions } from "../gqlData_Reducer"

/* eslint-disable */
import { USER_LOGIN, LoginResponse, LoginArgs, Farms_Data, User_Data, USER_INFO, UserInfoResponse, UPDATE_USER_INFO, UpdateUserInfoArgs, UpdateUserInfoResponse } from "./GQLCalls/getCalls"
import { USER_FARMS, FarmsResponse, UserFarmsArgs, UserInfoArgs } from "./GQLCalls/getCalls"

import { UserStates, UserActions } from "./UserSlices/userSlice"
import { PersistPartial } from "redux-persist/lib/persistReducer";
/* eslint-enable */

export type UseUser = {
  doLogin:(username:string, password:string) => void,
  doLogOut:() => void,
  createNewUser:()=>void,
  getUserFarms:()=>void
  getUserData:(jwt?:string)=>void,
  updateUserData:(firstname:string,lastname:string,phone:string,email:string,country:string,address:string, jwt?:string)=>void,
  data: UserStates & PersistPartial,
  userToken?: string ,
  userFarms?: Farms_Data[],
  userData?: User_Data
} 

export const useUser = (userToken?:string):UseUser => {
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const UserStates = useAppSelector(({graphQlData:{user}}) => user)
  const {fullState, UserToken, UserFarms, UserData} = useMemo(() => {
    //TODO: UserStates.UserInfo is not array....
    var info = (UserStates.UserInfo as unknown) as any
    return {
      fullState: UserStates, 
      UserToken: UserStates.UserToken, 
      UserFarms: UserStates.UserFarms,
      UserData: info&&info[0]?info[0]:undefined
    }
  }, [UserStates])
  
  useEffect(() => {
    if (userToken) {
      getUserFarms()
    }
  }, []) // ??? UserToken

  const getUserFarms = async (farm_id?:number) => {
    if (UserToken) {
      await client.query<FarmsResponse>({query:USER_FARMS, variables:{farm_id} as UserFarmsArgs}).then(e => {
        if (e && e.data && e.data.getUserFarms) {
          var FarmsData = e.data.getUserFarms
          if (FarmsData && FarmsData.length) {
            dispatch({type:''+gqlActions.user.setUserFarms, payload:FarmsData})
          }
        }
      })
    }
  }

  const getUserData = async (jwt?:string) => {
    if (UserToken) {
      await client.query<UserInfoResponse>({query:USER_INFO, variables:{jwt:jwt?jwt:UserToken} as UserInfoArgs}).then(e => {
        if (e && e.data && e.data.getUserInfo) {
          //TODO: is not array.... ???
          var UserData = e.data.getUserInfo
          if (UserData) { 
            dispatch({type:''+gqlActions.user.setUserData, payload:UserData})
          }
        }
      })
    }
  }
  const updateUserData = async (firstname:string,lastname:string,phone:string,email:string,country:string,address:string, jwt?:string) => {
    if (UserToken) {
      await client.mutate<UpdateUserInfoResponse>({mutation:UPDATE_USER_INFO, variables:{firstname, lastname, phone, email, country, address,jwt:jwt?jwt:UserToken} as UpdateUserInfoArgs}).then(e => {
        if (e && e.data && e.data.updateUserInfo) {
          //TODO: is not array.... ???
          var UserData = e.data.updateUserInfo
          if (UserData && UserData.success) {
            dispatch({type:''+gqlActions.user.setUserData, payload:{firstname, lastname, phone, email, country, address} as User_Data})
          }
        }
      })
    }
  }

  const doLogin = async (username:string, password:string) => {
    //TODO: ... not this "only demo"
    //LOCK only demo avaible
    if (username !== "AgriDemo") return;
    await client.query<LoginResponse>({query:USER_LOGIN, variables:{username, password} as LoginArgs}).then(e => {
      if (e && e.data && e.data.login) {
        const loginData = e.data.login
        if (loginData.code + '' === "200" && loginData.message) {
          dispatch({type:''+gqlActions.user.setUserToken, payload:loginData.message})
          getUserFarms()
        }
      }
    })
  }
  const doLogOut = () => {
    if (UserToken) {
      dispatch({type:''+gqlActions.user.clear, payload:'all'}) //TODO: get actual type
    }
  }
  const createNewUser = async () => {
    
  }

  return { doLogin, doLogOut, createNewUser, data:fullState, getUserFarms, userToken:UserToken, userFarms:UserFarms, getUserData, userData:UserData, updateUserData}
}