import { colors } from "../Styles";
import { AttentionTypes, FeederStatus } from "./valueTypes";


  export const _feederColor = (feederColor?:FeederStatus): string => {
    let color: string

    switch (feederColor) {
      case FeederStatus.error:
        color = colors.systemRed;
        break;
      case FeederStatus.dosing:
        color = colors.systemGreen;
        break;
      case FeederStatus.calibration:
        color = colors.systemYellow;
        break;
      default:
        color = colors.greyDarkPrimary
    }
    return color
  }
  
  export const _attentionColor = (attentionColor?: AttentionTypes): string => {
    let color: string

    switch (attentionColor) {
      case AttentionTypes.alert:
        color = colors.systemRed;
        break;
      case AttentionTypes.warning:
        color = colors.systemYellow;
        break;
      case AttentionTypes.good:
        color = colors.systemGreen;
        break;
      case AttentionTypes.neutral:
        color = colors.greyDarkPrimary;
        break;
      default:
        color = ""
    }

    return color
  }