import { useTranslation } from "react-i18next"
import { colors, fonts, Units, useUnitsComponent } from "../../../Styles"
import { NumberDisplayList, ProgressCircle, Widget } from "../..";

import { Sections as Sections1, Sections2 } from '../../../Assets/TestData/sectionsData';
import { TestData as Pens1, TestData2 as Pens2 } from '../../../Assets/TestData/lf_Data';
import { useLocation } from 'react-router-dom'
/**
 * InThisSystem
 * @returns A widget with general system informations.
 */
export default function InThisSystem() {
  const {t} = useTranslation()
  const getUnit = useUnitsComponent();  

  let location = useLocation()
  const Pens = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?Pens2:Pens1:Pens1
  const Sections = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?Sections2:Sections1:Sections1

  const numberOfSections = Sections.length;

  const totalNumberOfPens = Pens.length;

  const totalNumberOfAnimals = Pens.filter(pen => pen.animal).length;

  return(
    <Widget
      header={<p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("progressComponents.inThisSystem")}</p>}
      width="100%"
      height="100%"
      content={
          <div style={{display: "flex", flexDirection: "column", gap: "30px", padding: "20px 0px"}}>
          <NumberDisplayList values={[`${totalNumberOfPens}`, `${totalNumberOfAnimals}`, `${numberOfSections}`]} units={[t("units.capacity"), t("globalMenu.animals"), t("locationFeeder.sections.sections")]} gridTemplate="'1fr 1fr 1fr'"/>
          <ProgressCircle header={t("progressComponents.animalsNo")} unit={getUnit(Units.animalsCapacity)}  progressed={totalNumberOfAnimals} total={totalNumberOfPens}/>
        </div>
      }
    />
  )
}