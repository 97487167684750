import react, { useState } from "react";
import { colors, fonts } from "../../Styles"

interface BigButton_Props {
  title: string
  subTitle: string
  onClick: () => void
}

export default function BigButton({title, subTitle, onClick}:BigButton_Props) {
  const [isHovered, setIsHovered] = useState<boolean>(false); 

  return (
    <div 
      onClick={onClick} 
      style={{
        cursor: "pointer", 
        width: "320px", 
        height: "200px", 
        marginBottom: "20px", 
        backgroundColor: colors.greyPrimary, 
        border: "5px solid"+colors.greyPrimary, 
        borderRadius: "10px", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        justifyContent: "center" ,
        filter: isHovered ? 'opacity(0.5)' : 'opacity(1)',
        transition: '0.3s'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <p style={{...fonts.header, ...fonts.fontSize2, color: colors.greyLightTertiary}}>{title}</p>
      <p style={{...fonts.paragraph, ...fonts.fontSize4, color: colors.greyLightTertiary}}>{subTitle}</p>
    </div>
  )
}

