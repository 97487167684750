import React, { type PropsWithChildren } from "react"
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor, setupStore, RootState, AppStore, useAppSelector } from './Store'

import { Client as Styletron } from "styletron-engine-monolithic"
import { Provider as StyletronProvider } from "styletron-react"
import { DarkTheme, BaseProvider } from "baseui"

import { render, type RenderOptions } from "@testing-library/react"

import "./Assets/Fonts/fonts.css"
import { colors } from "./Styles/index"

import { CookieModal } from "./Components"
import { MainNavigator } from './Navigation/index'


/* TODO: ApolloConnectors Should only be initilazed after login is confirmed */
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

interface ExtendedRenderOptions extends Omit<RenderOptions, "queries"> {
  preloadedState?: Partial<RootState>
  store?: AppStore,
  client?: ApolloClient<NormalizedCacheObject>
}

//import { Suspense } from 'react'
//App.logout().then(() => client.resetStore());   //clearStore
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_ADDRESS + '/graphql/',
  //process.env.REACT_APP_GRAPHQL_ADDRESS + '/graphql/',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  
  var token = localStorage.getItem('token')
  var activedomain = localStorage.getItem('activedomain')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': "true",
      token: process.env.REACT_APP_GRAPHQL_TOKEN?process.env.REACT_APP_GRAPHQL_TOKEN:'NOT',
      authorization: token ? token : "",
      activedomain: activedomain ? activedomain: ""
    }
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        /*getOnlineState: {
          // Don't cache separate results based on any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with the existing list items.
          merge(existing = [], incoming) {
            return [...existing, incoming]
          },
        }*/
      }
    }
  }
})

const setupApolloClient = () => new ApolloClient({
  //uri: process.env.REACT_APP_GRAPHQL_ADDRESS,
  link: ApolloLink.from([authLink, httpLink]), // authLink.concat(httpLink), ??
  cache: cache
})

const Wrapper = ({ children }:PropsWithChildren, store:any, client:ApolloClient<NormalizedCacheObject>) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyletronProvider value={new Styletron()}>
        <BaseProvider theme={DarkTheme} zIndex={999}
          overrides={{
            AppContainer:{
              style: () => ({
                outline: `0px #000 solid`,
                backgroundColor: colors.greyDarkSecondary, 
                position: 'absolute',
                top: 0, left: 0, bottom: 0, right: 0,
                height: '100%', width: '100%', default: {height: '100%', width: '100%'},
                overflow: "hidden"
              })
            }
          }}>
          <ApolloProvider client={client} >
            {children}
            <CookieModal/>
          </ApolloProvider>
        </BaseProvider>
      </StyletronProvider>
    </PersistGate>
  </Provider>
)

export function renderWithProviders( ui: React.ReactElement, extendedRenderOptions: ExtendedRenderOptions = {}) {
  const {
    preloadedState = {},
    // Automatically create a store instance if no store was passed in
    store = setupStore(preloadedState),
    client = setupApolloClient(),
    ...renderOptions
  } = extendedRenderOptions

  // Return an object with the store and all of RTL's query functions
  return { 
    store, client, 
    ...render(ui, { wrapper: (e) => Wrapper(e, store, client), ...renderOptions }) 
  }
}

export function AgrisysApp():JSX.Element {
  return Wrapper({children:<MainNavigator/>}, store, setupApolloClient())
}
