import React, { useMemo } from 'react';
import { frames } from '../../../Styles';
import { useMediaQuery } from '../../../Styles/mediaQueries';
import { Attentions, Farrowing, Feeding, InThisSystem, PageHeader, SystemControls } from '../../../Components';
import { useTranslation } from 'react-i18next';
import { SystemData } from '../../../Assets/TestData/systems';

export default function FreedaLocationFeeder() {
  const { t } = useTranslation();

  const { systemName } = useMemo(() => {
    const systemData = SystemData.find((system) => system.systemNo === 2);
    return {
      systemName: systemData?.name || "",
    };
  }, []);

  const lf_Grid_XLDesktop = useMediaQuery(`(min-width: 1775px)`);
  const lf_Grid_Desktop = useMediaQuery(`(min-width: 1676px) and (max-width: 1774px)`);
  const lf_Grid_XSDesktop = useMediaQuery(`(min-width: 1460px) and (max-width: 1675px)`);
  const lf_Grid_Laptop = useMediaQuery(`(min-width: 1021px) and (max-width: 1459px)`);
  const lf_Grid_tablet = useMediaQuery(`(min-width: 601px) and (max-width: 1020px)`);

  // Grid columns and rows
  const _gridColumns = lf_Grid_XLDesktop? "320px 634px 1fr" : lf_Grid_Desktop? "320px 320px 1fr 1fr 1fr" : lf_Grid_XSDesktop ? "320px 320px 1fr" : lf_Grid_Laptop ? "320px 320px" : lf_Grid_tablet ? "1fr 310px" : ""
  const _gridRows = lf_Grid_XLDesktop||lf_Grid_Desktop? "367px 367px" : lf_Grid_XSDesktop? "367px 367px 367px" : lf_Grid_Laptop||lf_Grid_tablet? "367px fit-content fit-content fit-content" : ""

  // Grid areas
  const _gridArea_inThisSystem = "1/1/2/2"
  const _gridArea_systemControls = lf_Grid_XLDesktop? "2/1/3/2" : lf_Grid_Desktop||lf_Grid_XSDesktop||lf_Grid_Laptop||lf_Grid_tablet ? "1/2/2/3" : ""
  const _gridArea_attentions = lf_Grid_XLDesktop? "1/3/3/4" : lf_Grid_Desktop ? "1/3/2/6": lf_Grid_XSDesktop? "1/3/4/4" : lf_Grid_Laptop||lf_Grid_tablet ? "4/1/5/3" : ""
  const _gridArea_feeding = lf_Grid_XLDesktop? "1/2/2/3" : lf_Grid_Desktop||lf_Grid_XSDesktop||lf_Grid_Laptop||lf_Grid_tablet ? "2/1/3/3" : ""
  const _gridArea_calendar = lf_Grid_XLDesktop? "2/2/3/3" : lf_Grid_Desktop ? "2/3/3/6": lf_Grid_XSDesktop||lf_Grid_Laptop||lf_Grid_tablet ? "3/1/4/3" : ""
 
  // Attention widget height
  const _height_attentions = lf_Grid_XLDesktop? "754px" : lf_Grid_Desktop ? "367px" : lf_Grid_XSDesktop ? "1141px" : lf_Grid_Laptop||lf_Grid_tablet ? "fit-content" : ""

  // Grid area justify content
  const _justifyContent = lf_Grid_Laptop||lf_Grid_tablet ? "center" : "start"

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        {/* TODO: Change page name */}
        <PageHeader topHeader={systemName} subHeader={t("overview")}/>
      </frames._page_inner_header>
      <frames._page_inner_content_scroll>
        <div style={{display: "flex", alignItems: "start", justifyContent: lf_Grid_Laptop ? "center" : "start"}}>
          <div style={{display: "grid", gridAutoColumns: _gridColumns, gridAutoRows: _gridRows, width: lf_Grid_Laptop ? "fit-content" : "100%", alignItems: "start", gap: "20px"}}>
            <div style={{gridArea: _gridArea_inThisSystem, display: "flex", height: "367px", justifyContent: _justifyContent}}><InThisSystem/></div> 
            <div style={{gridArea: _gridArea_systemControls, display: "flex", height: "367px", justifyContent: _justifyContent}}><SystemControls/></div>
            <div style={{gridArea: _gridArea_feeding, display: "flex", justifyContent: _justifyContent}}><Feeding/></div>
            <div style={{gridArea: _gridArea_calendar, display: "flex", justifyContent: _justifyContent}}><Farrowing/></div>
            <div style={{gridArea: _gridArea_attentions, display: "flex", height: _height_attentions, justifyContent: _justifyContent}}><Attentions/></div>
          </div>
        </div>
      </frames._page_inner_content_scroll>
    </frames.Page_inner>
  );
}