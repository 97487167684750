import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors, css, Units, useUnitsComponent } from "../../../Styles";
import { NumberDisplayList, ProgressCircle, Section_Add, Section_Basic, Section_Edit, Section_Move } from "../..";
import { _attentionColor, _feederColor } from "../../../Definitions/valueColors";

import { getSectionById } from "../../../Assets/TestData/sectionsData";
import { Milestone, TestCalculate_Progresse } from "../../../Assets/TestData/animalData";
import { SectionBasicTypes } from "../Section/sectionBasic";

interface sectionModal_Props {
  activeSectionId?: number
  onPenSelected?: (penId: number) => void;
}
/**
 * SectionModal
 * @param activeSectionId The section number.
 * @param onPenSelected Function to handle the penId when a pen is clicked.
 * @returns A "div" to place in a modal to show all content from a Location Feeder Section.
 */
export default function SectionModal({activeSectionId, onPenSelected}:sectionModal_Props) {
  const {t} = useTranslation();
  const getUnit = useUnitsComponent();  

  const [isAddSection, setAddSection] = useState<boolean>(false) // Section Add
  const [isMoveSection, setMoveSection] = useState<boolean>(false) // Section Move
  const [isEditSection, setEditSection] = useState<boolean>(false) // Section Edit
  const [activePenId, setActivePenId] = useState<number | null>(null); // track activePenId

  // #region | Section Modal Functionality
  function PenClick(penId: number) {
    setActivePenId(penId); 
    if (onPenSelected) {
      onPenSelected(penId);  // Call the prop to send the penId to the parent
    }
  }
  // #endregion

  // #region | Section Modal Data
  const activeSection = activeSectionId ? getSectionById(activeSectionId) : null;

  const animalsTotal = activeSection 
    ? activeSection.pens.filter(pen => pen.animal).length
    : 0;

  const animalsFarrowed = activeSection 
    ? activeSection.pens.filter(pen => pen.animal?.latestMilestone === Milestone.Farrowing).length
    : 0;

  const foodTotal = activeSection 
    ? Math.floor((activeSection.pens.reduce((sum, pen) => sum + (pen.animal?.totalFood ?? 0), 0)*10))/10
    : 0;

  const foodProgressed = activeSection 
    ? Math.floor((activeSection.pens.reduce((sum, pen) => sum + (pen.animal?TestCalculate_Progresse(pen.animal):0), 0)*10))/10
    : 0;

  const increasedPens = activeSection
    ? activeSection.pens.filter(pen =>
        pen.animal && (pen.animal.feedCorrection ?? 0) > 0
      ).length
    : 0;

  const decreasedPens = activeSection
    ? activeSection.pens.filter(pen =>
        pen.animal && (pen.animal.feedCorrection ?? 0) < 0
      ).length
    : 0;

  const emptyPens = activeSection
    ? activeSection.pens.filter(pen => pen.penNo).length - animalsTotal
    : 0;

  const disabledPens = activeSection
    ? activeSection.pens.filter(pen => 
        pen.feeding === false
      ).length - emptyPens
    : 0;
  // #endregion

  // #region | Pen Modal Components (Seperator)
  // Seperator Line for Pen Modal
  const seperatorLine = <div style={{width: "90%", height: "1.5px", backgroundColor: colors.greyPrimary, margin: "20px auto"}}/>
  // #endregion

  return (
    <div style={{backgroundColor: colors.greyLightTertiary, borderRadius: "0px 0px 10px 10px", padding: "30px 20px" }}>
      <div style={{ ...css.modalTop }}>
        {/* Current Progress */}
        <ProgressCircle header={t("progressComponents.currentProgress")}
          total={foodTotal} 
          progressed={foodProgressed}
          unit={getUnit(Units.kG_grey)} 
        />
        {/* Number of Animals */}
        <ProgressCircle header={t("progressComponents.animalsNo")} 
          total={animalsTotal} 
          progressed={animalsFarrowed}
          unit={getUnit(Units.farrowedTotal_grey)}
        />
        {/* Current Feed Corrections */}
        <NumberDisplayList units={[t("feed.feedCorrections.increased"), t("feed.feedCorrections.decreased"), t("feed.feedCorrections.disabled")]}
          values={[increasedPens, decreasedPens, disabledPens]}
          header={t("locationFeeder.sectionsPopup.feedCorrections")}
        />
      </div>
          
      {seperatorLine}
          
      { isAddSection
        ? <Section_Add sectionId={activeSectionId ?? 0} onCancelBtn={() => setAddSection(false)}/>
        : isMoveSection
        ? <Section_Move sectionId={activeSectionId ?? 0} onCancelBtn={() => setMoveSection(false)}/> 
        : isEditSection
        ? <Section_Edit sectionId={activeSectionId ?? 0} onCancelBtn={() => setEditSection(false)}/>
        : <Section_Basic sectionId={activeSectionId ?? 0} sectionType={SectionBasicTypes.advancedPen} onPenClick={PenClick} onAddBtn={() => setAddSection(true)} onMoveBtn={() => setMoveSection(true)} onEditBtn={() => setEditSection(true)}/>
      }
    </div>
  )
}