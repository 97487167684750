import { platform } from "os"
import { FeedCurve } from "../Containers"

export const flags = {
    en:require("../Assets/Flags/en.png"),
    dk:require("../Assets/Flags/dk.png"),
    de:require("../Assets/Flags/de.png"),
    es:require("../Assets/Flags/es.png"),
    ma:require("../Assets/Flags/ma.png")
} as Record<string, any>

export const icons = {
    // Large
    dashboard: require("../Assets/Icons/Large/dashboard.png"),
    dashboardInv: require("../Assets/Icons/Large/dashboard_inv.png"),
    kpi: require("../Assets/Icons/Large/kpi-dashboard.png"),
    kpiInv: require("../Assets/Icons/Large/kpi-dashboard_inv.png"),
    feedCurves: require("../Assets/Icons/Large/feedCurves.png"),
    FeedCurvesInv: require("../Assets/Icons/Large/feedCurves_inv.png"),
    silos: require("../Assets/Icons/Large/silos.png"),
    silosInv: require("../Assets/Icons/Large/silos_inv.png"),
    animals: require("../Assets/Icons/Large/animals.png"),
    animalsInv: require("../Assets/Icons/Large/animals_inv.png"),
    animalsColor: require("../Assets/Icons/Large/animals_color.png"),
    reports: require("../Assets/Icons/Large/reports.png"),
    reportsInv: require("../Assets/Icons/Large/reports_inv.png"),
    logBook: require("../Assets/Icons/Large/logbook.png"),
    logBookInv: require("../Assets/Icons/Large/logbook_inv.png"),
    globalSettings: require("../Assets/Icons/Large/global-settings.png"),
    globalSettingsInv: require("../Assets/Icons/Large/global-settings_inv.png"),
    start: require("../Assets/Icons/Large/start.png"),
    calibrate: require("../Assets/Icons/Large/calibrate.png"),
    onOff: require("../Assets/Icons/Large/on_off.png"),
    empty: require("../Assets/Icons/Large/empty.png"),

    platformOperation: require("../Assets/Icons/Large/platform-operation.png"),
    platformDesktop: require("../Assets/Icons/Large/platform-desktop.png"),
    system: require("../Assets/Icons/Large/system.png"),
    system2: require("../Assets/Icons/Large/system2.png"),
    autoPig: require("../Assets/Icons/Large/autopig.png"),
    
    activatorLF: require("../Assets/Icons/Large/activator.png"),
    feederLF: require("../Assets/Icons/Large/location_feeder.png"),
    motorLF: require("../Assets/Icons/Large/motor.png"),

    freedaPPT: require("../Assets/Icons/Large/freeda_ppt.png"),
    freedaSorter: require("../Assets/Icons/Large/freeda_sorter.png"),
    freedaLF: require("../Assets/Icons/Large/freeda_lf.png"),
    freedaESF: require("../Assets/Icons/Large/freeda_esf.png"),
    freedaESF_Dynamic: require("../Assets/Icons/Large/freeda_esf_dynamic.png"),
    freedaESF_HeatDetection: require("../Assets/Icons/Large/freeda_esf_heat-detection.png"),
    freedaESF_WalkIn: require("../Assets/Icons/Large/freeda_esf_walk-in.png"),
    freedaESF_WalkThrough: require("../Assets/Icons/Large/freeda_esf_walk-through.png"),
    freedaESF_WeightMonitoring: require("../Assets/Icons/Large/freeda_esf_weight-monitoring.png"),

    // Small
    search: require("../Assets/Icons/Small/search.png"),
    passwordShow: require("../Assets/Icons/Small/passwordShow.png"),
    passwordHide: require("../Assets/Icons/Small/passwordHide.png"),
    password: require("../Assets/Icons/Small/password.png"),
    user: require("../Assets/Icons/Small/user.png"),
    mail: require("../Assets/Icons/Small/mail.png"),
    phone: require("../Assets/Icons/Small/phone.png"),
    goToArrow: require("../Assets/Icons/Small/arrow.png"),
    goLeftArrow: require("../Assets/Icons/Small/arrow_left.png"),
    goRightArrow: require("../Assets/Icons/Small/arrow_right.png"),
    close: require("../Assets/Icons/Small/close.png"),
    clear: require("../Assets/Icons/Small/clear.png"),
    fetch: require("../Assets/Icons/Small/fetch.png"),
    kg: require("../Assets/Icons/Small/kg.png"),
    kg_white: require("../Assets/Icons/Small/kg_white.png"),
    mA: require("../Assets/Icons/Small/ma.png"),
    animalNo: require("../Assets/Icons/Small/animal_number.png"),
    responderNo: require("../Assets/Icons/Small/responder_number.png"),
    lifeNo: require("../Assets/Icons/Small/life_number.png"),
    location: require("../Assets/Icons/Small/location.png"),
    condition: require("../Assets/Icons/Small/condition.png"),
    group: require("../Assets/Icons/Small/group.png"),
    attentionType: require("../Assets/Icons/Small/attention.png"),
    add_white: require("../Assets/Icons/Small/add_white.png"),
    add: require("../Assets/Icons/Small/add.png"),
    minus_white: require("../Assets/Icons/Small/minus_white.png"),
    check: require("../Assets/Icons/Small/check.png"),
    check_white: require("../Assets/Icons/Small/check_white.png"),
    check_green: require("../Assets/Icons/Small/check_green.png"),
    yesterday_white: require("../Assets/Icons/Small/yesterday_white.png"),
    today_white: require("../Assets/Icons/Small/today_white.png"),
    moveAnimal_white: require("../Assets/Icons/Small/move_animal_white.png"),
    moveAnimal: require("../Assets/Icons/Small/move_animal.png"),
    edit: require("../Assets/Icons/Small/edit.png"),
    edit_white: require("../Assets/Icons/Small/edit_white.png"),
    time_white: require("../Assets/Icons/Small/time_white.png"),
    calendar: require("../Assets/Icons/Small/calendar.png"),
    calendar_white: require("../Assets/Icons/Small/calendar_white.png"),
    animalType: require("../Assets/Icons/Small/animal_type_white.png"),
    imgMissing: require("../Assets/Icons/Small/img_missing.png"),
    progressStep1: require("../Assets/Icons/Small/progressstep1.png"),
    progressStep2: require("../Assets/Icons/Small/progressstep2.png"),
    progressStep3: require("../Assets/Icons/Small/progressstep3.png"),
    progressStepReady: require("../Assets/Icons/Small/progressstepready.png"),
    delete: require("../Assets/Icons/Small/delete.png"),
    delete_white: require("../Assets/Icons/Small/delete_white.png"),
} 

export const logos = {
    whiteAgrisysLogo: require("../Assets/Logos/agrisys_white.png"),
    blackAgrisysLogo: require("../Assets/Logos/agrisys_black.png"),
    whiteFreedaLogo: require("../Assets/Logos/freeda_white_orange.png"),
    greyFreedaLogo: require("../Assets/Logos/freeda_grey_orange.png")
}

export const decors = {
    def_bg: require("../Assets/Decors/def_bg.png")
}

export const autopigDias = {
    autopig: require("../Assets/Img/Autopig/autopig.png"),
    autopig1: require("../Assets/Img/Autopig/autopig_1.png"),
    autopig2: require("../Assets/Img/Autopig/autopig_2.png"),
    autopig3: require("../Assets/Img/Autopig/autopig_3.png"),
    autopig4: require("../Assets/Img/Autopig/autopig_4.png"),
    autopig5: require("../Assets/Img/Autopig/autopig_5.png"),
    autopig6: require("../Assets/Img/Autopig/autopig_6.png"),
    autopig7: require("../Assets/Img/Autopig/autopig_7.jpg"),
    autopig8: require("../Assets/Img/Autopig/autopig_8.png")
}

export const images = {
    farmstatus: require("../Assets/Img/farm_status.png"),
    totalFeed: require("../Assets/Img/total_feed.png"),
    waterUsage: require("../Assets/Img/water_usage.png"),
    spreading: require("../Assets/Img/spreading.png"),
}

const all = {
    flags: flags,
    icons: icons,
    logos: logos,
    decors: decors,
    autopigDias: autopigDias,
    images: images
}
export default all