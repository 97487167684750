import { gql } from "@apollo/client"

export type SingleStringValue = {
  value: string;
}

//#region Object properties
type CommonObjectArgs = {
  uid: number
  __typename?: string;
}
export type Activitie = CommonObjectArgs & {
  activitietype: Activitietypes;
  activitiecode: Activitiecodes;
  penid: number;
  timestamp: number;
  data: string;
  quantity: number;
  weight: number;
  others: boolean;
  days: number;
};

export type Activitiecodes = CommonObjectArgs & {
  name: string;
  code: number;
  description: string;
  inuse: boolean;
  others: boolean;
};

export type Activitietypes = CommonObjectArgs & {
  name: string;
  description: string;
  quantitymultiplier: number;
  lock: boolean;
};

export type Graph = CommonObjectArgs & {
  name: string;
  data: string;
};

export type Module = CommonObjectArgs & {
  sysid: number;
  mapindex: number;
  name: string;
  settings: string;
  tempval: number;
  flowmeterval: number;
};

export type Pen = CommonObjectArgs & {
  name: string;
  pensize: number;
  activedate: number;
  count: number;
  graphid: number;
  sectionid: number;
  modules: Module[];
};

export type Period = CommonObjectArgs & {
  startdate: number;
  enddate: number;
  penid: number;
};

export type Report = CommonObjectArgs & {
  name: string;
  startdate: number;
  enddate: number;
  data: string;
  isarchived: boolean;
};

export type Section = CommonObjectArgs & {
  name: string;
  pens: Pen[];
}

export type Weighings = CommonObjectArgs & {
  penid: number;
  timestamp: number;
  quantity: number;
  weight: number;
};
//#endregion
//-------------------------------------------------------------------------
//#region Response types
export interface GetActivitiesResponse {
  getActivities: Activitie[];
}

export interface GetActivitieCodesResponse {
  getActivitieCodes: Activitiecodes[];
}

export interface GetActivitieTypesResponse {
  getActivitieTypes: Activitietypes[];
}

export interface GetGraphsResponse {
  getGraphs: Graph[];
}

export interface GetModulesResponse {
  getModules: Module[];
}

export interface GetPensModulesResponse {
  getPens: Pen[];
}

export interface GetPensResponse {
  getPens: Pen[];
}

export interface GetPeriodsResponse {
  getPeriods: Period[];
}

export interface GetReportsResponse {
  getReports: Report[];
}

export interface GetSectionsPensModulesResponse {
  getSections: Section[];
}

export interface GetSectionsPensResponse {
  getSections: Section[];
}

export interface GetSectionsResponse {
  getSections: Section[];
}

export interface GetWeighingsResponse {
  getWeighings: Weighings[];
}
//#endregion
//-------------------------------------------------------------------------
//#region CallArgs
type CommonCallArgs = {
  domain: string;
}

export type GetActivitiesArgs = CommonCallArgs &  {
  penid?: number;
}

export type GetActivitieCodesArgs = CommonCallArgs & {
  uid?: number;
}

export type GetActivitieTypesArgs = CommonCallArgs & {
  uid?: number;
}

export type GetGraphsArgs = CommonCallArgs & {
  uid?: number;
}

export type GetModulesArgs = CommonCallArgs & {
  uid?: number;
}

export type GetPensModulesArgs = CommonCallArgs & {
  penIds: number[];
}

export type GetPensArgs = CommonCallArgs & {
  penIds: number[];
}

export type GetPeriodsArgs = CommonCallArgs & {
  penId?: number;
}

export type GetReportsArgs = CommonCallArgs & {
  uid?: number;
}

export type GetSectionsPensModulesArgs = CommonCallArgs & {
  sectionIds: number[];
}

export type GetSectionsPensArgs = CommonCallArgs & {
  sectionIds: number[];
}

export type GetSectionsArgs = CommonCallArgs & {
  sectionIds: number[];
}

export type GetWeighingsArgs = CommonCallArgs & {
  penid?: number;
}
//#endregion
//-------------------------------------------------------------------------
// GraphQL queries
//type Activitie_Keys = keyof (Activitie)
export const GET_ACTIVITIES = gql`
  query GetActivities($domain: String!, $penid: Int) {
    getActivities(domain: $domain, penid: $penid ) {
      uid
      activitietype {
        uid
        name
        description
        lock
        quantitymultiplier
      }
      activitiecode {
        uid
        name
        code
        description
        inuse
        others
      }
      penid
      timestamp
      data
      quantity
      weight
      others
      days
    }
  }`;

export const GET_ACTIVITIE_CODES = gql`
  query GetActivitieCodes($domain: String!, $uid: Int) {
    getActivitieCodes(domain: $domain, uid: $uid) {
      uid
      name
      code
      description
      inuse
      others
    }
  }`;

export const GET_ACTIVITIE_TYPES = gql`
  query GetActivitieTypes($domain: String!, $uid: Int) {
    getActivitieTypes(domain: $domain, uid: $uid) {
      uid
      name
      description
    }
  }`;

export const GET_GRAPHS = gql`
  query GetGraphs($domain: String!, $uid: Int) {
    getGraphs(domain: $domain, uid: $uid) {
      uid
      name
      data
    }
  }`;

export const GET_MODULES = gql`
  query GetModules($domain: String!, $uid: Int) {
    getModules(domain: $domain, uid: $uid) {
      uid
      sysid
      mapindex
      name
      settings
      tempval
      flowmeterval
    }
  }`;

export const GET_PENS_MODULES = gql`
  query GetPens($domain: String!, $penIds: [Int!]) {
    getPens(domain: $domain, penIDS: $penIds) {
      uid
      name
      pensize
      activedate
      count
      graphid
      sectionid
      modules {
        uid
        sysid
        mapindex
        name
        settings
        tempval
        flowmeterval
      }
    }
  }`;

export const GET_PENS = gql`
  query GetPens($domain: String!, $penIds: [Int!]) {
    getPens(domain: $domain, penIDS: $penIds) {
      uid
      name
      pensize
      activedate
      count
      graphid
      sectionid
    }
  }`;

export const GET_PERIODS = gql`
  query GetPeriods($domain: String!, $penId: Int) {
    getPeriods(domain: $domain, penID: $penId) {
      uid
      startdate
      enddate
      penid
    }
  }`;

export const GET_REPORTS = gql`
  query GetReports($domain: String!, $uid: Int) {
    getReports(domain: $domain, UID: $uid) {
      uid
      name
      startdate
      enddate
      data
      isarchived
    }
  }`;

export const GET_SECTIONS_PENS_MODULES = gql`
    query GetSections($domain: String!, $sectionIds: [Int!]) {
      getSections(domain: $domain, sectionIDS: $sectionIds) {
        name
        uid
        pens {
          uid
          name
          pensize
          activedate
          count
          graphid
          sectionid
          modules {
            uid
            sysid
            mapindex
            name
            settings
            tempval
            flowmeterval
          }
        }
      }
    }
  `;

export const GET_SECTIONS_PENS = gql`
    query GetSections($domain: String!, $sectionIds: [Int!]) {
      getSections(domain: $domain, sectionIDS: $sectionIds) {
        name
        uid
        pens {
          uid
          name
          pensize
          activedate
          count
          graphid
          sectionid
        }
      }
    }
  `;

export const GET_SECTIONS = gql`
    query GetSections($domain: String!, $sectionIds: [Int!]) {
      getSections(domain: $domain, sectionIDS: $sectionIds) {
        name
        uid
        pens {
          uid
        }
      }
    }
  `;

export const GET_WEIGHINGS = gql`
  query GetWeighings($domain: String!, $penid: Int) {
    getWeighings(domain: $domain, penid: $penid) {
      uid
      penid
      timestamp
      quantity
      weight
    }
  }`;
