import { SVGProps } from "react";

export default function ArrowUpSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 512 512" style={{color: props.color}} xmlns="http://www.w3.org/2000/svg">
      <rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke" />
      <svg width="256px" height="256px" viewBox="0 0 16 16" fill="currentColor" x="128" y="128" role="img" style={{display:"inline-block", verticalAlign:"middle"}} xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor"><path fill="currentColor" fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
        </g>
      </svg>
    </svg>
  )
}