import { CSSProperties } from "react"
import { colors, css, fonts } from "../Styles"
  
export enum TableStyles_Types {
  primaryTable = "primaryTable",
  secondaryTable = "secondaryTable",
  feedCurveTable = "feedCurveTable",
  blueTable = "blueTable"
}

export const CreateTableStyle = (index: number, tableType?: TableStyles_Types | string) => {
  let tableStyle: CSSProperties = {};
  let headCellStyle: CSSProperties = {};
  let bodyCellStyle: (colIndex: number) => CSSProperties = () => ({});
  let bottomCellStyle: CSSProperties = {};

  /*eslint no-fallthrough: "error"*/
  switch (tableType) {
    case TableStyles_Types.secondaryTable:
      tableStyle = {
        ...css.tableStyle,
        maxHeight: "99%",
        backgroundColor: colors.greyLightSecondary,
        border: "5px solid"+colors.greyPrimary,
        borderRadius: "0px 0px 10px 10px",
        overflowY: "scroll",
        overflowX: "scroll",
        scrollbarColor: colors.greySecondary + colors.greyPrimary,
      };
      headCellStyle = {
        ...css.headCellStyle,
        height: "20px",
        backgroundColor: colors.greyPrimary,
        outline: "4px solid",
        outlineColor: colors.greyPrimary,
        outlineOffset: "-3px",
      };
      bodyCellStyle = (colIndex: number) => ({
        ...css.bodyCellStyle,
      });
      bottomCellStyle = {
        ...css.bottomCellStyle,
        backgroundColor: colors.greyPrimary,
        outline: "4px solid",
        outlineColor: colors.greyPrimary,
        outlineOffset: "-3px",
      }
    break;
    case TableStyles_Types.blueTable:
      tableStyle = {
        ...css.tableStyle,
        borderRadius: "0px 0px 10px 10px",
      };
      headCellStyle = {
        ...css.headCellStyle,
        height: "20px",
        backgroundColor: colors.blueDarkPrimary,
        outline: "4px solid",
        outlineColor: colors.blueDarkPrimary,
        outlineOffset: "-3px",
      };
      bodyCellStyle = (colIndex: number) => ({
        ...css.bodyCellStyle,
      });
      bottomCellStyle = {
        ...css.bottomCellStyle,
      }
    break;
    case TableStyles_Types.feedCurveTable: 
      // headCellStyle, bodyCellStyle and bottomCellStyle is in feedCurveTable.tsx and feedCurveTableEdit.tsx
      tableStyle = {
        ...css.tableStyle,
        maxHeight: "100%",
        backgroundColor: colors.greyDarkSecondary,
        border: "5px solid"+colors.greyPrimary,
        // borderLeft: "5px solid"+colors.greyPrimary,
        // borderBottom: "5px solid"+colors.greyPrimary,
        // borderRight: "5px solid"+colors.greyPrimary,
        overflowY: "scroll",
        overflowX: "scroll",
        scrollbarColor: colors.greySecondary + colors.greyPrimary,
        borderRadius: "10px",
      };
      headCellStyle = {        
              ...css.headCellStyle,
              height: "40px",
              position: "sticky",
              top: "0px !important",
              borderRight: index === 1 ? `2px solid ${colors.greySecondary}` : "none",
              // borderLeft: index === content.length - 2 ? `2px solid ${colors.greySecondary}` : "none",
              backgroundColor: colors.greyPrimary,
            };
            bodyCellStyle = (colIndex: number) => ({
        ...css.bodyCellStyle,
      });
      bottomCellStyle = {
        ...css.bottomCellStyle,
        backgroundColor: colors.greyPrimary,
        outline: "4px solid",
        outlineColor: colors.greyPrimary,
        outlineOffset: "-3px",
      }
    break;
    case TableStyles_Types.primaryTable:
    default:
      tableStyle = {
        ...css.tableStyle
      };
      headCellStyle = {
        ...css.headCellStyle
      };
      bodyCellStyle = (colIndex: number) => ({
        ...css.bodyCellStyle
      });
      bottomCellStyle = {
        ...css.bottomCellStyle
      }
  }
  return {
    tableStyle, headCellStyle, bodyCellStyle, bottomCellStyle
  };
};

// Eksempel på brug (Maja) - se evt. tableList.tsx
//
// Import af definition:
// import CreateTableStyle, {TableStyles_Types} from "../../Definitions/tableStyles"
// 
// Indsæt under props for det pågældende component:
// tableType?: TableStyles_Types | string,
//
// Kald på de forskellige componenter, hvor stylingen ønskes:
// <TableList
// tableType={TableStyles_Types.secondaryTable}
// />