//import { client } from '../../api/client'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'

import { Graph_QL, DynamicDataObk } from '../../';

import { LiveStateItem } from '../device_QlCalls'
import { GET_DAILYSCALES, GetDailyScaleArgs, DailyScaleItem, DailyScaleResponse } from "../device_QlCalls"
import { GET_DAILYTEMPERATURES, GetDailyTempArgs, DailyTempItem, DailyTempResponse } from "../device_QlCalls"

type Autopig_DataTypes = DailyScaleItem[]|DailyTempItem[]|LiveStateItem
export type AutopigStates = {
  State?:LiveStateItem,
  Scales?:DailyScaleItem[],
  Temps?:DailyTempItem[],
  CurveSet?:any,
  Configuration?:any
}
type AutopigStates_Keys = keyof ({all:any} & AutopigStates)

export type Autopig_Item = DynamicDataObk<AutopigStates>
const dataAdapter = createEntityAdapter({
  selectId: (item:Autopig_Item) => Graph_QL.ObjToString(item.con),
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
})

//#region Helper TransFormers
const data_TransForm = (DataContainer?:Autopig_DataTypes) => {
  if (DataContainer) {
    if (Array.isArray(DataContainer)) {
      return DataContainer.map((e) => {
        var entry = {...e }
        if (e.timestamp) {
          var timestamp = e.timestamp
          if (typeof timestamp === "string") timestamp = new Date(parseInt(timestamp))
          else if (typeof timestamp === "number") timestamp = new Date(timestamp)
          entry.timestamp = timestamp.getTime()
        }
        if (entry.gatewayindex) delete entry.gatewayindex
        if (entry.mapdeviceindex) delete entry.mapdeviceindex
        if (entry.__typename) delete entry.__typename
        return entry
      })
    }
    else {
      var entry = {...DataContainer as LiveStateItem}
      delete entry.__typename
      return entry
    }
  }
  else return undefined
}

const UpdateEntryState = (state:EntityState<Autopig_Item, string>, Entries:Autopig_Item) => {
  var Current = state.ids.find(e => e === Entries.con.toString())
  if (Current) {
    var NewEntries = Object.assign({}, state.entities[Current], Entries)
    dataAdapter.upsertOne(state, NewEntries)
  }
  else dataAdapter.addOne(state, Entries)
}

const SingleDataType_Adder = (state:EntityState<Autopig_Item, string>, payload:DynamicDataObk[], DataKey:AutopigStates_Keys) => {
  payload.forEach((entries:DynamicDataObk) => {
    var NewDatas: Autopig_DataTypes | undefined = undefined
    if (DataKey === "State") NewDatas = data_TransForm(entries.data)
    else NewDatas = data_TransForm(entries.data as unknown as Autopig_DataTypes)

    var Data = {[DataKey]:NewDatas}
    UpdateEntryState(state, {data:Data, con:entries.con})
  })
}
//#endregion

const AutopigSlice = createSlice({
  name: 'device/autopig', initialState:dataAdapter.getInitialState<Autopig_Item[]>([]),
  reducers: {
    scales: (state, {payload}:PayloadAction<Autopig_Item[]>) => {
      SingleDataType_Adder(state, payload, "Scales")
    },
    temps: (state, {payload}:PayloadAction<Autopig_Item[]>) => {
      SingleDataType_Adder(state, payload, "Temps")
    },
    state: (state, {payload}:PayloadAction<Autopig_Item[]>) => {
      SingleDataType_Adder(state, payload, "State")
    },
    added: (state, {payload, type}:PayloadAction<Autopig_Item[]>) => {
      payload.forEach(entries => {
        var DataEntries:Autopig_Item = {con: entries.con, data:{}}
        if (entries.data.State) DataEntries.data.State = data_TransForm(entries.data.State) as LiveStateItem
        if (entries.data.Scales) DataEntries.data.Scales = data_TransForm(entries.data.Scales) as DailyScaleItem[]
        if (entries.data.Temps) DataEntries.data.Temps = data_TransForm(entries.data.Temps) as DailyTempItem[]

        UpdateEntryState(state, DataEntries)
      })
    },
    deleted: (state, {payload}:PayloadAction<Autopig_Item[]|Graph_QL.Connection[]>) => {
      payload.forEach(entries => {
        var entriesCon:Graph_QL.ConnectionObj = Object.hasOwn(entries, "con")?(entries as Autopig_Item).con:(entries as Graph_QL.Connection).connection
        var Current = entriesCon?state.ids.find(e => e === Graph_QL.ObjToString(entriesCon)):undefined
        if (Current) {
          //if reference does not contain singular data container reference, delete the full entries store
          if (Object.hasOwn(entries, "data")) {
            //else go through each singular data container reference
            if (Object.hasOwn((entries.data as AutopigStates), "State")) {
              UpdateEntryState(state, {con:entriesCon, data:{...entries.data, State:undefined}})
            }
            if (Object.hasOwn((entries.data as AutopigStates), "Scales")) { //TODO: could delete on timestamp ???
            // (entries as AutopigStates).data.forEach(entry => {
            //   //if data entry reference exists: delete it
            //   var Data_Current = state.entities[Current+''].data.findIndex(e => e.id === entry.id)
            //   if (~Data_Current)state.entities[Current+''].data.splice(Data_Current, 1)
            // })
            // //if data entries container is empty: delete the entries store
            // //if ((entries as AutopigStates).data.length === 0) dataAdapter.removeOne(state, Current)
              UpdateEntryState(state, {con:entriesCon, data:{...entries.data,Scales:undefined}})
            }
            if (Object.hasOwn((entries as AutopigStates), "Temps")) {
              UpdateEntryState(state, {con:entriesCon, data:{...entries.data,Temps:undefined}})
            }
            //if data entries container is empty: delete the entries store
            if (!Object.values((entries.data as AutopigStates)).some(e => e)) dataAdapter.removeOne(state, Current)
          }
          else dataAdapter.removeOne(state, Current)
        }
      })
      //Do not remove all in deleted... use intentional clear
    },
    clear: (state, {payload}:PayloadAction<Graph_QL.Connection[]|undefined>) => {
      if (payload) dataAdapter.removeMany(state, payload.map(e => e.toString()))
      else dataAdapter.removeAll(state)
    }
  }
})

//const { actions, reducer } = ActiveSlice
//export const { added, deleted } = LogSlice.actions
export const AutopigActions = AutopigSlice.actions
export default AutopigSlice.reducer