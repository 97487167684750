import ProgressCircle from "../../ProgressViewer/progressCircle"
import { Widget } from "../../BaseComponents"
import { useFeedingData } from "../../../Assets/TestData/lf_dashboard";
import Check, { CheckStyles } from "../../BaseComponents/checkBox";
import ReportShortCut from "../../Reports/reportShortCut";
import { TestData as ReportData } from "../../../Assets/TestData/reportTemplates";
import { useMediaQuery } from "../../../Styles/mediaQueries";
import { colors, fonts } from "../../../Styles";
import { useTranslation } from "react-i18next";
/**
 * Feeding
 * @returns A widget widt feeding calendar.
 */
export default function Feeding() {
  const {t} = useTranslation();
  let FeedingData = useFeedingData();

  const tablet = useMediaQuery(`(min-width: 601px) and (max-width: 1021px)`);

  const getReportName = (reportId: number) => {
    const report = ReportData.find(template => template.reportTemplateNo === reportId);
    return report ? report.name : "";
  };

  return(
    <div style={{display: "flex", flexDirection: "column", width: tablet? "100%" : "fit-content", gap: "10px"}}>
      <Widget
        width="100%"
        header={<p style={{...fonts.header, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("feed.feedCorrections.feeding")}</p>}
        onSave={() => (console.log("save"))}
        content={
          <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between", padding: "20px"}}>
             {FeedingData.map((feeding, feedingIndex) =>
              feeding.progressCircle
                .filter(progress => progress.show)  // Filter to only include progress circles with show=true
                .map((progress, progressIndex) => (
                  <div style={{ minWidth: "170px" }} key={`${feedingIndex}-${progressIndex}`}>
                    <ProgressCircle
                      header={progress.header}
                      unit={progress.unit}
                      progressed={progress.progresed}
                      total={progress.total}
                    />
                  </div>
                ))
            )}
          </div>
        }
        editContent={
          <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "10px", padding: "20px"}}>
           {FeedingData.map((feeding, feedingIndex) =>
              feeding.progressCircle.map((progress, progressIndex) => (
                <div style={{ width: "fit-content" }} key={`${feedingIndex}-${progressIndex}`}>
                  <Check
                    text={progress.header}
                    isChecked={progress.show}
                    checkStyle={CheckStyles.grey}
                  />
                </div>
              ))
            )}
          </div>
        }
      />
      <div style={{display: "flex", flexWrap: "wrap", width: tablet? "100%" : "634px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "10px"}}>
      {FeedingData.map((feeding, feedingIndex) =>
          feeding.report?.map((reportId, reportIndex) => (
            <ReportShortCut
              key={`${feedingIndex}-${reportIndex}`}
              //header={getReportName(reportId)}
              header={feeding.extra&&feeding.extra[reportIndex]?feeding.extra[reportIndex].text: getReportName(reportId)}
              amount={feeding.extra&&feeding.extra[reportIndex]?feeding.extra[reportIndex].number: 0}
              onClick={() => console.log(`Report ${reportId} clicked`)}
            />
          ))
        )}
      </div>
      
    </div>
  )
}