import { css, frames, icons } from "../../Styles/index"
import { useTranslation } from "react-i18next"
import "../../Translations/index"
import { ButtonList } from "../../Components"
import { NavigationFunction, Pages } from "../Routers/CloudPanel/toolsMenu_Router"
import { useNavigate } from "react-router"
import "../../Components/languageSelector"
import { StyleObject } from "styletron-react"
import { useMemo } from "react"
import { ButtonTypes } from "../../Components/BaseComponents/button"

interface ToolsMenu_Props {
  overrides?:{
      gridArea?: string
  }
}

export default function ToolsMenu({overrides}:ToolsMenu_Props) {
  const navigator = useNavigate()
  const {t} = useTranslation()
  
  const BtnFunc_Router = (btn_Index:number) => {
    switch (btn_Index) {
      case 0: // Navigate Def
        NavigationFunction(navigator, Pages.graphQl)
      break
      case 1: // Navigate Def
        NavigationFunction(navigator, Pages.xml)
      break
      case 2:
        NavigationFunction(navigator, Pages.farms)
      break
      case 3:
        NavigationFunction(navigator, Pages.users)
      break
      default:
        alert("Error ???")
    }
  }

  const _Root = useMemo<StyleObject>(() => {
    return Object.assign(
        {} as StyleObject, //default stlye
        overrides&&overrides.gridArea? {gridArea:overrides.gridArea} : {}
    )
  },[overrides?.gridArea])

  return (
    <frames.ToolsMenu $style={_Root}>
      <ButtonList content={[t("toolsMenu.graphQl"), t("toolsMenu.xml"), t("toolsMenu.farms"), t("toolsMenu.users")]}
        onClick={(e) => BtnFunc_Router(e)}
        startEnhancers={[
          {def:icons.dashboard, active:icons.dashboardInv}, 
          {def:icons.kpi, active:icons.kpiInv}, 
          {def:icons.feedCurves, active:icons.feedCurves},
          {def:icons.feedCurves, active:icons.feedCurves},
        ]}
        overrides={{
          button: {
            buttonType: ButtonTypes.globalMenuButton,
          }
        }}
        listStyle="group"
      />
    </frames.ToolsMenu>
  )

}
