import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypes, Modal, SelectField } from "../..";
import { Field_Types } from "../../../Definitions";
import { colors, fonts, icons } from "../../../Styles";

import { TestData } from "../../../Assets/TestData/animalData"

interface AddAnimalToPen_Props{
  activePen: number | null
  isModalOpen: boolean
  goBack: () => void,
}
/**
 * MoveAnimalToPen
 * @param activePen The pen where an animal is going to be assigned to.
 * @param goBack Function to go back.
 * @returns a modal with a pen where the animal, based on selected animal No, is going to be assigned to.
 */
export default function AddAnimalToPen({activePen, isModalOpen, goBack}:AddAnimalToPen_Props) {
  const {t} = useTranslation()
  const [selectedAnimal, setAnimal] = useState<number>(0)

  // Find label in select field options corresponding to their respective ID
  const findLabelById = (id: number, options: { label: string, id: number }[]) => {
    const option = options.find(option => option.id === id);
    return option ? option.label : "";
  };

  const Data = TestData
  const FeedCurvesOptions = useMemo(() => {
  const options = Data.map(animal => ({
    label: animal.animalNo.toString(),
    id: animal.animalNo
  }));
  return options
}, [Data] )

  return (
    <Modal isModalOpen={isModalOpen}
      modalHeader={t("buttons.add") + " " + t("animal.animal")}
      modalSubHeader={"Farrowing stable section x"} //TODO MAJA: Systemnavn, section nr, pen nr. HVis flere pens valgt skal disse "listes op"
      overrides={{ 
        isPrimaryComponent: true 
      }}
      goBackBtn={t("buttons.goBackBtn")}
      onGoBack={goBack}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "60px", marginTop: "25px"}}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px"}}>
        <p style={{...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("animal.animalNo")}</p>
          <SelectField
            defaultValue={findLabelById(selectedAnimal, FeedCurvesOptions)}
            options={FeedCurvesOptions}
            textStart={true}
            fieldType={Field_Types.shortInputField}
            onChange={(e) => setAnimal(parseInt(e[0].id + ''))}
          />
          <img src={icons.goRightArrow} style={{width: "20px"}} alt="Go Right Arrow"/>
          <p style={{...fonts.paragraph, ...fonts.fontSize3, color: colors.greyLightTertiary}}>{t("locationFeeder.penPopup.pen")} {activePen}</p>
        </div>
        <Button 
          content={t("buttons.add") + " " + t("animal.animal") + " " + t("locationFeeder.sections.to") + " " + t("locationFeeder.penPopup.pen")}
          overrides={{
            buttonType: ButtonTypes.tertiaryButton
          }}
          // onClick={} TODO CTO: gem til db og luk modal
        />
      </div>
    </Modal>
  )
}