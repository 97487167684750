import React from 'react'
import { useTranslation } from 'react-i18next'
import {frames, images } from '../../Styles'
import { ButtonList, ButtonTypes, Farrowing, Feeding, PageHeader } from '../../Components/'

export default function Kpi() {
  const {t} = useTranslation()

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between"}}>
        <PageHeader topHeader='KPI' subHeader={t("overview")}/>
          <ButtonList content={["edit kpi dashboard"]} 
                overrides={{button: {buttonType: ButtonTypes.tertiaryButton, disableActive: true}}}
              />
            
        </div>
      </frames._page_inner_header>
      <frames._page_inner_content_scroll>
        <div style={{display: "flex", flexWrap: "wrap", alignItems: "start", justifyContent: "start", gap: "20px", maxWidth: "1288px"}}>
          <div style={{display: "flex", flexDirection: "column", gap: "20px", minWidth: "634px", width: "45%"}}>
            <img src={images.images.farmstatus} alt={"gok"} style={{minWidth: "634px", height: "fit-content"}}/>
            <img src={images.images.waterUsage} alt={"gok"} style={{minWidth: "634px", height: "fit-content"}}/>
          </div>
          <img src={images.images.totalFeed} alt={"gok"} style={{minWidth: "634px", width: "45%", height: "fit-content"}}/>
          <Feeding/>
          <Farrowing/>
        </div>
      </frames._page_inner_content_scroll>
      </frames.Page_inner>
  );
}