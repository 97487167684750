import React, { CSSProperties, useState } from 'react'
import { CheckBox, FeedPopover, InputTypes, PopoverTypes, ProgressBar, SelectField, SkipFeedModal } from "../.."
import { colors, fonts, icons } from "../../../Styles"
import { Field_Types, FieldStyle_Types } from '../../../Definitions';
import { useTranslation } from 'react-i18next';

import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';
import { TestData as PenData} from '../../../Assets/TestData/lf_Data';
import { TestData as AnimalData, TestCalculate_Progresse } from '../../../Assets/TestData/animalData'
import { penTypes } from '../../../Definitions/valueTypes';
import { CheckStyles } from '../../BaseComponents/checkBox';

interface PenEdit_Props {
  penNo: number
  penType: penTypes
  onCheck?: (isChecked: boolean) => void
  isChecked?: boolean
}
/**
 * Pen_Edit
 * @param penNo Unique identifier for each pen.
 * @param penType Basic, advanced and single. Each renders different content of animal data.
 * @returns a editable pen with varying content of animal data for Freeda Location Feeder systems.
 */
export default function Pen_Edit({ penNo, penType, onCheck, isChecked }: PenEdit_Props) {
  const {t} = useTranslation()
  const [showSkipFeedModal, setShowSkipFeedModal] = useState(false);

  // Find the pen and related data
  const pen = PenData.find(item => item.penNo === penNo)
  const [skipFeed, setSkipFeed] = useState(pen)

  // Find the animal and related data
  const animal = AnimalData.find(item => item.animalNo === pen?.animal);
  const [feedCorrection, setFeedCorrection] = useState(animal?.feedCorrection)

  // #region | Popover functions 
  // Skip Feeding Popover functions
  const plan = () => {
    setShowSkipFeedModal(true); // Show the modal when "plan" is clicked
  };

  const forNow = () => {
    if (skipFeed) {
      setSkipFeed({...skipFeed, feeding: !skipFeed.feeding})
    }
  };

  const standard = () => {
    console.log("standard");
  };

  // Feedcorrections Popover functions
  const decrease = () => {
    setFeedCorrection((feedCorrection ?? 0) -1);
  };

  const increase = () => {
    setFeedCorrection((feedCorrection?? 0) +1);
  };

  const reset = () => {
    setFeedCorrection(0);
  };
  // #endregion

  // #region | Data handling
  // Handle feedCurve data and parse an empty string if label is undefined
  const feedCurve = FeedCurveData.find(e => e.id === animal?.feedPlan);
  const feedCurveName = feedCurve?.name ?? "";

  // Handle PenData and feeding status
  // const penData = PenData.find(pen => pen.penNo === animal.pen);
  const feeding = skipFeed?.feeding ? 'On' : 'Off';
  const feedingBtn = skipFeed?.feeding ? t("buttons.forNow") : t("buttons.startFeeding");
  const feedingStyle: CSSProperties = {
    color: skipFeed?.feeding ? colors.systemGreen : colors.systemRed
  };

  const skipStartDate = pen?.skip?.startDate
  const skipEndDate = pen?.skip?.endDate
  const skipStartTime = pen?.skip?.startTime
  const skipEndTime = pen?.skip?.endTime

  const skipped = pen?.skip
  ? (skipStartDate === skipEndDate
      ? `Skip feeding: ${skipStartDate} ${skipStartTime} - ${skipEndTime}`
      : `Skip feeding: ${skipStartDate} ${skipStartTime} - ${skipEndDate} ${skipEndTime}`)
  : "No planned feed break";

  // Handle progress bar data
  const progressBarTotal = animal?.totalFood;
  const progressBarProgressed = animal?TestCalculate_Progresse(animal):0;

  // Create ProgressBar component
  const progressBar = (
    <ProgressBar 
      total={progressBarTotal} 
      progressed={progressBarProgressed}
    />
  );
  // #endregion

  // #region | Content
  // Advanced content to parse in the div
  const advancedContent =
    <div style={{width: "591px", padding: "0 50px 0px 10px"}}>
      {animal? 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 90px 25px 90px 45px 131px 45px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <CheckBox
            checkStyle={CheckStyles.transparent}
            isChecked={isChecked}
            onChange={(checked) => {
              if (onCheck) {
                onCheck(checked);
              }
          }}/>
          <span style={{cursor: "default"}}>{pen?.penNo}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{animal.animalNo}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{animal.latestMilestone}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{animal.milestoneDay}</span>
          <SelectField defaultValue={feedCurveName} options={FeedCurveData.map((feedCurve) => ({label: feedCurve.name, id: feedCurve.id}))} fieldStyle={FieldStyle_Types.tableEdit} fieldType={Field_Types.sectionEditField}  />
          <FeedPopover
            popoverType={PopoverTypes.feedCorrection}
            inputType={InputTypes.Number}
            closeOnBtnClick={[false, false, true]}
            header={t("feed.feedCorrections.feedCorrection")}
            input={feedCorrection} 
            btnText={[icons.minus_white, icons.add_white, t("buttons.reset")]}
            onClick={(i => {
              if (i === 0) decrease()
              else if (i === 1) increase()
              else reset()
            })}
          />
          <span style={{filter: "opacity(0.5)", cursor: "default"}}> {progressBar} </span>
          <FeedPopover
            popoverType={PopoverTypes.skipFeeding}
            inputType={InputTypes.String}
            header={t("feed.feedCorrections.skipFeeding")}
            closeOnBtnClick={[true, true, true]}
            input={feeding} 
            overrides={{ 
              value: { ...feedingStyle } 
            }}
            btnText={[t("buttons.plan"), feedingBtn, t("buttons.standard")]}
            onClick={(i => {
              if (i === 0) plan()
              else if (i === 1) forNow()
              else standard()
            })}
          />
        </div>
      : 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 426px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <span></span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{pen?.penNo}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{t("locationFeeder.penPopup.empty")}</span>
        </div>
      }
    </div>

  const singleContent = 
    <div style={{width: "591px", padding: "0 50px 0px 10px"}}>
      {animal? 
        <div style={{ width: "591px", display: "grid", gridTemplateColumns: "90px 45px 45px 411px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <SelectField defaultValue={feedCurveName} options={FeedCurveData.map((feedCurve) => ({label: feedCurve.name, id: feedCurve.id}))} fieldStyle={FieldStyle_Types.tableEdit} fieldType={Field_Types.sectionEditField}  />
          <FeedPopover
              popoverType={PopoverTypes.feedCorrection}
              inputType={InputTypes.Number}
              closeOnBtnClick={[false, false, true]}
              header={t("feed.feedCorrections.feedCorrection")}
              input={feedCorrection} 
              btnText={[icons.minus_white, icons.add_white, t("buttons.reset")]}
              onClick={(i => {
                if (i === 0) decrease()
                else if (i === 1) increase()
                else reset()
              })}
            />
          <FeedPopover
            popoverType={PopoverTypes.skipFeeding}
            inputType={InputTypes.String}
            header={t("feed.feedCorrections.skipFeeding")}
            input={feeding} 
            closeOnBtnClick={[true, true, true]}
            overrides={{ 
              value: { ...feedingStyle } 
            }}
            btnText={[t("buttons.plan"), feedingBtn, t("buttons.standard")]}
            onClick={(i => {
              if (i === 0) plan()
              else if (i === 1) forNow()
              else standard()
            })}
          />
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{skipped}</span>
        </div>
      : 
        <></>
      }
    </div>
  // #endregion

  return (
    <div style={{
      width: "651px",
      height: "35px", }}>
      <div style={{
        width: "100%",
        height: "100%",
        textAlign: 'center',
        alignContent: "center",
        backgroundColor: colors.greyLightSecondary,
        transition: 'filter 0.3s',
        color: colors.greyDarkPrimary,
        ...fonts.paragraph,
        ...fonts.fontSize4,
      }}>
        {penType === 'advanced' ? advancedContent : penType === 'single' ? singleContent : <></>}
      </div>

      {/* Render SkipFeeding modal when showSkipFeedModal is true */}
      {showSkipFeedModal && <SkipFeedModal goBack={() => setShowSkipFeedModal(false)}/>}

    </div>
  );
}