import { Units, useUnitsComponent } from "../../Styles";
import { TestData as Pens1, TestData2 as Pens2 } from '../../Assets/TestData/lf_Data';
import { ReportTemplate } from "./reportTemplates";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom'

export type ProgressCircle = {
  header?: string
  unit?: JSX.Element
  progresed: number
  total: number
  show: boolean
}

export type Feeding = {
  progressCircle: ProgressCircle[];
  report?: ReportTemplate['reportTemplateNo'][]
  extra?: {
    text:string, number:number
  }[]
};

export const useFeedingData = (): Feeding[] => {
  const getUnit = useUnitsComponent();
  const {t} = useTranslation();

  let location = useLocation()
  const Pens = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?Pens2:Pens1:Pens1

  const totalNumberOfPens = Pens.length;
  const totalNumberOfAnimals = Pens.filter((pen) => pen.animal).length;

  const report = [5, 6]

  const Demodata:{progressed:number, total:number}[] = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?[
    {progressed:96.7, total:100 },
    {progressed:10.1, total:100 },
    {progressed:162.9 , total:259.66 },
    {progressed:0, total:0 }
  ]:[
    {progressed:573.3, total:1484.9 },
    {progressed:46, total:1432 },
    {progressed:911.6 , total:1484.9 },
    {progressed:0, total:0 }
  ]:[//KPI
    {progressed:96.7+573.3, total:100+1484.9 },
    {progressed:10.1+46, total:100+1432 },
    {progressed:162.9+911.6, total:256.6+1484.9 },
    {progressed:0, total:0 }
  ]

  const extras:{ text:string, number:number }[] = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?[
    {text:"Feed Balance (today)", number:0},
    {text:"Feed Balance (yesterday)", number:0},
  ]:[
    {text:"Feed Balance (today)", number:230},
    {text:"Feed Balance (yesterday)", number:15},
  ]:[//KPI
    {text:"Feed Balance (today)", number:230},
    {text:"Feed Balance (yesterday)", number:15},
  ]

  return [
    {
      progressCircle: [
        { header: t("feed.remaining.today"), unit: getUnit(Units.kG_grey), progresed: Demodata[0].progressed, total: Demodata[0].total, show: true },
        { header: t("feed.remaining.yesterday"), unit: getUnit(Units.kG_grey), progresed: Demodata[1].progressed, total: Demodata[1].total, show: true },
        { header: t("feed.fed.today"), unit: getUnit(Units.kG_grey), progresed: Demodata[2].progressed, total: Demodata[2].total, show: true },
        { header: t("feed.fed.period"), unit: getUnit(Units.kG_grey), progresed: Demodata[3].progressed, total: Demodata[3].total, show: false },
      ],
      report: report,
      extra: extras
    }
  ];
};

export type Calendar = {
  progressCircle: ProgressCircle[];
  report?: ReportTemplate['reportTemplateNo'][];
  extra?: {
    text:string, number:number
  }[]
};

export const useCalendarData = (): Calendar[] => {
  const getUnit = useUnitsComponent();
  const {t} = useTranslation();

  let location = useLocation()
  const Pens = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?Pens2:Pens1:Pens1

  const totalNumberOfPens = Pens.length;
  const totalNumberOfAnimals = Pens.filter((pen) => pen.animal).length;

  const report = [7]

  const Demodata:{progressed:number, total:number}[] = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 1?[
    {progressed:0, total:0 },
    {progressed:98, total:149 }
  ]:[
    {progressed:0, total:0 },
    {progressed:197, total:298 }
  ]:[//KPI
    {progressed:0, total:0 },
    {progressed:98+197, total:149+298 }
  ]

  const extras:{ text:string, number:number }[] = (location.state && location.state["system_index"] !== undefined)?location.state["system_index"] === 0?[
    {text:"Farrowing (today)", number:0},
  ]:[
    {text:"Farrowing (today)", number:0},
  ]:[//KPI
    {text:"Farrowing (today)", number:0},
  ]

  return [
    {
      progressCircle: [
        { header: t("reports.farrowingsToday"), unit: getUnit(Units.farrowedTotal_grey), progresed: Demodata[0].progressed, total: Demodata[0].total, show: true },
        { header: t("reports.farrowed"), unit: getUnit(Units.farrowedTotal_grey), progresed: Demodata[1].progressed, total: Demodata[1].total, show: true },
      ],
      report: report,
      extra: extras
    }
  ];
};