import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavigationFunction as RootNavigation, Pages } from "../../../Navigation/Routers/root"
import { useTranslation } from 'react-i18next';
import { Button, ButtonTypes, Modal, PageHeader, FeedCurveViewer, FeedCurveCard } from '../../../Components';
import { colors, frames, icons } from '../../../Styles';

import { useAppSelector } from '../../../Store';
import Graph_QL, { useFeedComponents } from '../../../Services/Agrisys_GqlDataCaller';
import { useFeedCurves } from '../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/useFeedCurves';
import { getFeedCurveComponentsIds } from '../../../Components/_Components/getFeedCurveComponentsIds';

export default function FeedCurves() {
  const {t} = useTranslation()
  const navigator = useNavigate()
  const [showFeedCurve, setShowFeedCurve] = useState<number|undefined>(undefined)
  // #region || Data
  
  const farm = useAppSelector(({activeData}) => activeData.farm)
  const domain = Graph_QL.ObjToString({domain: farm ?? ""});
  const connection = Graph_QL.create({domain: farm ?? ""});

  const {call: getFeedCurvesInfo, data: feedCurvesState } = useFeedCurves(connection);
  
  const feedCurvesData = useMemo(() => {
    return feedCurvesState && feedCurvesState[domain] ? feedCurvesState[domain].data : [];
  },[domain, feedCurvesState])
  
  const feedCurvesIds = useMemo(() => {
    return feedCurvesData.map((feedCurve) => feedCurve.id)
  },[feedCurvesData])

  useEffect(() => {
    if (!feedCurvesState[domain]) {
      getFeedCurvesInfo(connection, {domain})
    }
  },[connection, domain, feedCurvesState, getFeedCurvesInfo])



  const {call: getFeedComponentsInfo, data: feedComponentsState} = useFeedComponents(connection);

  const componentIds = useMemo(() => {
    return feedCurvesData.flatMap((feedCurve) => feedCurve.mixture).map((mixture) => mixture.component).filter((value, index, self) => self.indexOf(value) === index);
  },[feedCurvesData]);

  const feedComponents = useMemo(() => {
    const feedComponents = feedComponentsState && feedComponentsState[domain] ? feedComponentsState[domain].data : [];
    return feedComponents.filter((feedComponent) => componentIds.includes(feedComponent.id));
  },[componentIds, domain, feedComponentsState])

  useEffect(() => {
    if (!feedComponentsState[domain]) {
      getFeedComponentsInfo(connection, {domain, ids: componentIds});
    }
  },[componentIds, connection, domain, feedComponents.length, feedComponentsState, getFeedComponentsInfo])

  // #endregion
  const onFeedCurveSelect = (id:number) => {
    setShowFeedCurve(id)
  }

  // Save current FeedCurveId and selected FeedCurveData to const
  const currentFeedCurveId = () => feedCurvesIds.indexOf(showFeedCurve!);
  const selectedFeedCurveData = feedCurvesData.find(feedCurve => feedCurve.id === showFeedCurve);

  // Function to handle navigation between FeedCurves in popup Modal with looping
  const navigateFeedCurves = (direction: number) => {
    const currentIndex = currentFeedCurveId();
    if (currentIndex !== -1) {
      const newIndex = (currentIndex + direction + feedCurvesIds.length) % feedCurvesIds.length;
      setShowFeedCurve(feedCurvesIds[newIndex]);
    }
  };

  // Navigate to next FeedCurve in popup Modal
  const goToNext = () => { navigateFeedCurves(1) }
  // Navigate to previous FeedCurve in popup Modal
  const goToPrevious = () => { navigateFeedCurves(-1) }

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <PageHeader topHeader={t("globalMenu.feedCurves")} subHeader={t("overview")}/>
      </frames._page_inner_header>

      <frames._page_inner_content>
        {/* Wrap of Feedcurve cards with miniature feedCurveViewer */}
        <frames._page_inner_content_scroll>
          <frames.CardsColumnCount>
          {feedCurvesData.map((feedCurve) => (
            <FeedCurveCard key={feedCurve.id} feedCurve={feedCurve} feedComponents={feedComponents} onFeedCurveSelect={onFeedCurveSelect} feedCurveId={feedCurve.id} />
          ))}
          </frames.CardsColumnCount>
        </frames._page_inner_content_scroll>
      </frames._page_inner_content>
      
      {/* Popup Modal for clicked feedcurve */}
      <Modal
        isModalOpen={!!showFeedCurve}
        overrides={{
          isPrimaryComponent: true,
          root: { padding: "40px 5px 5px 5px" },
          background: colors.greyLightTertiary
        }}
        modalHeader={t("feed.feedPlan.feedCurve") + ` ${showFeedCurve}`}
        modalSubHeader={`${selectedFeedCurveData?.name}`}
        modalContent={
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px"}}>
            <Button 
              content={t("buttons.prev") + " " + t("feed.feedPlan.feedCurve")}
              onClick={goToPrevious}
              startEnhancer={icons.goLeftArrow}
              overrides={{ 
                buttonType: ButtonTypes.textButtonSmall
              }}
            />  
            <div style={{backgroundColor: colors.blueDarkSecondary, height: "20px", width: "1.5px", margin: "0 auto"}}/>
            <Button 
              content={t("buttons.next") + " " + t("feed.feedPlan.feedCurve")}
              onClick={goToNext}
              endEnhancer={icons.goRightArrow}
              overrides={{ 
                buttonType: ButtonTypes.textButtonSmall
              }}
            /> 
          </div>
        }
        onCloseBtn='close'
        onClose={() => { setShowFeedCurve(undefined) }}
      >
        {/* Modal content */}
        <div style={{backgroundColor: colors.greyLightTertiary, borderRadius: "0px 0px 10px 10px", padding: "0px 20px 30px 20px"}}>
        
          {/* FeedCurveViewer */}
          {selectedFeedCurveData && (
            <FeedCurveViewer data={selectedFeedCurveData} feedComponents={feedComponents} showComponentLabels={true} showMilestoneLabels={true} />
          )}

          {/* Show more FeedCurve details btn */}
          <div style={{margin: "20px auto 0px auto", width: "100%", textAlign: "center"}}>
            <Button 
              content={t("feed.feedPlan.feedCurve") + " " + t("buttons.details")}
              onClick={() => {
                RootNavigation(navigator, Pages.feedCurves, Pages.feedCurve, true, {selected:selectedFeedCurveData?.id})
              }}
              overrides={{ 
                buttonType: ButtonTypes.tertiaryButton
              }}
            />
          </div>
        </div>
      </Modal>
    </frames.Page_inner>
  );
}  