import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import merge from 'lodash/merge';
import { keyBy } from "lodash";

import { FeedComponentInfo } from "../GQLCalls/getFeedComponentsCall";
import Graph_QL, { DynamicDataObk } from "../..";


export type FeedComponentItem = DynamicDataObk<FeedComponentInfo[]>

const feedComponentsDataAdapter = createEntityAdapter({
  selectId: (item: FeedComponentItem) => Graph_QL.ObjToString(item.con)
});

const mergePayloadAndState = (state: EntityState<FeedComponentItem, string>, {con, data}: FeedComponentItem) => {
  const identifier = Graph_QL.ObjToString(con);
  if (state.entities[identifier]) {
    const mergedData = Object.values(merge(keyBy(state.entities[identifier].data, "id"), keyBy(data, "id")));
    const newPayload = {con, data: mergedData}
    feedComponentsDataAdapter.upsertOne(state, newPayload);
  } else { feedComponentsDataAdapter.addOne(state, {con, data})}
}

const feedComponentsSlice = createSlice({
  name: "feedComponents",
    initialState: feedComponentsDataAdapter.getInitialState<FeedComponentItem[]>([]),
      reducers: {
        maintained: (state, {payload}:PayloadAction<FeedComponentItem>) => {
          mergePayloadAndState(state, payload);
        },
        deleted: (state, {payload}:PayloadAction<FeedComponentItem[]>) => {
          //#TODO
      },
    }
})

export const FeedComponentsActions = feedComponentsSlice.actions;
export default feedComponentsSlice.reducer;