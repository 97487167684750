//import React from "react";
import { NavigateFunction, RouteObject, Outlet } from "react-router-dom";
import { Logsndata, XML, Farms, Configurations, Software, Users } from "../../../Containers/index";
import TabMenu from "../../Menus/tabMenu";

/* eslint-disable */
export enum Pages {
  home="./",
  tools="tools",

  graphQl="GraphQl",
  logsndata = "Logsndata",
  configurations = "configurations",
  software = "software",

  xml = "xml",
  
  farms = "farms",

  users = "users"
}
/* eslint-enable */

export const Router = [{
  path:`/${Pages.tools}`, element: <Outlet key={"main"}/>,
  children:[ //...ToolsMenuRouter,
    { path:`/${Pages.tools}/${Pages.graphQl}`, element:<TabMenu key={"graphQl"} main={Pages.graphQl} overrides={{base_alternative:Pages.logsndata}} contexts={[Pages.configurations, Pages.software]} />,
      children:[
        { index:true, path:`/${Pages.tools}/${Pages.graphQl}/${Pages.logsndata}`, element:<Logsndata/>},
        { path:`/${Pages.tools}/${Pages.graphQl}/${Pages.configurations}`, element:<Configurations/>},
        { path:`/${Pages.tools}/${Pages.graphQl}/${Pages.software}`, element:<Software/>},
      ]
    },  
    { path:`/${Pages.tools}/${Pages.xml}`, element:<XML/> },
    { path:`/${Pages.tools}/${Pages.farms}`, element:<Farms/> },
    { path:`/${Pages.tools}/${Pages.users}`, element:<Users/> }
  ]
}] as RouteObject[]

export const NavigationFunction = (Navigator: NavigateFunction, navigation_page:Pages, navigation_sub?:Pages) => {
  console.log("nav", navigation_page, navigation_sub)
  switch (navigation_page) {
    case Pages.home:
      Navigator(Pages.home)
    break
    case Pages.graphQl:
      switch (navigation_sub) {
        case Pages.logsndata:
          Navigator(Pages.logsndata)
        break
        case Pages.configurations:
          Navigator(Pages.configurations)
        break
        case Pages.software:
          Navigator(Pages.software)
        break
        default:
          Navigator("../"+Pages.tools+"/"+Pages.graphQl+"/"+Pages.logsndata, {replace:true})
      }
    break
    case Pages.xml:
      Navigator(Pages.tools+"/"+Pages.xml)
    break
    case Pages.farms:
      Navigator(Pages.tools+"/"+Pages.farms)
    break
    case Pages.users:
      Navigator(Pages.tools+"/"+Pages.users)
    break
    default:
      console.log("Error page not found (404)???, [ToolsMenu]")
  }
}
