import { FeedComponentInfo } from "../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedComponentsCall";
import { colors } from "../../Styles";

enum dataFetch_Params {
  type = "type",
  value = "value",
  style = "style",
  static = "static"
}
export interface Component_Data {
  datas: Component[],
  toString: () => string           //ListData_ValueTypes     //CSSProperties
  getStatic: ( valueIndex:number) => boolean
  data: (dataindex:number, valueIndex:number, fetch?:dataFetch_Params) => any|string|number|boolean|any|boolean
}
export function newComponent(datas:Component|Component[]): Component_Data {
  return new _Component(datas)
}
class _Component implements Component_Data {
  datas:Component[]
  public constructor(datas:Component|Component[]) {
    this.datas = Array.isArray(datas)?datas:[datas]
  }

  getStatic(valueIndex:number) { 
    return valueIndex===0?true:false
  }
  data (dataindex:number, valueIndex:number, fetch=dataFetch_Params.value) {
    switch (fetch) {
      case dataFetch_Params.value:
        return this.datas[dataindex]["id"]
      case dataFetch_Params.static:
        return this.getStatic(valueIndex)
    }
  }

  toString() {
    return "NON"
  }
}

  export type Component = {
    id: number; //DataTYPE ID, static
    name: string; 
    readyMixture: boolean;
    priority: number;
    ds: number;
    energy: number;
    protein: number;
    deleted: boolean;
  };

  export const TestData: FeedComponentInfo[] = [
    {
      id: 1,
      name: "Gestation mix 1",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
    },
    {
      id: 2,
      name: "Gestation mix 2",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
    },
    {
      id: 3,
      name: "Farrowing mix 1",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
    },
    {
      id: 4,
      name: "Farrowing mix 2",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
    },
    {
      id: 6,
      name: "Deworming",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
    }
  ]

  export const TestData2: FeedComponentInfo[] = [
    {
      id: 1,
      name: "Duroc Breeding",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
    },
    {
      id: 2,
      name: "Duroc Gestation",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
      },
    {
      id: 3,
      name: "Duroc Gestation Plus",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
      },
    {
      id: 4,
      name: "Duroc Farrowing",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
      },
    {
      id: 5,
      name: "Finisher",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
     },
    {
      id: 6,
      name: "Finisher Plus",
      readyMixture: false,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
     },
    {
      id: 7,
      name: "Weaner",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
      },
    {
      id: 8,
      name: "Weaner Plus",
      readyMixture: false,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
      },
    {
      id: 9,
      name: "Gilts",
      readyMixture: true,
      priority: 1,
      ds: 100,
      energy: 100,
      protein: 100,
      deleted: false,
      },
    
  ]

