//import { client } from '../../api/client'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'

import { Graph_QL, DynamicDataObk } from '../../';

import { GET_SECTIONS, GetSectionsArgs, Section, GetSectionsResponse } from "../GQLCalls/getCalls"
import { Pen } from "../GQLCalls/getCalls"

type Struckture_DataTypes = Section[]|any[]
export type StrucktureStates = {
  Sections?:Section[],
  Pens?:any[]
}
type StrucktureStates_Keys = keyof ({all:any} & StrucktureStates)

export type Struckture_Item = DynamicDataObk<StrucktureStates>
export const dataAdapter = createEntityAdapter({
  selectId: (item:Struckture_Item) => Graph_QL.ObjToString(item.con),
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
})

//#region Helper TransFormers
const data_TransForm = (DataContainer?:Struckture_DataTypes|undefined) => {
  if (DataContainer) {
    if (Array.isArray(DataContainer)) {
      return DataContainer.map((e) => {
        var entry = {...e }
        if (e.pens) {
          entry.pens = e.pens.map((pen:Pen) => {
            var _pen = {...pen}
            if (_pen.__typename) delete _pen.__typename
            return _pen
          })
        }
        if (entry.__typename) delete entry.__typename
        return entry
      })
    }
    else {
      // var entry = {...DataContainer as LiveStateItem}
      // delete entry.__typename
      // return entry
      return undefined
    }
  }
  else return undefined
}

const UpdateEntryState = (state:EntityState<Struckture_Item, string>, Entries:Struckture_Item) => {
  var Current = state.ids.find(e => e === Entries.con.toString())
  if (Current) {
    var NewEntries = Object.assign({}, state.entities[Current], Entries)
    dataAdapter.upsertOne(state, NewEntries)
  }
  else dataAdapter.addOne(state, Entries)
}

const SingleDataType_Adder = (state:EntityState<Struckture_Item, string>, payload:DynamicDataObk[], DataKey:StrucktureStates_Keys) => {
  payload.forEach((entries:DynamicDataObk) => {
    var NewDatas: Struckture_DataTypes|undefined = undefined
    //if (DataKey === "State") NewDatas = data_TransForm(entries.data as unknown as LiveStateItem)
    //else 
    NewDatas = data_TransForm(entries.data as unknown as Struckture_DataTypes)

    var Data = {[DataKey]:NewDatas}
    UpdateEntryState(state, {data:Data, con:entries.con})
  })
}
//#endregion

const StrucktureSlice = createSlice({
  name: 'domain/struckture', initialState:dataAdapter.getInitialState(),
  reducers: {
    sections: (state, {payload}:PayloadAction<Struckture_Item[]>) => {
      SingleDataType_Adder(state, payload, "Sections")
    },
    pens: (state, {payload}:PayloadAction<Struckture_Item[]>) => {
      SingleDataType_Adder(state, payload, "Pens")
    },
    added: (state, {payload}:PayloadAction<Struckture_Item[]>) => {
      payload.forEach(entries => {
        var DataEntries:Struckture_Item = {con: entries.con, data:{}}
        if (entries.data.Sections) DataEntries.data.Sections = data_TransForm(entries.data.Sections) as Section[]
        if (entries.data.Pens) DataEntries.data.Pens = data_TransForm(entries.data.Pens) as any[]

        UpdateEntryState(state, DataEntries)
      })
    },
    deleted: (state, {payload}:PayloadAction<Struckture_Item[]|Graph_QL.Connection[]>) => {
      payload.forEach(entries => {
        var entriesCon:Graph_QL.ConnectionObj = Object.hasOwn(entries, "con")?(entries as Struckture_Item).con:(entries as Graph_QL.Connection).connection
        var Current = state.ids.find(e => e === entriesCon.toString())
        if (Current) {
          //if referance does not contain singular data container referance, delete the full entries store
          if (Object.hasOwn(entries, "data")) {
            //else go through each singular data container referance
            if (Object.hasOwn((entries as Struckture_Item), "Sections")) { //TODO: could delete on timestamp ???
            // (entries as Autopig_Item).data.forEach(entry => {
            //   //if data entry referance exists: delete it
            //   var Data_Current = state.entities[Current+''].data.findIndex(e => e.id === entry.id)
            //   if (~Data_Current)state.entities[Current+''].data.splice(Data_Current, 1)
            // })
            // //if data entries container is empty: delete the entries store
            // //if ((entries as Autopig_Item).data.length === 0) dataAdapter.removeOne(state, Current)
              UpdateEntryState(state, {con:entriesCon, data:{...entries.data, Sections:undefined}})
            }
            if (Object.hasOwn((entries as Struckture_Item), "Pens")) {
              UpdateEntryState(state, {con:entriesCon, data:{...entries.data, Pens:undefined}})
            }
            // //if data entries container is empty: delete the entries store
            if (!Object.values((entries as Struckture_Item)).some(e => e)) dataAdapter.removeOne(state, Current)
          }
          else dataAdapter.removeOne(state, Current)
        }
      })
      //Do not remove all in deleted... use intentional clear
    },
    clear: (state, {payload}:PayloadAction<Graph_QL.Connection[]|undefined>) => {
      if (payload) dataAdapter.removeMany(state, payload.map(e => e.toString()))
      else dataAdapter.removeAll(state)
    }
  }
})

//const { actions, reducer } = ActiveSlice
//export const { added, deleted } = LogSlice.actions
export const StrucktureActions = StrucktureSlice.actions
export default StrucktureSlice.reducer