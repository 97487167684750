import { css, frames, icons } from "../../Styles/index"
import { useTranslation } from "react-i18next"
import "../../Translations/index"
import { ButtonList } from "../../Components"
import { NavigationFunction, Pages } from "../Routers/CloudPanel/systemMenu_Router"
import { useNavigate } from "react-router"
import "../../Components/languageSelector"
import { StyleObject } from "styletron-react"
import { useMemo } from "react"
import { ButtonTypes } from "../../Components/BaseComponents/button"

interface SystemMenu_Props {
  overrides?:{
      gridArea?: string
  } 
}

export default function SystemMenu({overrides}:SystemMenu_Props) {
  const navigator = useNavigate()
  //const {t} = useTranslation()
  
  const BtnFunc_Router = (btn_Index:number) => {
    switch (btn_Index) {
      // case 0: 
      //   NavigationFunction(navigator, Pages.autopig)
      //   break
      case 0: 
        NavigationFunction(navigator, Pages.freeda_lf)
        break
      case 1:
        NavigationFunction(navigator, Pages.freeda_lf2)
        break
      default:
        console.log("Error no route ???", btn_Index)
        break;
    }
  }

  const _Root = useMemo<StyleObject>(() => {
    return Object.assign(
        {} as StyleObject, //default stlye
        overrides&&overrides.gridArea? {gridArea:overrides.gridArea} : {}
    )
  },[overrides?.gridArea])

  return (
    <frames.SystemMenu $style={_Root}>    
      <ButtonList content={[/*"AutoPig",*/ /*"Freeda Location Feeder"*/ "Farrowing 1", /*"Freeda Location Feeder"*/ "Farrowing 2", "Freeda Walk-Through /-In", "Freeda Ppt", "Freeda Sorting"]}
        onClick={(e) => BtnFunc_Router(e)}
        startEnhancers={[/*icons.autoPig,*/ icons.freedaLF, icons.freedaLF, icons.freedaESF_WalkThrough, icons.freedaPPT, icons.freedaSorter]}
        overrides={{
          button: { 
            buttonType: ButtonTypes.primaryButton,
          },
          disabled:[2,3,4],
          useNavigationControl: "system_index"
        }}
        listStyle="vertical"
      />
    </frames.SystemMenu>
  )

}
