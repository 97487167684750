/* eslint-disable */
enum Colors {
    blackPrimary = "#1D1D1B",
    greyDarkPrimary = "#293032",
    greyDarkSecondary = "#3B4143",
    greyPrimary = "#757D80",
    greySecondary = "#697073",
    greyTertiary = "#C8C8C9",
    greyLightPrimary = "#CCCCCC",
    greyLightSecondary = "#D9D9D9",
    greyLightTertiary = "#E8E8E8",
    whitePrimary = "#FFFFFF",
    transparent = "#FFFFFF00",

    blueDarkPrimary = "#4563AC",
    blueDarkSecondary = "#3D5AA3",
    blueLightPrimary = "#A4B9CF",
    blueLightSecondary = "#93AECB",

    systemRed = "#772634",
    systemYellow = "#CCC27D",
    systemGreen = "#67A86B",

    graphWater = "#2E7CBD",
    graphTemperature = "#B21920",
    graphGrowth = "#F9C953",
    graphActivity = "#E5560F",
    graphAverage= "#00933D",
    graphBlueOne = "#91AFD3",
    graphBlueTwo = "#1C2D41",
    graphBlueThree = "#7EA1BB",
    graphBlueFour = "#3B506F",
    graphBlueFive = "#A8BAD3",
    graphBlueSix = "#E6EEFF",
    graphBlueSeven = "#728AA9",
    graphBlueEight = "#5B7691",
    graphBlueNine = "#D3E4F7",
    graphBlueTen = "#4D6786",
    graphBlueEleven = "#2E4259",
    graphBlueTwelve = "#C6D6EB",
}

const GRAPH_COLOR_AMOUNT = 12;

export const getGraphColor = (id: number) => {
    switch (Math.abs(id) % GRAPH_COLOR_AMOUNT) {
        case (0): {
            return Colors.graphBlueOne;
        }
        case (1): {
            return Colors.graphBlueTwo;
        }
        case (2) : {
            return Colors.graphBlueThree; 
        }
        case (3) : {
            return Colors.graphBlueFour; 
        }
        case (4) : {
            return Colors.graphBlueFive; 
        }
        case (5) : {
            return Colors.graphBlueSix; 
        }
        case (6): {
            return Colors.graphBlueSeven;
        }
        case (7) : {
            return Colors.graphBlueEight; 
        }
        case (8) : {
            return Colors.graphBlueNine; 
        }
        case (9) : {
            return Colors.graphBlueTen; 
        }
        case (10) : {
            return Colors.graphBlueEleven; 
        }
        case (11): {
            return Colors.graphBlueTwelve;
        }
        default: {
            return Colors.graphBlueOne;
        }
    }
}
export { Colors as colors}
/* eslint-enable */