import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import Graph_QL, { DynamicDataObk } from "../..";
import { FeedCurveInfo } from "../GQLCalls/getFeedCurvesCall";


export type FeedCurveItem = DynamicDataObk<FeedCurveInfo[]>

const feedCurvesDataAdapter = createEntityAdapter({
  selectId: (item: FeedCurveItem) => Graph_QL.ObjToString(item.con)
});

const mergePayloadAndState = (state: EntityState<FeedCurveItem, string>, {con, data}: FeedCurveItem) => {
  const identifier = Graph_QL.ObjToString(con);
  if (state.entities[identifier]) {
    const dataDictionary = keyBy(state.entities[identifier].data, "id")
    data.forEach((entry) => dataDictionary[entry.id] = entry)
    const newPayload = {con, data: Object.values(dataDictionary)}
    feedCurvesDataAdapter.upsertOne(state, newPayload);
  } else { feedCurvesDataAdapter.addOne(state, {con, data})}
}

const feedCurvesSlice = createSlice({
  name: "feedCurves",
    initialState: feedCurvesDataAdapter.getInitialState<FeedCurveItem[]>([]),
      reducers: {
        maintained: (state, {payload}:PayloadAction<FeedCurveItem>) => {
          mergePayloadAndState(state, payload);
        },
        deleted: (state, {payload}:PayloadAction<FeedCurveItem[]>) => {
          //#TODO
      },
    }
})

export const FeedCurvesActions = feedCurvesSlice.actions;
export default feedCurvesSlice.reducer;