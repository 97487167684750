import react, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../Services'
import { useAppSelector } from '../../Store'
import { CurveViewer, PageHeader, InputFieldList_PropHandle, Button, ButtonTypes } from "../../Components"
import { colors, fonts, icons, frames } from '../../Styles'
import { autopigDias } from '../../Styles/images'

import { TypedDocumentNode, gql, useLazyQuery } from "@apollo/client"
// # GetLive
export interface GetLiveStateArgs {
  domain:string, gate:number, device:number,
  //humanTime?:boolean
}
export interface LiveStateItem {
  state:boolean, __typename?:string,
  value1:number, value2:number, value3:number, 
  value4:number, value5:number, value6:number, 
}
export interface LiveStateResponse {
  getOnlineState:LiveStateItem
}
export const GET_LIVE_STATE: TypedDocumentNode<LiveStateResponse, GetLiveStateArgs> = gql`
  query GetOnlineState($domain: String!, $gate: Int!, $device: Int!) {
    getOnlineState(domain: $domain, gatewayindex: $gate, mapdeviceindex: $device) {
      state
      value1 value2 value3
      value4 value5 value6
    }
  }
`;

interface SlideshowProps {
  images?: string[];
}

const AutoPig: React.FC<SlideshowProps> = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [intervalTime, setIntervalTime] = useState(2); // default 2 seconds
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [showAutoPig, setShowAutoPig] = useState(false);

  const [getStateFromDevice, {called, fetchMore, refetch, loading, error, data, startPolling, stopPolling }] = useLazyQuery(GET_LIVE_STATE, {
    variables: {domain:"demo", gate:2, device:40} as GetLiveStateArgs,
    onCompleted: (data) => {},
    fetchPolicy: "no-cache",
    pollInterval: 1000,
  })
  const {State, weight} = useMemo(() => {
    var State = false
    var weight:number|string = 0
    if (data && data.getOnlineState) {
      console.log("Datas:", data.getOnlineState)
      State = data.getOnlineState.state
      weight = data.getOnlineState.value1
      weight = weight / 1000
      weight = weight.toFixed(1).padStart(2, '0')
    }
    return {State, weight}
  }, [data]);

  // #region | Slideshow functionality 
  const images = [
    autopigDias.autopig1,
    autopigDias.autopig2,
    autopigDias.autopig3,
    autopigDias.autopig4,
    autopigDias.autopig5,
    autopigDias.autopig6,
    autopigDias.autopig7,
    autopigDias.autopig8
  ];

  const toggleShowAutoPig = () => {
    setShowAutoPig((prev) => {
      var _new = !prev
      if (_new) startPolling(1000)
      else stopPolling()
      return _new
    }); // Skift tilstand
  };

  const handleStartStop = () => {
    setIsRunning((prev) => !prev);
  };

  const handleIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (newValue === '') {
      setIntervalTime(0);
      return;
    }

    const newInterval = Number(newValue);
    if (newInterval > 0) {
      setIntervalTime(newInterval);
    }
  };

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, intervalTime * 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isRunning, intervalTime, images.length]);
  // #endregion

  // #region | Btns and sec input
  const switchBtn = 
    <Button 
      content={showAutoPig ? 'Show Slideshow' : 'Show AutoPig'} 
      onClick={toggleShowAutoPig}
      overrides={{
        buttonType: ButtonTypes.quaternaryButton,
        disableActive: true
      }}
    />

  const slideshowBtn =
    <Button 
      content={isRunning ? 'Stop Slideshow' : 'Start Slideshow'}
      onClick={handleStartStop}
      overrides={{
        buttonType: ButtonTypes.quaternaryButton,
        disableActive: true
      }}
    />

  const slideshowSec = 
    <div style={{
      height: "50px", 
      width: "170px", 
      display:"flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
      backgroundColor: colors.greyDarkPrimary, 
      border: "none", 
      borderRadius: "10px"
    }}>
      <p style={{ color: colors.greyLightTertiary, ...fonts.header, ...fonts.fontSize4, padding: "0px 10px" }}>Slideshow Speed (sec)</p>
      <input
        type="number"
        value={intervalTime}
        onChange={handleIntervalChange}
        placeholder="Indtast sekunder"
        style={{ backgroundColor: colors.greyDarkPrimary, border: "none", outline: "none", color: colors.greyLightTertiary, ...fonts.paragraph, ...fonts.fontSize4, padding: "0px 10px", width: "100px" }}
      />
    </div>
  // #endregion

  return (
    <frames.Page_inner>
      <frames._page_inner_header>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
        <PageHeader topHeader='AutoPig' subHeader='Overview'/>
          <div style={{height: "50px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px"}}>
            {showAutoPig ? 
            <> {switchBtn} </> 
            : <> 
              {slideshowSec}
              {slideshowBtn}
              {switchBtn} 
            </> }
          </div>
        </div>
      </frames._page_inner_header>
      <div style={{ overflowY: "scroll", scrollbarColor: colors.greyDarkPrimary+colors.greyDarkSecondary, scrollbarWidth: "thin" }}>
        {showAutoPig ?
        // #region | Autopig
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", height: "100%" }}>
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -180%)" }}>
              <p style={{ backgroundColor: colors.greyLightTertiary, padding: "10px", borderRadius: "10px", ...fonts.header, ...fonts.fontSize0, color: colors.greyDarkPrimary }}>
                {weight + " kg"}
              </p>
            </div>
            <img style={{ maxHeight: "80vh", maxWidth: "100%" }} src={autopigDias.autopig} alt="AutoPig" />
          </div>
        </>
        // #endregion
        : 
        // #region | Slideshow
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", height: "100%" }}>
            <img
              src={images[currentImageIndex]}
              alt={`Slide ${currentImageIndex + 1}`}
              style={{ maxWidth: "1200px", maxHeight: "80vh" }}
            />
          </div>
        // #endregion
        }
      </div>
    </frames.Page_inner>
  );
};

export default AutoPig;

// export default function AutoPig() {
//   const {t} = useTranslation()

//   // const activefarm = useAppSelector(state => state.activeData.farm)

//   // if (!activefarm) return null

//   return (
//     <>
//       <PageHeader topHeader='AutoPig' subHeader={t("overview")}/>
//       {/* <CurveViewer userDomain={"cuarte"} userGate={1} userDevice={40} setLastDays={40} /> */}
//     </>
//   );
// }