import { colors } from "../Styles";
import { AttentionTypes } from "./valueTypes";

export const CreateAttentionStyles = (attentionType?:string) => {
    let textColor = "";
    let attentionText = "";
    let attentionColor = "";
  
    switch (attentionType) {
      case AttentionTypes.good:
        textColor = colors.greyLightTertiary
        attentionText = "System Running"
        attentionColor = colors.systemGreen
      break;
      case AttentionTypes.warning:
        textColor = colors.greyDarkPrimary
        attentionText = "System Warning"
        attentionColor = colors.systemYellow
      break;
      case AttentionTypes.alert:
        textColor = colors.greyLightTertiary
        attentionText = "System Alert"
        attentionColor = colors.systemRed
      break;
      case AttentionTypes.neutral:
      default:
        textColor = colors.greyLightTertiary
        attentionText = "System Off"
        attentionColor = colors.blackPrimary
      break;
    }

    return { textColor, attentionText, attentionColor }
  }