import react, { CSSProperties, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AddAnimalToPen, ProgressBar } from "../.."
import { colors, fonts, } from "../../../Styles"
import { penTypes } from '../../../Definitions/valueTypes';

import { TestData as FeedCurveData } from '../../../Assets/TestData/feedCurvesData';
import { TestData as PenData} from '../../../Assets/TestData/lf_Data';
import { TestData as AnimalData, TestCalculate_Progresse } from '../../../Assets/TestData/animalData'

interface PenAdd_Props {
  penNo: number
  hasHoverEffekt?: boolean
}
/**
 * Pen_Add
 * @param penNo Unique identifier for each pen.
 * @param hasHoverEffekt Boolean that, if true, adds a hover effect to the pen.
 * @param onClick Dynamic onClick eventhandler.
 * @returns a pen where onMouseEnter adds a hovereffect on empty pens.
 */
export default function Pen_Add({ penNo, hasHoverEffekt }: PenAdd_Props) {
  const {t} = useTranslation()
  const [isHovered, setIsHovered] = useState(false);
  const [showAddAnimalToPenModal, setAddAnimalToPenModal] = useState(false) // Add Animal Modal

  // Find the pen and related data
  const pen = PenData.find(item => item.penNo === penNo)

  // Find the animal and related data
  const animal = AnimalData.find(item => item.animalNo === pen?.animal);

    // #region | Data handling
  // Handle feedCurve data and parse an empty string if label is undefined
  const feedCurve = FeedCurveData.find(e => e.id === animal?.feedPlan);
  const feedCurveName = feedCurve?.name ?? "";

  // Handle PenData and feeding status
  // const penData = PenData.find(pen => pen.penNo === animal.pen);
  const feedCorrection = animal?.feedCorrection
  const feeding = pen?.feeding ? 'On' : 'Off';
  const feedingStyle: CSSProperties = {
    color: pen?.feeding ? colors.systemGreen : colors.systemRed
  };

  // Handle progress bar data
  const progressBarTotal = animal?.totalFood;
  const progressBarProgressed = animal?TestCalculate_Progresse(animal):0;

  // Create ProgressBar component
  const progressBar = (
    <ProgressBar 
      total={progressBarTotal} 
      progressed={progressBarProgressed}
    />
  );
  // #endregion

  // #region | Content
  // Advanced content to parse in the div
  const advancedContent =
    <div style={{width: "591px", padding: "0 50px 0px 10px"}}>
      {animal? 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 90px 25px 90px 45px 131px 45px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <span></span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{pen?.penNo}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{animal.animalNo}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{animal.latestMilestone}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}>{animal.milestoneDay}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default", paddingLeft: "5px"}}>{feedCurveName}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default", paddingLeft: "5px"}}>{feedCorrection}</span>
          <span style={{filter: "opacity(0.5)", cursor: "default"}}> {progressBar} </span>
          <span style={{filter: "opacity(0.5)", cursor: "default", paddingLeft: "5px", ...feedingStyle,}}>{feeding}</span>
        </div>
      : 
        <div style={{ display: "grid", gridTemplateColumns: "35px 45px 45px 426px", textAlign: "start", alignItems: "center", gap: "5px" }}>
          <span></span>
          <span style={{cursor: "pointer"}}>{pen?.penNo}</span>
          <span style={{cursor: "pointer"}}>{t("locationFeeder.penPopup.empty")}</span>
        </div>
      }
    </div>
  // #endregion
    
  // Open Add Animal Modal on pen click
  const handlePenClick = () => {
    if (!animal) {
      setAddAnimalToPenModal(true);
    }
  };

  // Apply hover effect only if hasHoverEffekt is true
  const handleMouseEnter = () => {
    if (hasHoverEffekt && !animal) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (hasHoverEffekt && !animal) {
      setIsHovered(false);
    }
  };

  return (
    <>
      <div 
        onClick={handlePenClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ 
          width: "651px",
          height: "35px",
          cursor: hasHoverEffekt && !animal? "pointer" : "", 
      }}>
        <div style={{
          width: "100%",
          height: "100%",
          textAlign: 'center',
          alignContent: "center",
          backgroundColor: colors.greyLightSecondary,
          filter: isHovered ? 'opacity(0.5)' : 'opacity(1)',
          transition: 'filter 0.3s',
          color: colors.greyDarkPrimary,
          ...fonts.paragraph,
          ...fonts.fontSize4,
        }}>
          {advancedContent}
        </div>
      </div>
      {/* Add Animal Modal */}
      <AddAnimalToPen isModalOpen={showAddAnimalToPenModal} activePen={pen?.penNo ?? 0} goBack={() => setAddAnimalToPenModal(false)} />
    </>
  );
}