import { useEffect, useState } from "react";
import { NumberDisplayList } from ".."
import { useTranslation } from "react-i18next";

import { getPenById } from "../../Assets/TestData/lf_Data";

interface activations_Props {
  penId: number
}
/**
 * Activations
 * @param penId Number to identify which pen the data should come from.
 * @returns a NumberDisplayList that shows "activations today" and "last activated" for a specifik pen.
 */
export default function Activations({ penId }: activations_Props) {
  const {t} = useTranslation()
  const [unit, setUnit] = useState<string>('');
  const [lastActivated, setLastActivated] = useState<string>('');

  // Get pen from penId
  const pen = penId ? getPenById(penId) : null;

  // Get activationsToday from specifik pen
  const activationsToday = pen?.activationsToday ?? 0
  
  const _activationsToday = activationsToday === 1 ? t("units.timeToday") : t("units.timesToday");

  // Format last activated timestamp and return "unit text" and hours/minutes based on minut amount
  const formatTimeFromTimestamp = (timestamp: number | undefined) => {
    if (!timestamp) {
      return { time: '-', unit: t("noData") }; // If timestamp is undefined, return empty strings
    }

    const timeInMs = timestamp * 1000; 
    const now = Date.now(); 
    const difference = now - (now - 1000*60*timestamp);
      
    const totalMinutes = Math.floor(difference / 1000 / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
     
    let time: string;
    let unit: string;
      
    if (totalMinutes >= 120) {
      time = `${String(hours)}`; 
      unit = t("units.hoursAgo"); 
      } else if (totalMinutes >= 60) {
        time = `${String(hours)}`;
        unit = t("units.hourAgo") //
      } else if (totalMinutes === 1) {
        time = `${String(totalMinutes)}`;
        unit = t("units.minutAgo"); //
      } else {
        time = `${String(minutes)}`;
        unit = t("units.minutesAgo");
      }
      
    return { time, unit };
  };

  // Update last activated every minut to watch changes live
  useEffect(() => {
    const updateLastActivated = () => {
      if (penId) {
        const activePen = getPenById(penId);
        if (activePen) {
          const { time, unit: newUnit } = formatTimeFromTimestamp(activePen.lastActivated);
          setLastActivated(`${time}`);
          setUnit(newUnit);
        }
      }
    };
    
    const intervalId = setInterval(updateLastActivated, 60000);
    updateLastActivated();

  return () => clearInterval(intervalId);
  }, [penId]);

  return (
    <NumberDisplayList
      headers={[t("locationFeeder.penPopup.activations"), t("locationFeeder.penPopup.lastActivation")]}
      values={[activationsToday, lastActivated]} // TODO: get data
      units={[_activationsToday , unit]}
      gridTemplate="'1fr 1fr'"
    />
  )
}