import { GenericRow } from "../../../Components/Tables/_Tables/useColumnConstructor";
import { Milestone, MixtureComponent } from "../../../Services/Agrisys_GqlDataCaller/FeedDataCalls/GQLCalls/getFeedCurvesCall";

export interface FeedCurveTableData extends GenericRow {
  milestone?: string,
  id: number,
  dsWeight: number,
  totalAmount: number, 
}

export const convertDenseToSparse = (mixture: MixtureComponent[], milestones: Milestone[], componentIds: number[], isPercentileEdit: boolean): Map<number,FeedCurveTableData>  => {
  const sparseData: Map<number,FeedCurveTableData> = new Map();
  [...mixture].sort((mixA, mixB) => mixA.day-mixB.day).forEach((entry) => {
    if (sparseData.has(entry.day)){
      const previousValues = sparseData.get(entry.day)!;
      sparseData.set(entry.day, {...previousValues, [entry.component]: entry.amount, dsWeight: previousValues.dsWeight + (entry.ds*entry.amount), totalAmount: previousValues.totalAmount + entry.amount})
    } else {
      sparseData.set(entry.day, {[entry.component]: entry.amount, id: entry.day, dsWeight: (entry.ds*entry.amount), totalAmount: entry.amount})
    }
  })
  milestones.forEach(({startDay, milestone}) => {
    if (sparseData.has(startDay)) {
      const previousValues = sparseData.get(startDay)!
      sparseData.set(startDay, {...previousValues, milestone})
    }
  })
  sparseData.forEach((row, key) => {
    const componentValue = Object.assign({},...componentIds.map((id) => getValueForComponent(id, row, isPercentileEdit)));
    sparseData.set(key, {...row, ...componentValue, ds: row.totalAmount === 0 ? 0 : Math.round((row.dsWeight/row.totalAmount)*100)/100})
  })
  return sparseData;
}

const getValueForComponent = (id: number, data: FeedCurveTableData, isPercentileEdit: boolean) => {
  if (!isPercentileEdit) {
    return {[String(id)]: data[String(id)]};
  }
  const amount = data[String(id)] as number ?? 0
  if (amount===0) {return {[String(id)]: undefined}}
  return {[String(id)]: Math.round(((amount / data.totalAmount)*100)*100)/100};
}
